import {
  BzDateFns,
  CalculatePaths,
  PaymentMethod,
  PaymentWorkflowOption,
  formatUsc,
  getPaymentWorkflowOptionFromPaymentMethod,
  isNullish,
  toPlural,
} from '@breezy/shared'
import { faFileInvoiceDollar } from '@fortawesome/pro-regular-svg-icons'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { useCompanyHiddenPaymentMethods } from 'src/hooks/useCompanyHiddenPaymentMethods'
import { useQuery } from 'urql'
import { BaseContactCell } from '../../adam-components/ListPage/ContactCell'
import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { DetailsCell } from '../../adam-components/ListPage/DetailsCell'
import {
  ListPageTable,
  ListPageTableColsConfig,
} from '../../adam-components/ListPage/ListPageTable'
import { QuickbooksAccountingSyncInvoiceButton } from '../../components/AccountingIntegration/QuickbooksDesktop/QuickbooksDesktopAccountingSyncButton'
import { QuickbooksSyncInvoiceButton } from '../../components/AccountingIntegration/QuickbooksOnline/QuickbooksSyncButton'
import InvoiceStatusTag from '../../components/Invoicing/InvoiceStatusTag/InvoiceStatusTag'
import { EmDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import { ReadStaleInvoiceInfoForCompanyQuery } from '../../generated/user/graphql'
import { GQL_READ_STALE_INVOICES_FOR_COMPANY } from '../../gql/AccountingStaleInfo.gql'
import { useBusinessUnits } from '../../hooks/fetch/useBusinessUnits'
import { trpc } from '../../hooks/trpc'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import {
  useQuickbooksDesktopEnabled,
  useQuickbooksOnlineEnabled,
} from '../../providers/CompanyFinancialConfigWrapper'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { useStrictContext } from '../../utils/react-utils'
import { InvoiceActionsDropdown } from './InvoiceActionsDropdown'
import {
  InvoiceListingContext,
  InvoiceListingDataContext,
} from './InvoiceListPageContexts'
import { InvoiceListPageTableTeamMemberCell } from './InvoiceListPageTableTeamMemberCell'
import { InvoiceListingInvoice } from './InvoiceListPageTypes'

type TotalsRectangleProps = React.PropsWithChildren<{
  fullyPaid: boolean
  labelClassName?: string
  amountUsc: number
}>

const TotalsRectangleLabel = React.memo<TotalsRectangleProps>(
  ({ children, fullyPaid, labelClassName, amountUsc }) => (
    <>
      <span
        className={classNames(
          'baseline baseline mr-1 text-xs font-semibold',
          {
            'text-bz-gray-700': !fullyPaid,
          },
          labelClassName,
        )}
      >
        {children}
      </span>
      <span className="baseline">{formatUsc(amountUsc)}</span>
    </>
  ),
)

export const InvoiceListTable = React.memo<{ accountManagerEnabled: boolean }>(
  ({ accountManagerEnabled }) => {
    const {
      pagination: { page, pageSize, setPage, setPageSize },
      userMap,
      hasFilters,
      searchTerm,
    } = useStrictContext(InvoiceListingContext)
    const { data, listDataFetching, totalItems } = useStrictContext(
      InvoiceListingDataContext,
    )

    const companyGuid = useExpectedCompanyGuid()
    const tzId = useExpectedCompanyTimeZoneId()

    const isQuickbooksDesktopEnabled = useQuickbooksDesktopEnabled()
    const [
      {
        data: accountingStaleInvoicesData,
        fetching: isAccountingStaleInvoicesFetching,
      },
      refetchAccountingStaleInvoices,
    ] = useQuery({
      query: GQL_READ_STALE_INVOICES_FOR_COMPANY,
      variables: {
        companyGuid,
      },
      pause: !isQuickbooksDesktopEnabled,
    })
    const staleInvoiceMap = useMemo(() => {
      return accountingStaleInvoicesData?.invoicesV2AccountingStale.reduce(
        (acc, curr) => {
          if (curr.invoiceGuid) {
            acc[curr.invoiceGuid] = curr
          }
          return acc
        },
        {} as Record<
          string,
          ReadStaleInvoiceInfoForCompanyQuery['invoicesV2AccountingStale'][number]
        >,
      )
    }, [accountingStaleInvoicesData])

    const isQuickbooksOnlineEnabled = useQuickbooksOnlineEnabled()
    const qboStaleInvoicesQuery = trpc.qbo[
      'finance-app:get-stale-invoices'
    ].useQuery(
      {},
      {
        enabled: isQuickbooksOnlineEnabled,
      },
    )

    const isBusinessUnitsEnabled = useFeatureFlag('businessUnits')
    const businessUnits = useBusinessUnits()
    const shouldShowBusinessUnitsColumns = useMemo(
      () => isBusinessUnitsEnabled && (businessUnits?.length ?? 0) > 0,
      [isBusinessUnitsEnabled, businessUnits],
    )

    const { hiddenPaymentMethods } = useCompanyHiddenPaymentMethods()

    const hiddenPaymentWorkflowOptions = useMemo<
      PaymentWorkflowOption[]
    >(() => {
      if (isNullish(hiddenPaymentMethods)) {
        return []
      }

      const options: PaymentWorkflowOption[] = hiddenPaymentMethods.map(
        paymentMethod =>
          getPaymentWorkflowOptionFromPaymentMethod(paymentMethod),
      )

      if (hiddenPaymentMethods.includes(PaymentMethod.CARD)) {
        options.push('Payment Link')
      }

      return options
    }, [hiddenPaymentMethods])

    const cols = useMemo(() => {
      const cols: ListPageTableColsConfig<InvoiceListingInvoice> = [
        {
          header: 'Details',
          flex: 1,
          minWidth: 300,
          render: invoice => {
            const {
              invoiceGuid,
              accountGuid,
              locationGuid,
              job,
              maintenancePlan,
              displayId,
              contact,
              address,
              fullyPaid,
              totalUsc,
              paidUsc,
              processingPaymentsUsc,
            } = invoice

            const amountDueUsc = totalUsc - paidUsc - processingPaymentsUsc

            const detailItems: React.ReactNode[] = [
              <Link
                blue={false}
                bold={false}
                to={CalculatePaths.accountDetails({ accountGuid })}
              >
                {contact.contactName}
              </Link>,
            ]

            if (job) {
              detailItems.push(
                <Link
                  blue={false}
                  bold={false}
                  to={CalculatePaths.jobDetails({ jobGuid: job.jobGuid })}
                >
                  {job.jobType} (#{job.displayId})
                </Link>,
              )
            }
            if (maintenancePlan) {
              detailItems.push(
                <Link
                  blue={false}
                  bold={false}
                  to={CalculatePaths.maintenancePlanDetails({
                    maintenancePlanGuid: maintenancePlan.maintenancePlanGuid,
                  })}
                >
                  {maintenancePlan.name}
                </Link>,
              )
            }

            if (address) {
              detailItems.push(
                <Link
                  blue={false}
                  bold={false}
                  to={CalculatePaths.locationDetails({ locationGuid })}
                >
                  {address}
                </Link>,
              )
            }

            return (
              <DetailsCell
                dropdown={
                  <InvoiceActionsDropdown
                    {...invoice}
                    hiddenPaymentMethods={hiddenPaymentWorkflowOptions}
                  />
                }
                link={{
                  to: CalculatePaths.invoiceOverview({ invoiceGuid }),
                  label: displayId,
                }}
                detailItems={detailItems}
                footer={
                  <div className="flex flex-row">
                    <DetailChip green={fullyPaid}>
                      <TotalsRectangleLabel
                        fullyPaid={fullyPaid}
                        amountUsc={totalUsc}
                      >
                        Total
                      </TotalsRectangleLabel>

                      {amountDueUsc > 0 && invoice.status !== 'VOIDED' && (
                        <>
                          <TotalsRectangleLabel
                            fullyPaid={fullyPaid}
                            labelClassName="ml-3"
                            amountUsc={amountDueUsc}
                          >
                            Due
                          </TotalsRectangleLabel>
                        </>
                      )}
                      {/* TODO: holding off for design input */}
                      {/* {processingPaymentsUsc > 0 &&
                        invoice.status !== 'VOIDED' && (
                          <>
                            <TotalsRectangleLabel
                              fullyPaid={fullyPaid}
                              labelClassName="ml-3"
                              amountUsc={processingPaymentsUsc}
                            >
                              Due
                            </TotalsRectangleLabel>
                          </>
                        )} */}
                    </DetailChip>
                  </div>
                }
              />
            )
          },
        },
        {
          header: 'Status',
          minWidth: 150,
          cellClassName: 'align-middle',
          render: ({ status }) => <InvoiceStatusTag status={status} />,
        },
        {
          header: 'Contact',
          minWidth: 250,
          render: ({ contact }) => <BaseContactCell {...contact} />,
        },
        {
          header: 'Issue Date',
          minWidth: 150,
          render: ({ issuedAt }) => (
            <>
              {issuedAt ? (
                BzDateFns.format(
                  BzDateFns.parseISO(issuedAt, tzId),
                  'MMM d, yyyy',
                )
              ) : (
                <EmDash />
              )}
            </>
          ),
        },
        {
          header: 'Due Date',
          minWidth: 150,
          render: ({ dueAt, numDaysOverdue, isTerminalStatus, status }) =>
            status === 'VOIDED' ? (
              <EmDash />
            ) : (
              <div>
                <div>{dueAt ?? <EmDash />}</div>
                {!isTerminalStatus && (numDaysOverdue ?? 0) > 0 && (
                  <div className="mt-1 text-bz-gray-700">
                    ({numDaysOverdue} {toPlural(numDaysOverdue ?? 0, 'day')}{' '}
                    past)
                  </div>
                )}
              </div>
            ),
        },
        {
          header: 'Created By',
          minWidth: 120,
          render: ({ createdByUserGuid }) => (
            <InvoiceListPageTableTeamMemberCell
              user={userMap[createdByUserGuid ?? '']}
            />
          ),
        },
      ]

      if (accountManagerEnabled) {
        cols.push({
          header: 'Acct. Manager',
          minWidth: 140,
          render: ({ accountManagerUserGuid }) => {
            return (
              <>
                {accountManagerUserGuid ? (
                  <InvoiceListPageTableTeamMemberCell
                    user={userMap[accountManagerUserGuid ?? '']}
                  />
                ) : (
                  <EmDash />
                )}
              </>
            )
          },
        })
      }

      cols.push({
        header: 'Lead Source',
        minWidth: 150,
        render: ({ leadSource }) => (
          <>{leadSource ? <span>{leadSource.name}</span> : <EmDash />}</>
        ),
      })

      if (shouldShowBusinessUnitsColumns) {
        cols.push({
          header: 'Business Unit',
          minWidth: 150,
          render: ({ businessUnitLink }) => (
            <>
              {businessUnitLink && businessUnitLink.businessUnit ? (
                <span>{businessUnitLink.businessUnit.name}</span>
              ) : (
                <EmDash />
              )}
            </>
          ),
        })
      }

      if (isQuickbooksDesktopEnabled) {
        cols.push({
          header: 'QBD',
          minWidth: 80,
          render: ({ invoiceGuid, displayId }) => {
            return (
              <QuickbooksAccountingSyncInvoiceButton
                invoiceGuid={invoiceGuid}
                invoiceDisplayId={displayId?.toString()}
                isStale={staleInvoiceMap?.[invoiceGuid]?.isStale}
                loading={isAccountingStaleInvoicesFetching}
                onSuccess={() => {
                  refetchAccountingStaleInvoices()
                }}
              />
            )
          },
        })
      }

      if (isQuickbooksOnlineEnabled) {
        cols.push({
          header: 'QBO',
          minWidth: 80,
          render: ({ invoiceGuid, status }) => {
            const button = (
              <QuickbooksSyncInvoiceButton
                placement="left"
                params={{ invoiceGuid }}
                loading={qboStaleInvoicesQuery.isLoading}
                staleInfo={qboStaleInvoicesQuery.data?.[invoiceGuid]}
                onSuccess={qboStaleInvoicesQuery.refetch}
              />
            )
            if (status === 'DRAFT') {
              return (
                <Tooltip title="Cannot sync a draft to Quickbooks">
                  <div>
                    <div className="pointer-events-none opacity-20">
                      {button}
                    </div>
                  </div>
                </Tooltip>
              )
            }
            return button
          },
        })
      }
      return cols
    }, [
      accountManagerEnabled,
      isQuickbooksDesktopEnabled,
      isQuickbooksOnlineEnabled,
      hiddenPaymentWorkflowOptions,
      tzId,
      userMap,
      staleInvoiceMap,
      isAccountingStaleInvoicesFetching,
      refetchAccountingStaleInvoices,
      qboStaleInvoicesQuery.isLoading,
      qboStaleInvoicesQuery.data,
      qboStaleInvoicesQuery.refetch,
      shouldShowBusinessUnitsColumns,
    ])

    return (
      <ListPageTable
        data={data}
        fetching={listDataFetching}
        cols={cols}
        rowKey="invoiceGuid"
        page={page}
        pageSize={pageSize}
        totalItems={totalItems}
        noResultsIcon={faFileInvoiceDollar}
        setPage={setPage}
        setPageSize={setPageSize}
        hasFilters={hasFilters || !!searchTerm}
        itemsDescriptor="invoices"
      />
    )
  },
)
