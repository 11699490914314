import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema } from '../../contracts/_common'
import { AccountType } from '../Accounts/AccountType'
import { Guid, bzOptional } from '../common-schemas'
import { CompanyGuidContainer, ForCompany, ForCompanyUser } from '../Company/Company'
import { Discount, DiscountType } from '../Discounts/DiscountTypes'
import { PhotoGuid } from '../Photos/Photos'

export enum PricebookItemTypeEnum {
  SERVICE = 'SERVICE',
  MATERIAL = 'MATERIAL',
  EQUIPMENT = 'EQUIPMENT',
  LABOR = 'LABOR',
}

export const PositiveReasonableMoneyAmountSchema = z.number().gte(0).lte(1000000000)

export const PricebookItemNameSchema = z.string().min(1).max(300)
export type PricebookItemName = z.infer<typeof PricebookItemNameSchema>
export const PricebookItemDescriptionSchema = z.string()
export type PricebookItemDescription = z.infer<typeof PricebookItemDescriptionSchema>
export const PricebookItemTypeSchema = z.nativeEnum(PricebookItemTypeEnum)
export const PricebookItemImageURLSchema = bzOptional(z.string())
export const PricebookItemCostUSDSchema = z.number()
export type PricebookItemCostUSD = z.infer<typeof PricebookItemCostUSDSchema>
export const PricebookPriceUSDSchema = z.number()
export type PricebookItemPriceUSD = z.infer<typeof PricebookPriceUSDSchema>
export const PricebookItemIsTaxableSchema = z.boolean()
export type PricebookItemIsTaxable = z.infer<typeof PricebookItemIsTaxableSchema>
export const PricebookItemIsActiveSchema = z.boolean()
export type PricebookItemIsActive = z.infer<typeof PricebookItemIsActiveSchema>
export const PricebookItemIsDiscountableSchema = z.boolean()
export type PricebookItemIsDiscountable = z.infer<typeof PricebookItemIsDiscountableSchema>

export const SavePricebookItemDTOSchema = z.object({
  pricebookItemGuid: bzOptional(guidSchema),
  pricebookCategoryGuid: bzOptional(guidSchema),
  companyGuid: guidSchema,
  name: PricebookItemNameSchema,
  description: PricebookItemDescriptionSchema,
  itemType: PricebookItemTypeSchema,
  imageURL: PricebookItemImageURLSchema,
  costUSD: PricebookItemCostUSDSchema,
  priceUSD: PricebookPriceUSDSchema,
  isTaxable: PricebookItemIsTaxableSchema,
  isActive: PricebookItemIsActiveSchema,
  isDiscountable: PricebookItemIsDiscountableSchema,
  qboIncomeAccountId: bzOptional(z.string()),
  sourcePhotoGuid: bzOptional(guidSchema),
  photoGuid: bzOptional(guidSchema),
})

export type SavePricebookItemDto = z.infer<typeof SavePricebookItemDTOSchema>

export const BasePricebookCategorySchema = z.object({
  pricebookCategoryGuid: guidSchema,
  name: z.string(),
  parentCategoryGuid: bzOptional(guidSchema),
  sourcePhotoUrl: bzOptional(z.string()),
  sourcePhotoGuid: bzOptional(guidSchema),
  photoGuid: bzOptional(guidSchema),
  photoUrl: bzOptional(z.string()),
})

export const PricebookCategorySchema = BasePricebookCategorySchema.extend({
  parentPricebookCategory: bzOptional(BasePricebookCategorySchema),
})

export type PricebookCategory = z.infer<typeof PricebookCategorySchema>

export const PricebookItemSchema = z.object({
  pricebookItemGuid: guidSchema,
  companyGuid: guidSchema,
  name: PricebookItemNameSchema,
  description: PricebookItemDescriptionSchema,
  itemType: PricebookItemTypeSchema,
  imageURL: PricebookItemImageURLSchema,
  costUSD: PricebookItemCostUSDSchema,
  priceUSD: PricebookPriceUSDSchema,
  isTaxable: PricebookItemIsTaxableSchema,
  isActive: PricebookItemIsActiveSchema,
  isDiscountable: PricebookItemIsDiscountableSchema,
  pricebookCategoryGuid: bzOptional(guidSchema),
  qboIncomeAccountId: bzOptional(z.string()),
  pricebookCategory: bzOptional(PricebookCategorySchema),
  sourcePhotoUrl: bzOptional(z.string()),
  sourcePhotoGuid: bzOptional(guidSchema),
  photoGuid: bzOptional(guidSchema),
  photoUrl: bzOptional(z.string()),
})

export type PricebookItem = z.infer<typeof PricebookItemSchema>

export type PricebookItemGuid = string

export const pricebookItemGuidContainerSchema = z.object({
  companyGuid: guidSchema,
  pricebookItemGuid: guidSchema,
})
export type PricebookItemGuidContainer = z.infer<typeof pricebookItemGuidContainerSchema>

export type PricebookCategoryGuid = string

export type IPricebookItemWriter = AsyncFn<ForCompanyUser<SavePricebookItemDto>, PricebookItemGuid>
export type PricebookItemDeleter = AsyncFn<ForCompany<PricebookItemGuidContainer>>

export const PricebookTaxRateNameSchema = z.string()
export type PricebookTaxRateName = z.infer<typeof PricebookTaxRateNameSchema>

export const rateMin0Max1Schema = z.number().min(0).max(1)
export const PricebookTaxRateValueSchema = rateMin0Max1Schema
export type PricebookTaxRateValue = z.infer<typeof PricebookTaxRateValueSchema>

export const PricebookTaxRateIsActiveSchema = z.boolean()
export type PricebookTaxRateIsActive = z.infer<typeof PricebookTaxRateIsActiveSchema>

const BasePricebookTaxRateDtoSchema = z.object({
  name: PricebookTaxRateNameSchema,
  rate: PricebookTaxRateValueSchema,
})

export const PricebookTaxRateDtoSchema = BasePricebookTaxRateDtoSchema.extend({
  pricebookTaxRateGuid: guidSchema,
})

export type PricebookTaxRateDto = z.infer<typeof PricebookTaxRateDtoSchema>

export const PricebookTaxRateEnhancementsSchema = z.object({
  accountTypes: bzOptional(z.array(z.nativeEnum(AccountType))),
  zipCodes: bzOptional(z.array(z.string())),
})

export type EnhancedPricebookTaxRateEnhancements = z.infer<typeof PricebookTaxRateEnhancementsSchema>

export type EnhancedPricebookTaxRateDto = PricebookTaxRateDto & EnhancedPricebookTaxRateEnhancements

export const SavePricebookTaxRateDTOSchema = BasePricebookTaxRateDtoSchema.merge(
  PricebookTaxRateEnhancementsSchema,
).extend({
  companyGuid: guidSchema,
  pricebookTaxRateGuid: bzOptional(guidSchema),
  isActive: PricebookTaxRateIsActiveSchema,
  isDefault: bzOptional(z.boolean()),
})

export type PricebookTaxRateGuid = Guid
export type TaxRate = number

export type SavePricebookTaxRateDto = z.infer<typeof SavePricebookTaxRateDTOSchema>

export type IPricebookTaxRateWriter = AsyncFn<ForCompanyUser<SavePricebookTaxRateDto>, PricebookTaxRateGuid>
export type IPricebookTaxRateReader = AsyncFn<PricebookTaxRateGuid, EnhancedPricebookTaxRateDto>

export type PricebookItemsReader = AsyncFn<CompanyGuidContainer, PricebookItem[]>
export type PricebookCategoriesReader = AsyncFn<CompanyGuidContainer, PricebookCategory[]>

export const PricebookDiscountNameSchema = z.string()
export type PricebookDiscountName = z.infer<typeof PricebookDiscountNameSchema>

export const PricebookDiscountDescriptionSchema = z.string().min(1).max(1000000)

export type PricebookDiscountDescription = z.infer<typeof PricebookDiscountDescriptionSchema>

export const BaseSavePricebookDiscountDTOSchema = z.object({
  pricebookDiscountGuid: bzOptional(guidSchema),
  companyGuid: guidSchema,
  name: PricebookDiscountNameSchema,
  description: PricebookDiscountDescriptionSchema,
  isActive: z.boolean(),
  type: z.nativeEnum(DiscountType),
})

export const FlatSavePricebookDiscountDTOSchema = BaseSavePricebookDiscountDTOSchema.extend({
  type: z.literal(DiscountType.FLAT),
  discountAmountUsd: z.number(),
  discountRate: z.null(),
})

export const RateSavePricebookDiscountDTOSchema = BaseSavePricebookDiscountDTOSchema.extend({
  type: z.literal(DiscountType.RATE),
  discountAmountUsd: z.null(),
  discountRate: z.number(),
})

export const SavePricebookDiscountDTOSchema = z.discriminatedUnion('type', [
  FlatSavePricebookDiscountDTOSchema,
  RateSavePricebookDiscountDTOSchema,
])

export type PricebookDiscountGuid = Guid

export type SavePricebookDiscountDto = Discount & {
  pricebookDiscountGuid?: PricebookDiscountGuid
}

export type IPricebookDiscountWriter = AsyncFn<ForCompanyUser<SavePricebookDiscountDto>, PricebookTaxRateGuid>

export const DeletePricebookCategoryDTOSchema = z.object({
  pricebookCategoryGuid: guidSchema,
  companyGuid: guidSchema,
})

export type DeletePricebookCategoryDto = {
  pricebookCategoryGuid: PricebookCategoryGuid
}

export type IPricebookCategoryDeleter = AsyncFn<ForCompany<DeletePricebookCategoryDto>>

export const SavePricebookCategoryDTOSchema = z.object({
  pricebookCategoryGuid: bzOptional(guidSchema),
  companyGuid: guidSchema,
  name: z.string(),
  parentCategoryGuid: bzOptional(guidSchema),
  sourcePhotoGuid: bzOptional(guidSchema),
  photoGuid: bzOptional(guidSchema),
})

export type SavePricebookCategoryDto = z.infer<typeof SavePricebookCategoryDTOSchema>

export type PricebookItemPhoto = {
  pricebookItemGuid: PricebookItemGuid
  photoGuid: PhotoGuid
}

export type PricebookCategoryPhoto = {
  pricebookCategoryGuid: PricebookCategoryGuid
  photoGuid: PhotoGuid
}

export type IPricebookCategoryWriter = AsyncFn<ForCompanyUser<SavePricebookCategoryDto>, PricebookCategoryGuid>

export type PricebookPhotoFallbackWriter = AsyncFn<
  CompanyGuidContainer,
  Record<PricebookItemTypeEnum, PhotoGuid | undefined>
>

export type PricebookItemDefaultPhotoReader = AsyncFn<
  CompanyGuidContainer,
  Record<PricebookItemTypeEnum, PhotoGuid | undefined>
>

export const getDefaultTaxRate = (
  taxRates: EnhancedPricebookTaxRateDto[],
  accountType: AccountType | undefined,
  zipCode: string | undefined,
  defaultPricebookTaxRateGuid: Guid | undefined,
  locationDefaultPricebookTaxRateGuid: Guid | undefined,
): EnhancedPricebookTaxRateDto => {
  let zipAndAccountType: EnhancedPricebookTaxRateDto | undefined = undefined
  let zipOnly: EnhancedPricebookTaxRateDto | undefined = undefined
  let accountTypeOnly: EnhancedPricebookTaxRateDto | undefined = undefined
  let companyDefault: EnhancedPricebookTaxRateDto | undefined = undefined
  let other: EnhancedPricebookTaxRateDto | undefined = undefined

  if (locationDefaultPricebookTaxRateGuid) {
    const locationDefaultTaxRate = taxRates.find(
      taxRate => taxRate.pricebookTaxRateGuid === locationDefaultPricebookTaxRateGuid,
    )
    if (locationDefaultTaxRate) {
      return locationDefaultTaxRate
    }
  }

  // I'm reversing because if there are dupes I want the first one to be the one we use, and I'm going to be overwriting
  // a lot.
  for (const taxRate of taxRates.reverse()) {
    let matchesZip = false
    let matchesAccountType = false

    if (taxRate.pricebookTaxRateGuid === defaultPricebookTaxRateGuid) {
      companyDefault = taxRate
      continue
    }

    if (zipCode && taxRate.zipCodes?.length) {
      if (!taxRate.zipCodes.includes(zipCode)) {
        continue
      }
      matchesZip = true
    }
    if (accountType && taxRate.accountTypes?.length) {
      if (!taxRate.accountTypes.includes(accountType)) {
        continue
      }
      matchesAccountType = true
    }

    if (matchesZip && matchesAccountType) {
      zipAndAccountType = taxRate
    } else if (matchesZip) {
      zipOnly = taxRate
    } else if (matchesAccountType) {
      accountTypeOnly = taxRate
    } else {
      other = taxRate
    }
  }

  return (
    zipAndAccountType ??
    zipOnly ??
    accountTypeOnly ??
    companyDefault ??
    other ?? {
      pricebookTaxRateGuid: '',
      name: '',
      rate: 0,
    }
  )
}
