import {
  Named,
  PricebookTaxRateDto,
  TaxRateSetEventData,
  isNullish,
} from '@breezy/shared'
import { Button } from 'antd'
import { useCallback, useMemo, useState } from 'react'
import PricebookTaxRateSelectionDrawer from '../../components/Invoicing/PricebookTaxRateSelectionDrawer/PricebookTaxRateSelectionDrawer'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useUpsertCompanyBillingProfile } from '../../hooks/useUpsertCompanyBillingProfile'
import { useMessage } from '../../utils/antd-utils'
import { MaintenancePlanBillingProfileViewProps } from './maintenancePlanConfigUtils'

const MaintenancePlanTaxRateManageView = ({
  companyGuid,
  billingProfiles,
  onMutate,
}: MaintenancePlanBillingProfileViewProps) => {
  const message = useMessage()
  const [isSelecting, setIsSelecting] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [justPickedTaxRate, setJustPickedTaxRate] =
    useState<PricebookTaxRateDto>()
  const taxRate = useMemo(() => {
    if (!isNullish(justPickedTaxRate)) return justPickedTaxRate
    if (billingProfiles.length < 1) return undefined
    return billingProfiles[0].maintenancePlanTaxRate
  }, [justPickedTaxRate, billingProfiles])
  const { upsert } = useUpsertCompanyBillingProfile()

  const upsertTaxRate = useCallback(
    async (taxRateGuid?: string) => {
      setIsUploading(true)
      const {
        __typename,
        emailAddress,
        phoneNumber,
        businessAddress,
        defaultTaxRate,
        maintenancePlanTaxRate,
        maintenancePlanServiceItem,
        ...originalRow
      } = billingProfiles[0]
      try {
        await upsert({
          object: {
            ...originalRow,
            companyGuid,
            maintenancePlanTaxRateGuid: taxRateGuid,
            updatedAt: new Date().toISOString(),
          },
        })
        onMutate()
      } catch (e) {
        console.error(`Failed to update Tax Rate`, e)
        message.error(`There was an unknown issue updating the Tax Rate`)
      }
      setIsUploading(false)
    },
    [upsert, companyGuid, billingProfiles, onMutate, message],
  )

  const onSelectTaxRate = useCallback(
    async (t: Named<TaxRateSetEventData>) => {
      setIsSelecting(false)
      setJustPickedTaxRate({
        pricebookTaxRateGuid: t.taxRateGuid,
        name: t.name,
        rate: t.rate,
      })
      await upsertTaxRate(t.taxRateGuid)
    },
    [upsertTaxRate],
  )

  const clearTaxRate = useCallback(async () => {
    setJustPickedTaxRate(undefined)
    await upsertTaxRate(undefined)
  }, [upsertTaxRate])

  return (
    <>
      <PricebookTaxRateSelectionDrawer
        title="Select Tax Rate"
        onSubmit={onSelectTaxRate}
        onCancel={() => setIsSelecting(false)}
        open={isSelecting}
      />
      <div className="col w-full px-2 pt-2">
        <div className="row flex-between ">
          <div className="col">
            <h3 className="grey9">Tax Rate</h3>
          </div>
          <div className="row gap-x-2">
            {!!billingProfiles?.[0]?.maintenancePlanTaxRate && (
              <Button onClick={clearTaxRate}>Clear</Button>
            )}
            <Button type="primary" onClick={() => setIsSelecting(true)}>
              Select Tax Rate
            </Button>
          </div>
        </div>
        {!isUploading && (
          <p className="grey9 w-full text-center text-[20px] font-semibold">
            {taxRate
              ? `${taxRate.name} - ${taxRate.rate.toLocaleString(undefined, {
                  style: 'percent',
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 4,
                })}`
              : 'None Set'}
          </p>
        )}
        {isUploading && <LoadingSpinner />}
      </div>
    </>
  )
}

export default MaintenancePlanTaxRateManageView
