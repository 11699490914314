import {
  isNullish,
  IsoDateString,
  RoleId,
  toRoleDisplayName,
} from '@breezy/shared'
import React from 'react'
import PersonAvatar from 'src/components/PersonAvatar/PersonAvatar'

type TeamMemberCellProps = {
  user?: {
    userGuid: string
    firstName: string
    lastName: string
    userRoles: {
      role: RoleId
    }[]
    deactivatedAt?: IsoDateString
    avatarColor?: string
    avatarImageUrl?: string
  }
}

export const TeamMemberCell = React.memo<TeamMemberCellProps>(({ user }) => {
  if (isNullish(user)) {
    return null
  }

  return (
    <PersonAvatar
      userGuid={user.userGuid}
      avatarAltShortString={user.firstName[0] + user.lastName[0]}
      avatarAltColor={user.avatarColor}
      avatarImgSrc={user.avatarImageUrl}
      deactivatedAt={user.deactivatedAt}
      popoverContent={
        <>
          <div className="font-semibold">
            {user.firstName} {user.lastName}
          </div>
          {user.userRoles.length > 0 && (
            <div className="text-bz-gray-900">
              {toRoleDisplayName(user.userRoles[0].role)}
            </div>
          )}
        </>
      }
    />
  )
})
