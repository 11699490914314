import {
  AppointmentGuid,
  BzDateFns,
  filterableJobClasses,
  formatJobClass,
  JobClass,
  nextGuid,
  R,
  simplePrioritiesForJobClass,
} from '@breezy/shared'
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Draggable } from '@mobiscroll/react'
import { Badge, Button, Select } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useLocalStorage, useSessionStorage } from 'react-use'
import {
  BaseLoadingSpinner,
  LoadingSpinner,
} from '../../../components/LoadingSpinner'
import LocalStorageMultiSelectFilterButton from '../../../components/LocalStorageMultiSelectFilterButton/LocalStorageMultiSelectFilterButton'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import tailwindConfig from '../../../tailwind.config'
import { useStrictContext } from '../../../utils/react-utils'
import { AppointmentCard } from '../Cards/AssignmentCard'
import { useSchedulePendingChanges } from '../PendingChanges/SchedulePendingChangesContext'
import {
  NewAssignmentCalendarEvent,
  SchedulePageContext,
} from '../scheduleUtils'
import {
  EnhancedUnassignedAppointment,
  sortFilterByFilterType,
  UnscheduledSortFilterType,
} from './toScheduleUtils'

export const ToSchedulePane = React.memo(() => {
  const [collapsed, setCollapsed] = useLocalStorage('toSchedulePaneOpen', false)

  const {
    pendingChanges,
    unassignedAppointments: appointments,
    allAppointments,
  } = useSchedulePendingChanges()

  const [sortFilterType, setSortFilterType] =
    useSessionStorage<UnscheduledSortFilterType>(
      'unscheduledSortFilterType',
      UnscheduledSortFilterType.ARRIVAL_WINDOW,
    )

  const [shownJobClasses, setShownJobClasses] =
    useState<Partial<Record<JobClass, true>>>()

  const onJobClassFilterChanged = useCallback((values?: Set<JobClass>) => {
    if (!values) {
      setShownJobClasses(undefined)
      return
    }
    const jobClasses: Partial<Record<JobClass, true>> = {}
    for (const jobClass of values) {
      jobClasses[jobClass] = true
    }
    setShownJobClasses(jobClasses)
  }, [])

  const unscheduledAppointmentsWithNewEventsMap = useMemo(() => {
    const map: Record<string, true> = {}
    for (const event of R.values(pendingChanges.newEventMap)) {
      if (event.appointmentGuid) {
        map[event.appointmentGuid] = true
      }
    }
    return map
  }, [pendingChanges.newEventMap])

  const enhancedAppointments = useMemo(() => {
    const seenAppointments = new Set<AppointmentGuid>(
      // If the appointment exists in the new event map, then we must have dragged it from "To Schedule" already
      R.keys(unscheduledAppointmentsWithNewEventsMap),
    )
    const enhancedAppointments: EnhancedUnassignedAppointment[] = []
    for (const appointment of appointments ?? []) {
      if (seenAppointments.has(appointment.appointmentGuid)) {
        continue
      }

      const { jobClass, durationMinutes } = appointment.job.jobType
      if (shownJobClasses && !shownJobClasses[jobClass]) {
        continue
      }

      enhancedAppointments.push({
        ...appointment,
        priority: simplePrioritiesForJobClass[jobClass],
        estimatedDurationMinutes: durationMinutes,
      })
      seenAppointments.add(appointment.appointmentGuid)
    }

    // Find any appointments that had assignments but all of them were canceled
    for (const appointment of allAppointments) {
      // We don't want to double-add, which could happen since the appointment map also has the unassigned
      // appointments.
      if (seenAppointments.has(appointment.appointmentGuid)) {
        continue
      }
      // If there are no assignments, then there are no assignments. If there are assignments, then if all of them are
      // deleted then it's the same as no assignments.
      let hasScheduledAssignment = false
      for (const assignment of appointment.assignments ?? []) {
        if (!pendingChanges.deletedEventMap[assignment.assignmentGuid]) {
          hasScheduledAssignment = true
          break
        }
      }
      // If there is a scheduled assignment, we don't want to show it here.
      if (hasScheduledAssignment) {
        continue
      }

      const { jobClass, durationMinutes } = appointment.job.jobType
      if (shownJobClasses && !shownJobClasses[jobClass]) {
        continue
      }

      enhancedAppointments.push({
        ...appointment,
        priority: simplePrioritiesForJobClass[jobClass],
        estimatedDurationMinutes: durationMinutes,
      })
      seenAppointments.add(appointment.appointmentGuid)
    }

    return R.sortWith(
      sortFilterByFilterType[sortFilterType],
      enhancedAppointments,
    )
  }, [
    unscheduledAppointmentsWithNewEventsMap,
    sortFilterType,
    appointments,
    shownJobClasses,
    allAppointments,
    pendingChanges.deletedEventMap,
  ])

  const loading = !enhancedAppointments || !appointments

  return (
    <div
      className={classNames(
        'schedule-panel relative flex min-h-0 flex-col overflow-hidden transition-all',
        collapsed
          ? 'min-w-[80px]  max-w-[80px]'
          : 'min-w-[380px] max-w-[380px]',
      )}
    >
      <div
        className={classNames(
          'flex items-center px-6 pt-6',
          collapsed ? 'flex-col' : 'flex-row',
        )}
      >
        <Button
          shape="circle"
          type="text"
          icon={
            <FontAwesomeIcon
              icon={collapsed ? faChevronLeft : faChevronRight}
            />
          }
          onClick={() => setCollapsed(!collapsed)}
        />

        <div
          className={classNames(
            'ml-1 flex origin-center flex-row items-center',
            {
              'mt-14 rotate-90 whitespace-nowrap': collapsed,
            },
          )}
        >
          <div className="mr-2 text-base">To Schedule</div>
          {loading
            ? collapsed && <BaseLoadingSpinner size={3} />
            : appointments.length > 0 && (
                <Badge
                  color={tailwindConfig.theme.extend.colors['bz-orange']['600']}
                  count={appointments?.length ?? 0}
                  className={classNames({ '-rotate-90': collapsed })}
                />
              )}
        </div>
      </div>
      {!collapsed &&
        (loading ? (
          <div className="relative flex-1">
            <LoadingSpinner className="absolute inset-0" />
          </div>
        ) : (
          <>
            <div className="mt-2 flex flex-row items-center px-6">
              <div className="ml-4 font-semibold">Sort By:</div>
              <Select
                className="mx-2"
                popupMatchSelectWidth={false}
                value={sortFilterType}
                onChange={setSortFilterType}
                options={R.values(UnscheduledSortFilterType).map(value => ({
                  label: value,
                  value,
                }))}
              />
              <LocalStorageMultiSelectFilterButton
                title="Filter by Job Class"
                options={[...filterableJobClasses]}
                displayNameSelector={jobClass => formatJobClass(jobClass)}
                localStorageKey="unscheduledJobClassesFilter"
                onChanged={onJobClassFilterChanged}
              />
            </div>

            <ToSchedulePaneContent appointments={enhancedAppointments} />
          </>
        ))}
    </div>
  )
})

type ToScheduleCardProps = {
  appointment: EnhancedUnassignedAppointment
}

const ToScheduleCard = React.memo<ToScheduleCardProps>(({ appointment }) => {
  const tzId = useExpectedCompanyTimeZoneId()
  const { setSelectedAppointmentGuid } = useStrictContext(SchedulePageContext)

  // This seems like very weird react. Normally you'd use a ref for this. But this is coming straight from the docs.
  // https://demo.mobiscroll.com/eventcalendar/external-drag-drop-schedule-unschedule
  const [draggable, setDraggable] = useState<HTMLDivElement>()

  const setDragElm = useCallback((elm: HTMLDivElement) => {
    setDraggable(elm)
  }, [])

  const dragEvent = useMemo<NewAssignmentCalendarEvent>(() => {
    const assignmentGuid = nextGuid()

    const start = BzDateFns.startOfDay(BzDateFns.now(tzId))
    const end = BzDateFns.addMinutes(
      start,
      appointment.estimatedDurationMinutes,
    )
    const endStr = BzDateFns.format(end, 'HH:mm')
    return {
      id: assignmentGuid,
      start: '00:00',
      end: endStr,
      assignmentGuid,
      appointmentGuid: appointment.appointmentGuid,
      appointmentInfo: appointment,
      jobClass: appointment.job.jobType.jobClass,
      accountDisplayName: appointment.job.account.accountDisplayName,
    }
  }, [appointment, tzId])

  const canceled = !!appointment.cancellationStatus?.canceled

  return (
    <div
      className="select-none bg-white"
      ref={setDragElm}
      onClick={() => setSelectedAppointmentGuid(appointment.appointmentGuid)}
    >
      <AppointmentCard
        key={appointment.appointmentGuid}
        appointment={appointment}
        className={canceled ? 'cursor-not-allowed' : 'cursor-grab'}
      />
      {!canceled && <Draggable dragData={dragEvent} element={draggable} />}
    </div>
  )
})

type ToSchedulePaneContentProps = {
  appointments: EnhancedUnassignedAppointment[]
}

const ToSchedulePaneContent = React.memo<ToSchedulePaneContentProps>(
  ({ appointments }) => {
    return (
      <div className="overflow-auto px-6 pb-6 *:mt-3">
        {appointments.map(appointment => (
          <ToScheduleCard
            key={appointment.appointmentGuid}
            appointment={appointment}
          />
        ))}
      </div>
    )
  },
)
