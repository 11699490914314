import { bzOptional } from '@breezy/shared'
import { z } from 'zod'

export const createAccountLocationFormSchema = z.object({
  // displayName: z.string(),
  addressLineOne: z.string(),
  addressLineTwo: bzOptional(z.string()),
  addressCity: z.string(),
  addressState: z.string(),
  addressZipCode: z.string(),
  estimatedSquareFootage: bzOptional(z.number()),
  estimatedBuildDate: bzOptional(z.date()),
  defaultPricebookTaxRateGuid: bzOptional(z.string()),
})

export type CreateAccountLocationFormSchema = z.infer<
  typeof createAccountLocationFormSchema
>
