import { gql } from '../generated'

export const UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_FULLY_PAID_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCompanyConfigAutoSyncInvoiceOnFullyPaid(
      $object: AccCompanyConfigInsertInput!
    ) {
      insertAccCompanyConfigOne(
        object: $object
        onConflict: {
          constraint: acc_company_config_company_guid_accounting_integration_type_key
          updateColumns: [autosyncInvoiceOnFullyPaid, updatedAt]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_ISSUED_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCompanyConfigAutoSyncInvoiceOnIssued(
      $object: AccCompanyConfigInsertInput!
    ) {
      insertAccCompanyConfigOne(
        object: $object
        onConflict: {
          constraint: acc_company_config_company_guid_accounting_integration_type_key
          updateColumns: [autosyncInvoiceOnIssued, updatedAt]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_PAYMENT_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCompanyConfigAutoSyncInvoiceOnPayment(
      $object: AccCompanyConfigInsertInput!
    ) {
      insertAccCompanyConfigOne(
        object: $object
        onConflict: {
          constraint: acc_company_config_company_guid_accounting_integration_type_key
          updateColumns: [autosyncInvoiceOnPayment, updatedAt]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_INVOICE_ON_VOIDED_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCompanyConfigAutoSyncInvoiceOnVoided(
      $object: AccCompanyConfigInsertInput!
    ) {
      insertAccCompanyConfigOne(
        object: $object
        onConflict: {
          constraint: acc_company_config_company_guid_accounting_integration_type_key
          updateColumns: [autosyncInvoiceOnVoided, updatedAt]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_ACCOUNTING_COMPANY_CONFIG_AUTO_SYNC_PAYOUTS_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCompanyConfigAutoSyncPayouts(
      $object: AccCompanyConfigInsertInput!
    ) {
      insertAccCompanyConfigOne(
        object: $object
        onConflict: {
          constraint: acc_company_config_company_guid_accounting_integration_type_key
          updateColumns: [autosyncPayouts, updatedAt]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_ACCOUNTING_COMPANY_CONFIG_SYNC_BUSINESS_UNITS_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCompanyConfigSyncBusinessUnits(
      $object: AccCompanyConfigInsertInput!
    ) {
      insertAccCompanyConfigOne(
        object: $object
        onConflict: {
          constraint: acc_company_config_company_guid_accounting_integration_type_key
          updateColumns: [syncBusinessUnits, updatedAt]
        }
      ) {
        __typename
      }
    }
  `)

export const UPSERT_ACCOUNTING_COMPANY_CONFIG_SYNC_TAXES_FULL_INTEGRATION_MUTATION =
  gql(/* GraphQL */ `
    mutation UpsertAccountingCompanyConfigSyncTaxesFullIntegration(
      $object: AccCompanyConfigInsertInput!
    ) {
      insertAccCompanyConfigOne(
        object: $object
        onConflict: {
          constraint: acc_company_config_company_guid_accounting_integration_type_key
          updateColumns: [syncTaxesFullIntegration, updatedAt]
        }
      ) {
        __typename
      }
    }
  `)

export const READ_ACCOUNTING_COMPANY_CONFIG_QUERY = gql(/* GraphQL */ `
  query ReadAccountingCompanyConfigQuery($companyGuid: uuid!) {
    accCompanyConfigByPk(companyGuid: $companyGuid) {
      autosyncInvoiceOnFullyPaid
      autosyncInvoiceOnIssued
      autosyncInvoiceOnPayment
      autosyncInvoiceOnVoided
      autosyncPayouts
      syncBusinessUnits
      syncTaxesFullIntegration
    }
  }
`)
