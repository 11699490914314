import {
  DEFAULT_SCHEDULING_CAPABILITY,
  InviteUserDtoSchema,
  PHONE_NUMBER_TYPES,
  PermissionV2,
  PhoneNumberType,
  PhotoRecord,
  R,
  RoleId,
  SCHEDULING_CAPABILITY_DISPLAY_INFO,
  SchedulingCapability,
  USER_AVATAR_COLOR_DEFAULT,
  User,
  UserAvatarColors,
  bzExpect,
  isNullish,
  noOp,
  phoneUtils,
  prettifyPhoneNumberType,
  toPhone,
  upperPascalCaseToHumanReadable,
} from '@breezy/shared'
import { faCrown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd'
import { useForm } from 'antd/lib/form/Form'
import cn from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { OnsiteBasicModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { trpc } from '../../hooks/trpc'
import useAppNavigation from '../../hooks/useAppNav'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'
import { usePrincipalUser } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { BehindFeatureFlag } from '../BehindFeatureFlag'
import DangerConfirmModal from '../DangerConfirmModal/DangerConfirmModal'
import PermissionControls from '../PermissionControls/PermissionControls'
import { usePermissionControls } from '../PermissionControls/usePermissionControls'
import { Authorized } from '../Permissions/Authorized/Authorized'
import {
  AsyncPhotoUploadWithThumbnail,
  OnPhotoUploadChange,
  usePhotoUpload,
} from '../Upload/AsyncPhotoUpload'
import PhotoCropModal, {
  usePhotoCrop,
} from '../Upload/PhotoCropModal/PhotoCropModal'
import { AsyncUploadWrapper, useSynchronousUpload } from '../Upload/Upload'

type FormSchema = {
  firstName: string
  lastName: string
  phoneNumber: string
  phoneNumberType: PhoneNumberType
  schedulingCapability: SchedulingCapability
  emailAddress: string
  roles: RoleId[]
  color?: string
}

const { Option } = Select

type SettingsCreateEditUserPageProps = {
  user?: User & { avatar?: { photoGuid: string; cdnUrl: string } }
  savedUser?: () => void
}

const SettingsCreateEditUserForm = React.memo<SettingsCreateEditUserPageProps>(
  ({ user, savedUser }) => {
    const message = useMessage()
    const appNav = useAppNavigation()
    const {
      pendingPermissionsV2,
      toggleSuperAdmin,
      isSuperAdmin,
      isDirty: isDirtyPermissions,
    } = usePermissionControls()
    const [form] = useForm<FormSchema>()
    const companyGuid =
      usePrincipalUser().expectCompanyUserPrincipal().company.companyGuid
    const utils = trpc.useUtils()
    const inviteUserMutation = trpc.user['users:invite-user'].useMutation()
    const updateUserMutation = trpc.user['users:update'].useMutation()
    const deleteUserMutation = trpc.user['users:deactivate'].useMutation()
    const updateUserPasswordMutation =
      trpc.user['users:update-password'].useMutation()
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
    const [isFormDirty, setIsFormDirty] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [password, setPassword] = useState('')

    const saveNewPassword = useCallback(() => {
      updateUserPasswordMutation.mutate(
        {
          userGuid: bzExpect(user?.userGuid),
          password,
        },
        {
          onSuccess: () => {
            message.success('Password updated')
            setPassword('')
          },
          onError: () => {
            message.error('An error occurred updating password')
          },
        },
      )
    }, [message, password, updateUserPasswordMutation, user?.userGuid])

    const formatName = (n: string) => {
      return n
        .toLowerCase()
        .split('_')
        .join(' ')
        .replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    }

    const [sourcePhoto, setSourcePhoto] = useState<
      | {
          photoGuid: string
          cdnUrl: string
        }
      | undefined
    >(user?.avatar)

    const [photo, setPhoto] = useState<
      | {
          photoGuid: string
          cdnUrl: string
        }
      | undefined
    >(user?.avatar)

    const onCropEdit = useCallback(
      (record: PhotoRecord) => {
        if (isNullish(photo)) {
          setPhoto(record)
        } else {
          setPhoto({
            ...photo,
            photoGuid: record.photoGuid,
            cdnUrl: record.cdnUrl,
          })
        }
      },
      [photo],
    )

    const onRevertToDefaultPhoto = useCallback(() => {
      setPhoto(sourcePhoto)
    }, [sourcePhoto])

    const onRemovePhoto = useCallback(() => {
      setSourcePhoto(undefined)
      setPhoto(undefined)
    }, [])

    const onUploadChange: OnPhotoUploadChange = useSynchronousUpload(record => {
      setSourcePhoto(record)
      setPhoto(record)
    })

    const { cropModalVisible, openCropModal, photoCropModalProps } =
      usePhotoCrop({
        sourcePhotoUrl: sourcePhoto?.cdnUrl,
        onCropEdit,
        onClose: noOp,
        mode: 'embedded',
      })

    const { loading: isPhotoUploading, ...useUploadProps } =
      usePhotoUpload(onUploadChange)

    const isDirty = useMemo(() => {
      const dirtyPhoto = user?.avatarPhotoGuid !== photo?.photoGuid

      return isFormDirty || isDirtyPermissions || dirtyPhoto
    }, [
      isFormDirty,
      isDirtyPermissions,
      user?.avatarPhotoGuid,
      photo?.photoGuid,
    ])

    const shouldBlock = useCallback<BlockerFunction>(() => {
      return isDirty && !isSubmitting
    }, [isDirty, isSubmitting])

    const blocker = useBlocker('createEditUserForm', shouldBlock)

    const inviteUserViaApi = useCallback(
      (values: FormSchema) => {
        inviteUserMutation.mutate(
          {
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            phoneNumberType: values.phoneNumberType,
            schedulingCapability: values.schedulingCapability,
            emailAddress: values.emailAddress,
            roles: values.roles,
            permissions: [],
            permissionsV2: pendingPermissionsV2,
            // use the company id of the current user
            companyGuid,
          },
          {
            onSuccess: async () => {
              await utils.user['users:get'].invalidate()
              savedUser && savedUser()
              appNav.navigateToTeamPage()
            },
            onError: () => {
              setIsSubmitting(false)
              message.error('An error occurred saving user')
            },
          },
        )
      },
      [
        savedUser,
        inviteUserMutation,
        companyGuid,
        appNav,
        utils,
        pendingPermissionsV2,
        message,
      ],
    )

    const updateUserViaApi = useCallback(
      (values: FormSchema) => {
        if (!user) {
          return
        }

        updateUserMutation.mutate(
          {
            userGuid: user.userGuid,
            firstName: values.firstName,
            lastName: values.lastName,
            phoneNumber: values.phoneNumber,
            phoneNumberType: values.phoneNumberType,
            schedulingCapability: values.schedulingCapability,
            roles: values.roles,
            permissions: [],
            permissionsV2: pendingPermissionsV2,
            // use the company id of the current user
            companyGuid,
            color: values.color,
            avatarPhotoGuid: photo?.photoGuid ?? null,
          },
          {
            onSuccess: async () => {
              await utils.user['users:get-by-guid'].invalidate({
                userGuid: user.userGuid,
              })
              await utils.user['users:get'].invalidate()
              savedUser && savedUser()
              appNav.navigateToTeamPage()
            },
            onError: err => {
              setIsSubmitting(false)
              console.error(err)
            },
          },
        )
      },
      [
        user,
        updateUserMutation,
        pendingPermissionsV2,
        companyGuid,
        photo?.photoGuid,
        utils.user,
        savedUser,
        appNav,
      ],
    )

    const deactivateUser = useCallback(() => {
      if (!user) {
        return
      }
      setIsSubmitting(true)

      deleteUserMutation.mutate(
        {
          email: user.emailAddress,
        },
        {
          onSuccess: async () => {
            await utils.user['users:get-by-guid'].invalidate({
              userGuid: user.userGuid,
            })
            await utils.user['users:get'].invalidate()
            message.success('The user has been removed from your account.')
            appNav.navigateToTeamPage()
          },
          onError: () => {
            setIsSubmitting(false)
            message.error('An error occurred saving user')
          },
        },
      )
    }, [user, deleteUserMutation, appNav, utils, message])

    const initialValues = useMemo<Partial<FormSchema>>(
      () => ({
        firstName: user?.firstName,
        lastName: user?.lastName,
        emailAddress: user?.emailAddress,
        phoneNumber: user?.phoneNumbers[0]
          ? toPhone(user.phoneNumbers[0]).number
          : '',
        phoneNumberType: user?.phoneNumbers[0]?.type ?? 'MOBILE',
        schedulingCapability:
          user?.schedulingCapability ?? DEFAULT_SCHEDULING_CAPABILITY,
        roles: user?.roles.map(r => r.role),
        color: user?.color ?? USER_AVATAR_COLOR_DEFAULT,
      }),
      [user],
    )

    const changedValues = useCallback(
      (_: unknown, values: FormSchema) => {
        if (!R.equals(values, initialValues)) {
          setIsFormDirty(true)
        } else {
          setIsFormDirty(false)
        }
      },
      [initialValues],
    )

    return (
      <Form
        form={form}
        layout="vertical"
        onFinish={user ? updateUserViaApi : inviteUserViaApi}
        initialValues={initialValues}
        onValuesChange={changedValues}
        validateTrigger="onBlur"
        className="flex w-full flex-col pb-9"
      >
        <div className="mb-6 flex flex-row items-center justify-end">
          <div className="space-x-2">
            {isDirty && (
              <Button
                onClick={() => {
                  setIsSubmitting(true)
                  appNav.navigateToTeamPage()
                }}
                disabled={
                  inviteUserMutation.isLoading || updateUserMutation.isLoading
                }
              >
                Cancel
              </Button>
            )}
            {user && (
              <>
                <Button
                  type="primary"
                  danger
                  className="bg-bz-red-200 font-semibold text-bz-red-900"
                  onClick={() => setIsConfirmModalOpen(true)}
                  disabled={updateUserMutation.isLoading}
                >
                  Remove Team Member
                </Button>
                <DangerConfirmModal
                  title="Remove Team Member"
                  open={isConfirmModalOpen}
                  onOk={deactivateUser}
                  onCancel={() => setIsConfirmModalOpen(false)}
                  promptText={
                    <div className="text-start">
                      <span className="font-semibold">
                        Are you sure you want to remove this team member?
                      </span>{' '}
                      This user will have their access immediately removed and
                      can no longer access your Breezy account.
                    </div>
                  }
                />
              </>
            )}
            <Button
              type="primary"
              htmlType="submit"
              className="font-semibold"
              onClick={() => setIsSubmitting(true)}
              disabled={
                inviteUserMutation.isLoading ||
                updateUserMutation.isLoading ||
                !isDirty
              }
            >
              {user ? 'Save changes' : 'Save & Invite'}
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-6">
          <div className="col-span-3 lg:col-span-1">
            <h3 className="font-semibold text-bz-gray-900">General Info</h3>
            <div className="text-bz-gray-700">
              General information and account information for this team member.
            </div>
          </div>

          <div className="col-span-4 lg:col-span-3">
            <BehindFeatureFlag
              enabledFeatureFlag="user-avatars"
              render={
                <div className="mb-6 flex flex-row items-center gap-3">
                  <AsyncPhotoUploadWithThumbnail
                    thumbnail={{
                      width: 104,
                      height: 104,
                    }}
                    sourcePhotoUrl={sourcePhoto?.cdnUrl ?? ''}
                    photoUrl={photo?.cdnUrl ?? ''}
                    onPhotoUploadChange={onUploadChange}
                    onOpenCrop={openCropModal}
                    onRevertToDefaultPhoto={onRevertToDefaultPhoto}
                    onRemovePhoto={onRemovePhoto}
                    loading={isPhotoUploading}
                  />

                  <div className="mb-6 flex flex-col gap-1">
                    <span className="text-sm font-semibold">Avatar Image</span>

                    <span className="text-sm">
                      Square aspect ratio, .PNG and .JPG file types.
                    </span>

                    <AsyncUploadWrapper accept="image/*" {...useUploadProps}>
                      <Typography.Link>Choose Image</Typography.Link>
                    </AsyncUploadWrapper>
                  </div>

                  <OnsiteBasicModal open={cropModalVisible}>
                    {cropModalVisible && (
                      <PhotoCropModal {...photoCropModalProps} />
                    )}
                  </OnsiteBasicModal>
                </div>
              }
            />

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (!value) {
                          throw new Error('First name is required')
                        }

                        const result =
                          InviteUserDtoSchema.shape.firstName.safeParse(value)
                        if (!result.success) {
                          throw new Error(
                            result.error.issues[0]?.message ||
                              result.error.message,
                          )
                        }
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (!value) {
                          throw new Error('Last name is required')
                        }

                        const result =
                          InviteUserDtoSchema.shape.lastName.safeParse(value)
                        if (!result.success) {
                          throw new Error(
                            result.error.issues[0]?.message ||
                              result.error.message,
                          )
                        }
                      },
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="emailAddress"
                  label="Email Address"
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (!value) {
                          return Promise.reject(
                            new Error(
                              'Email addresses are required for all team members',
                            ),
                          )
                        }
                        const result =
                          InviteUserDtoSchema.shape.emailAddress.safeParse(
                            value,
                          )
                        if (!result.success) {
                          throw new Error(
                            result.error.issues[0]?.message ||
                              result.error.message,
                          )
                        }
                      },
                    },
                  ]}
                >
                  <Input disabled={!!user} />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="phoneNumber"
                  label="Phone"
                  rules={[
                    {
                      required: true,
                      validator: async (_, value) => {
                        if (!value) {
                          throw new Error('Phone is required')
                        }

                        const result =
                          InviteUserDtoSchema.shape.phoneNumber.safeParse(value)
                        if (!result.success) {
                          throw new Error(
                            result.error.issues[0]?.message ||
                              result.error.message,
                          )
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    onBlur={e => {
                      form.setFieldsValue({
                        phoneNumber: phoneUtils.tryFormat(e.target.value),
                      })
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item name="phoneNumberType" label="Type">
                  <Select
                    options={PHONE_NUMBER_TYPES.map(value => ({
                      value,
                      label: prettifyPhoneNumberType(value),
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="roles"
                  label="Roles"
                  className="mb-2"
                  rules={[
                    {
                      required: true,
                      message: 'At least 1 role for each Team Member',
                    },
                  ]}
                >
                  <Select placeholder="Select Roles" allowClear mode="multiple">
                    {Object.values(RoleId).map(name => {
                      return (
                        <Option key={name} value={name}>
                          {formatName(name)}
                        </Option>
                      )
                    })}
                  </Select>
                </Form.Item>
                <div className="text-xs text-bz-gray-700">
                  Roles help identify the type of work this user does within
                  your company.
                </div>
              </Col>
            </Row>
            <Row className="mt-4" gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="schedulingCapability"
                  label="Scheduling Capability"
                  className="mb-2"
                >
                  <Select
                    options={Object.entries(
                      SCHEDULING_CAPABILITY_DISPLAY_INFO,
                    ).map(([capability, { displayName, details }]) => ({
                      value: capability,
                      label: `${displayName}`,
                    }))}
                  />
                </Form.Item>
                <div className="text-xs text-bz-gray-700">
                  The <i>Scheduling Capability</i> field helps the system to
                  display and suggest relevant team members on the schedule.
                </div>
              </Col>

              <BehindFeatureFlag
                enabledFeatureFlag="user-avatars"
                render={
                  <Col span={12}>
                    <Form.Item name="color" label="Schedule Color">
                      <Select
                        options={Object.entries(UserAvatarColors).map(
                          ([color, hexValue]) => ({
                            label: (
                              <div className="flex flex-row items-center gap-2">
                                <div
                                  className="h-[16px] w-[16px] rounded-full"
                                  style={{ background: hexValue }}
                                />

                                <span>
                                  {upperPascalCaseToHumanReadable(color)}
                                  {hexValue === USER_AVATAR_COLOR_DEFAULT
                                    ? ' (Default)'
                                    : ''}
                                </span>
                              </div>
                            ),
                            value: hexValue,
                          }),
                        )}
                      />
                    </Form.Item>
                  </Col>
                }
              />
            </Row>
          </div>
        </div>
        <Divider />
        <div
          className={cn(
            'grid flex-1 grid-cols-4 gap-6',
            !isSuperAdmin ? 'pb-9' : '',
          )}
        >
          <div className="col-span-3 lg:col-span-1">
            <h3 className="font-semibold text-bz-gray-900">Permissions</h3>
            <div className="text-bz-gray-700">
              Choose what team members can view and manage in your Breezy
              account.
            </div>
            <div className="mt-6 flex flex-col rounded border border-solid border-bz-gray-500 p-4">
              <div className="flex flex-row items-center gap-2">
                <Switch checked={isSuperAdmin} onChange={toggleSuperAdmin} />
                <div>Make Super Admin</div>
              </div>
              <div className="mt-2 text-bz-gray-700">
                Super admins have full access and permissions to everything in
                your Breezy account.
              </div>
            </div>
          </div>
          <div className="col-span-4 lg:col-span-3">
            {isSuperAdmin ? <SuperAdminInfo /> : <PermissionControls />}
          </div>
        </div>
        {user && (
          <Authorized to={PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM}>
            <>
              <Divider />
              <div
                className={cn(
                  'grid flex-1 grid-cols-4 gap-6',
                  !isSuperAdmin ? 'pb-9' : '',
                )}
              >
                <div className="col-span-3 lg:col-span-1">
                  <h3 className="font-semibold text-bz-gray-900">
                    Password Management
                  </h3>
                  <div className="text-bz-gray-700">
                    Reset the password for this user.
                  </div>
                  <div className="col-span-4 lg:col-span-3">
                    <div className="mt-2 flex flex-row gap-2">
                      <Input.Password
                        value={password}
                        placeholder="********"
                        onChange={e => setPassword(e.target.value)}
                      />
                      <Button
                        onClick={() => saveNewPassword()}
                        disabled={password === ''}
                        loading={updateUserPasswordMutation.isLoading}
                        type="default"
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Authorized>
        )}
        {blocker.state === 'blocked' && (
          <CloseConfirmModal
            open
            onCancel={blocker.reset}
            onConfirm={blocker.proceed}
          />
        )}
      </Form>
    )
  },
)

const SuperAdminInfo = () => {
  return (
    <div className="flex h-full flex-col items-center justify-center rounded bg-daybreak-blue-100 p-8">
      <div className="rounded-full bg-daybreak-blue-200 p-3">
        <FontAwesomeIcon
          icon={faCrown}
          style={{ fontSize: 36 }}
          className={` text-daybreak-blue-900`}
        />
      </div>
      <h3 className="mt-3">
        Super admins have full access to everything in your Breezy account.{' '}
      </h3>
      <div className="text-bz-gray-700">
        Make sure you want to grant this level of access to this user
      </div>
    </div>
  )
}

export default SettingsCreateEditUserForm
