import {
  AppointmentGuid,
  BzDateFns,
  InferredAppointmentStatus,
  IsoDateString,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { faClose } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import classNames from 'classnames'
import React from 'react'
import { ScheduleAppointmentFragment } from '../../generated/user/graphql'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { CardSection } from './Cards/cardUtils'
import { InstantBookingCardContent } from './InstantBookingCard'
import { RunningLateStatus } from './scheduleUtils'
import { usePersistedDismissedRunningLateAlerts } from './usePersistedDismissedRunningLateAlerts'

type RunningLateDismissButtonProps = {
  appointmentGuid: AppointmentGuid
  runningLateStatus?: RunningLateStatus
}

const RunningLateDismissButton = React.memo<RunningLateDismissButtonProps>(
  ({ appointmentGuid, runningLateStatus }) => {
    const { isDismissed, dismiss } =
      usePersistedDismissedRunningLateAlerts(appointmentGuid)

    if (isDismissed || !runningLateStatus) {
      return null
    }

    return (
      <Button
        icon={<FontAwesomeIcon icon={faClose} />}
        shape="circle"
        type="text"
        className="absolute right-3 top-1.5"
        onClick={dismiss}
      />
    )
  },
)

type StatusBoxProps = {
  appointmentGuid: AppointmentGuid
  endOfAppointmentNextSteps?: unknown
  cancellationStatus?: ScheduleAppointmentFragment['cancellationStatus']
  appointmentStatus?: InferredAppointmentStatus
  runningLateStatus?: RunningLateStatus
  instantBookingAt?: IsoDateString
}

export const StatusBox = React.memo<StatusBoxProps>(
  ({
    appointmentGuid,
    endOfAppointmentNextSteps,
    cancellationStatus,
    appointmentStatus,
    runningLateStatus,
    instantBookingAt,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    if (
      !endOfAppointmentNextSteps &&
      !cancellationStatus &&
      !appointmentStatus &&
      !runningLateStatus &&
      !instantBookingAt
    ) {
      return null
    }
    let bgClassName: string = ''
    let borderColorClassName: string = ''
    let content: React.ReactNode = null
    const assignmentStatusIsComplete = appointmentStatus === 'COMPLETED'

    // If the assignment is "completed", it's either complete or incomplete based on the end of appointment next
    // steps. Otherwise, if the appointment was canceled, we show that state. If it's not complete and not canceled,
    // we don't show anything for this box.
    if (assignmentStatusIsComplete) {
      const nextSteps = tryParseEndOfAppointmentNextSteps(
        endOfAppointmentNextSteps,
      )

      // If the work is incomplete, the `allOnsiteWorkCompleted` flag will literally be false. Not falsy: if it's
      // undefined for some reason we want to treat this as completed (since that's what the status says) and just
      // show less info.
      if (nextSteps?.data.allOnsiteWorkCompleted === false) {
        bgClassName = 'bg-bz-orange-100'
        borderColorClassName = 'border-bz-orange-300'
        content = (
          <>
            <CardSection title="On-site Work Incomplete">
              {nextSteps.data.allOnsiteWorkNotCompletedDetails ?? (
                <i>No details provided</i>
              )}
            </CardSection>
            {nextSteps.data.dispatcherNote && (
              <CardSection title="Note for Dispatch">
                {nextSteps.data.dispatcherNote}
              </CardSection>
            )}
          </>
        )
      } else {
        bgClassName = 'bg-bz-green-100'
        borderColorClassName = 'border-bz-green-300'
        content = (
          <>
            <CardSection title="On-site Work Complete">
              The technician indicated that all on-site work has been completed.
            </CardSection>
            {nextSteps?.data.dispatcherNote && (
              <CardSection title="Note for Dispatch">
                {nextSteps?.data.dispatcherNote}
              </CardSection>
            )}
          </>
        )
      }
    } else if (cancellationStatus?.canceled) {
      bgClassName = 'bg-bz-red-100'
      borderColorClassName = 'border-bz-red-300'
      content = (
        <>
          <CardSection title="Cancellation Date">
            {BzDateFns.formatFromISO(
              cancellationStatus.updatedAt,
              'MMMM d, yyyy @ h:mma',
              tzId,
            )}
          </CardSection>
          {cancellationStatus.reason && (
            <CardSection title="Reason">
              {cancellationStatus.reason}
            </CardSection>
          )}
          {cancellationStatus.note && (
            <CardSection title="Note">{cancellationStatus.note}</CardSection>
          )}
        </>
      )
    } else if (runningLateStatus) {
      bgClassName = 'bg-bz-orange-100'
      borderColorClassName = 'border-bz-orange-300'
      content = (
        <CardSection title="Running Late">
          The technician is running late.
          {runningLateStatus === 'AFTER_ARRIVAL_WINDOW'
            ? ' The arrival window has passed. '
            : ' The arrival window ends in less than 30 minutes. '}
          Please verify their status.
        </CardSection>
      )
    }
    // Since we return a `null` above, we know here we actually have a box.
    return (
      <div className="flex flex-col gap-y-3">
        {content && (
          <div
            className={classNames(
              'relative rounded-xl border border-solid px-4 py-3',
              bgClassName,
              borderColorClassName,
            )}
          >
            <div className="space-y-3">{content}</div>
            <RunningLateDismissButton
              appointmentGuid={appointmentGuid}
              runningLateStatus={runningLateStatus}
            />
          </div>
        )}
        {instantBookingAt && (
          <div className="relative rounded-xl border border-solid border-bz-border bg-bz-fill-quaternary px-4 py-3">
            <InstantBookingCardContent instantBookingAt={instantBookingAt} />
          </div>
        )}
      </div>
    )
  },
)
