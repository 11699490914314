import {
  CompanyLeadSource,
  CompanyLeadSourceGuid,
  JOBS_V2_CLASSES,
  JobsV2Class,
  R,
  UserGuid,
  jobClassDisplayNames,
} from '@breezy/shared'
import { faCheck, faClose, faSliders } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Select, Switch } from 'antd'
import React, { useCallback, useMemo, useState } from 'react'
import { BehindFeatureFlag } from 'src/components/BehindFeatureFlag'
import { ListPageFilterButton } from '../../adam-components/ListPage/ListPageFilterButton'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { GetInvoiceListUsersQuery } from '../../generated/user/graphql'
import { useFeatureFlag } from '../../hooks/useFeatureFlags'
import { useBooleanState } from '../../utils/react-utils'
import { InvoiceListPageBusinessUnit } from './InvoiceListPage.gql'

const JOBS_V2_CLASS_OPTIONS = JOBS_V2_CLASSES.map(value => ({
  value,
  label: jobClassDisplayNames[value],
}))

export const FILTER_LABELS = {
  showMPInvoices: 'Show Maintenance Plan Invoices',
  jobClasses: 'Job Class',
  status: 'Invoice Status',
  users: 'Created by',
  leadSources: 'Lead Source',
  acctManagers: 'Account Manager',
  businessUnits: 'Business Unit',
}

export const computeHasFilters = (
  showMPInvoices: boolean,
  selectedJobClasses: JobsV2Class[],
  selectedUserGuids: UserGuid[],
  selectedCompanyLeadSourceGuids: CompanyLeadSourceGuid[],
  selectedAccountManagerUserGuids: UserGuid[],
  selectedBusinessUnits: string[],
  disableHidingMPInvoicesFromInvoiceList: boolean,
) =>
  (showMPInvoices && !disableHidingMPInvoicesFromInvoiceList) ||
  selectedJobClasses.length +
    selectedUserGuids.length +
    selectedCompanyLeadSourceGuids.length +
    selectedAccountManagerUserGuids.length +
    selectedBusinessUnits.length +
    0 >
    0

export type MoreFiltersProps = {
  showMPInvoices: boolean
  setShowMPInvoices: (show: boolean) => void
  selectedJobClasses: JobsV2Class[]
  setSelectedJobClasses: (jobClasses: JobsV2Class[]) => void
  selectedUserGuids: UserGuid[]
  setSelectedUserGuids: (userGuids: UserGuid[]) => void
  selectedCompanyLeadSourceGuids: CompanyLeadSourceGuid[]
  setSelectedCompanyLeadSourceGuids: (
    companyLeadSourceGuids: CompanyLeadSourceGuid[],
  ) => void
  selectedAccountManagerUserGuids: UserGuid[]
  setSelectedAccountManagerUserGuids: (userGuids: UserGuid[]) => void
  selectedBusinessUnitGuids: string[]
  setSelectedBusinessUnitGuids: (businessUnitGuids: string[]) => void
  userData?: GetInvoiceListUsersQuery
  fetchingUsers: boolean
  fetchingCompanyLeadSources: boolean
  leadSourceData?: CompanyLeadSource[]
  accountManagerEnabled: boolean
  businessUnitsData?: InvoiceListPageBusinessUnit[]
  fetchingBusinessUnitsData: boolean
}

export const MoreFilters = React.memo<MoreFiltersProps>(
  ({
    showMPInvoices: externalShowMPInvoices = false,
    setShowMPInvoices: externalSetShowMPInvoices,
    selectedJobClasses: externalSelectedJobClasses = [],
    setSelectedJobClasses: externalSetSelectedJobClasses,
    selectedUserGuids: externalSelectedUserGuids = [],
    setSelectedUserGuids: externalSetSelectedUserGuids,
    selectedCompanyLeadSourceGuids: externalSelectedCompanyLeadSourceGuids = [],
    setSelectedCompanyLeadSourceGuids:
      externalSetSelectedCompanyLeadSourceGuids,
    selectedAccountManagerUserGuids:
      externalSelectedAccountManagerUserGuids = [],
    setSelectedAccountManagerUserGuids:
      externalSetSelectedAccountManagerUserGuids,
    selectedBusinessUnitGuids: externalBusinessUnits = [],
    setSelectedBusinessUnitGuids: externalSetSelectedBusinessUnitGuids,
    userData,
    fetchingUsers,
    fetchingCompanyLeadSources,
    leadSourceData,
    accountManagerEnabled,
    businessUnitsData,
    fetchingBusinessUnitsData,
  }) => {
    const [moreFiltersOpen, openMoreFilters, closeMoreFilters] =
      useBooleanState()

    const disableHidingMPInvoicesFromInvoiceList = useFeatureFlag(
      'disable-hiding-mp-invoices-from-invoice-list',
    )

    const [internalShowMPInvoices, setShowMPInvoices] = useState<boolean>()
    const showMPInvoices = internalShowMPInvoices ?? externalShowMPInvoices
    const [internalSelectedJobClasses, setSelectedJobClasses] =
      useState<JobsV2Class[]>()
    const selectedJobClasses =
      internalSelectedJobClasses ?? externalSelectedJobClasses
    const [internalSelectedUserGuids, setSelectedUserGuids] =
      useState<string[]>()
    const selectedUserGuids =
      internalSelectedUserGuids ?? externalSelectedUserGuids

    const [
      internalSelectedCompanyLeadSourceGuids,
      setSelectedCompanyLeadSourceGuids,
    ] = useState<string[]>()
    const selectedCompanyLeadSourceGuids =
      internalSelectedCompanyLeadSourceGuids ??
      externalSelectedCompanyLeadSourceGuids

    const [
      internalSelectedAccountManagerUserGuids,
      setSelectedAccountManagerUserGuids,
    ] = useState<string[]>()
    const selectedAccountManagerUserGuids =
      internalSelectedAccountManagerUserGuids ??
      externalSelectedAccountManagerUserGuids

    const [internalSelectedBusinessUnitGuids, setSelectedBusinessUnitGuids] =
      useState<string[]>()
    const selectedBusinessUnitGuids =
      internalSelectedBusinessUnitGuids ?? externalBusinessUnits

    const clearInternalFilters = useCallback(() => {
      setShowMPInvoices(undefined)
      setSelectedJobClasses(undefined)
      setSelectedUserGuids(undefined)
      setSelectedCompanyLeadSourceGuids(undefined)
      setSelectedAccountManagerUserGuids(undefined)
      setSelectedBusinessUnitGuids(undefined)
    }, [])

    const closeModal = useCallback(() => {
      clearInternalFilters()
      closeMoreFilters()
    }, [clearInternalFilters, closeMoreFilters])

    const onConfirm = useCallback(() => {
      externalSetShowMPInvoices(showMPInvoices)
      externalSetSelectedJobClasses(selectedJobClasses)
      externalSetSelectedUserGuids(selectedUserGuids)
      externalSetSelectedCompanyLeadSourceGuids(selectedCompanyLeadSourceGuids)
      externalSetSelectedAccountManagerUserGuids(
        selectedAccountManagerUserGuids,
      )
      externalSetSelectedBusinessUnitGuids(selectedBusinessUnitGuids)

      closeModal()
    }, [
      closeModal,
      externalSetSelectedAccountManagerUserGuids,
      externalSetSelectedBusinessUnitGuids,
      externalSetSelectedCompanyLeadSourceGuids,
      externalSetSelectedJobClasses,
      externalSetSelectedUserGuids,
      externalSetShowMPInvoices,
      selectedAccountManagerUserGuids,
      selectedBusinessUnitGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      showMPInvoices,
    ])
    const hasFilters = useMemo(
      () =>
        computeHasFilters(
          showMPInvoices,
          selectedJobClasses,
          selectedUserGuids,
          selectedCompanyLeadSourceGuids,
          selectedAccountManagerUserGuids,
          externalBusinessUnits,
          disableHidingMPInvoicesFromInvoiceList,
        ),
      [
        disableHidingMPInvoicesFromInvoiceList,
        selectedAccountManagerUserGuids,
        selectedCompanyLeadSourceGuids,
        selectedJobClasses,
        selectedUserGuids,
        showMPInvoices,
        externalBusinessUnits,
      ],
    )

    const userOptions = useMemo(
      () =>
        userData?.companyUsers.map(({ userGuid, userByUserGuid }) => ({
          value: userGuid,
          label: userByUserGuid?.fullName,
        })) ?? [],
      [userData?.companyUsers],
    )

    const leadSourceOptions = useMemo(() => {
      return (
        leadSourceData?.map(leadSource => ({
          value: leadSource.companyLeadSourceGuid,
          label: leadSource.name,
        })) ?? []
      )
    }, [leadSourceData])

    const businessUnitOptions = useMemo(() => {
      return (
        businessUnitsData?.map(businessUnit => ({
          label: businessUnit.name,
          value: businessUnit.businessUnitGuid,
        })) ?? []
      )
    }, [businessUnitsData])

    const [areYouSureOpen, openAreYouSure, closeAreYouSure] = useBooleanState()

    const onClose = useCallback(() => {
      if (
        showMPInvoices !== externalShowMPInvoices ||
        !R.equals(
          selectedJobClasses.sort(),
          externalSelectedJobClasses.sort(),
        ) ||
        !R.equals(selectedUserGuids.sort(), externalSelectedUserGuids.sort()) ||
        !R.equals(
          selectedCompanyLeadSourceGuids.sort(),
          externalSelectedCompanyLeadSourceGuids.sort(),
        ) ||
        !R.equals(
          selectedAccountManagerUserGuids.sort(),
          externalSelectedAccountManagerUserGuids.sort(),
        )
      ) {
        closeMoreFilters()
        openAreYouSure()
      } else {
        closeModal()
      }
    }, [
      closeModal,
      closeMoreFilters,
      externalSelectedAccountManagerUserGuids,
      externalSelectedCompanyLeadSourceGuids,
      externalSelectedJobClasses,
      externalSelectedUserGuids,
      externalShowMPInvoices,
      openAreYouSure,
      selectedAccountManagerUserGuids,
      selectedCompanyLeadSourceGuids,
      selectedJobClasses,
      selectedUserGuids,
      showMPInvoices,
    ])

    const onAreYouSureCancel = useCallback(() => {
      closeAreYouSure()
      openMoreFilters()
    }, [closeAreYouSure, openMoreFilters])

    const onAreYouSureConfirm = useCallback(() => {
      closeAreYouSure()
      closeModal()
    }, [closeAreYouSure, closeModal])

    return (
      <>
        <ListPageFilterButton
          hasFilters={hasFilters}
          size="large"
          icon={<FontAwesomeIcon icon={faSliders} />}
          onClick={openMoreFilters}
        >
          More Filters
        </ListPageFilterButton>
        <OnsiteConfirmModal
          open={areYouSureOpen}
          danger
          header="Discard changes?"
          onCancel={onAreYouSureCancel}
          onConfirm={onAreYouSureConfirm}
          confirmText="Discard Changes"
        >
          The filters that you've set will not be applied unless you select
          "Show Results". Are you sure you want to close without applying the
          selected filters?
        </OnsiteConfirmModal>
        <OnsiteConfirmModal
          size="large-width"
          open={moreFiltersOpen}
          header="Filters"
          onCancel={onClose}
          onConfirm={onConfirm}
          confirmText="Apply"
        >
          <Form className="space-y-6" layout="vertical">
            {!disableHidingMPInvoicesFromInvoiceList ? (
              <div className="flex flex-row items-center rounded-md border border-solid border-bz-gray-500 px-3 py-2">
                <div className="mr-4 flex min-w-0 flex-1">
                  Show Maintenance Plan Invoices
                </div>
                <Switch
                  checked={showMPInvoices}
                  onChange={setShowMPInvoices}
                  checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                  unCheckedChildren={<FontAwesomeIcon icon={faClose} />}
                />
              </div>
            ) : null}

            <Form.Item label="Job Class">
              <Select
                size="large"
                mode="multiple"
                optionFilterProp="label"
                value={selectedJobClasses}
                options={JOBS_V2_CLASS_OPTIONS}
                onChange={setSelectedJobClasses}
                placeholder="Select job classes"
              />
            </Form.Item>
            <Form.Item label="Created by">
              <Select
                disabled={fetchingUsers}
                size="large"
                mode="multiple"
                optionFilterProp="label"
                value={selectedUserGuids}
                options={userOptions}
                onChange={setSelectedUserGuids}
                placeholder="Select users"
              />
            </Form.Item>
            <Form.Item label="Lead Sources">
              <Select
                disabled={fetchingCompanyLeadSources}
                size="large"
                mode="multiple"
                optionFilterProp="label"
                value={selectedCompanyLeadSourceGuids}
                options={leadSourceOptions}
                onChange={setSelectedCompanyLeadSourceGuids}
                placeholder="Select Lead Sources"
              />
            </Form.Item>
            {accountManagerEnabled && (
              <Form.Item label="Account Manager">
                <Select
                  disabled={fetchingUsers}
                  size="large"
                  mode="multiple"
                  optionFilterProp="label"
                  value={selectedAccountManagerUserGuids}
                  options={userOptions}
                  onChange={setSelectedAccountManagerUserGuids}
                  placeholder="Select Account Managers"
                />
              </Form.Item>
            )}

            <BehindFeatureFlag
              enabledFeatureFlag="businessUnits"
              render={
                <Form.Item label="Business Units">
                  <Select
                    disabled={fetchingBusinessUnitsData}
                    size="large"
                    mode="multiple"
                    optionFilterProp="label"
                    value={selectedBusinessUnitGuids}
                    options={businessUnitOptions}
                    onChange={setSelectedBusinessUnitGuids}
                    placeholder="Select Business Units"
                  />
                </Form.Item>
              }
            />
          </Form>
        </OnsiteConfirmModal>
      </>
    )
  },
)
