import { PricebookItemTypeEnum, PricebookItemTypeSchema } from '@breezy/shared'
import { Form, Select } from 'antd'

export const PricebookFormItemType = () => (
  <Form.Item
    name="itemType"
    label="Type"
    rules={[
      {
        required: true,
        validator: async (_, value) => {
          if (!value) {
            return Promise.reject(new Error('Type is required'))
          }
          const result = PricebookItemTypeSchema.safeParse(value)
          if (!result.success) {
            throw new Error(
              result.error.issues[0]?.message || result.error.message,
            )
          }
        },
      },
    ]}
  >
    <Select
      options={Object.values(PricebookItemTypeEnum).map(type => ({
        label: type,
        value: type,
      }))}
    />
  </Form.Item>
)
