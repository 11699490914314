import {
  AccountGuid,
  AccountType,
  CompanyGuid,
  INVOICE_TERMS_V2,
  InvoiceTermV2,
  bzExpect,
  nextGuid,
} from '@breezy/shared'
import { Button, Divider, Form, FormInstance, Input } from 'antd'
import { useWatch } from 'antd/lib/form/Form'
import classNames from 'classnames'
import { useCallback, useMemo } from 'react'
import { useQuery } from 'urql'

import { faBan } from '@fortawesome/pro-regular-svg-icons'
import BzSelect from '../../../elements/BzSelect/BzSelect'
import { trpc } from '../../../hooks/trpc'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import { useIsSmallScreen } from '../../../hooks/useIsMobile'
import { PRETTY_INVOICE_TERMS_FOR_ACCOUNT_TERM } from '../../../pages/Invoices/invoiceUtils'
import { useModalState } from '../../../utils/react-utils'
import GqlQueryLoader from '../../GqlQueryLoader/GqlQueryLoader'
import LeadSourceAttributionDescriptionFormItem from '../../LeadSourceAttributionDescriptionFormItem/LeadSourceAttributionDescriptionFormItem'
import LeadSourceReferringContactFormItem from '../../LeadSourceReferringContactFormItem/LeadSourceReferringContactFormItem'
import LeadSourceSelectFormItem from '../../LeadSourceSelectFormItem/LeadSourceSelectFormItem'
import { LoadingSpinner } from '../../LoadingSpinner'
import { SwitchWithIconAndText } from '../../SwitchWithIconAndText/SwitchWithIconAndText'
import { Tag } from '../../Tags'
import TrpcQueryLoader from '../../TrpcQueryLoader'
import { COMPANY_CONFIG_QUERY } from './CreateOrEditAccountForm.gql'
import { DoNotServiceAccountModal } from './DoNotServiceAccountModal'

export type AccountFormSchema = {
  accountType: AccountType
  accountDisplayName: string
  accountManagerUserGuid?: string
  accountTagGuids?: string[]
  leadSourceGuid?: string
  leadSourceReferringContactGuid?: string
  leadSourceAttributionDescription?: string
  doNotService?: boolean
  doNotServiceReason?: string
  defaultInvoiceTerm?: InvoiceTermV2
}

type FormMode =
  | 'master-create'
  | 'master-edit'
  | 'embedded-create'
  | 'embedded-edit'

const isMasterForm = (
  mode: FormMode,
): mode is 'master-create' | 'master-edit' =>
  mode === 'master-create' || mode === 'master-edit'
export type CreateOrEditAccountFormProps =
  | {
      mode: 'embedded-create' | 'embedded-edit'
      initialValues?: Partial<AccountFormSchema>
      extendedForm: FormInstance<Record<string, unknown> & AccountFormSchema>
      accountGuid?: undefined
      companyGuid: CompanyGuid
      hiddenFields?: Array<keyof AccountFormSchema>
      accountTags: { tagGuid: string; name: string; color: string }[]
      onFinish?: undefined
      onCancel?: undefined
    }
  | {
      mode: 'master-create' | 'master-edit'
      initialValues?: Partial<AccountFormSchema>
      accountGuid: AccountGuid
      companyGuid: CompanyGuid
      hiddenFields?: Array<keyof AccountFormSchema>
      extendedForm?: undefined
      accountTags: { tagGuid: string; name: string; color: string }[]
      onFinish: () => void
      onCancel: () => void
    }

const CreateOrEditAccountForm = ({
  initialValues = {},
  extendedForm,
  mode,
  onFinish,
  onCancel,
  accountGuid,
  companyGuid,
  hiddenFields,
  accountTags,
}: CreateOrEditAccountFormProps) => {
  const [thisAccountForm] = Form.useForm<AccountFormSchema>()

  const fetchCompanyLeadSourcesQuery = trpc.leadAttribution[
    'company-lead-sources:fetch'
  ].useQuery({ companyGuid: bzExpect(companyGuid) })

  const companyConfigQuery = useQuery({
    query: COMPANY_CONFIG_QUERY,
    variables: { companyGuid: bzExpect(companyGuid) },
  })

  const doNotServiceEnabled = useFeatureFlag('do-not-service')
  const isEditMode = mode.endsWith('edit')
  const showDoNotService = doNotServiceEnabled && isEditMode
  const [markDoNotServiceOpen, openMarkDoNotService, closeMarkDoNotService] =
    useModalState(false)

  const form = extendedForm ?? thisAccountForm
  const selectedAccountType = useWatch('accountType', form)
  const selectedLeadSourceGuid = useWatch('leadSourceGuid', form)
  const selectedAccountManagerUserGuid = useWatch(
    'accountManagerUserGuid',
    form,
  )
  const selectedDefaultInvoiceTerm = useWatch('defaultInvoiceTerm', form)

  const selectedDoNotService = useWatch('doNotService', form)
  const selectedDoNotServiceReason = useWatch('doNotServiceReason', form)
  const selectedAccountTagGuids = useWatch('accountTagGuids', form)
  const usersQuery = trpc.user['users:get'].useQuery()
  const accountUpsertMutation = trpc.accounts['accounts:upsert'].useMutation()
  const mutateNote = trpc.notes['notes:put-linked'].useMutation()
  const initialDoNotService = initialValues.doNotService
  const initialDoNotServiceReason = initialValues.doNotServiceReason

  const onFormSubmit = useCallback(
    async (values: AccountFormSchema) => {
      if (values.doNotServiceReason) {
        const noteGuid = nextGuid()

        const noteValue = values.doNotService
          ? `This account has been marked as Do Not Service for the following reason: "${values.doNotServiceReason}"`
          : `This account has the Do Not Service status removed for the following reason: "${values.doNotServiceReason}"`

        await mutateNote.mutateAsync({
          linkData: {
            primaryNoteType: 'ACCOUNT',
            accountGuid: bzExpect(accountGuid, 'AccountGuid should be set'),
          },
          noteGuid,
          value: noteValue,
          taggedUsers: [],
        })
      }
      await accountUpsertMutation.mutateAsync(
        {
          accountGuid: bzExpect(accountGuid, 'AccountGuid should be set'),
          accountType: values.accountType,
          displayName: values.accountDisplayName,
          accountManagerUserGuid: values.accountManagerUserGuid,
          companyLeadSource: {
            leadSourceGuid: values.leadSourceGuid,
            leadSourceReferringContactGuid:
              values.leadSourceReferringContactGuid,
            leadSourceAttributionDescription:
              values.leadSourceAttributionDescription,
          },
          accountTags: values.accountTagGuids
            ? values.accountTagGuids.map(tagGuid => ({
                accountGuid: bzExpect(accountGuid, 'AccountGuid should be set'),
                tagGuid,
              }))
            : [],
          doNotService: values.doNotService,
          doNotServiceReason: values.doNotService
            ? values.doNotServiceReason
            : null,
          defaultInvoiceTerm: values.defaultInvoiceTerm,
        },
        {
          onSuccess() {
            onFinish && onFinish()
          },
        },
      )
    },
    [accountGuid, accountUpsertMutation, onFinish, mutateNote],
  )

  const companyLeadSources = useMemo(
    () => fetchCompanyLeadSourcesQuery.data,
    [fetchCompanyLeadSourcesQuery.data],
  )

  const selectedLeadSource = useMemo(
    () =>
      companyLeadSources?.find(
        source => source.companyLeadSourceGuid === selectedLeadSourceGuid,
      ),
    [companyLeadSources, selectedLeadSourceGuid],
  )

  const isSmallScreen = useIsSmallScreen()

  if (fetchCompanyLeadSourcesQuery.isLoading || !companyLeadSources) {
    return <LoadingSpinner />
  }

  return (
    <TrpcQueryLoader
      query={usersQuery}
      render={data => (
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            accountType: initialValues.accountType ?? AccountType.RESIDENTIAL,
            accountDisplayName: initialValues.accountDisplayName ?? '',
            accountManagerUserGuid:
              initialValues.accountManagerUserGuid ?? undefined,
            leadSourceGuid: initialValues.leadSourceGuid ?? undefined,
            leadSourceReferringContactGuid:
              initialValues.leadSourceReferringContactGuid ?? undefined,
            leadSourceAttributionDescription:
              initialValues.leadSourceAttributionDescription ?? undefined,
            accountTagGuids: initialValues.accountTagGuids,
            doNotService: initialDoNotService ?? false,
            doNotServiceReason: initialDoNotServiceReason ?? '',
            defaultInvoiceTerm: initialValues.defaultInvoiceTerm,
          }}
          disabled={accountUpsertMutation.isLoading}
          onFinish={onFormSubmit}
        >
          <Form.Item
            name="accountType"
            label="Account Type"
            className="semibold_14_22 grey9"
            rules={[
              {
                required: true,
                message: 'Account type is required',
              },
            ]}
            required
          >
            <BzSelect
              title="Account Type"
              sheetSize="half"
              size="middle"
              value={selectedAccountType}
              options={[
                {
                  label: 'Residential',
                  value: AccountType.RESIDENTIAL,
                },
                {
                  label: 'Commercial',
                  value: AccountType.COMMERCIAL,
                },
              ]}
              onChange={(value: AccountType) => {
                form.setFieldsValue({ accountType: value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="accountDisplayName"
            label="Account Display Name"
            className="semibold_14_22 grey9"
            rules={[
              {
                required: true,
                message: 'Account display name is required',
              },
            ]}
            required
          >
            <Input
              name="accountDisplayName"
              id="accountDisplayName"
              autoComplete="organization"
            />
          </Form.Item>

          <Form.Item
            name="defaultInvoiceTerm"
            label="Default Invoice Term"
            className="grey9"
          >
            <BzSelect
              title="Default Invoice Term"
              size="middle"
              allowClear
              placeholder="Invoice Term"
              value={selectedDefaultInvoiceTerm}
              options={INVOICE_TERMS_V2.filter(term => term !== 'AUTO').map(
                term => ({
                  label: PRETTY_INVOICE_TERMS_FOR_ACCOUNT_TERM[term],
                  value: term,
                }),
              )}
              onChange={(value: InvoiceTermV2 | undefined) => {
                form.setFieldsValue({ defaultInvoiceTerm: value })
              }}
            />
          </Form.Item>

          <GqlQueryLoader
            query={companyConfigQuery}
            loadingComponent={<></>}
            render={companyConfigData => (
              <>
                {companyConfigData.companyConfigByPk?.accountManagerEnabled && (
                  <Form.Item
                    name="accountManagerUserGuid"
                    label="Account Manager"
                    className="semibold_14_22 grey9"
                    required={false}
                  >
                    <BzSelect
                      title="Account Manager"
                      size="middle"
                      allowClear
                      value={selectedAccountManagerUserGuid}
                      options={data.users.map(user => ({
                        label: `${user.firstName} ${user.lastName}`,
                        value: user.userGuid,
                      }))}
                      onChange={(value: string) => {
                        form.setFieldsValue({ accountManagerUserGuid: value })
                      }}
                    />
                  </Form.Item>
                )}
              </>
            )}
          />

          {!hiddenFields?.includes('leadSourceGuid') && (
            <LeadSourceSelectFormItem
              form={form}
              companyLeadSources={companyLeadSources}
              required={false}
            />
          )}
          {selectedLeadSource?.attributionLinkingStrategy ===
            'REQUIRE_CONTACT_GUID' &&
            !hiddenFields?.includes('leadSourceReferringContactGuid') && (
              <LeadSourceReferringContactFormItem
                initialContactGuid={
                  initialValues?.leadSourceReferringContactGuid
                }
                onContactUpdated={contact => {
                  form.setFieldsValue({
                    leadSourceReferringContactGuid:
                      contact?.contactGuid ?? undefined,
                  })
                }}
              />
            )}
          {selectedLeadSource?.attributionLinkingStrategy ===
            'REQUIRE_ATTRIBUTION_DESCRIPTION' &&
            !hiddenFields?.includes('leadSourceAttributionDescription') && (
              <LeadSourceAttributionDescriptionFormItem
                leadSource={selectedLeadSource}
                defaultValue={initialValues?.leadSourceAttributionDescription}
              />
            )}

          <Form.Item
            label="Account Tags"
            name="accountTagGuids"
            data-form-id="accountTagsSelect"
            className="mt-4"
          >
            <BzSelect
              mode="multiple"
              title="Account Tags"
              size="middle"
              allowClear
              values={selectedAccountTagGuids ?? []}
              style={{ width: '100%' }}
              placeholder="Please select"
              tagRender={(props: { value: string; onClose: () => void }) => {
                const tagToRender = accountTags.find(
                  tag => props.value === tag.tagGuid,
                )
                if (!tagToRender) {
                  return <></>
                }
                return <Tag compact tag={tagToRender} onClose={props.onClose} />
              }}
              options={accountTags.map(tag => ({
                label: tag.name,
                value: tag.tagGuid,
              }))}
              filterOption={(input, option) => {
                if (!option || !option.label) {
                  return false
                }

                return option.label
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }}
              onChange={(accountTagGuids: string[]) => {
                form.setFieldsValue({ accountTagGuids })
              }}
            />
          </Form.Item>
          {showDoNotService && (
            <>
              <SwitchWithIconAndText
                cols={[22, 2]}
                formItemName="doNotService"
                icon={faBan}
                text='Mark as "Do Not Service"'
                tooltip={
                  selectedDoNotService && selectedDoNotServiceReason
                    ? `Reason: ${selectedDoNotServiceReason}`
                    : undefined
                }
                onChange={value => {
                  if (value !== initialDoNotService) {
                    // This is updated in the onSave below
                    form.setFieldsValue({ doNotService: false })
                    openMarkDoNotService()
                  } else {
                    // essentially user is "undoing" the toggle action, so no need for the modal
                    form.setFieldsValue({
                      doNotService: value,
                      doNotServiceReason: initialDoNotServiceReason ?? '',
                    })
                  }
                }}
                extraStyle={{ borderRadius: '8px' }}
              />
              <Form.Item name="doNotServiceReason" hidden>
                <Input />
              </Form.Item>
            </>
          )}

          {markDoNotServiceOpen && (
            <DoNotServiceAccountModal
              doNotService={initialDoNotService ?? false}
              onClose={() => {
                form.setFieldsValue({
                  doNotService: initialDoNotService,
                  doNotServiceReason: initialDoNotServiceReason ?? '',
                })
                closeMarkDoNotService()
              }}
              onSave={(value, reason) => {
                form.setFieldsValue({
                  doNotService: value,
                  doNotServiceReason: reason,
                })
                closeMarkDoNotService()
              }}
            />
          )}

          {isMasterForm(mode) && <Divider />}
          {isMasterForm(mode) && (
            <div className={`flex space-x-4`}>
              <div className="w-full">
                <div className={`flex justify-end space-x-4`}>
                  <Form.Item
                    className={classNames('semibold_14_22 grey9', {
                      'flex-1': isSmallScreen,
                    })}
                  >
                    <Button
                      block={isSmallScreen}
                      size={isSmallScreen ? 'large' : 'middle'}
                      onClick={onCancel}
                    >
                      Cancel
                    </Button>
                  </Form.Item>
                  <Form.Item
                    className={classNames('semibold_14_22 grey9', {
                      'flex-1': isSmallScreen,
                    })}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      block={isSmallScreen}
                      size={isSmallScreen ? 'large' : 'middle'}
                    >
                      {mode === 'master-create'
                        ? 'Create New Account'
                        : 'Update Account'}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    />
  )
}

export default CreateOrEditAccountForm
