import { BzDateFns, formatUsc, usdToUsCents } from '@breezy/shared'
import { useQuery } from 'urql'

import { gql } from '../../../generated'
import { useFeatureFlag } from '../../../hooks/useFeatureFlags'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'

export const DO_NOT_SERVICE_QUERY = gql(/* GraphQL */ `
  query DoNotService($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      doNotService
      doNotServiceReason
    }
  }
`)

export const OPEN_ACCOUNT_BALANCE_QUERY = gql(/* GraphQL */ `
  query OpenAccountBalance($accountGuid: uuid!) {
    aggregatableInvoicesAggregate(
      where: { status: { _in: ["OPEN"] }, accountGuid: { _eq: $accountGuid } }
    ) {
      # refunds - see which payments may have a refund
      # insert own records to
      # ask how complete we want this to be -- is this sufficient, or should we think about refunds and uncollectables?
      aggregate {
        paidUsdSum: sum {
          paidUsd
        }
        processingUsdSum: sum {
          processingUsd
        }
        totalUscSum: sum {
          totalUsc
        }
      }
      nodes {
        dueAt
        invoiceGuid
      }
    }
  }
`)

export const useDoNotService = (accountGuid: string) => {
  const doNotServiceEnabled = useFeatureFlag('do-not-service')
  const [{ data, fetching }] = useQuery({
    query: DO_NOT_SERVICE_QUERY,
    variables: { accountGuid },
  })
  if (!doNotServiceEnabled) return null
  if (fetching) return null
  if (!data) return null
  const { accountsByPk } = data
  const doNotService = accountsByPk?.doNotService
  const doNotServiceReason = accountsByPk?.doNotServiceReason
  return doNotService ? doNotServiceReason : null
}

export const useOpenAccountBalance = (
  accountGuid: string,
): null | {
  overdueBalanceUsc: number
  overdueBalanceFormatted: string | null
  dateOfOldestOpenInvoice: string | null
  ageOfOldestOpenInvoice: number | null
  oldestOpenInvoiceId: string | null
} => {
  const tzId = useExpectedCompanyTimeZoneId()
  const overdueAccountBalanceEnabled = useFeatureFlag('overdue-account-balance')
  const [{ data, fetching }] = useQuery({
    query: OPEN_ACCOUNT_BALANCE_QUERY,
    variables: { accountGuid },
  })
  if (!overdueAccountBalanceEnabled) return null
  if (fetching) return null
  if (!data) return null
  const { aggregatableInvoicesAggregate } = data
  const aggregate = aggregatableInvoicesAggregate?.aggregate
  const nodes = aggregatableInvoicesAggregate?.nodes

  const paidUsdSum = aggregate?.paidUsdSum
  const processingUsdSum = aggregate?.processingUsdSum
  const totalUscSum = aggregate?.totalUscSum

  const paidUsc = usdToUsCents(
    (processingUsdSum?.processingUsd || 0) + (paidUsdSum?.paidUsd || 0),
  )
  const overdueBalanceUsc = (totalUscSum?.totalUsc || 0) - paidUsc

  const dateOfOldestOpenInvoice =
    overdueBalanceUsc > 0
      ? nodes?.sort(
          (a, b) =>
            new Date(a.dueAt ?? '').getTime() -
            new Date(b.dueAt ?? '').getTime(),
        )[0]?.dueAt ?? null
      : null
  const endOfToday = BzDateFns.endOfDay(BzDateFns.now(tzId))
  const startOfToday = BzDateFns.startOfDay(endOfToday)
  const ageOfOldestOpenInvoice = dateOfOldestOpenInvoice
    ? BzDateFns.differenceInDays(
        startOfToday,
        BzDateFns.parseISO(dateOfOldestOpenInvoice, tzId),
      )
    : null

  const oldestOpenInvoiceId = dateOfOldestOpenInvoice
    ? nodes?.find(node => node.dueAt === dateOfOldestOpenInvoice)
        ?.invoiceGuid ?? null
    : null

  return {
    overdueBalanceUsc,
    overdueBalanceFormatted:
      overdueBalanceUsc > 0 ? formatUsc(overdueBalanceUsc) : null,
    dateOfOldestOpenInvoice,
    ageOfOldestOpenInvoice,
    oldestOpenInvoiceId,
  }
}
