import {
  ACCOUNTS_RECEIVABLE_INVOICE_STATUSES,
  ACTIVE_ESTIMATE_STATUSES,
  AccountGuid,
  bzExpect,
  invoiceV2ToInvoiceV1Status,
  isNullish,
  usCentsToUsd,
} from '@breezy/shared'
import { JobDetailsForOutcomes } from '../../components/JobOutcomesModal/job-outcome-modal-types'
import { KanbanJob } from '../../components/Kanban/kanbanUtils'
import { Job } from '../../gql/queries/Jobs.gql'

export const getAssignedTechniciansFromJobs = (
  jobs: Job[],
): Job['appointments'][number]['assignments'][number]['technician'][] => {
  const appointments = jobs.map(({ appointments }) => appointments).flat()
  const assignments = appointments
    .map(appointment => appointment.assignments)
    .flat()

  return assignments.map(assignment => assignment.technician)
}

export const getAccountManagersFromJobs = (jobs: Job[]) => {
  return jobs
    .filter(({ account }) => !isNullish(account.accountManager))
    .map(job => bzExpect(job.account.accountManager))
}

export const mergeFilters = (
  ...filters: { key: string; optionsSelected: string[] }[]
): { key: string; optionsSelected: string[] }[] => {
  const merged = filters.reduce<{
    [filterKey: string]: { key: string; optionsSelected: string[] }
  }>((acc, curr) => {
    if (acc[curr.key]) {
      acc[curr.key].optionsSelected.push(...curr.optionsSelected)
    } else {
      acc[curr.key] = curr
    }

    return acc
  }, {})

  return Object.values(merged).map(filter => ({
    key: filter.key,
    optionsSelected: Array.from(new Set(filter.optionsSelected)),
  }))
}

export const getJobLifecycleStatusFinancialRollupData = (jobs: KanbanJob[]) => {
  return jobs.reduce((acc, job) => {
    const statusGuid = job.statusId

    if (!acc[statusGuid]) {
      acc[statusGuid] = {
        totalAmountInvoicedUsd: 0,
        totalAmountEstimatedUsc: 0,
      }
    }

    let totalInvoicedUsd = 0

    const totalInvoicedUsc = job.job.jobInvoices
      .filter(ji =>
        ACCOUNTS_RECEIVABLE_INVOICE_STATUSES.includes(
          invoiceV2ToInvoiceV1Status(ji.invoice.status),
        ),
      )
      .reduce((sum, ji) => sum + ji.invoice.totalUsc, 0)
    totalInvoicedUsd = usCentsToUsd(totalInvoicedUsc)

    // Sum up the total amount estimated based on the selected option
    const totalEstimatedUsc = [
      ...job.job.estimates,
      ...job.job.linkedJobEstimates.map(lje => lje.estimate),
    ]
      .filter(e => ACTIVE_ESTIMATE_STATUSES.includes(e.status))
      .reduce((sum: number, estimate: Job['estimates'][0]) => {
        const selectedOption = estimate.estimateOptions.find(
          option => option.isSelected,
        )
        const optionToSum = selectedOption
          ? selectedOption
          : estimate.estimateOptions.reduce(
              (max, opt) => (opt.totalUsc > max.totalUsc ? opt : max),
              estimate.estimateOptions[0],
            )

        return sum + (optionToSum?.totalUsc ?? 0)
      }, 0)

    acc[statusGuid].totalAmountInvoicedUsd += totalInvoicedUsd
    acc[statusGuid].totalAmountEstimatedUsc += totalEstimatedUsc

    return acc
  }, {} as JobLifecycleStatusFinancialRollupData)
}

export type JobLifecycleStatusFinancialRollupData = Record<
  string,
  {
    totalAmountInvoicedUsd: number
    totalAmountEstimatedUsc: number
  }
>

export const mapKanbanJobToJobOutcomesModalJob = (
  kanbanJob: KanbanJob,
): JobDetailsForOutcomes => ({
  jobGuid: kanbanJob.job.jobGuid,
  accountGuid: kanbanJob.job.account.accountGuid as AccountGuid,
  jobType: kanbanJob.job.jobType,
  isOpportunity: kanbanJob.job.isOpportunity,
  isHotLead: kanbanJob.job.isHotLead,
  isMembershipOpportunity: kanbanJob.job.isMembershipOpportunity,
  isMembershipRenewalOpportunity: kanbanJob.job.isMembershipRenewalOpportunity,
  isConverted: kanbanJob.job.isConverted,
  isMembershipSold: kanbanJob.job.isMembershipSold,
  commissionOverheadDeductionRate:
    kanbanJob.job.commissionOverheadDeductionRate,
  commissionOverheadFlatDeductionUsc:
    kanbanJob.job.commissionOverheadFlatDeductionUsc,
  commissionJobCostsDeductionRate:
    kanbanJob.job.commissionJobCostsDeductionRate,
  commissionJobCostsFlatDeductionUsc:
    kanbanJob.job.commissionJobCostsFlatDeductionUsc,
  teamMembers: kanbanJob.job.jobTeamMembers,
  workCompletedAt: kanbanJob.job.workCompletedAt,
})
