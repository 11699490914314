import {
  BzDateFns,
  Guid,
  InvoiceStatuses,
  IsoDateString,
  LOAN_PAYMENT_COMPLETED_STATUSES,
  SelfServePaymentInvoice,
  TimeZoneId,
} from '@breezy/shared'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSubscription } from 'urql'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { trpc } from '../../hooks/trpc'
import { useUnauthLdCompanyContextUpdater } from '../../hooks/useFeatureFlags'
import { UnauthPricebookPhotosEnabledProvider } from '../../hooks/useIsPricebookPhotosEnabled'
import { FinancialConfigContext } from '../../providers/CompanyFinancialConfigWrapper'
import {
  ANON_LATEST_LOAN_RECORD_SUBSCRIPTION,
  SimpleLoanRecord,
} from './LoanRecordSubscription.anon-gql'
import { IssueLoading, NotFound } from './NotFound'
import { useAnonGetCompanyBillingProfile } from './hooks/useCompanyBillingProfile.anon-gql'
import { useFetchCompanyTimezone } from './hooks/useFetchCompanyTimeZone'

export type SelfServePaymentFormContextInvoice = {
  invoiceGuid: Guid
  displayId: string
  companyGuid: Guid
  accountGuid: Guid
  totalPriceUsd: number
  referenceNumber: string
  status: InvoiceStatuses
  paymentsSummary: {
    amountDueUsd: number
    totalPaidAmountUsd: number
    paymentTypesDescription?: string
    lastPaidAt?: IsoDateString
  }
  links: {
    jobGuid?: Guid
    maintenancePlanGuid?: Guid
  }
}

type SelfServePaymentFormContextType = {
  invoiceGuid: Guid
  invoice: SelfServePaymentFormContextInvoice
  companyGuid: string
  companyName: string
  wisetackEnabled: boolean
  paymentCompleted: boolean
  loanApplicationStarted: boolean
  loanPaymentSucceeded: boolean
  didPaymentSucceed: boolean
  contactGuid?: string
  loanRecord?: SimpleLoanRecord
  tilledMerchantId: string
  setPaymentSuccessStatus: (didPaymentSucceed: boolean) => void
  tzId: TimeZoneId
  refetchInvoice: () => void
}

export const SelfServePaymentFormContext = React.createContext<
  SelfServePaymentFormContextType | undefined
>(undefined)

type SelfServePaymentContextType = {
  invoiceGuid: string
  companyGuid: string
  companyName: string
  contractorLicenseNumber?: string
  wisetackMerchantId?: string
  logoUrl: string
  invoice: SelfServePaymentInvoice
  accountGuid: string
  wisetackEnabled: boolean
  loanApplicationStarted: boolean
}

export const SelfServePaymentContext =
  React.createContext<SelfServePaymentContextType>({
    invoiceGuid: '',
    companyGuid: '',
    companyName: '',
    contractorLicenseNumber: '',
    logoUrl: '',
    invoice: {} as SelfServePaymentInvoice,
    wisetackMerchantId: '',
    accountGuid: '',
    wisetackEnabled: false,
    loanApplicationStarted: false,
  })

type InnerSelfServePaymentProviderProps = React.PropsWithChildren<{
  invoice: SelfServePaymentInvoice
  refetchInvoice: () => void
  loanRecord?: SimpleLoanRecord
  contactGuid?: string
}>

const InnerSelfServePaymentProvider =
  React.memo<InnerSelfServePaymentProviderProps>(
    ({ invoice, refetchInvoice, loanRecord, contactGuid, children }) => {
      const [didPaymentSucceed, setDidPaymentSucceed] = useState(false)
      const setPaymentSuccessStatus = useCallback(
        (didPaymentSucceed: boolean) => {
          setDidPaymentSucceed(didPaymentSucceed)
        },
        [],
      )

      const { companyGuid, invoiceGuid } = invoice

      const {
        data: billingProfileData,
        fetching: billingProfileFetching,
        error: billingProfileFetchError,
      } = useAnonGetCompanyBillingProfile(companyGuid)

      const { timezone: tzId } = useFetchCompanyTimezone(invoice?.companyGuid)

      const billingProfile = billingProfileData?.billingProfiles?.[0]

      const loanApplicationStarted = useMemo(() => {
        return !!loanRecord?.loanApplicationLink
      }, [loanRecord?.loanApplicationLink])

      const paymentCompleted = useMemo(() => {
        return invoice.paymentsSummary.amountDueUsd === 0
      }, [invoice.paymentsSummary.amountDueUsd])

      const loanPaymentSucceeded = useMemo(
        () =>
          !!loanRecord?.wisetackLoanStatuses[0].loanStatus &&
          LOAN_PAYMENT_COMPLETED_STATUSES.includes(
            loanRecord.wisetackLoanStatuses[0].loanStatus,
          ),
        [loanRecord?.wisetackLoanStatuses],
      )

      if (billingProfileFetching) {
        return <LoadingSpinner />
      }

      if (!billingProfile || billingProfileFetchError) {
        return <IssueLoading />
      }

      if (!billingProfile.tilledMerchantAccountId) {
        return <NotFound />
      }
      const wisetackEnabled =
        !!billingProfile.wisetackMerchantId && !!contactGuid

      return (
        <SelfServePaymentFormContext.Provider
          value={{
            invoiceGuid,
            invoice: {
              ...invoice,
              displayId: invoice.displayId.toString(),
            },
            companyGuid,
            companyName: billingProfile.businessFullLegalName,
            wisetackEnabled,
            tilledMerchantId: billingProfile.tilledMerchantAccountId,
            setPaymentSuccessStatus,
            paymentCompleted,
            loanApplicationStarted,
            loanPaymentSucceeded,
            didPaymentSucceed,
            contactGuid,
            loanRecord,
            tzId: tzId || BzDateFns.UTC,
            refetchInvoice,
          }}
        >
          <SelfServePaymentContext.Provider
            value={{
              invoiceGuid,
              companyGuid,
              accountGuid: invoice.accountGuid,
              contractorLicenseNumber: billingProfile.contractorLicenseNumber,
              logoUrl: billingProfile.logoUrl,
              invoice,
              companyName: billingProfile.businessFullLegalName,
              loanApplicationStarted,
              wisetackEnabled,
            }}
          >
            <FinancialConfigContext.Provider
              value={{
                merchantId: billingProfile.tilledMerchantAccountId,
                wisetackEnabled:
                  !!billingProfile.wisetackMerchantId && !!contactGuid,
                wisetackMerchantId: billingProfile.wisetackMerchantId,
              }}
            >
              {children}
            </FinancialConfigContext.Provider>
          </SelfServePaymentContext.Provider>
        </SelfServePaymentFormContext.Provider>
      )
    },
  )

type SelfServePaymentProviderProps = React.PropsWithChildren<{
  invoiceReferenceNumber: string
  contactGuid?: string
}>

export const SelfServePaymentProvider =
  React.memo<SelfServePaymentProviderProps>(
    ({ children, invoiceReferenceNumber, contactGuid }) => {
      const {
        data: invoice,
        isFetching: invoiceIsFetching,
        isError: invoiceFetchError,
        refetch: refetchInvoice,
      } = trpc.invoice['unauth:invoicing:get-invoice-for-payment'].useQuery(
        { referenceNumber: invoiceReferenceNumber },
        {
          retry: false,
          refetchOnWindowFocus: false,
          onError: error => {
            console.error(error)
          },
        },
      )

      const [loanRecordLiveQuery] = useSubscription({
        query: ANON_LATEST_LOAN_RECORD_SUBSCRIPTION,
        variables: {
          invoiceReferenceNumber,
        },
        pause: !contactGuid,
      })

      const loanRecord = useMemo<SimpleLoanRecord | undefined>(() => {
        return loanRecordLiveQuery.data?.wisetackLoanRecords[0]
      }, [loanRecordLiveQuery.data?.wisetackLoanRecords])

      // If the loan record changes, we need to refetch the invoice
      // to get the latest payment summary
      useEffect(() => {
        if (loanRecord) {
          refetchInvoice()
        }
      }, [loanRecord, refetchInvoice])

      const navigate = useNavigate()
      const { updateLdContext, isLdContextUpdated } =
        useUnauthLdCompanyContextUpdater()

      useEffect(() => {
        if (invoice) {
          updateLdContext(invoice.companyGuid)
        }
      }, [invoice, updateLdContext])

      useEffect(() => {
        if (isLdContextUpdated && invoice) {
          if (!contactGuid) {
            navigate(`/invoice/${invoice.invoiceGuid}`)
          } else {
            navigate(`/invoice/${invoice.invoiceGuid}/${contactGuid}`)
          }
        }
      }, [isLdContextUpdated, invoice, contactGuid, navigate])

      if (invoiceIsFetching && !invoice) {
        return <LoadingSpinner />
      }

      if (invoiceFetchError) {
        return <IssueLoading />
      }

      if (!invoice) {
        return <NotFound />
      }

      return (
        <UnauthPricebookPhotosEnabledProvider companyGuid={invoice.companyGuid}>
          <InnerSelfServePaymentProvider
            invoice={invoice}
            contactGuid={contactGuid}
            loanRecord={loanRecord}
            refetchInvoice={refetchInvoice}
          >
            {children}
          </InnerSelfServePaymentProvider>
        </UnauthPricebookPhotosEnabledProvider>
      )
    },
  )
