import { Radio, RadioGroupProps } from 'antd'
import { useCallback } from 'react'
import GqlQueryLoader from 'src/components/GqlQueryLoader/GqlQueryLoader'
import { gql } from 'src/generated'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from 'src/providers/PrincipalUser'
import { useMutation, useQuery } from 'urql'

const SCHEDULE_COLOR_PREFERENCE_QUERY = gql(/* GraphQL */ `
  query ScheduleColorPreferencesQuery($userGuid: uuid!) {
    userSettingsByPk(userGuid: $userGuid) {
      scheduleColorPreference
    }
  }
`)

const UPDATE_SCHEDULE_COLOR_PREFERENCE_MUTATION = gql(/* GraphQL */ `
  mutation ScheduleColorPreferencesUpdateScheduleColorPreference(
    $companyGuid: uuid!
    $scheduleColorPreference: String!
    $userGuid: uuid!
  ) {
    insertUserSettingsOne(
      object: {
        companyGuid: $companyGuid
        scheduleColorPreference: $scheduleColorPreference
        userGuid: $userGuid
      }
      onConflict: {
        constraint: user_settings_pkey
        updateColumns: scheduleColorPreference
      }
    ) {
      scheduleColorPreference
    }
  }
`)

const SCHEDULE_COLOR_PREFERENCES = {
  JOB_CLASS: { label: 'By Job Class' },
  TEAM_MEMBER: { label: 'By Technician' },
} as const

export const ScheduleColorPreferences = () => {
  const companyGuid = useExpectedCompanyGuid()
  const userGuid = useExpectedUserGuid()

  const scheduleColorPreferenceQuery = useQuery({
    query: SCHEDULE_COLOR_PREFERENCE_QUERY,
    variables: { userGuid },
  })

  const [, updateScheduleColorPreference] = useMutation(
    UPDATE_SCHEDULE_COLOR_PREFERENCE_MUTATION,
  )

  const onScheduleColorPreferenceChange: NonNullable<
    RadioGroupProps['onChange']
  > = useCallback(
    event => {
      const preference = event.target.value

      if (preference === '') {
        return
      }

      updateScheduleColorPreference({
        companyGuid,
        userGuid,
        scheduleColorPreference: preference,
      })

      scheduleColorPreferenceQuery[1]()
    },
    [
      companyGuid,
      scheduleColorPreferenceQuery,
      updateScheduleColorPreference,
      userGuid,
    ],
  )

  return (
    <GqlQueryLoader
      query={scheduleColorPreferenceQuery}
      render={data => (
        <div className="flex flex-col gap-2">
          <span className="text-sm font-semibold text-bz-text">
            Schedule Color Preferences
          </span>

          <div className="flex flex-col gap-1">
            <Radio.Group
              value={
                data.userSettingsByPk?.scheduleColorPreference ?? 'JOB_CLASS'
              }
              onChange={onScheduleColorPreferenceChange}
              size="large"
            >
              {Object.entries(SCHEDULE_COLOR_PREFERENCES).map(
                ([preference, options]) => (
                  <Radio.Button key={preference} value={preference}>
                    {options.label}
                  </Radio.Button>
                ),
              )}
            </Radio.Group>

            <span className="text-sm text-bz-text-description">
              Customize the color scheme for how for schedule items based on Job
              Class or Technician.
            </span>
          </div>
        </div>
      )}
      errorComponent={<></>}
    />
  )
}
