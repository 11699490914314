import { BzDateFns, IsoDateString } from '@breezy/shared'
import { faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'

type InstantBookingCardContentProps = {
  instantBookingAt: IsoDateString
}

export const InstantBookingCardContent =
  React.memo<InstantBookingCardContentProps>(({ instantBookingAt }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    return (
      <div className="flex items-center gap-3">
        <div>
          <div
            className={classNames(
              'mx-auto flex h-[32px] w-[32px] items-center justify-center rounded-full bg-bz-fill-secondary',
            )}
          >
            <FontAwesomeIcon
              className="h-4 w-4 text-bz-text-secondary"
              icon={faGlobePointer}
            />
          </div>
        </div>
        <div>
          <span className="font-semibold">Instantly booked</span>
          {` on ${BzDateFns.formatFromISO(
            instantBookingAt,
            "MMMM d, yyyy, 'at' h:mm a",
            tzId,
          )}.`}
        </div>
      </div>
    )
  })
