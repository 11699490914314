import {
  AccountGuid,
  bzExpect,
  InstalledEquipmentSummary,
  InstalledHvacSystem,
  LocalDate,
  R,
  ZoningInfo,
} from '@breezy/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { EquipmentCollapsible } from '../../../components/collapsibles/InstalledEquipmentCollapsible/EquipmentCollapsible'
import { HvacSystemsCollapsible } from '../../../components/collapsibles/InstalledHvacSystemsCollapsible/HvacSystemsCollapsible'
import EquipmentUpsertDrawer from '../../../components/EquipmentUpsertDrawer/EquipmentUpsertDrawer'
import { InstalledHvacSystemUpsertDrawer } from '../../../components/InstalledHvacSystemUpsertDrawer/InstalledHvacSystemUpsertDrawer'
import { DocumentType, gql } from '../../../generated/user'
import { UpsertOp } from '../../../utils/upsert-utils'
import { WithAvailableAccountLocations } from '../WithAvailableAccountLocations'

const ACCOUNT_LOCATIONS_EQUIPMENT_AND_HVAC_SYSTEMS_QUERY = gql(/* GraphQL */ `
  query AccountLocationsEquipmentAndHvacSystems($accountGuid: uuid!) {
    accountsByPk(accountGuid: $accountGuid) {
      mailingAddressGuid
      accountLocations {
        location {
          locationGuid
          installedHvacSystems {
            installedHvacSystemGuid
            filterSize
            friendlyName
            notes
            zoningInfo: zoningInfoJson
            installedEquipment {
              ...InstalledEquipment
            }
          }
          installedEquipment {
            ...InstalledEquipment
          }
        }
        accountGuid
        companyGuid
        isArchived
        isBillingAddress
      }
    }
  }
`)

type AccountLocationsEquipmentAndHvacSystemsQuery = DocumentType<
  typeof ACCOUNT_LOCATIONS_EQUIPMENT_AND_HVAC_SYSTEMS_QUERY
>

const convertQueryToAccountEquipmentAndHvacSystems = (
  accountLocations: NonNullable<
    AccountLocationsEquipmentAndHvacSystemsQuery['accountsByPk']
  >['accountLocations'],
): {
  installedEquipment: InstalledEquipmentSummary[]
  hvacSystems: InstalledHvacSystem[]
} => {
  const EQUIPMENT_DATE_FIELDS = [
    'installationDate',
    'estimatedEndOfLifeDate',
    'manufacturerWarrantyStartDate',
    'manufacturerWarrantyEndDate',
    'laborWarrantyStartDate',
    'laborWarrantyEndDate',
    'manufacturingDate',
  ] as const

  const parseEquipment = (
    equipment: NonNullable<
      AccountLocationsEquipmentAndHvacSystemsQuery['accountsByPk']
    >['accountLocations'][number]['location']['installedEquipment'][number],
  ): Omit<InstalledEquipmentSummary, 'locationGuid'> => {
    const dateFields = R.pick(EQUIPMENT_DATE_FIELDS, equipment)
    return {
      ...R.mapObjIndexed(
        value => (value ? LocalDate.parse(value) : undefined),
        dateFields,
      ),
      ...R.omit(EQUIPMENT_DATE_FIELDS, equipment),
      sourceType: equipment.sourceType,
    }
  }

  const installedEquipment: InstalledEquipmentSummary[] = []
  const hvacSystems: InstalledHvacSystem[] = []

  accountLocations.forEach(({ location, ...rest }) => {
    const myInstalledEquipment: InstalledEquipmentSummary[] = (
      location.installedEquipment ?? []
    ).map(equipment => ({
      locationGuid: location.locationGuid,
      ...parseEquipment(equipment),
    }))

    const myHvacSystems: InstalledHvacSystem[] = (
      location.installedHvacSystems ?? []
    ).map(({ installedEquipment, zoningInfo, ...rest }) => ({
      locationGuid: location.locationGuid,
      installedEquipment: installedEquipment.map(equipment => ({
        locationGuid: location.locationGuid,
        ...parseEquipment(equipment),
      })),
      zoningInfo: zoningInfo as ZoningInfo,
      ...rest,
    }))

    installedEquipment.push(...myInstalledEquipment)
    hvacSystems.push(...myHvacSystems)
  })

  return { installedEquipment, hvacSystems }
}

const useFetchAccountEquipmentAndHvacSystems = (accountGuid: AccountGuid) => {
  const accountLocationsQuery = useQuery({
    query: ACCOUNT_LOCATIONS_EQUIPMENT_AND_HVAC_SYSTEMS_QUERY,
    variables: {
      accountGuid,
    },
  })

  const { installedEquipment, hvacSystems } = useMemo(() => {
    return convertQueryToAccountEquipmentAndHvacSystems(
      accountLocationsQuery[0].data?.accountsByPk?.accountLocations ?? [],
    )
  }, [accountLocationsQuery])

  return {
    accountLocationsQuery,
    refetch: accountLocationsQuery[1],
    fetching: accountLocationsQuery[0].fetching,
    installedEquipment,
    hvacSystems,
  }
}

const useEditEquipmentAndHvacSystemsProps = (refetch: () => void) => {
  const [installedEquipmentMutState, setInstalledEquipmentMutState] =
    useState<UpsertOp<InstalledEquipmentSummary>>()

  const closeEquipmentEdit = useCallback(
    () => setInstalledEquipmentMutState(undefined),
    [],
  )
  const onEquipmentEdit = useCallback(() => {
    refetch()
    closeEquipmentEdit()
  }, [closeEquipmentEdit, refetch])

  const [hvacSystemMutState, setHvacSystemMutState] =
    useState<UpsertOp<InstalledHvacSystem>>()

  const closeHvacSystemEdit = useCallback(
    () => setHvacSystemMutState(undefined),
    [],
  )
  const onHvacSystemEdit = useCallback(() => {
    refetch()
    closeHvacSystemEdit()
  }, [closeHvacSystemEdit, refetch])
  return {
    installedEquipmentMutState,
    setInstalledEquipmentMutState,
    hvacSystemMutState,
    setHvacSystemMutState,
    closeEquipmentEdit,
    onEquipmentEdit,
    closeHvacSystemEdit,
    onHvacSystemEdit,
  }
}

type AccountEquipmentAndHvacSystemCollapsibleProps = {
  accountGuid: AccountGuid
  editable?: boolean
}

export const AccountEquipmentAndHvacSystemCollapsible =
  React.memo<AccountEquipmentAndHvacSystemCollapsibleProps>(
    ({ editable = false, accountGuid }) => {
      const { installedEquipment, hvacSystems, refetch } =
        useFetchAccountEquipmentAndHvacSystems(accountGuid)
      const {
        installedEquipmentMutState,
        setInstalledEquipmentMutState,
        hvacSystemMutState,
        setHvacSystemMutState,
        onEquipmentEdit,
        onHvacSystemEdit,
        closeEquipmentEdit,
        closeHvacSystemEdit,
      } = useEditEquipmentAndHvacSystemsProps(refetch)
      return (
        <>
          <EquipmentCollapsible
            equipment={installedEquipment}
            onAddEquipment={() => setInstalledEquipmentMutState('create new')}
            onEquipmentEdit={setInstalledEquipmentMutState}
            canManage={editable}
            refresh={refetch}
          />

          <HvacSystemsCollapsible
            canManage={editable}
            hvacSystems={hvacSystems}
            onHvacSystemAdd={() => setHvacSystemMutState('create new')}
            onHvacSystemEdit={hvacSystem => setHvacSystemMutState(hvacSystem)}
            onEquipmentEdit={setInstalledEquipmentMutState}
          />

          {installedEquipmentMutState === 'create new' && (
            <WithAvailableAccountLocations
              accountGuid={accountGuid}
              render={accountLocations => (
                <EquipmentUpsertDrawer
                  mode="create-for-account"
                  availableLocations={accountLocations}
                  isOpen={!!installedEquipmentMutState}
                  onCancel={closeEquipmentEdit}
                  onMutate={onEquipmentEdit}
                />
              )}
            />
          )}

          {installedEquipmentMutState &&
            installedEquipmentMutState !== 'create new' && (
              <WithAvailableAccountLocations
                accountGuid={accountGuid}
                render={accountLocations => (
                  <EquipmentUpsertDrawer
                    mode="update"
                    location={bzExpect(
                      accountLocations.find(
                        l =>
                          l.locationGuid ===
                          installedEquipmentMutState.locationGuid,
                      ),
                    )}
                    initialValues={installedEquipmentMutState}
                    isOpen={!!installedEquipmentMutState}
                    onCancel={closeEquipmentEdit}
                    onMutate={onEquipmentEdit}
                  />
                )}
              />
            )}

          {hvacSystemMutState === 'create new' && (
            <WithAvailableAccountLocations
              accountGuid={accountGuid}
              render={accountLocations => (
                <InstalledHvacSystemUpsertDrawer
                  mode="create-for-account"
                  availableLocations={accountLocations}
                  isOpen={!!hvacSystemMutState}
                  onCancel={closeHvacSystemEdit}
                  onMutate={onHvacSystemEdit}
                />
              )}
            />
          )}

          {hvacSystemMutState && hvacSystemMutState !== 'create new' && (
            <WithAvailableAccountLocations
              accountGuid={accountGuid}
              render={accountLocations => (
                <InstalledHvacSystemUpsertDrawer
                  mode="update"
                  location={bzExpect(
                    accountLocations.find(
                      l => l.locationGuid === hvacSystemMutState.locationGuid,
                    ),
                  )}
                  initialValues={hvacSystemMutState}
                  isOpen={!!hvacSystemMutState}
                  onCancel={closeHvacSystemEdit}
                  onMutate={onHvacSystemEdit}
                />
              )}
            />
          )}
        </>
      )
    },
  )
