import { DocumentType, gql } from 'src/generated/user'

export const RECENT_JOBS_QUERY = gql(/* GraphQL */ `
  query JobsRecentJobsQuery(
    $companyGuid: uuid!
    $closedStatuses: [String!]!
    $jobLifecycleStatusUpdatedAt: timestamptz!
  ) {
    jobs(
      where: {
        companyGuid: { _eq: $companyGuid }
        _or: [
          { jobLifecycleStatus: { specialStatus: { _nin: $closedStatuses } } }
          { jobLifecycleStatus: { specialStatus: { _isNull: true } } }
          { jobLifecycleStatusUpdatedAt: { _gt: $jobLifecycleStatusUpdatedAt } }
        ]
      }
    ) {
      companyGuid
      jobGuid
      jobLifecycleStatusGuid
      displayId
      workCompletedAt
      isOpportunity
      isHotLead
      isMembershipOpportunity
      isMembershipRenewalOpportunity
      isConverted
      isMembershipSold
      commissionOverheadDeductionRate
      commissionOverheadFlatDeductionUsc
      commissionJobCostsDeductionRate
      commissionJobCostsFlatDeductionUsc
      account {
        accountGuid
        accountDisplayName
        accountManager {
          userGuid
          firstName
          lastName
        }
        accountContacts {
          primary
          contact {
            firstName
            lastName
            primaryPhoneNumber {
              phoneNumber
            }
            primaryEmailAddress {
              emailAddress
            }
          }
        }
        tags {
          tag {
            tagGuid
            companyGuid
            createdByUserGuid
            name
            color
          }
        }
      }
      appointments {
        appointmentWindowStart
        appointmentWindowEnd
        assignments {
          technician {
            userGuid
            firstName
            lastName
            userPrimaryHueHex
            avatarPhoto {
              cdnUrl
            }
          }
        }
      }
      jobLifecycleStatus {
        stage
        name
        jobLifecycleGuid
      }
      jobType {
        name
        jobClass
        opportunityConversionThresholdUsc
      }
      location {
        locationGuid
        address {
          addressGuid
          line1
          zipCode
          city
          stateAbbreviation
          geoLocation
        }
      }
      tags {
        tag {
          tagGuid
          companyGuid
          createdByUserGuid
          name
          color
        }
      }
      jobTeamMembers {
        jobGuid
        userGuid
        turnedOverJob
        commissionRate
        bonusUsc
      }
      estimates {
        estimateGuid
        displayId
        status
        estimateOptions {
          totalUsc
          estimateGuid
          isSelected
          estimateOptionGuid
          seq
        }
      }
      linkedJobEstimates {
        estimate {
          estimateGuid
          displayId
          status
          estimateOptions {
            totalUsc
            estimateGuid
            isSelected
            estimateOptionGuid
            seq
          }
        }
      }
      invoicesQueryables {
        displayId
        invoiceGuid
        invoiceStatus
        invoiceTerm
        totalAmountUsd
      }
      jobInvoices {
        invoice {
          displayId
          displayIdV2
          invoiceGuid
          status
          invoiceTerm
          totalUsc
        }
      }
      linkedJobs {
        job {
          displayId
          jobGuid
          jobType {
            name
          }
        }
      }
      linkedToJobs {
        job {
          displayId
          jobGuid
        }
        linked_job {
          displayId
          jobGuid
        }
      }
    }
  }
`)

export type Job = DocumentType<typeof RECENT_JOBS_QUERY>['jobs'][number]
