import { Text, View } from '@react-pdf/renderer'
import { tailwindColors } from '../../../../../tailwind.config'

type PDFBillingDisclaimerProps = {
  disclaimer: string
}

export const PDFBillingDisclaimer = ({
  disclaimer,
}: PDFBillingDisclaimerProps) => {
  return (
    <View style={{ padding: '0 30px' }}>
      <Text
        style={{
          fontSize: 16,
          lineHeight: '24px',
          fontWeight: 'semibold',
          color: tailwindColors.bz.text,
        }}
      >
        Disclaimer
      </Text>
      <Text
        style={{
          fontSize: 12,
          lineHeight: '20px',
          color: tailwindColors['bz-gray'][1000],
        }}
      >
        {disclaimer}
      </Text>
    </View>
  )
}
