import { Image, View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'

import { faImage } from '@fortawesome/pro-regular-svg-icons'
import { tailwindColors } from '../../../../../tailwind.config'
import { FontAwesomePdfIcon } from './FontAwesomePdfIcon'

type ResizedPdfImageProps = {
  cdnUrl: string | undefined
  width: number
  height: number
  imageMargin?: number
  style?: Style
}

export const ResizedPdfImage = ({
  cdnUrl,
  width: outerWidth,
  height: outerHeight,
  imageMargin,
  style,
}: ResizedPdfImageProps) => {
  const width = outerWidth - (imageMargin ?? 0) * 2
  const height = outerHeight - (imageMargin ?? 0) * 2
  return (
    <View
      style={{
        width: outerWidth,
        height: outerHeight,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: tailwindColors['bz-gray'][500],
        ...style,
      }}
    >
      {cdnUrl ? (
        <Image src={cdnUrl} style={{ width: width, height: height }} />
      ) : (
        <FontAwesomePdfIcon
          faIcon={faImage}
          style={{
            width: outerWidth / 2,
            height: outerHeight / 2,
            color: tailwindColors['bz-gray'][600],
          }}
        />
      )}
    </View>
  )
}
