import {
  BzDateFns,
  calculateInvoiceTotals,
  CalculatePaths,
  CommonDiscountUsc,
  formatMoney,
  formatUsc,
  paymentMethodDisplayName,
  R,
  toPlural,
  uscMultiply,
} from '@breezy/shared'
import { faEdit, faEye } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'antd'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { LabeledItemGrid } from '../../adam-components/LabeledItemGrid'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { SectionedSection } from '../../adam-components/SectionedCard/SectionedContent'
import { EmDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import InvoiceStatusTag from '../Invoicing/InvoiceStatusTag/InvoiceStatusTag'
import { LoadingSpinner } from '../LoadingSpinner'
import { InvoicesWidgetInvoice } from './InvoicesWidget.gql'

const MAX_LINE_ITEMS = 4

type InvoiceWidgetCardProps = InvoicesWidgetInvoice & {
  readonly?: boolean
  small?: boolean
}

export const InvoiceWidgetCard = React.memo<InvoiceWidgetCardProps>(
  ({
    readonly,
    displayIdV2,
    invoiceGuid,
    status,
    issuedAt,
    createdByUser,
    cartItems,
    totalUsc,
    discounts,
    paymentRecords,
    small,
    jobLink,
    maintenancePlanLink,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const navigate = useNavigate()

    const discountAmountUsc = useMemo(() => {
      // NOTE: these are not the ACTUAL totals. We're just trying to get the discount amount, so tax information is
      // spoofed and those numbers (and all the other totals) are unreliable.
      const invoiceTotals = calculateInvoiceTotals(
        cartItems.map(({ cartItem }) => ({ ...cartItem, isTaxable: false })),
        0,
        // The discriminated union isn't reflected by the graphql query
        discounts as CommonDiscountUsc[],
        [],
        undefined,
      )

      return invoiceTotals.discountAmountUsc
    }, [cartItems, discounts])

    const actionsSection = useMemo<SectionedSection | undefined>(() => {
      if (readonly) {
        return undefined
      }
      const items: React.ReactNode[] = []

      if (status === 'OPEN') {
        items.push(
          <Button
            key="present"
            size="large"
            icon={<FontAwesomeIcon icon={faEye} />}
            onClick={() => {
              navigate(
                CalculatePaths.invoiceOverview({
                  invoiceGuid,
                  present: true,
                }),
              )
            }}
          >
            Present
          </Button>,
        )
      }

      if (!['VOIDED', 'UNCOLLECTABLE'].includes(status)) {
        items.push(
          <Button
            key="edit"
            size="large"
            icon={<FontAwesomeIcon icon={faEdit} />}
            onClick={() =>
              navigate(CalculatePaths.invoiceEdit({ invoiceGuid }))
            }
          >
            {status === 'PAID' ? 'Revise' : 'Edit'}
          </Button>,
        )
      }

      if (items.length) {
        return {
          verticalPaddingClassName: 'pt-3 pb-4',
          content: (
            <div className="flex flex-row items-center space-x-2">{items}</div>
          ),
        }
      }
    }, [invoiceGuid, navigate, readonly, status])

    const sections = useMemo(() => {
      const sections: SectionedSection[] = [
        {
          verticalPaddingClassName: 'pb-2.5 pt-4',
          content: (
            <div className="flex flex-row justify-between">
              <Link to={CalculatePaths.invoiceOverview({ invoiceGuid })} bold>
                {displayIdV2}
              </Link>
              <InvoiceStatusTag status={status} />
            </div>
          ),
        },
        {
          verticalPaddingClassName: 'py-3',
          content: (
            <div>
              <LabeledItemGrid
                items={[
                  ...(paymentRecords.length
                    ? R.flatten(
                        paymentRecords.map(
                          (
                            { paymentMethod, amountUsd, paymentRecordGuid },
                            i,
                          ) => [
                            paymentRecords.length === 1
                              ? 'Payment'
                              : `Payment #${i + 1}`,
                            <Link
                              to={CalculatePaths.paymentDetails({
                                paymentRecordGuid,
                              })}
                            >
                              {paymentMethodDisplayName(paymentMethod)} (
                              {formatMoney(amountUsd)})
                            </Link>,
                          ],
                        ),
                      )
                    : []),
                  'Issued Date',
                  issuedAt ? (
                    BzDateFns.format(
                      BzDateFns.parseISO(issuedAt, tzId),
                      'MMM d, yyyy',
                    )
                  ) : (
                    <span className="text-bz-text-tertiary">
                      <EmDash />
                    </span>
                  ),
                  'Prepared By',
                  createdByUser.fullName,
                  ...(jobLink
                    ? [
                        'Job',
                        <Link
                          to={`/jobs/${jobLink.jobGuid}`}
                          className="text-sm"
                        >
                          {jobLink.job.jobType.name} #{jobLink.job.displayId}
                        </Link>,
                      ]
                    : []),
                  ...(maintenancePlanLink
                    ? [
                        'Maintenance Plan',
                        <Link
                          to={`/maintenance-plans/${maintenancePlanLink.maintenancePlanGuid}`}
                          className="text-sm"
                        >
                          View Maintenance Plan
                        </Link>,
                      ]
                    : []),
                  `${cartItems.length} ${toPlural(
                    cartItems.length,
                    'Line Item',
                  )}`,
                ]}
              />
              <div className="mt-2.5 grid w-full min-w-0 max-w-full grid-cols-[1fr_auto] gap-x-1 gap-y-0.5 rounded-lg border border-solid border-bz-border p-3 text-bz-text-secondary">
                {cartItems
                  .slice(0, MAX_LINE_ITEMS)
                  .map(({ cartItem: { name, quantity, unitPriceUsc } }, i) => (
                    <React.Fragment key={`${name}_${i}`}>
                      <div className="truncate">{name}</div>
                      <div className="text-right">
                        {formatUsc(uscMultiply(quantity, unitPriceUsc))}
                      </div>
                    </React.Fragment>
                  ))}
                {cartItems.length > MAX_LINE_ITEMS && (
                  <>
                    <div>
                      {cartItems.length - MAX_LINE_ITEMS} more{' '}
                      {toPlural(cartItems.length - MAX_LINE_ITEMS, 'item')}
                      ...
                    </div>
                    <div className="text-right">...</div>
                  </>
                )}
                {discountAmountUsc > 0 && (
                  <>
                    <div className="text-bz-green-800">Discount</div>
                    <div className="text-right text-bz-green-800">
                      -{formatUsc(discountAmountUsc)}
                    </div>
                  </>
                )}
                <div className="font-semibold">Total w/ Tax</div>
                <div className="text-right font-semibold">
                  {formatUsc(totalUsc)}
                </div>
              </div>
            </div>
          ),
        },
      ]

      if (actionsSection) {
        sections.push(actionsSection)
      }

      return sections
    }, [
      actionsSection,
      cartItems,
      createdByUser.fullName,
      discountAmountUsc,
      displayIdV2,
      invoiceGuid,
      issuedAt,
      jobLink,
      maintenancePlanLink,
      paymentRecords,
      status,
      totalUsc,
      tzId,
    ])

    return <SectionedCard dashed small={small} sections={sections} />
  },
)

export type InvoicesWidgetProps = {
  invoices: InvoicesWidgetInvoice[]
  readonly?: boolean
  fetching?: boolean
  small?: boolean
}

export const InvoicesWidget = React.memo<InvoicesWidgetProps>(
  ({ invoices, readonly, fetching, small }) => {
    if (fetching) {
      return <LoadingSpinner />
    }
    return (
      <div className="space-y-3">
        {invoices.map(invoice => (
          <InvoiceWidgetCard
            key={invoice.invoiceGuid}
            readonly={readonly}
            small={small}
            {...invoice}
          />
        ))}
      </div>
    )
  },
)
