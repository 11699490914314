import {
  bzExpect,
  getDefaultTaxRate,
  JOB_APPOINTMENT_GUID_QUERY_PARAM,
  nextGuid,
} from '@breezy/shared'
import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import { useQueryParam } from 'use-query-params'
import { OnsitePageLoader } from '../../adam-components/OnsitePage/OnsitePageLoader'
import { GET_PRICEBOOK_TAX_RATES } from '../../components/Pricebook/PricebookPickers.gql'
import { useMaintenancePlanJobOnsiteDiscount } from '../../hooks/useMaintenancePlanJobDiscount'
import { EstimateEditView } from './EstimateEditView'
import { RELEVANT_NEW_ESTIMATE_DATA_QUERY } from './EstimatesFlow.gql'
import { EstimatesContext, useRelevantEstimateData } from './estimatesFlowUtils'

export const EstimateCreationPage = React.memo(() => {
  const jobGuid = bzExpect(
    useParams().jobGuid,
    'jobGuid',
    'JobGuid is required',
  )

  const newEstimateGuid = useMemo(() => nextGuid(), [])

  const {
    companyDefaultTaxRateGuid,
    isFetching: isRelevantDataFetching,
    ...relevantData
  } = useRelevantEstimateData()

  const mpOnsiteDiscounts = useMaintenancePlanJobOnsiteDiscount({ jobGuid })

  // urql's `useQuery` will infuriatingly re-run when we do a mutation, which I really don't want because the whole page
  // will remount. So this tells it to cut it out after its initial fetch.
  const [isPaused, setIsPaused] = useState(false)

  const [
    {
      data: relevantNewEstimateData,
      fetching: fetchingRelevantNewEstimateData,
    },
  ] = useQuery({
    query: RELEVANT_NEW_ESTIMATE_DATA_QUERY,
    variables: {
      jobGuid,
    },
    pause: isPaused,
  })

  useEffect(() => {
    if (relevantNewEstimateData) {
      setIsPaused(true)
    }
  }, [relevantNewEstimateData])

  const [jobAppointmentGuid] = useQueryParam<string>(
    JOB_APPOINTMENT_GUID_QUERY_PARAM,
  )

  const [{ data: taxRates, fetching: fetchingTaxRates }] = useQuery({
    query: GET_PRICEBOOK_TAX_RATES,
  })

  const defaultTaxRate = useMemo(
    () =>
      getDefaultTaxRate(
        taxRates?.pricebookTaxRates ?? [],
        relevantNewEstimateData?.jobsByPk?.account.accountType,
        relevantNewEstimateData?.jobsByPk?.location.address.zipCode,
        companyDefaultTaxRateGuid,
        relevantNewEstimateData?.jobsByPk?.location.pricebookTaxRate
          ?.pricebookTaxRateGuid,
      ),
    [
      companyDefaultTaxRateGuid,
      relevantNewEstimateData?.jobsByPk?.account.accountType,
      relevantNewEstimateData?.jobsByPk?.location.address.zipCode,
      taxRates?.pricebookTaxRates,
      relevantNewEstimateData?.jobsByPk?.location.pricebookTaxRate
        ?.pricebookTaxRateGuid,
    ],
  )

  const isLoading =
    isRelevantDataFetching ||
    fetchingRelevantNewEstimateData ||
    fetchingTaxRates

  if (isLoading) {
    return <OnsitePageLoader />
  }

  return (
    <EstimatesContext.Provider
      value={{
        estimateGuid: newEstimateGuid,
        jobGuid,
        accountGuid: relevantNewEstimateData?.jobsByPk?.accountGuid ?? '',
        locationGuid: relevantNewEstimateData?.jobsByPk?.locationGuid ?? '',
        jobAppointmentGuid,
        ...relevantData,
      }}
    >
      <EstimateEditView
        isNew
        defaultTaxRate={defaultTaxRate}
        defaultDiscounts={mpOnsiteDiscounts}
        pointOfContact={{
          firstName:
            relevantNewEstimateData?.jobsByPk?.pointOfContact?.firstName ?? '',
          fullName:
            relevantNewEstimateData?.jobsByPk?.pointOfContact?.fullName ?? '',
          phoneNumber:
            relevantNewEstimateData?.jobsByPk?.pointOfContact
              ?.primaryPhoneNumber?.phoneNumber,
          email:
            relevantNewEstimateData?.jobsByPk?.pointOfContact
              ?.primaryEmailAddress?.emailAddress,
        }}
        location={{
          line1:
            relevantNewEstimateData?.jobsByPk?.location.address.line1 ?? '',
          city: relevantNewEstimateData?.jobsByPk?.location.address.city ?? '',
          stateAbbreviation:
            relevantNewEstimateData?.jobsByPk?.location.address
              .stateAbbreviation ?? '',
          zipCode:
            relevantNewEstimateData?.jobsByPk?.location.address.zipCode ?? '',
        }}
      />
    </EstimatesContext.Provider>
  )
})

export default EstimateCreationPage
