import { BzDateFns, formatUsc, IsoDateString, TimeZoneId } from '@breezy/shared'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { EstimateSummaryData } from '../../../../../components/DownloadableInvoice/DownloadableInvoice'
import tailwindConfig, { tailwindColors } from '../../../../../tailwind.config'
import { PdfBillingInfo } from './usePdfBillingInfo'

type Address = {
  line1: string
  line2?: string
  city: string
  stateAbbreviation: string
  zipCode: string
}

type PDFJobBillingHeaderProps = PdfBillingInfo & {
  summaryData: EstimateSummaryData
  issuedAt: IsoDateString
  displayId: string
  tzId: TimeZoneId
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 16,
    marginTop: 16,
  },
  addressContainer: {
    flex: 1,
    minWidth: 0,
  },
  summaryContainer: {
    flexDirection: 'column',
    minHeight: 0,
    flex: 2,
    borderWidth: 2,
    borderColor: tailwindColors['bz-gray'][400],
    borderRadius: 6,
  },
  summaryHeader: {
    backgroundColor: tailwindColors['bz-gray'][300],
    padding: '4px 12px',
    fontSize: 16,
    fontWeight: 'bold',
  },
  summaryContent: {
    padding: '4px 12px 10px',
  },
  summaryRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  divider: {
    borderBottomWidth: 1,
    borderBottomStyle: 'dashed',
    borderBottomColor: tailwindColors['bz-gray'][400],
    marginVertical: 4,
  },
  addressTitle: {
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 'semibold',
    marginBottom: 8,
    color: tailwindColors['bz-gray'][1000],
  },
  addressContactName: {
    fontWeight: 'semibold',
    fontSize: 14,
    lineHeight: '22px',
    color: tailwindColors['bz-gray'][1000],
  },
  addressLine: {
    fontSize: 14,
    lineHeight: '22px',
    color: tailwindColors['bz-gray'][1000],
  },
  summaryLabel: {
    fontWeight: 'semibold',
    fontSize: 14,
    lineHeight: '22px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    maxWidth: 150,
  },
  summaryValue: {
    fontSize: 14,
    lineHeight: '22px',
    color: tailwindColors.bz.text,
  },
})

export const PDFJobBillingHeader = ({
  serviceAddress,
  billingAddress,
  addressesMatch,
  displayId,
  issuedAt,
  summaryData,
  tzId,
}: PDFJobBillingHeaderProps) => {
  const formatAddress = (address: Address): string[] => {
    const lines: string[] = []
    lines.push(address.line1)
    if (address.line2) {
      lines.push(address.line2)
    }
    lines.push(
      `${address.city}, ${address.stateAbbreviation} ${address.zipCode}`,
    )
    return lines
  }

  return (
    <View style={styles.container}>
      {serviceAddress && billingAddress && (
        <>
          <View style={styles.addressContainer}>
            <Text style={styles.addressTitle}>Service Address</Text>
            {serviceAddress.contactName && (
              <Text style={styles.addressContactName}>
                {serviceAddress.contactName}
              </Text>
            )}
            {formatAddress(serviceAddress.address).map((line: string) => (
              <Text key={line} style={styles.addressLine}>
                {line}
              </Text>
            ))}
          </View>

          <View style={styles.addressContainer}>
            <Text style={styles.addressTitle}>Billing Address</Text>
            {!addressesMatch && billingAddress.contactName && (
              <Text style={styles.addressContactName}>
                {billingAddress.contactName}
              </Text>
            )}
            {addressesMatch ? (
              <Text style={styles.addressLine}>(Same as service)</Text>
            ) : (
              formatAddress(billingAddress.address).map((line: string) => (
                <Text key={line} style={styles.addressLine}>
                  {line}
                </Text>
              ))
            )}
          </View>
        </>
      )}

      <View style={styles.summaryContainer}>
        <View
          style={{
            backgroundColor:
              tailwindConfig.theme.extend.colors.bz['fill-tertiary'],
            flexDirection: 'row',
          }}
        >
          <Text style={styles.summaryHeader}>Estimate #{displayId}</Text>
        </View>
        <View style={styles.summaryContent}>
          <View style={[styles.summaryRow]}>
            <Text
              style={[
                styles.summaryLabel,
                {
                  color: tailwindConfig.theme.extend.colors.bz['text-tertiary'],
                },
              ]}
            >
              Issued Date
            </Text>
            <Text style={styles.summaryValue}>
              {BzDateFns.formatFromISO(issuedAt, 'MMM d, yyyy', tzId)}
            </Text>
          </View>
          <View style={styles.divider} />
          {summaryData.options.map(({ name, totalUsc }, index) => (
            <View key={name + totalUsc + index} style={styles.summaryRow}>
              <Text style={styles.summaryLabel}>{name}</Text>
              <Text style={styles.summaryValue}>{formatUsc(totalUsc)}</Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}
