import {
  AddressDto,
  AppointmentType,
  BzDateFns,
  CalculatePaths,
  dates,
  formatUsc,
  Guid,
  IsoDateString,
  JobClass,
} from '@breezy/shared'
import { faEdit, faMap } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Tag } from '../../../components/Tags'
import { AbbreviatedList } from '../../../elements/AbbreviatedList/AbbreviatedList'
import { CallablePhoneLink } from '../../../elements/CallablePhoneLink/CallablePhoneLink'
import { Link } from '../../../elements/Link/Link'
import { useMapLink } from '../../../hooks/useMapLink'
import { OnResize, useResizeObserver } from '../../../hooks/useResizeObserver'
import { useExpectedCompanyTimeZoneId } from '../../../providers/PrincipalUser'
import { getColorForJobClass } from '../../../utils/job-utils'
import { useStrictContext } from '../../../utils/react-utils'
import { AppointmentStatusTag } from '../AppointmentStatusTag'
import { StatusBox } from '../EventStatusBox'
import {
  ScheduleAppointment,
  ScheduleAssignment,
  UnassignedAppointment,
} from '../Schedule.gql'
import { ScheduleCancelAppointmentModal } from '../ScheduleCancelAppointmentModal'
import {
  getInferredAppointmentStatusFromAssignments,
  SchedulePageContext,
  TechnicianResource,
  useRunningLateStatus,
} from '../scheduleUtils'
import { BaseCard, CardSection } from './cardUtils'

type MapIconProps = {
  address: AddressDto
}

const MapIcon = React.memo<MapIconProps>(({ address }) => {
  const [mapsButtonUrl] = useMapLink(address)

  return (
    <Link to={mapsButtonUrl} target="_blank">
      <FontAwesomeIcon
        icon={faMap}
        className={classNames(
          'h-4 w-4 border-0 border-b border-solid border-transparent hover:border-bz-primary',
        )}
      />
    </Link>
  )
})

const renderMoreTags = (num: number) => (
  <Tag
    hideIcon
    noPopover
    compact
    tagStyleVersion="v2"
    className="mr-0"
    tag={{ name: `+${num}`, color: '' }}
  />
)

type CardContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  jobClass: JobClass
}

const CardContainer = React.memo<CardContainerProps>(
  ({ jobClass, ...rest }) => {
    const { ribbon } = getColorForJobClass(jobClass)
    return <BaseCard ribbonColorClassName={ribbon} {...rest} />
  },
)

type JobTypeSectionProps = {
  jobGuid: Guid
  displayId: number
  jobTypeName: string
  appointmentType: AppointmentType
}

const JobTypeSection = React.memo<JobTypeSectionProps>(
  ({ jobGuid, jobTypeName, appointmentType, displayId }) => (
    <CardSection title="Job Type">
      <Link bold={false} to={CalculatePaths.jobDetails({ jobGuid })}>
        {jobTypeName} #{displayId}
      </Link>
      <span className="ml-1">({appointmentType})</span>
    </CardSection>
  ),
)

type PrimaryContactSectionProps = {
  fullName: string
  phoneNumber?: string
}

const PrimaryContactSection = React.memo<PrimaryContactSectionProps>(
  ({ fullName, phoneNumber }) => {
    const nameDivRef = useRef<HTMLDivElement>(null)

    const [isTwoLines, setIsTwoLines] = useState(false)

    const onResize = useCallback<OnResize>(({ height }) => {
      // With the line height as what it currently is, the height of the div will be 22px if it's only one line. If it's
      // two lines it will be 44. I can use any number that's between 22 and 44 (exclusive) to determine if I'm two
      // lines. I'm using 33 because it's half way and if we adjust the line height slightly, it should still work.
      if (height > 33) {
        setIsTwoLines(true)
      } else {
        setIsTwoLines(false)
      }
    }, [])

    useResizeObserver(nameDivRef, onResize)

    return (
      <CardSection title="Primary Contact">
        <div ref={nameDivRef} className="inline-block">
          {fullName}
        </div>

        {phoneNumber && (
          <>
            {!isTwoLines && <span> • </span>}
            <CallablePhoneLink phoneNumber={phoneNumber}>
              <span className="whitespace-nowrap">{phoneNumber}</span>
            </CallablePhoneLink>
          </>
        )}
      </CardSection>
    )
  },
)

const MAX_TAGS = 5

type AccountTagsSectionProps = {
  tags: ScheduleAppointment['job']['account']['tags']
}

const AccountTagsSection = React.memo<AccountTagsSectionProps>(({ tags }) => (
  <TagsSection title="Account Tags">
    {tags.map((tag, i) => (
      <Tag
        compact
        tagStyleVersion="v2"
        noPopover
        key={`${tag.tag.name}_${i}`}
        tag={tag.tag}
      />
    ))}
  </TagsSection>
))

type TagsSectionProps = React.PropsWithChildren<{
  title: string
}>

const TagsSection = React.memo<TagsSectionProps>(({ title, children }) => {
  const childArray = React.Children.toArray(children)
  return childArray.length ? (
    <CardSection title={title}>
      <div className="mt-0.5 space-y-1">
        {childArray.slice(0, MAX_TAGS)}
        {childArray.length > MAX_TAGS ? (
          <Popover
            content={
              <div className="flex flex-col items-start space-y-1">
                <div className="mb-2 font-semibold">Additional tags</div>
                {childArray.slice(MAX_TAGS)}
              </div>
            }
            zIndex={99999}
          >
            <div className="inline-block">
              {renderMoreTags(childArray.length - MAX_TAGS)}
            </div>
          </Popover>
        ) : null}
      </div>
    </CardSection>
  ) : null
})

type ServiceLocationSectionProps = {
  locationGuid: Guid
  address: AddressDto
}

const ServiceLocationSection = React.memo<ServiceLocationSectionProps>(
  ({ locationGuid, address }) => (
    <CardSection title="Service Location">
      <div className="flex flex-row items-center">
        <div className="min-w-0 flex-1">
          <Link
            bold={false}
            to={CalculatePaths.locationDetails({ locationGuid })}
          >
            <div>
              {address.line1}
              {address.line2 && <>, {address.line2}</>}
            </div>

            <div>
              {address.city}, {address.stateAbbreviation}, {address.zipCode}
            </div>
          </Link>
        </div>
        <div className="mx-2">
          <MapIcon address={address} />
        </div>
      </div>
    </CardSection>
  ),
)

type ArrivalWindowSectionProps = {
  appointmentWindowStart: IsoDateString
  appointmentWindowEnd: IsoDateString
}

const ArrivalWindowSection = React.memo<ArrivalWindowSectionProps>(
  ({ appointmentWindowStart, appointmentWindowEnd }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const arrivalWindow = useMemo(() => {
      const dow = BzDateFns.formatFromISO(appointmentWindowStart, 'EEE', tzId)

      const range = dates.calculateDateTimeWindow(
        appointmentWindowStart,
        appointmentWindowEnd,
        tzId,
        {
          includeDate: true,
        },
      )
      return `${dow}, ${range}`
    }, [appointmentWindowEnd, appointmentWindowStart, tzId])

    return (
      <CardSection title="Date & Arrival Window">{arrivalWindow}</CardSection>
    )
  },
)

type AssignmentCardProps = {
  start: IsoDateString
  end: IsoDateString
  assignment: ScheduleAssignment
  tech?: TechnicianResource
  className?: string
  openChecklists: () => void
  closePopover: () => void
}

export const AssignmentCard = React.memo<AssignmentCardProps>(
  ({
    start,
    end,
    assignment,
    tech,
    className,
    openChecklists,
    closePopover,
  }) => {
    const tzId = useExpectedCompanyTimeZoneId()

    const { setEditingAppointmentGuid } = useStrictContext(SchedulePageContext)

    const { appointment, assignmentGuid } = assignment
    const {
      job,
      appointmentType,
      appointmentWindowStart,
      appointmentWindowEnd,
      endOfAppointmentNextSteps,
      cancellationStatus,
      appointmentGuid,
      appointmentChecklistInstances,
    } = appointment
    const { jobGuid, jobType, pointOfContact, account, jobInvoices, location } =
      job

    const onEdit = useCallback(() => {
      closePopover()
      setEditingAppointmentGuid(appointmentGuid)
    }, [appointmentGuid, closePopover, setEditingAppointmentGuid])

    const techAssignmentTime = useMemo(() => {
      const range = dates.calculateDateTimeWindow(start, end, tzId, {
        includeDate: true,
      })
      return range
    }, [end, start, tzId])

    const status = useMemo(
      () =>
        getInferredAppointmentStatusFromAssignments(
          [assignment],
          appointment.cancellationStatus?.canceled,
        ),
      [appointment.cancellationStatus?.canceled, assignment],
    )

    const runningLateStatus = useRunningLateStatus(
      appointmentGuid,
      appointmentWindowEnd,
      status,
    )

    const [cancelOpen, setCancelOpen] = useState(false)

    const openCancel = useCallback(() => {
      setCancelOpen(true)
      // Annoying edge case: the "Cancel Visit" button in the popover is above another event. It will trigger a "hover"
      // on that event when the tooltip disappears. For whatever reason the modal's overlay doesn't prevent the hover
      // event immediately. Putting 0 and 10 here aren't good enough. 100 doesn't event consistently work. 200 seems
      // good and because the z-index of the modal is more than the tooltip it doesn't degrade the user experience.
      setTimeout(closePopover, 200)
    }, [closePopover])
    const closeCancel = useCallback(() => setCancelOpen(false), [])

    return (
      <CardContainer className={className} jobClass={jobType.jobClass}>
        <StatusBox
          instantBookingAt={job.instantBookingAt}
          appointmentGuid={appointmentGuid}
          endOfAppointmentNextSteps={endOfAppointmentNextSteps}
          cancellationStatus={cancellationStatus}
          appointmentStatus={status}
          runningLateStatus={runningLateStatus}
        />
        <div className="relative">
          <JobTypeSection
            jobGuid={jobGuid}
            displayId={job.displayId}
            jobTypeName={jobType.name}
            appointmentType={appointmentType}
          />
          <AppointmentStatusTag
            status={status}
            className="absolute right-0 top-0"
          />
        </div>
        <PrimaryContactSection
          fullName={pointOfContact.fullName}
          phoneNumber={pointOfContact.primaryPhoneNumber?.phoneNumber}
        />
        <AccountTagsSection tags={account.tags} />
        <ServiceLocationSection
          address={location.address}
          locationGuid={location.locationGuid}
        />
        <ArrivalWindowSection
          appointmentWindowStart={appointmentWindowStart}
          appointmentWindowEnd={appointmentWindowEnd}
        />
        {/* I'm being a little lazy here. I could pull these out into their own components, but they only make sense
            when it's an assignment, so I'm not going to use them in the other card. */}
        {tech && (
          <CardSection title="Assigned Technician">
            <span>
              {tech.firstName} {tech.lastName},
            </span>{' '}
            <span className="whitespace-nowrap">{techAssignmentTime}</span>
          </CardSection>
        )}
        {jobInvoices.length > 0 && (
          <CardSection title="Invoices">
            <div className="mt-0.5">
              <AbbreviatedList
                renderMore={renderMoreTags}
                spacingClassName="space-x-1.5"
              >
                {jobInvoices.map(
                  ({ invoice: { displayIdV2, totalUsc, status } }) => {
                    return (
                      <Tag
                        key={displayIdV2}
                        compact
                        tagStyleVersion="v2"
                        noPopover
                        hideIcon
                        tag={{
                          name: `${displayIdV2} (${formatUsc(totalUsc)})`,
                        }}
                        className="mr-0"
                        overrideStyle={
                          status === 'PAID'
                            ? {
                                bgColor: 'bg-bz-green-100',
                                textColor: 'text-bz-green-900',
                                border: 'border-bz-green-400',
                              }
                            : undefined
                        }
                      />
                    )
                  },
                )}
              </AbbreviatedList>
            </div>
          </CardSection>
        )}
        {appointmentChecklistInstances.length > 0 && (
          <CardSection title="Completed Checklist">
            <Link
              bold={false}
              onClick={() => {
                closePopover()
                openChecklists()
              }}
            >
              {appointmentChecklistInstances.length === 1
                ? appointmentChecklistInstances[0].appointmentChecklist.name
                : `${appointmentChecklistInstances.length} checklists`}
            </Link>
          </CardSection>
        )}
        {status !== 'CANCELED' && status !== 'COMPLETED' && (
          <div className="flex flex-row space-x-2">
            <Button
              block
              type="primary"
              icon={<FontAwesomeIcon icon={faEdit} />}
              onClick={onEdit}
            >
              Edit
            </Button>
            <Button danger block onClick={openCancel}>
              Cancel Visit
            </Button>
          </div>
        )}
        {cancelOpen && (
          <ScheduleCancelAppointmentModal
            onClose={closeCancel}
            appointmentGuid={appointmentGuid}
            assignmentGuid={assignmentGuid}
            assignmentTechName={
              tech ? `${tech.firstName} ${tech.lastName}` : 'this technician'
            }
          />
        )}
      </CardContainer>
    )
  },
)

type AppointmentCardProps = {
  appointment: UnassignedAppointment
  className?: string
}

export const AppointmentCard = React.memo<AppointmentCardProps>(
  ({ appointment, className }) => {
    const {
      job,
      appointmentGuid,
      appointmentType,
      appointmentWindowStart,
      appointmentWindowEnd,
      endOfAppointmentNextSteps,
      cancellationStatus,
    } = appointment
    const { jobGuid, jobType, pointOfContact, account, location } = job

    const canceled = !!appointment.cancellationStatus?.canceled

    return (
      <CardContainer
        data-testid="appointment-card"
        data-accountdisplayname={account.accountDisplayName}
        jobClass={jobType.jobClass}
        className={classNames(
          className,
          canceled
            ? 'border-2 border-solid border-bz-red-600'
            : 'border border-solid border-bz-border',
        )}
      >
        <StatusBox
          appointmentGuid={appointmentGuid}
          endOfAppointmentNextSteps={endOfAppointmentNextSteps}
          cancellationStatus={cancellationStatus}
        />
        <div className="relative">
          <JobTypeSection
            jobGuid={jobGuid}
            displayId={job.displayId}
            jobTypeName={jobType.name}
            appointmentType={appointmentType}
          />
          <AppointmentStatusTag
            status={canceled ? 'CANCELED' : 'UNASSIGNED'}
            className={classNames('absolute right-0 top-0', {
              'bg-bz-red-200': canceled,
            })}
            border="strong"
          />
        </div>
        <ArrivalWindowSection
          appointmentWindowStart={appointmentWindowStart}
          appointmentWindowEnd={appointmentWindowEnd}
        />
        <PrimaryContactSection
          fullName={pointOfContact.fullName}
          phoneNumber={pointOfContact.primaryPhoneNumber?.phoneNumber}
        />
        <AccountTagsSection tags={account.tags} />
        <ServiceLocationSection
          address={location.address}
          locationGuid={location.locationGuid}
        />
      </CardContainer>
    )
  },
)
