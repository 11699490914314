import { Guid, isNullish } from '@breezy/shared'
import { Select } from 'antd'
import { useMemo } from 'react'
import { gql } from 'src/generated/user'
import { useQuery } from 'urql'
import { useFetchPersonResources } from '../../hooks/fetch/useFetchUserDetails'
import { m } from '../../utils/react-utils'
import { LoadingSpinner } from '../LoadingSpinner'
import PersonResourceView from './PersonResourceView'

const USER_AVATAR_QUERY = gql(/* GraphQL */ `
  query UserPersonResourceSelectUserAvatarQuery($_in: [uuid!]!) {
    users(where: { userGuid: { _in: $_in } }) {
      userGuid
      avatarPhoto {
        cdnUrl
      }
    }
  }
`)

type UserPersonResourceSelectProps = {
  userGuid: Guid
  setUserGuid: (userGuid: Guid) => void
}

const containerClassName = 'min-w-[240px] min-h-[46px]'
export const UserPersonResourceSelect = m<UserPersonResourceSelectProps>(
  ({ userGuid, setUserGuid }) => {
    const users = useFetchPersonResources()

    const userPhotosQuery = useQuery({
      query: USER_AVATAR_QUERY,
      variables: {
        _in: isNullish(users) ? [] : users.map(user => user.userGuid),
      },
    })

    const userPhotos = useMemo<Record<string, string>>(() => {
      const photos = userPhotosQuery[0].data

      if (isNullish(photos)) {
        return {}
      }

      return photos.users.reduce<Record<string, string>>((acc, curr) => {
        if (!isNullish(curr.avatarPhoto)) {
          acc[curr.userGuid] = curr.avatarPhoto.cdnUrl
        }

        return acc
      }, {})
    }, [userPhotosQuery])

    if (!users || userPhotosQuery[0].fetching)
      return (
        <div className={containerClassName}>
          <LoadingSpinner />
        </div>
      )

    if (users.length === 0)
      return <div className={containerClassName}>No users found</div>

    return (
      <Select
        value={userGuid}
        onChange={setUserGuid}
        className={containerClassName}
      >
        {users.map(u => (
          <Select.Option key={u.userGuid} value={u.userGuid}>
            <PersonResourceView
              {...u}
              avatarData={{
                ...u.avatarData,
                avatarImgSrc: userPhotos[u.userGuid],
              }}
            />
          </Select.Option>
        ))}
      </Select>
    )
  },
)
