import {
  HtmlString,
  IsoDateString,
  stripHtml,
  TimeZoneId,
} from '@breezy/shared'
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import {
  EstimateSummaryData,
  InvoiceBillingInfo,
} from '../../../../../components/DownloadableInvoice/DownloadableInvoice'
import { tailwindColors } from '../../../../../tailwind.config'
import { PDFJobBillingHeader } from './PDFJobBillingHeader'
import { PdfBillingInfo } from './usePdfBillingInfo'

const styles = StyleSheet.create({
  header: {
    flexDirection: 'row',
    paddingBottom: 20,
    borderBottomWidth: 2,
    borderBottomStyle: 'dashed',
    borderBottomColor: tailwindColors['bz-gray'][400],
    alignItems: 'flex-start',
  },
  companyInfo: {
    flex: 1,
    flexDirection: 'column',
  },
  businessName: {
    fontSize: 20,
    lineHeight: '28px',
    fontWeight: 'semibold',
    marginBottom: 8,
    color: tailwindColors['bz-gray'][1000],
  },
  infoText: {
    fontSize: 14,
    lineHeight: '22px',
    color: tailwindColors['bz-gray'][1000],
  },
  logo: {
    width: 214,
    height: 88,
    objectFit: 'contain',
    objectPosition: 'right top',
  },
})

export const PDFCompanyBillingHeader = ({
  businessFullLegalName,
  companyAddressDisplay,
  companyInfoDisplay,
  companyLogoUrl,
}: PdfBillingInfo) => {
  return (
    <View style={styles.header}>
      <View style={styles.companyInfo}>
        <Text style={styles.businessName}>{businessFullLegalName}</Text>
        <Text style={styles.infoText}>{companyAddressDisplay}</Text>
        {companyInfoDisplay && (
          <Text style={styles.infoText}>{companyInfoDisplay}</Text>
        )}
      </View>
      <Image src={companyLogoUrl} style={styles.logo} />
    </View>
  )
}

export type BillingSummaryInfo = {
  summaryData: EstimateSummaryData
  issuedAt: IsoDateString
  displayId: string
  tzId: TimeZoneId
  message?: HtmlString
}

export type PDFJobBillingHeaderProps = BillingSummaryInfo & InvoiceBillingInfo

export type PDFBillingHeaderProps = PdfBillingInfo &
  BillingSummaryInfo & {
    numOptions: number
  }

export const PDFBillingHeader = ({
  summaryData,
  issuedAt,
  displayId,
  tzId,
  message,
  numOptions,
  ...pdfBillingInfo
}: PDFBillingHeaderProps) => {
  return (
    <View
      style={{
        flexDirection: 'column',
      }}
    >
      <View
        style={{
          flexDirection: 'column',
          paddingHorizontal: 30,
        }}
      >
        <PDFCompanyBillingHeader {...pdfBillingInfo} />
        <PDFJobBillingHeader
          {...pdfBillingInfo}
          displayId={displayId}
          summaryData={summaryData}
          issuedAt={issuedAt}
          tzId={tzId}
        />
        {message && (
          <View
            style={{
              flexDirection: 'column',
              marginTop: 12,
            }}
          >
            <Text
              style={{
                fontSize: 16,
                fontWeight: 'bold',
                lineHeight: '24px',
                color: tailwindColors.bz.text,
              }}
            >
              Message
            </Text>
            <Text
              style={{
                fontSize: 14,
                lineHeight: '22px',
                color: tailwindColors['bz-gray'][1000],
              }}
            >
              {stripHtml(message)}
            </Text>
          </View>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginVertical: '24px',
          backgroundColor: tailwindColors['bz-gray'][300],
          padding: '8px 0',
        }}
      >
        <Text
          style={{
            color: tailwindColors['bz-gray'][1000],
            fontSize: 16,
            fontWeight: 'semibold',
            lineHeight: '24px',
            marginHorizontal: '-30px',
          }}
        >
          {`Please review the ${
            numOptions > 1
              ? `${numOptions} options that have`
              : 'estimate that has'
          } been prepared for your review`}
        </Text>
      </View>
    </View>
  )
}
