import {
  CalculatePaths,
  InvoiceV2Status,
  PaymentWorkflowOption,
  invoiceV2ToInvoiceV1Status,
  usCentsToUsd,
} from '@breezy/shared'
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { BaseLoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { usePaymentWorkflowWizard } from '../../components/PaymentWorkflow/hooks/usePaymentWorkflowWizard'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompanyGuid } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  useDownloadInvoice,
  useFetchPdfBlob,
  useOpenInvoiceInNewTab,
} from '../../utils/invoice-utils'
import { useStrictContext } from '../../utils/react-utils'
import { InvoiceListingDataContext } from './InvoiceListPageContexts'
import { InvoiceListingInvoice } from './InvoiceListPageTypes'

const canCollectPaymentOnInvoice = (
  status: InvoiceV2Status,
  amountDueUsc: number,
) => (status === 'OPEN' || status === 'UNCOLLECTABLE') && amountDueUsc > 0

type InvoiceActionsDropdownProps = InvoiceListingInvoice & {
  hiddenPaymentMethods?: PaymentWorkflowOption[]
}

export const InvoiceActionsDropdown = React.memo<InvoiceActionsDropdownProps>(
  ({
    invoiceGuid,
    accountGuid,
    displayId,
    status,
    totalUsc,
    paidUsc,
    processingPaymentsUsc,
    serviceCompletionDate,
    hiddenPaymentMethods,
  }) => {
    const companyGuid = useExpectedCompanyGuid()
    const navigate = useNavigate()
    const message = useMessage()
    const { refetch } = useStrictContext(InvoiceListingDataContext)
    const amountDueUsc = totalUsc - paidUsc - processingPaymentsUsc
    const { showPaymentWorkflowWizard, paymentWorkflowWizard } =
      usePaymentWorkflowWizard({
        invoice: {
          invoiceGuid,
          accountGuid,
          displayId,
          referenceNumber: invoiceGuid,
          status: invoiceV2ToInvoiceV1Status(status),
          serviceCompletionDate,
        },
        invoiceAmountDueUsd: usCentsToUsd(amountDueUsc),
        onPaymentSuccess: refetch,
        hiddenOptions: hiddenPaymentMethods,
      })
    const { downloadInvoicePDF, invoiceDownloading } = useDownloadInvoice(
      companyGuid,
      invoiceGuid,
      displayId,
    )

    const emailInvoiceMut =
      trpc.invoice['invoicing:email-invoice'].useMutation()

    const emailInvoicePDF = useCallback(() => {
      emailInvoiceMut.mutate(
        {
          invoiceGuid,
        },
        {
          onSuccess: () => {
            message.success('Successfully Emailed Invoice PDF to customer')
          },
          onError: e => {
            console.error(
              `There was an unexpected error sending the invoice pdf`,
              e,
            )
            message.error(
              `There was an unexpected error sending the invoice pdf`,
            )
          },
        },
      )
    }, [emailInvoiceMut, invoiceGuid, message])

    const fetchPdfBlob = useFetchPdfBlob(companyGuid)

    const { beginOpenInvoice, invoiceGenerating } = useOpenInvoiceInNewTab(
      invoiceGuid,
      fetchPdfBlob,
    )

    const dropdownItems = useMemo(() => {
      const items: ItemType[] = [
        {
          key: 'Send Copy',
          label: <div onClick={emailInvoicePDF}>Send Copy</div>,
        },
        {
          key: 'Download PDF',
          label: <div onClick={downloadInvoicePDF}>Download PDF</div>,
        },
        {
          key: 'Print',
          label: <div onClick={beginOpenInvoice}>Print</div>,
        },
      ]
      if (status !== 'VOIDED') {
        items.unshift({
          key: 'Edit',
          label: (
            <div
              onClick={() =>
                navigate(CalculatePaths.invoiceEdit({ invoiceGuid }))
              }
            >
              Edit
            </div>
          ),
        })
      }

      if (canCollectPaymentOnInvoice(status, amountDueUsc)) {
        items.push({
          key: 'Collect Payment',
          label: <div onClick={showPaymentWorkflowWizard}>Collect Payment</div>,
        })
      }

      return items
    }, [
      amountDueUsc,
      beginOpenInvoice,
      downloadInvoicePDF,
      emailInvoicePDF,
      invoiceGuid,
      navigate,
      showPaymentWorkflowWizard,
      status,
    ])
    const loading =
      invoiceDownloading || emailInvoiceMut.isLoading || invoiceGenerating

    return (
      <>
        <Dropdown
          disabled={loading}
          className="mb-4"
          menu={{
            items: dropdownItems,
          }}
        >
          <Button
            shape="circle"
            disabled={loading}
            icon={
              loading ? (
                <BaseLoadingSpinner size={5} />
              ) : (
                <FontAwesomeIcon icon={faEllipsis} />
              )
            }
          />
        </Dropdown>
        {paymentWorkflowWizard}
      </>
    )
  },
)
