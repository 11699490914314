import { isNullish } from '@breezy/shared'
import { TeamMemberCell } from 'src/adam-components/ListPage/TeamMemberCell'
import { GetEstimateListQuery } from 'src/generated/user/graphql'

export interface EstimatesV2ListPageTableTeamMemberCellProps {
  user?: GetEstimateListQuery['estimates'][number]['createdByUser']
}

export const EstimatesV2ListPageTableTeamMemberCell = (
  props: EstimatesV2ListPageTableTeamMemberCellProps,
) => {
  if (isNullish(props.user)) {
    return null
  }

  return (
    <TeamMemberCell
      user={{
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        avatarColor: props.user.userPrimaryHueHex,
        avatarImageUrl: props.user.avatarPhoto?.cdnUrl,
        userRoles: props.user.userRoles,
        userGuid: props.user.userGuid,
        deactivatedAt: props.user.deactivatedAt,
      }}
    />
  )
}
