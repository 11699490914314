import { BzDateFns, isNullish, IsoDateString } from '@breezy/shared'
import { Link } from 'src/elements/Link/Link'
import { gql } from 'src/generated'
import useIsMobile from 'src/hooks/useIsMobile'
import { useExpectedCompanyTimeZoneId } from 'src/providers/PrincipalUser'
import { useQuery } from 'urql'
import { PhotoAlbumWidget, PhotoAlbumWidgetProps } from './PhotoAlbumWidget'

const JOB_PHOTO_ALBUM_WIDGET_QUERY = gql(/* GraphQL */ `
  query JobPhotoAlbumWidgetQuery($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      jobGuid
      displayId
      jobType {
        name
      }
      location {
        locationGuid
        address {
          city
          stateAbbreviation
          zipCode
          line1
        }
      }
      linkedToJobs {
        linked_job {
          jobGuid
          displayId
          jobType {
            name
          }
          location {
            locationGuid
            address {
              city
              stateAbbreviation
              zipCode
              line1
            }
          }
          photoLinks {
            createdAt
            updatedAt
            photo {
              photoGuid
              cdnUrl
              resourceUrn
              createdByUserGuid
            }
          }
        }
      }
    }
    photoLinks(
      where: { jobGuid: { _eq: $jobGuid } }
      orderBy: { updatedAt: DESC }
    ) {
      createdAt
      updatedAt
      photo {
        photoGuid
        cdnUrl
        resourceUrn
        createdByUserGuid
      }
    }
  }
`)

export const useJobPhotoAlbumWidgetQuery = (jobGuid: string) =>
  useQuery({
    query: JOB_PHOTO_ALBUM_WIDGET_QUERY,
    variables: { jobGuid },
  })

export interface JobPhotoAlbumWidgetProps {
  photos: PhotoAlbumWidgetProps['photos']
  title: string
  jobGuid?: string
  numPhotos?: number
  location?: {
    address: {
      city: string
      stateAbbreviation: string
      line1: string
      zipCode: string
    }
  }
  lastUpdatedAt?: IsoDateString
  options?: {
    showLocation?: boolean
    thumbnailSize?: 'small' | 'large'
  }
  onWidgetClick?: () => void
  onPhotoClick?: (photo: PhotoAlbumWidgetProps['photos'][number]) => void
}

export const JobPhotoAlbumWidget = (props: JobPhotoAlbumWidgetProps) => {
  const timezoneId = useExpectedCompanyTimeZoneId()
  const isMobile = useIsMobile()

  const lastUpdatedAt = !isNullish(props.lastUpdatedAt)
    ? BzDateFns.formatFromISO(props.lastUpdatedAt, 'MMMM dd, yyyy', timezoneId)
    : null

  const location = !isNullish(props.location)
    ? `${props.location.address.line1}, ${props.location.address.city}, ${props.location.address.stateAbbreviation} ${props.location.address.zipCode}`
    : null

  return (
    <PhotoAlbumWidget
      photos={props.photos}
      onPhotoAlbumWidgetClick={props.onWidgetClick}
    >
      <div className="flex flex-col">
        <div className="mb-2 flex flex-col gap-[2px]">
          <div className="flex flex-row items-center justify-between">
            {!isNullish(props.jobGuid) ? (
              <Link to={`/jobs/${props.jobGuid}`} bold>
                <PhotoAlbumWidget.Title
                  text={`${props.title}${
                    !isNullish(props.numPhotos)
                      ? ' (' + props.numPhotos + ')'
                      : ''
                  }`}
                />
              </Link>
            ) : (
              <PhotoAlbumWidget.Title
                text={`${props.title}${
                  !isNullish(props.numPhotos)
                    ? ' (' + props.numPhotos + ')'
                    : ''
                }`}
              />
            )}

            {!isMobile && !isNullish(lastUpdatedAt) && (
              <PhotoAlbumWidget.Subtitle text={`Updated ${lastUpdatedAt}`} />
            )}
          </div>

          {isMobile && !isNullish(lastUpdatedAt) && (
            <PhotoAlbumWidget.Subtitle text={`Updated ${lastUpdatedAt}`} />
          )}

          {props.options?.showLocation && !isNullish(location) && (
            <PhotoAlbumWidget.Subtitle text={location} />
          )}
        </div>

        <PhotoAlbumWidget.ThumbnailGrid
          photoDistributionStrategy="balanced"
          onPhotoClick={props.onPhotoClick}
          thumbnailSize={props.options?.thumbnailSize}
        />
      </div>
    </PhotoAlbumWidget>
  )
}
