import { FEATURE_FLAGS, FeatureFlag } from '@breezy/shared'
import { useFeatureFlagReader } from '../../hooks/useFeatureFlags'

export const FeatureFlagInfo = () => {
  const ffReader = useFeatureFlagReader()
  const featureFlagValues = FEATURE_FLAGS.reduce((memo, flag) => {
    const flagValue = ffReader(flag)
    memo[flag] = flagValue
    return memo
  }, {} as Record<FeatureFlag, boolean>)

  return (
    <div>
      <h3>Feature Flags</h3>
      <ul>
        {Object.entries(featureFlagValues).map(([flagName, flagValue]) => {
          return (
            <li key={flagName}>
              {flagName}: {flagValue ? 'Enabled' : 'Disabled'}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
