import { OfficeRoutes, PermissionV2, createRoute } from '@breezy/shared'
import { DevSupportToolsPage } from 'src/pages/DevSupportToolsPage/DevSupportToolsPage'
import { QuickbooksDesktopConfigPage } from '../components/AccountingIntegration/QuickbooksDesktop/QuickbooksDesktopConfigPage'
import { QuickbooksConfigPage } from '../components/AccountingIntegration/QuickbooksOnline/QuickbooksConfigPage'
import { AppointmentChecklistSettingsPage } from '../components/AppointmentChecklists/AppointmentChecklistSettingsPage'
import { PricebookAdminPage } from '../components/PricebookAdmin/PricebookAdmin'
import { SettingsTeamPage } from '../components/SettingsTeam/SettingsTeamPage'
import WorkingHoursEditView from '../components/WorkingHoursEditView/WorkingHoursEditView'
import { AppointmentConfigurationSettingsPage } from '../pages/AppointmentConfigurationSettingsPage/AppointmentConfigurationSettingsPage'
import { AssistantsSettingsPage } from '../pages/AssistantsSettingsPage/AssistantsSettingsPage'
import { BillingProfileSettingsPage } from '../pages/BillingProfileSettingsPage/BillingProfileSettingsPage'
import BusinessUnitsSettingsPage from '../pages/BusinessUnitsSettingsPage/BusinessUnitsSettingsPage'
import { CompanyConfigSettingsPage } from '../pages/CompanyConfigSettingsPage/CompanyConfigSettingsPage'
import { DripMarketingSettingsPage } from '../pages/DripMarketingSettingsPage/DripMarketingSettingsPage'
import { InvoiceTemplatesPage } from '../pages/InvoiceTemplatesPage/InvoiceTemplatesPage'
import { JobLifecycleSettingsListPage } from '../pages/JobLifecycleSettings/JobLifecycleSettingsListPage'
import { JobRequestsSettingsPage } from '../pages/JobRequestsSettingsPage/JobRequestsSettingsPage'
import { JobTypesSettingsListPage } from '../pages/JobTypesSettings/JobTypesSettingsListPage'
import { LeadSourcesSettingsPage } from '../pages/LeadSourcesSettingsPage/LeadSourcesSettingsPage'
import MaintenancePlanConfigPage from '../pages/MaintenancePlanConfigPage/MaintenancePlanConfigPage'
import { OnlineBookingEditServiceTypePage } from '../pages/OnlineBookingSettingsPage/OnlineBookingEditServiceTypePage'
import { OnlineBookingSettingsLayout } from '../pages/OnlineBookingSettingsPage/OnlineBookingSettingsLayout'
import { OnlineBookingSettingsPage } from '../pages/OnlineBookingSettingsPage/OnlineBookingSettingsPage'
import SettingsCreateTeamMemberPage from '../pages/SettingsPage/SettingsCreateTeamMemberPage'
import SettingsEditTeamMemberPage from '../pages/SettingsPage/SettingsEditTeamMemberPage'
import { SettingsUnauthorized } from '../pages/SettingsPage/SettingsLayout'
import { SettingsNotificationsPage } from '../pages/SettingsPage/SettingsNotificationsPage'
import { SettingsTeamLayout } from '../pages/SettingsPage/SettingsTeamLayout'
import { UserProfilePage } from '../pages/SettingsPage/UserProfilePage'
import { UserSettingsPage } from '../pages/SettingsPage/UserSettingsPage'
import { TagsSettingsPage } from '../pages/TagsSettings'
import { TechnicianPerformanceSettingsPage } from '../pages/TechnicianPerformanceSettingsPage/TechnicianPerformanceSettingsPage'
import { TimesheetsSettingsPage } from '../pages/TimesheetsSettingsPage/TimesheetsSettingsPage'
import { ReactRouteMap } from './ReactRoute'

export const SettingsTeamRoutes = {
  CREATE: {
    ...OfficeRoutes.CREATE,
    path: 'create',
    element: <SettingsCreateTeamMemberPage />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM,
  },
  EDIT: {
    ...OfficeRoutes.EDIT,
    path: 'edit/:userGuid',
    element: <SettingsEditTeamMemberPage />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM,
  },
} satisfies ReactRouteMap

export const OnlineBookingSettingsRoutes = {
  EDIT_SERVICE_TYPE: {
    ...OfficeRoutes.ONLINE_BOOKING_EDIT_SERVICE_TYPE,
    path: 'edit-service-type/:serviceTypeGuid',
    element: <OnlineBookingEditServiceTypePage />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_ONLINE_BOOKING,
  },
} satisfies ReactRouteMap

export const SettingsReactRoutes = {
  USER_PROFILE: {
    ...OfficeRoutes.USER_PROFILE,
    path: 'user-profile',
    label: 'User Profile',
    element: <UserProfilePage />,
    principalUserRequiresCompany: true,
  },
  USER_SETTINGS: {
    ...OfficeRoutes.USER_SETTINGS,
    path: 'user-settings',
    label: 'User Settings',
    element: <UserSettingsPage />,
  },
  TEAM: {
    ...OfficeRoutes.TEAM,
    path: 'team',
    element: <SettingsTeamLayout />,
    unauthedElement: <SettingsUnauthorized label="Team" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM,
    children: [
      ...Object.values(SettingsTeamRoutes),
      {
        ...createRoute('*', {
          label: 'Team',
        }),
        element: <SettingsTeamPage />,
        principalUserRequiresCompany: true,
        permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TEAM,
      },
    ],
  },
  PRICEBOOK: {
    ...OfficeRoutes.PRICEBOOK,
    path: 'pricebook',
    element: <PricebookAdminPage />,
    unauthedElement: <SettingsUnauthorized label={'Pricebook'} />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_PRICEBOOK,
  },
  MAINTENANCE_PLANS: {
    ...OfficeRoutes.MAINTENANCE_PLANS_SETTINGS,
    path: 'maintenance-plans',
    element: <MaintenancePlanConfigPage />,
    unauthedElement: <SettingsUnauthorized label={'Maintenance Plans'} />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_MAINTENANCE_PLANS,
  },
  COMPANY_WORKING_HOURS: {
    ...OfficeRoutes.COMPANY_WORKING_HOURS,
    path: 'company-working-hours',
    element: <WorkingHoursEditView />,
    unauthedElement: <SettingsUnauthorized label={'Company Hours'} />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_COMPANY_HOURS,
  },
  COMPANY_CONFIG: {
    ...OfficeRoutes.COMPANY_CONFIG,
    path: 'company-config',
    element: <CompanyConfigSettingsPage />,
    principalUserRequiresCompany: true,
  },
  QUICKBOOKS: {
    ...OfficeRoutes.QUICKBOOKS,
    path: 'quickbooks',
    element: <QuickbooksConfigPage />,
    principalUserRequiresCompany: true,
  },
  QUICKBOOKS_DESKTOP: {
    ...OfficeRoutes.QUICKBOOKS_DESKTOP,
    path: 'quickbooks-desktop',
    element: <QuickbooksDesktopConfigPage />,
    principalUserRequiresCompany: true,
  },
  LIFECYCLES: {
    ...OfficeRoutes.LIFECYCLES,
    path: 'lifecycles',
    element: <JobLifecycleSettingsListPage />,
    unauthedElement: <SettingsUnauthorized label={'Job Pipeline'} />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_LIFECYCLES,
  },
  JOB_TYPES: {
    ...OfficeRoutes.JOB_TYPES,
    path: 'job-types',
    element: <JobTypesSettingsListPage />,
    unauthedElement: <SettingsUnauthorized label={'Job Types'} />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_TYPES,
  },
  CHECKLISTS: {
    ...OfficeRoutes.CHECKLISTS,
    path: 'checklist',
    element: <AppointmentChecklistSettingsPage />,
    unauthedElement: <SettingsUnauthorized label={'Appointment Checklists'} />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_CHECKLISTS,
  },
  TAGS: {
    ...OfficeRoutes.TAGS,
    path: 'tags',
    element: <TagsSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Tags" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_TAGS,
  },
  TIMESHEETS: {
    ...OfficeRoutes.TIMESHEETS_SETTINGS,
    path: 'timesheets',
    element: <TimesheetsSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Timesheets" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_TIMESHEETS_MANAGE,
  },
  TECHNICIAN_PERFORMANCE: {
    ...OfficeRoutes.TECHNICIAN_PERFORMANCE,
    path: 'technician-performance',
    element: <TechnicianPerformanceSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Technician Performance" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_TECHNICIAN_PERFORMANCE_VIEW,
  },
  LEAD_SOURCES: {
    ...OfficeRoutes.LEAD_SOURCES,
    path: 'lead-sources',
    element: <LeadSourcesSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Lead Sources" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_LEAD_SOURCES,
  },
  BILLING_PROFILE: {
    ...OfficeRoutes.BILLING_PROFILE,
    path: 'billing-profile',
    element: <BillingProfileSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Billing Profile" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.ALL_ACTIONS,
  },
  NOTIFICATIONS: {
    ...OfficeRoutes.NOTIFICATIONS_SETTINGS,
    path: 'notifications',
    element: <SettingsNotificationsPage />,
    unauthedElement: <SettingsUnauthorized label="Notifications Settings" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_NOTIFICATIONS,
  },
  DRIP_MARKETING: {
    ...OfficeRoutes.DRIP_MARKETING_SETTINGS,
    path: 'job-follow-up-emails',
    element: <DripMarketingSettingsPage />,
    unauthedElement: (
      <SettingsUnauthorized label="Job Follow-up Emails Settings" />
    ),
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING,
  },
  JOB_REQUESTS: {
    ...OfficeRoutes.JOB_REQUESTS,
    path: 'job-requests',
    element: <JobRequestsSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Job Requests" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_JOB_REQUESTS,
  },
  APPOINTMENT_CONFIGURATION: {
    ...OfficeRoutes.APPOINTMENT_SETTINGS,
    path: 'appointments',
    element: <AppointmentConfigurationSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Visit Settings" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_DRIP_MARKETING,
  },
  ASSISTANTS_SETTINGS: {
    ...OfficeRoutes.ASSISTANTS_SETTINGS,
    path: 'assistants',
    element: <AssistantsSettingsPage />,
    unauthedElement: <SettingsUnauthorized label="Assistants" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_ASSISTANT_SETTINGS,
  },
  ONLINE_BOOKING: {
    ...OfficeRoutes.ONLINE_BOOKING_SETTINGS,
    path: 'online-booking',
    element: <OnlineBookingSettingsLayout />,
    unauthedElement: <SettingsUnauthorized label="Online Booking" />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_ONLINE_BOOKING,
    children: [
      ...Object.values(OnlineBookingSettingsRoutes),
      {
        ...createRoute('*', {
          label: 'Online Booking',
        }),
        element: <OnlineBookingSettingsPage />,
        principalUserRequiresCompany: true,
        permission: PermissionV2.OFFICE_COMPANY_SETTINGS_MANAGE_ONLINE_BOOKING,
      },
    ],
  },
  INVOICE_TEMPLATES: {
    ...OfficeRoutes.INVOICE_TEMPLATES,
    path: 'invoice-templates',
    element: <InvoiceTemplatesPage />,
    principalUserRequiresCompany: true,
    permission: PermissionV2.OFFICE_FINANCIAL_INFORMATION_INVOICES_VIEW,
  },
  BUSINESS_UNITS: {
    ...OfficeRoutes.BUSINESS_UNITS,
    path: 'business-units',
    element: <BusinessUnitsSettingsPage />,
    principalUserRequiresCompany: true,
  },
  MIGRATION_TOOLS: {
    ...OfficeRoutes.MIGRATION_TOOLS,
    path: 'dev-support-tools',
    element: <DevSupportToolsPage />,
    principalUserRequiresCompany: true,
  },
} satisfies ReactRouteMap
