import { DiscountType } from '@breezy/shared'
import { faDollar, faPercent } from '@fortawesome/pro-regular-svg-icons'
import { View } from '@react-pdf/renderer'
import { Style } from '@react-pdf/types'
import { tailwindColors } from '../../../../../tailwind.config'
import { FontAwesomePdfIcon } from './FontAwesomePdfIcon'

type PDFDiscountIconProps = {
  discountType: DiscountType
  width?: number
  height?: number
  style?: Style
}

export const PDFDiscountIcon = ({
  discountType,
  width = 24,
  height = 24,
  style,
}: PDFDiscountIconProps) => {
  const iconWidth = (width * 2) / 3
  const iconHeight = (height * 2) / 3
  return (
    <View
      style={{
        backgroundColor: tailwindColors['bz-green'][200],
        borderRadius: 100,
        width: width,
        height: height,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}
    >
      <View style={{ width: iconWidth, height: iconHeight }}>
        <FontAwesomePdfIcon
          faIcon={discountType === DiscountType.FLAT ? faDollar : faPercent}
          style={{ color: tailwindColors['bz-green'][800] }}
        />
      </View>
    </View>
  )
}
