import {
  BzDateFns,
  EquipmentType,
  LocalDateString,
  MaintenanceOpportunity,
  TimeZoneId,
} from '@breezy/shared'
import { DocumentType, gql } from '../../../generated'
import { toMaintenancePlanOpportunityMinimalInfo } from '../../../gql/fragments/MaintenancePlanMinimal.gql'

export const LOCATIONS_WITHIN_RADIUS_QUERY = gql(/* GraphQL */ `
  query LocationsWithinRadius(
    $radius: Float!
    $point: geography!
    $appointmentAddressGuids: [uuid!]!
  ) {
    locations(
      where: {
        _and: [
          {
            address: {
              geoLocation: { _stDWithin: { distance: $radius, from: $point } }
            }
          }
          { address: { addressGuid: { _nin: $appointmentAddressGuids } } }
        ]
      }
      limit: 100
    ) {
      locationGuid
      lastVisitedAt
      displayName
      address {
        ...Address
        distBetweenMeters(args: { address_to_compare: $point })
      }
      installedEquipment {
        installedEquipmentGuid
        equipmentType
        installationDate
      }
      maintenancePlans {
        ...MaintenancePlanOpportunityMinimalInfo
      }
      accountLocations {
        account {
          accountGuid
          tags {
            tag {
              ...TagMinimal
            }
          }
          accountContacts(where: { primary: { _eq: true } }) {
            contact {
              firstName
              lastName
              primaryPhoneNumber {
                phoneNumber
              }
              primaryEmailAddress {
                emailAddress
              }
            }
          }
        }
      }
    }
  }
`)

export type FetchNearbyLocation = DocumentType<
  typeof LOCATIONS_WITHIN_RADIUS_QUERY
>['locations'][number]

export const toMaintenanceOpportunity = (
  location: FetchNearbyLocation,
  tzId: TimeZoneId,
): MaintenanceOpportunity => {
  const maintenancePlan =
    location.maintenancePlans.length > 0
      ? toMaintenancePlanOpportunityMinimalInfo(location.maintenancePlans[0])
      : undefined
  return {
    locationGuid: location.locationGuid,
    accountGuid: location.accountLocations[0]?.account.accountGuid,
    address: location.address,
    primaryContact: {
      firstName:
        location.accountLocations[0]?.account.accountContacts[0].contact
          .firstName,
      lastName:
        location.accountLocations[0]?.account.accountContacts[0].contact
          .lastName,
      phoneNumber:
        location.accountLocations[0]?.account.accountContacts[0].contact
          .primaryPhoneNumber?.phoneNumber,
      emailAddress:
        location.accountLocations[0]?.account.accountContacts[0].contact
          .primaryEmailAddress?.emailAddress,
    },
    maintenancePlan,
    lastVisitedAt: location.lastVisitedAt,
    lastMaintenanceVisitAt: maintenancePlan?.lastVisitedAt,
    equipmentMinimal: location.installedEquipment.map(equipment => ({
      equipmentGuid: equipment.installedEquipmentGuid,
      equipmentType: equipment.equipmentType as EquipmentType,
      installedAt: equipment.installationDate
        ? BzDateFns.formatISO(
            BzDateFns.parseLocalDate(
              equipment.installationDate as unknown as LocalDateString,
            ),
            tzId,
          )
        : undefined,
    })),
    accountTags: location.accountLocations[0]?.account.tags.map(tag => tag.tag),
    distFromMeters: location.address.distBetweenMeters ?? 0,
  }
}
