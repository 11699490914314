import {
  BusinessResourceConflictException,
  BusinessResourceDoesNotExistException,
  bzExpect,
  Event,
  EventPayload,
  IEventSourceReferenceNumberProvider,
  IEventStore,
  InvalidEntityStateException,
  isNullish,
  IsoDateString,
  MissingCaseError,
  Mutable,
  nextGuid,
  ReadOnlyEventStore,
  ThisShouldNeverHappenError,
} from '../../../common'
import { AccountGuid } from '../../Accounts/Account'
import { ForCompany } from '../../Company/Company'
import { DiscountType } from '../../Discounts/DiscountTypes'
import { DisplayIdGenerator } from '../../DisplayId/DisplayId'
import { PricebookTaxRateGuid } from '../../Pricebook/PricebookTypes'
import { ForUser, UserGuid } from '../../Users/User'
import { Estimate } from '../Estimates/Estimate'
import { EstimateFinalizeInput } from '../Estimates/EstimateTypes'
import { ConcreteFinanceDocumentType, FinanceDocumentViewModel } from '../FinanceDocumentTypes'
import { Invoice } from '../Invoicing/Invoice'
import { InvoiceEntityTypeName, InvoiceFinalizeInput, InvoiceGuid } from '../Invoicing/InvoiceTypes'
import {
  calculateCartOrderSummary,
  CartDiscountSetEventTypeName,
  CartDiscountV2RemovedEventTypeName,
  CartDiscountV2UpsertEventTypeName,
  CartItem,
  CartItemGuidContainer,
  CartItemRemovedEventTypeName,
  CartItemSetEventData,
  CartItemSetEventTypeName,
  CartNoDiscount,
  ComprehensiveTransactionViewModel,
  CreditSetEventData,
  CreditSetEventTypeName,
  DiscountGuidContainer,
  DiscountSetEventData,
  DiscountV2EventData,
  EstimateCreatedFromTransactionEventData,
  EstimateCreatedFromTransactionEventTypeName,
  ICartEntity,
  InvoiceCreatedEventTypeName,
  isTransactionDisplayNameSetEvent,
  isTransactionSummarySetEvent,
  LinkAddedEventTypeName,
  LinkedEntitiesEventData,
  LinkRemovedEventTypeName,
  TaxRateSetEventData,
  TaxRateSetEventTypeName,
  toV2Discount,
  TransactionCreatedEvent,
  TransactionCreatedEventData,
  TransactionCreatedEventTypeName,
  TransactionCreatedInput,
  TransactionDisplayNameSetEventData,
  TransactionDisplayNameSetEventTypeName,
  TransactionEntityTypeName,
  TransactionGuid,
  TransactionGuidContainer,
  TransactionInvoiceCreatedEventData,
  TransactionLinkMap,
  TransactionSummarySetEventData,
  TransactionSummarySetEventTypeName,
} from './TransactionTypes'

/** @deprecated */
export class Transaction implements ICartEntity {
  private internalModel: Mutable<ComprehensiveTransactionViewModel> | undefined
  private readonly clock: () => IsoDateString

  static createFinanceViewModeFromEvents = async (
    events: Event<unknown>[],
    entityReq: TransactionGuidContainer,
  ): Promise<FinanceDocumentViewModel> => {
    const x = new Transaction(new ReadOnlyEventStore(events))
    return await x
      .load({ companyGuid: events[0].companyGuid, transactionGuid: entityReq.transactionGuid })
      .then(x => ({ ...x.getComprehensiveViewModel(), documentType: 'Transaction' }))
  }

  constructor(private readonly eventStore: IEventStore, clockOverride?: () => IsoDateString) {
    this.clock = clockOverride ? clockOverride : () => new Date().toISOString()
  }

  // Queries

  isInitialized = (): boolean => !!this.internalModel

  getComprehensiveViewModel = (): ComprehensiveTransactionViewModel => {
    if (!this.internalModel) throw new BusinessResourceDoesNotExistException('Transaction is not initialized')

    return this.internalModel
  }

  getFinanceDocumentViewModel = (): FinanceDocumentViewModel => {
    const vm = this.getComprehensiveViewModel()
    return { ...vm, documentType: TransactionEntityTypeName }
  }

  // Commands

  // General Flow
  // 1. Init/Load the Object
  // 2. Receive Command
  // 3. Validate Command
  // 4. Emit + Persist Events
  // 5. Update Internal Object State

  init = async ({
    companyGuid,
    userGuid,
    accountGuid,
    taxRateGuid,
    rate,
    links,
    items,
    targetDocumentType,
    credit,
    transactionGuid,
  }: TransactionCreatedInput): Promise<TransactionGuid> => {
    if (this.internalModel) throw new BusinessResourceConflictException('Transaction is already initialized')

    const effectiveTransactionGuid = transactionGuid ?? nextGuid()

    this.internalModel = this.createInitialViewModel({
      actingUserGuid: userGuid,
      transactionGuid: effectiveTransactionGuid,
      companyGuid,
      transactionVersion: 0,
      taxRateGuid,
      rate,
      accountGuid,
      links,
      items,
      targetDocumentType,
      credit,
    })

    const e = this.createNextEvent<TransactionCreatedEventData>({
      eventType: TransactionCreatedEventTypeName,
      eventData: { taxRateGuid, rate, links, accountGuid, items, targetDocumentType, credit },
      userGuid,
    })

    return await this.persistAndApplyEvent(e).then(() => effectiveTransactionGuid)
  }

  load = async (req: ForCompany<TransactionGuidContainer>): Promise<Transaction> => {
    this.requireNotInitialized()
    const events = await this.eventStore.read({ entityGuid: req.transactionGuid, companyGuid: req.companyGuid })
    await events.map(this.applyEvent)
    return this
  }

  setItem = async (input: ForUser<CartItemSetEventData>): Promise<void> => {
    if (isNullish(input.unitPriceUsd)) {
      throw new InvalidEntityStateException('Unit Price must be set for a Transaction Item')
    }
    return this.applyEventIfInitialized(CartItemSetEventTypeName, input)
  }

  setSummary = (input: ForUser<TransactionSummarySetEventData>) =>
    this.applyEventIfInitialized(TransactionSummarySetEventTypeName, input)

  setDisplayName = (input: ForUser<TransactionDisplayNameSetEventData>) =>
    this.applyEventIfInitialized(TransactionDisplayNameSetEventTypeName, input)

  setCredit = async (input: ForUser<CreditSetEventData>): Promise<void> =>
    this.applyEventIfInitialized(CreditSetEventTypeName, input)

  removeItem = (input: ForUser<CartItemGuidContainer>): Promise<void> =>
    this.applyEventIfInitialized(CartItemRemovedEventTypeName, input)

  /** @deprecated Use upsertDiscountV2 and removeDiscountV2 instead */
  setDiscount = (input: ForUser<DiscountSetEventData>): Promise<void> =>
    this.applyEventIfInitialized(CartDiscountSetEventTypeName, input)

  upsertDiscountV2 = async (input: ForUser<DiscountV2EventData>): Promise<void> => {
    const currentDiscounts = this.internalModel?.discountsV2 || []

    const hasDifferentType = currentDiscounts.some(
      discount => discount.discountGuid !== input.discountGuid && discount.type !== input.type,
    )

    if (hasDifferentType) {
      const existingType = currentDiscounts[0].type
      const newType = input.type
      if (existingType === DiscountType.RATE && newType === DiscountType.FLAT) {
        throw new Error('Cannot apply a flat discount when a rate discount is already present')
      }
      if (existingType === DiscountType.FLAT && newType === DiscountType.RATE) {
        throw new Error('Cannot apply a rate discount when a flat discount is already present')
      }
      throw new Error('Cannot upsert discount with a different type from existing discounts')
    }
    if (
      currentDiscounts.some(
        discount => discount.discountGuid !== input.discountGuid && discount.type === DiscountType.RATE,
      )
    ) {
      throw new Error('Cannot apply more than one rate discount')
    }

    return this.applyEventIfInitialized(CartDiscountV2UpsertEventTypeName, input)
  }

  removeDiscountV2 = (input: ForUser<DiscountGuidContainer>): Promise<void> =>
    this.applyEventIfInitialized(CartDiscountV2RemovedEventTypeName, input)

  setTaxRate = (input: ForUser<TaxRateSetEventData>): Promise<void> =>
    this.applyEventIfInitialized(TaxRateSetEventTypeName, input)

  // addDiscount
  // updateDiscount
  // removeDiscount

  generateInvoice = async (
    input: ForUser<InvoiceFinalizeInput>,
    invoiceEventStore: IEventStore,
    invoiceReferenceNumberCreator: IEventSourceReferenceNumberProvider,
    displayIdGenerator: DisplayIdGenerator,
    invoiceGuid?: InvoiceGuid,
  ): Promise<Invoice> => {
    if (!this.internalModel) {
      throw new BusinessResourceDoesNotExistException('Transaction is not initialized')
    }

    const invoice = new Invoice(invoiceEventStore, this.clock)
    const vm = this.getComprehensiveViewModel()

    if (vm.items.length === 0) {
      throw new InvalidEntityStateException('Transaction has no items. An Invoice requires at least one item.')
    }

    if (vm.subtotalPriceUsd < 0) {
      throw new InvalidEntityStateException(
        'Transaction total is negative. An Invoice cannot be issued for an amount less than $0',
      )
    }

    const displayId = (
      await displayIdGenerator({
        companyGuid: vm.companyGuid,
        entityType: 'INVOICE',
      })
    ).toString()

    const referenceNumber = await invoiceReferenceNumberCreator({
      companyGuid: vm.companyGuid,
      entityType: InvoiceEntityTypeName,
    })
    const invoiceArgs = { ...vm, ...input, referenceNumber, displayId, invoiceGuid }

    const { guid: effectiveInvoiceGuid } = await invoice.init(invoiceArgs)

    await this.applyEventIfInitialized(InvoiceCreatedEventTypeName, {
      ...input,
      invoiceGuid: effectiveInvoiceGuid,
      referenceNumber,
    })
    return invoice
  }

  generateEstimate = async (
    input: ForUser<EstimateFinalizeInput>,
    estimatesEventStore: IEventStore,
  ): Promise<Estimate> => {
    if (!this.internalModel) {
      throw new BusinessResourceDoesNotExistException('Transaction is not initialized')
    }

    const vm = this.internalModel
    if (!vm.items || vm.items.length === 0) {
      throw new InvalidEntityStateException('Transaction has no items. An Estimate requires at least one item.')
    }

    if (vm.subtotalPriceUsd < 0) {
      throw new InvalidEntityStateException(
        'Transaction total is negative. An Estimate cannot be issued for an amount less than $0',
      )
    }

    const estimate = new Estimate(estimatesEventStore)
    const params = { ...vm, ...input }
    const { guid: estimateGuid, referenceNumber } = await estimate.init(params)
    await this.applyEventIfInitialized<EstimateCreatedFromTransactionEventData>(
      EstimateCreatedFromTransactionEventTypeName,
      {
        ...input,
        estimateGuid,
        referenceNumber,
        summary: params.summary,
        displayName: params.displayName,
      },
    )
    return estimate
  }

  linkTo = (input: ForUser<LinkedEntitiesEventData>) => this.applyEventIfInitialized(LinkAddedEventTypeName, input)

  unlinkFrom = (input: ForUser<LinkedEntitiesEventData>) =>
    this.applyEventIfInitialized(LinkRemovedEventTypeName, input)

  // Internal

  private applyEvent = (e: Event<unknown>): void => {
    if (e.eventType === TransactionCreatedEventTypeName) {
      const te = e as TransactionCreatedEvent
      this.internalModel = this.createInitialViewModel({
        actingUserGuid: te.actingUserGuid,
        transactionVersion: te.entityVersion,
        transactionGuid: te.entityGuid,
        companyGuid: te.companyGuid,
        accountGuid: te.eventData.accountGuid,
        taxRateGuid: te.eventData.taxRateGuid,
        rate: te.eventData.rate,
        links: te.eventData.links,
        targetDocumentType: te.eventData.targetDocumentType,
        credit: te.eventData.credit,
        items: te.eventData.items,
      })

      return
    }
    if (!this.internalModel) {
      throw new BusinessResourceDoesNotExistException('Transaction is not initialized')
    }

    const vm = this.internalModel
    vm.transactionVersion = e.entityVersion > vm.transactionVersion ? e.entityVersion : vm.transactionVersion
    if (e.eventType === CartItemSetEventTypeName) {
      const te = e as Event<CartItem>
      vm.items = vm.items.filter(i => i.itemGuid !== te.eventData.itemGuid).concat([te.eventData])
      this.updateOrderSummary()
    } else if (e.eventType === CartItemRemovedEventTypeName) {
      const te = e as Event<CartItemGuidContainer>
      vm.items = vm.items.filter(i => i.itemGuid !== te.eventData.itemGuid)
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountSetEventTypeName) {
      const te = e as Event<DiscountSetEventData>
      vm.discount = te.eventData
      if ((te.eventData.discountAmountUsd ?? 0) !== 0 || (te.eventData.discountRate ?? 0) !== 0) {
        vm.discountsV2 = [toV2Discount(te.eventData)]
      }
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountV2UpsertEventTypeName) {
      const te = e as Event<DiscountV2EventData>
      const existingIndex = vm.discountsV2.findIndex(d => d.discountGuid === te.eventData.discountGuid)
      if (existingIndex !== -1) {
        vm.discountsV2[existingIndex] = te.eventData
      } else {
        vm.discountsV2.push(te.eventData)
      }
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountV2RemovedEventTypeName) {
      const te = e as Event<DiscountGuidContainer>
      const existingIndex = vm.discountsV2.findIndex(d => d.discountGuid === te.eventData.discountGuid)
      if (existingIndex !== -1) {
        vm.discountsV2.splice(existingIndex, 1)
      }
      this.updateOrderSummary()
    } else if (e.eventType === CreditSetEventTypeName) {
      const te = e as Event<CreditSetEventData>
      vm.credit = te.eventData
      this.updateOrderSummary()
    } else if (e.eventType === InvoiceCreatedEventTypeName) {
      const te = e as Event<TransactionInvoiceCreatedEventData>
      if (!vm.childInvoiceGuids) vm.childInvoiceGuids = []
      vm.childInvoiceGuids.push(te.entityGuid)
    } else if (e.eventType === EstimateCreatedFromTransactionEventTypeName) {
      const te = e as Event<TransactionInvoiceCreatedEventData>
      if (!vm.childEstimateGuids) vm.childEstimateGuids = []
      vm.childEstimateGuids.push(te.entityGuid)
    } else if (e.eventType === TaxRateSetEventTypeName) {
      const te = e as Event<TaxRateSetEventData>
      vm.taxRate = te.eventData
      this.updateOrderSummary()
    } else if (e.eventType === LinkAddedEventTypeName) {
      const te = e as Event<LinkedEntitiesEventData>
      for (const entity of te.eventData.entities) {
        vm.links[entity.guidType] = entity.guid
      }
    } else if (e.eventType === LinkRemovedEventTypeName) {
      const te = e as Event<LinkedEntitiesEventData>
      for (const entity of te.eventData.entities) {
        // validate that we are unlinking the right Guid
        if (vm.links[entity.guidType] === entity.guid) vm.links[entity.guidType] = undefined
      }
    } else if (isTransactionSummarySetEvent(e)) {
      vm.summary = e.eventData.summary
      this.updateOrderSummary()
    } else if (isTransactionDisplayNameSetEvent(e)) {
      vm.displayName = e.eventData.displayName
      this.updateOrderSummary()
    } else {
      throw new MissingCaseError(`Unknown event type: ${e.eventType}`)
    }
    return
  }

  public static deriveDiscountFromDiscountV2 = (vm: ComprehensiveTransactionViewModel): DiscountSetEventData => {
    const discountType = vm.discountsV2.reduce((_, curr) => curr.type, DiscountType.FLAT)
    if (discountType === DiscountType.FLAT) {
      return vm.discountsV2.reduce(
        (acc, curr) => {
          if (curr.type === DiscountType.FLAT) {
            return {
              type: DiscountType.FLAT,
              discountAmountUsd: (acc.discountAmountUsd || 0) + (curr.discountAmountUsd || 0),
              discountRate: null,
            }
          }
          return acc
        },
        { type: DiscountType.FLAT, discountAmountUsd: 0, discountRate: null },
      )
    }
    if (discountType === DiscountType.RATE) {
      return vm.discountsV2.reduce(
        (acc, curr) => {
          if (curr.type === DiscountType.RATE) {
            return {
              type: DiscountType.RATE,
              discountRate: (acc.discountRate || 0) + (curr.discountRate || 0),
              discountAmountUsd: null,
            }
          }
          return acc
        },
        { type: DiscountType.RATE, discountRate: 0, discountAmountUsd: null },
      )
    }

    throw new ThisShouldNeverHappenError(`Unrecognized discount type: ${discountType}`)
  }

  private updateOrderSummary = () => {
    if (!this.internalModel) throw new BusinessResourceDoesNotExistException('Transaction is not initialized')
    const vm = this.internalModel

    vm.discount = Transaction.deriveDiscountFromDiscountV2(vm)

    const cartUpdate = calculateCartOrderSummary(vm)

    this.internalModel = {
      ...vm,
      ...cartUpdate,
    }
  }

  private requireNotInitialized = (): void => {
    if (this.isInitialized()) {
      throw new BusinessResourceConflictException('Transaction is already initialized')
    }
  }

  private requireInitialized = (): void => {
    if (!this.isInitialized()) {
      throw new BusinessResourceConflictException('Transaction is not initialized')
    }
  }

  private applyEventIfInitialized = async <TEventData>(
    eventType: string,
    userInput: ForUser<TEventData>,
  ): Promise<void> => {
    this.requireInitialized()
    const e = this.createNextEvent<TEventData>({ eventType, eventData: userInput, userGuid: userInput.userGuid })
    return this.persistAndApplyEvent(e)
  }

  // prettier-ignore
  private persistAndApplyEvent = async (e: Event<unknown>): Promise<void> => {
    await this.eventStore.create(e)
    this.applyEvent(e)
  }

  private createInitialViewModel = ({
    actingUserGuid,
    transactionGuid,
    companyGuid,
    accountGuid,
    transactionVersion,
    taxRateGuid,
    rate,
    targetDocumentType,
    links,
    items,
    credit,
    summary,
    displayName,
  }: {
    actingUserGuid: UserGuid
    transactionGuid: TransactionGuid
    companyGuid: string
    accountGuid: AccountGuid
    transactionVersion: number
    taxRateGuid: PricebookTaxRateGuid
    rate: number
    targetDocumentType: ConcreteFinanceDocumentType
    links: TransactionLinkMap
    items?: CartItem[]
    credit?: CreditSetEventData
    summary?: string
    displayName?: string
  }): ComprehensiveTransactionViewModel => ({
    originatingUserGuid: actingUserGuid,
    transactionGuid,
    transactionVersion,
    companyGuid,
    accountGuid,
    items: items ?? [],
    discount: CartNoDiscount,
    discountsV2: [],
    taxRate: {
      taxRateGuid,
      rate,
    },
    links,
    subtotalPriceUsd: 0,
    totalPriceUsd: 0,
    discountAmountUsd: 0,
    creditAmountUsd: 0,
    taxAmountUsd: 0,
    targetDocumentType,
    credit,
    childEstimateGuids: [],
    childInvoiceGuids: [],
    summary,
    displayName,
  })

  private createNextEvent = <TEventData>({
    eventType,
    eventData,
    userGuid,
  }: ForUser<EventPayload<TEventData>>): Event<TEventData> => {
    const { transactionGuid, companyGuid, transactionVersion } = bzExpect(
      this.internalModel,
      'Transaction internal model',
    )
    const nextVersion = transactionVersion + 1

    return {
      companyGuid,
      entityType: TransactionEntityTypeName,
      entityGuid: transactionGuid,
      entityVersion: nextVersion,
      actingUserGuid: userGuid,
      occurredAt: this.clock(),
      eventType,
      eventData,
    }
  }
}
