import { Dfns, JobClass, R, dates, isNullish } from '@breezy/shared'
import { useMemo } from 'react'
import { Job } from '../../gql/queries/Jobs.gql'
import { getAvatarShortStringForPerson } from '../../utils/TechnicianResource'
import { userColor } from '../../utils/color-utils'
import { TechnicianResource } from '../Scheduler/scheduleUtils'

export type BasicTech = Pick<
  TechnicianResource,
  | 'avatarColor'
  | 'avatarShortString'
  | 'userGuid'
  | 'deactivatedAt'
  | 'avatarImageUrl'
>

export type Status = {
  id: string
  name: string
  icon?: React.ReactNode
  summary?: React.ReactNode
}
export type Stage = {
  name: string
  statuses: Status[]
}

export type BasicKanbanData = {
  id: string
  boardId: string
  statusId: string
}

export type Board = {
  boardId: string
  statuses: Status[]
}

type StatusIdToStatusMap = Record<string, Status>

export type KanbanJob = BasicKanbanData & {
  job: Job & { jobClass: JobClass; isCreatedLinkedJob: boolean }
}

export const useStatusIdToStatusMap = (board: Board) =>
  useMemo(() => {
    const map: StatusIdToStatusMap = {}

    for (const status of board.statuses) {
      map[status.id] = status
    }
    return map
  }, [board])

// boardId -> stage name -> status name -> status id
export type BoardIdStageStatusToStatusIdMap = Record<
  string,
  Record<string, Record<string, string>>
>

type BoardId = string
type StatusName = string
type StatusId = string
export type BoardIdToStatusIdMap = Record<BoardId, Record<StatusName, StatusId>>

export const useBoardIdToStatusIdMap = (board: Board) =>
  useMemo(() => {
    const map: BoardIdToStatusIdMap = {}
    const statusMap: Record<StatusName, StatusId> = {}
    for (const status of board.statuses) {
      statusMap[status.name] = status.id
    }
    map[board.boardId] = statusMap

    return map
  }, [board])

type KanbanStatusName = string
export type GroupedKanbanData<T extends BasicKanbanData> = Record<
  KanbanStatusName,
  T[]
>

export const useGroupedKanbanData = <T extends BasicKanbanData>(
  data: readonly T[],
  board: Board,
) => {
  const statusIdToStatusMap = useStatusIdToStatusMap(board)

  return useMemo(() => {
    // status -> data
    const map: GroupedKanbanData<T> = {}
    for (const datum of data) {
      const status = statusIdToStatusMap[datum.statusId]
      const statusList = map[status.name] ?? []
      statusList.push(datum)
      map[status.name] = statusList
    }

    return map
  }, [data, statusIdToStatusMap])
}

export const useKanbanStatuses = <T extends BasicKanbanData>(
  groupedKanbanData: GroupedKanbanData<T>,
  board: Board,
  hideEmptyStatuses?: boolean,
) =>
  useMemo(() => {
    return board.statuses.reduce((acc, status) => {
      const items = groupedKanbanData[status.name] || []
      if (hideEmptyStatuses && items.length === 0) {
        return acc
      }

      acc.push({
        statusName: status.name,
        items,
        icon: status.icon,
        summary: status.summary,
      })
      return acc
    }, [] as Array<{ statusName: string; items: T[]; icon?: React.ReactNode; summary?: React.ReactNode }>)
  }, [groupedKanbanData, board, hideEmptyStatuses])

export const getNextAppointmentAndTechs = (
  appointments: Job['appointments'],
  tzId: string,
): [string, BasicTech[]] => {
  let nextAppointment: (typeof appointments)[number] | undefined = undefined

  const now = new Date()

  for (const appointment of appointments) {
    const appointmentStart = Dfns.parseISO(appointment.appointmentWindowStart)
    if (Dfns.isBefore(now, appointmentStart)) {
      continue
    }
    if (
      !nextAppointment ||
      appointment.appointmentWindowStart <
        nextAppointment.appointmentWindowStart
    ) {
      nextAppointment = appointment
    }
  }

  if (!nextAppointment) {
    return ['', []]
  }

  const range = dates.calculateDateTimeWindow(
    nextAppointment.appointmentWindowStart,
    nextAppointment.appointmentWindowEnd,
    tzId,
    { includeDate: true },
  )

  return [
    `${R.pipe(
      Dfns.parseISO,
      Dfns.format('eee'),
    )(nextAppointment.appointmentWindowStart)}, ${range}`,
    nextAppointment.assignments.map(assignment => ({
      ...assignment.technician,
      avatarShortString: getAvatarShortStringForPerson(assignment.technician),
      avatarColor: !isNullish(assignment.technician.userPrimaryHueHex)
        ? assignment.technician.userPrimaryHueHex
        : userColor(assignment.technician.userGuid),
      avatarImageUrl: assignment.technician.avatarPhoto?.cdnUrl,
    })),
  ]
}
