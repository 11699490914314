import { z } from 'zod'
import {
  AsyncFn,
  BzDateFns,
  IsoDateString,
  TimeZoneId,
  bzExpect,
  formatUsc,
  stripHtml,
  usCentsToUsd,
} from '../../../common'
import { guidSchema, isoDateStringSchema, uscSchema } from '../../../contracts'
import { AccountGuid } from '../../Accounts/Account'
import { CompanyGuid, CompanyGuidContainer } from '../../Company/Company'
import { InvoiceV2Enriched, invoiceV2ToInvoiceV1Status } from '../../InvoicesV2/InvoicesV2'
import { JobGuid } from '../../Job'
import { Guid, bzOptional } from '../../common-schemas'
import { ContactGuid } from '../../contacts/Contact'
import { InvoiceGuid, InvoiceStatuses } from '../Invoicing/InvoiceTypes'
import { PaymentRecordWriter } from '../Payments/PaymentTypes'
import { CancelLoanTransactionRequest, CreateLoanTransactionRequest } from './LoanTransactions'
import { PrequalRecord } from './Prequal'

const FINANCEABLE_INVOICE_STATUSES = [InvoiceStatuses.CREATED, InvoiceStatuses.ACCEPTED, InvoiceStatuses.PRESENTED]

export const MIN_FINANCEABLE_AMOUNT_USC = 50000
export const MIN_FINANCEABLE_AMOUNT_USD = usCentsToUsd(MIN_FINANCEABLE_AMOUNT_USC)
export const MAX_FINANCEABLE_AMOUNT_USC = 2500000
export const MAX_FINANCEABLE_AMOUNT_USD = usCentsToUsd(MAX_FINANCEABLE_AMOUNT_USC)

export const WISETACK_TRANSACTION_CALLBACK_URI = 'webhooks/wisetack-transaction'
export const WISETACK_PREQUAL_CALLBACK_URI = 'webhooks/wisetack-prequal'
export const FINANCING_OPTIONS = ['Loan Application', 'Pre-Qualification'] as const

export const DECLINEABLE_LOAN_STATUSES = ['INITIATED', 'PENDING', 'ACTIONS_REQUIRED']
export const CANCELABLE_LOAN_STATUSES = [
  'PENDING',
  'INITIATED',
  'ACTIONS_REQUIRED',
  'AUTHORIZED',
  'LOAN_TERMS_ACCEPTED',
]
export const LOAN_PAYMENT_COMPLETED_STATUSES = ['SETTLED', 'LOAN_CONFIRMED']
export const PAYABLE_LOAN_STATUSES = ['LOAN_CONFIRMED', 'SETTLED']

export type FinancingOption = (typeof FINANCING_OPTIONS)[number]

const LoanChangedStatusSchema = z.union([
  z.literal('INITIATED'),
  z.literal('ACTIONS_REQUIRED'),
  z.literal('AUTHORIZED'),
  z.literal('LOAN_TERMS_ACCEPTED'),
  z.literal('LOAN_CONFIRMED'),
  z.literal('SETTLED'),
  z.literal('DECLINED'),
  z.literal('EXPIRED'),
  z.literal('CANCELED'),
  z.literal('REFUNDED'),
])

export type LoanChangedStatus = z.infer<typeof LoanChangedStatusSchema>

const LoanStatusSchema = z.union([LoanChangedStatusSchema, z.literal('PENDING')])
export type LoanStatus = z.infer<typeof LoanStatusSchema>

export const LoanStatusDisplayNames: Record<LoanStatus, string> = {
  INITIATED: 'Initiated',
  ACTIONS_REQUIRED: 'Actions Required',
  AUTHORIZED: 'Authorized',
  LOAN_TERMS_ACCEPTED: 'Terms Accepted',
  LOAN_CONFIRMED: 'Loan Confirmed',
  SETTLED: 'Settled',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  CANCELED: 'Canceled',
  REFUNDED: 'Refunded',
  PENDING: 'Pending',
}

export const LoanStatusAbbrDisplayNames: Record<LoanStatus, string> = {
  ...LoanStatusDisplayNames,
  ACTIONS_REQUIRED: 'Actions Req.',
}

export const formatLoanAmount = (loanRecord: LoanRecord) => {
  const latestStatus = loanRecord.latestLoanRecordStatus.loanStatus

  let amount = loanRecord.requestedLoanAmountUsc

  switch (latestStatus) {
    case 'SETTLED':
    case 'AUTHORIZED':
    case 'LOAN_CONFIRMED':
    case 'LOAN_TERMS_ACCEPTED':
      amount = bzExpect(loanRecord.approvedLoanAmountUsc)
      break
    case 'DECLINED':
      return '—'
  }

  return formatUsc(amount)
}

const LoanRecordStatusWebhookSchema = z.object({
  transactionGuid: guidSchema,
  merchantGuid: guidSchema,
  requestedLoanAmountUsc: uscSchema,
  approvedLoanAmountUsc: bzOptional(uscSchema),
  settledLoanAmountUsc: bzOptional(uscSchema),
  processingFeeUsc: bzOptional(uscSchema),
  loanChangedStatus: LoanChangedStatusSchema,
  loanStatusDetail: bzOptional(z.string()),
  occurredAt: isoDateStringSchema,
  expiresAt: bzOptional(isoDateStringSchema),
})

type LoanStatusContainer<T> = {
  status: T
}

export type LoanRecordStatusWebhook = z.infer<typeof LoanRecordStatusWebhookSchema>
export type LoanRecordStatusUpdateResponse = LoanStatusContainer<LoanStatus> & {
  loanRecordGuid: Guid
}
export type LoanRecordStatusConsumer = AsyncFn<LoanRecordStatusWebhook, LoanRecordStatusUpdateResponse>

export type LoanRecordWriterRequest =
  | {
      type: 'create'
      data: CreateLoanTransactionRequest
    }
  | {
      type: 'upsert-external-transaction-status'
      data: Omit<LoanRecordStatusWebhook, 'merchantGuid'>
    }
  | {
      type: 'cancel'
      data: CancelLoanTransactionRequest
    }

export type LoanRecordWriter = AsyncFn<LoanRecordWriterRequest, LoanRecordStatusUpdateResponse>

export type LoanRecordStatus = {
  loanStatus: LoanStatus
  loanStatusDetail?: string
  occurredAt: IsoDateString
  createdAt: IsoDateString
}

export type LoanRecord = {
  loanRecordGuid: Guid
  companyGuid: CompanyGuid
  accountGuid: AccountGuid
  invoiceGuid: InvoiceGuid
  contactGuid: ContactGuid
  jobGuid?: JobGuid
  merchantGuid: Guid
  externalTransactionGuid: Guid
  requestedLoanAmountUsc: number
  approvedLoanAmountUsc?: number
  settledLoanAmountUsc?: number
  processingFeeUsc?: number
  loanApplicationLink: string
  createdAt: IsoDateString
  updatedAt: IsoDateString
  expiresAt?: IsoDateString
  loanRecordStatuses: LoanRecordStatus[]
  latestLoanRecordStatus: LoanRecordStatus
  contactName: string
}

export type LoanRecordQuerierRequest =
  | {
      type: 'read-by-loan-record-guid'
      data: CompanyGuidContainer & {
        loanRecordGuid: Guid
      }
    }
  | {
      type: 'read-by-guids'
      data: {
        accountGuid: Guid
        companyGuid: Guid
        invoiceGuid: Guid
        contactGuid: Guid
        jobGuid?: Guid
      }
    }
  | {
      type: 'read-by-transaction-guid'
      data: {
        transactionGuid: Guid
      }
    }
  | {
      type: 'read-by-job'
      data: {
        jobGuid: Guid
        companyGuid: Guid
      }
    }
  | {
      type: 'read-by-account'
      data: {
        accountGuid: Guid
        companyGuid: Guid
      }
    }
  | {
      type: 'read-by-invoice'
      data: {
        invoiceGuid: Guid
        companyGuid: Guid
      }
    }

export type LoanRecordQuerier = AsyncFn<LoanRecordQuerierRequest, LoanRecord[]>

export type WithMerchantId<T> = T & {
  merchantId: string
}

export const GetJobLoanRecordsSchema = z.object({
  jobGuid: guidSchema,
})

export type GetJobLoanRecordsRequest = z.infer<typeof GetJobLoanRecordsSchema>

export type CancelLoanIfActiveWhenOtherPaymentMade = PaymentRecordWriter

export const loanPromoRequestSchema = z.object({
  merchantGuid: guidSchema,
  amountUsc: uscSchema,
})

export type LoanPromoRequest = z.infer<typeof loanPromoRequestSchema>
export type LoanPromoResponse = {
  asLowAsDescription: string
  disclosure: string
}
export type LoanPromoReader = AsyncFn<LoanPromoRequest, LoanPromoResponse>

export const isFinanceableAmountUsd = (amountDueUsd: number) => amountDueUsd >= MIN_FINANCEABLE_AMOUNT_USD

export const getLoanPromoAmountUsc = (amountUsc: number) => Math.min(amountUsc, MAX_FINANCEABLE_AMOUNT_USC)

export const isFinanceable = (invoice: { invoiceStatus: InvoiceStatuses; balanceAmountUsd: number }) =>
  FINANCEABLE_INVOICE_STATUSES.includes(invoice.invoiceStatus) && isFinanceableAmountUsd(invoice.balanceAmountUsd)

export const canCancelLoan = (loanStatus: LoanStatus) => CANCELABLE_LOAN_STATUSES.includes(loanStatus)

export const getServiceDescription = (invoice: AbridgedFinancingInvoice, tzId: TimeZoneId) => {
  const summaryText = invoice?.summary || undefined
  const serviceCompletionDateText = invoice?.issuedAt
    ? `For HVAC Job completed on ${BzDateFns.format(BzDateFns.parseISO(invoice?.issuedAt, tzId), 'MMM d, yyyy')}`
    : undefined
  return summaryText ?? serviceCompletionDateText ?? ''
}

export const convertInvoiceV2EnrichedToAbridgedFinancingInvoice = (
  invoice: InvoiceV2Enriched,
): AbridgedFinancingInvoice => ({
  invoiceGuid: invoice.invoiceGuid,
  displayId: invoice.displayId,
  totalAmountUsd: usCentsToUsd(invoice.totalUsc),
  issuedAt: invoice.issuedAt,
  invoiceStatus: invoiceV2ToInvoiceV1Status(invoice.status),
  balanceAmountUsd: usCentsToUsd(invoice.dueUsc),
  summary: stripHtml(invoice.messageHtml ?? ''),
})

const getLoanPromoCacheKey = (request: LoanPromoRequest) => `${request.merchantGuid}-${request.amountUsc}`

export const createCachedLoanPromoReader = (inner: LoanPromoReader): LoanPromoReader => {
  const cache: Record<string, LoanPromoResponse> = {}
  return async (input: LoanPromoRequest) => {
    const cacheKey = getLoanPromoCacheKey(input)
    if (cache[cacheKey]) {
      return cache[cacheKey]
    }
    const result = await inner(input)
    cache[cacheKey] = result
    return result
  }
}

export const getFinancingRecordType = (record: PrequalRecord | LoanRecord) => {
  if ('prequalRecordGuid' in record) {
    return 'prequal'
  }
  return 'loan'
}

export const isLoanRecord = (record: PrequalRecord | LoanRecord): record is LoanRecord => {
  return 'loanRecordGuid' in record
}

export const isPrequalRecord = (record: PrequalRecord | LoanRecord): record is PrequalRecord => {
  return 'prequalRecordGuid' in record
}

export type AbridgedFinancingInvoice = {
  invoiceGuid: InvoiceGuid
  displayId: string
  totalAmountUsd: number
  issuedAt?: IsoDateString
  invoiceStatus: InvoiceStatuses
  balanceAmountUsd: number
  summary?: string
}
