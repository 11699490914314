import { gql } from '../../../generated'

export const INVOICE_COLLAPSIBLE_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription InvoiceCollapsibleData($where: InvoicesBoolExp!) {
    invoices(where: $where, orderBy: { issuedAt: DESC_NULLS_FIRST }) {
      serviceCompletionDate
      displayId
      displayIdV2
      invoiceGuid
      status
      totalUsc
      maintenancePlanLink {
        maintenancePlanGuid
      }
      jobLink {
        jobGuid
        job {
          displayId
          jobType {
            name
          }
        }
      }
      invoiceTerm
      issuedAt
      paymentRecords {
        paymentMethod
        paymentRecordGuid
        amountUsd
      }
      createdByUser {
        fullName
      }
      cartItems: invoiceCartItems(orderBy: { seq: ASC }) {
        cartItem {
          name
          unitPriceUsc
          quantity
          isDiscountable
        }
      }
      discounts: invoiceDiscounts {
        discountRate
        type: discountType
        discountAmountUsc
      }
    }
  }
`)
