import {
  PricebookCategoryGuid,
  containsAnyCase,
  nextGuid,
} from '@breezy/shared'
import { Button, Input, Tree, TreeProps } from 'antd'
import { DataNode } from 'antd/lib/tree'

import { useCallback, useMemo, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { trpc } from '../../hooks/trpc'
import { useExpectedCompany } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import { useModalState } from '../../utils/react-utils'
import { usePricebookAdmin } from './hooks/usePricebookAdmin'
import './PricebookAdmin'
import { PricebookCategories } from './PricebookAdminTypes'
import {
  UpsertPricebookCategoryFormValues,
  UpsertPricebookCategoryModal,
} from './UpsertPricebookCategoryModal'
import {
  buildSortedPricebookCategoryTreeStructure,
  getDataNodeParentKey,
  toTreeSelectDataNode,
} from './Utils/utils'

type PricebookCategoriesTreeProps = {
  categories: PricebookCategories
}

export const PricebookCategoriesTree = ({
  categories,
}: PricebookCategoriesTreeProps) => {
  const message = useMessage()
  const { companyGuid } = useExpectedCompany()
  const { categoriesMutated } = usePricebookAdmin()
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [autoExpandParent, setAutoExpandParent] = useState(true)
  const [isCreateTopLevelOpen, openCreateTopLevel, closeCreateTopLevel] =
    useModalState(false)

  const saveCategoryMutation =
    trpc.pricebook['pricebook:save-category'].useMutation()

  const categoriesTree = useMemo(() => {
    const onNewChildCategoryAdded = (
      parentCategoryGuid: PricebookCategoryGuid,
    ) => {
      if (!expandedKeys.includes(parentCategoryGuid))
        setExpandedKeys([parentCategoryGuid, ...expandedKeys])
    }

    return buildSortedPricebookCategoryTreeStructure(categories).map(c =>
      toTreeSelectDataNode(
        searchValue,
        onNewChildCategoryAdded,
        categoriesMutated,
        c,
      ),
    )
  }, [
    categories,
    searchValue,
    expandedKeys,
    setExpandedKeys,
    categoriesMutated,
  ])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    const newExpandedKeys = categories
      .map(item => {
        if (containsAnyCase(item.name, value)) {
          return getDataNodeParentKey(
            item.pricebookCategoryGuid,
            categoriesTree,
          )
        }
        return null
      })
      .filter((item, i, self) => item && self.indexOf(item) === i)
    setExpandedKeys(newExpandedKeys as React.Key[])
    setSearchValue(value)
    setAutoExpandParent(true)
  }

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys)
    setAutoExpandParent(false)
  }

  const createNewTopLevelCategory = useCallback(
    (formValues: UpsertPricebookCategoryFormValues) => {
      saveCategoryMutation.mutate(
        {
          companyGuid,
          name: formValues.name,
          pricebookCategoryGuid: nextGuid(),
          sourcePhotoGuid: formValues.sourcePhotoGuid,
          photoGuid: formValues.photoGuid,
        },
        {
          onSuccess: () => {
            closeCreateTopLevel()
            categoriesMutated()
          },
        },
      )
    },
    [saveCategoryMutation, companyGuid, closeCreateTopLevel, categoriesMutated],
  )

  const onDragEnter: TreeProps<DataNode>['onDragEnter'] = info => {
    setExpandedKeys(info.expandedKeys)
  }

  const onDrop: TreeProps<DataNode>['onDrop'] = async info => {
    const parentCategoryGuid = info.node.key as string
    const pricebookCategoryGuid = info.dragNode.key as string
    const pricebookCategory = categories?.find(
      c => c.pricebookCategoryGuid === pricebookCategoryGuid,
    )

    if (!pricebookCategory) {
      // should not happen, but just in case
      message.error('Category not found')
      return
    }

    if (pricebookCategory.parentCategoryGuid === parentCategoryGuid) {
      message.warning('Category is already a child of this category')
      return
    }

    saveCategoryMutation.mutate(
      {
        ...pricebookCategory,
        pricebookCategoryGuid,
        companyGuid,
        parentCategoryGuid,
      },
      {
        onSuccess: () => {
          message.success('Successfully updated category in Pricebook')
          setExpandedKeys(k => [...k, parentCategoryGuid as React.Key])
          categoriesMutated()
        },
        onError: e => {
          message.error(
            'There was an error updating the Pricebook Category. Please try again.',
          )
        },
      },
    )
  }

  return (
    <div className="pricebook-category-drawer__container">
      <Input
        placeholder="Search categories..."
        onChange={onSearchChange}
        allowClear={true}
        prefix={<AiOutlineSearch />}
      />
      <div className="my-4 flex space-x-2">
        <Button type="primary" onClick={openCreateTopLevel}>
          Create Top Level Category
        </Button>
      </div>
      <Tree
        showLine={{ showLeafIcon: false }}
        treeData={categoriesTree}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onExpand={onExpand}
        selectable={false}
        blockNode={true}
        draggable={true}
        onDragEnter={onDragEnter}
        onDrop={onDrop}
      />
      {isCreateTopLevelOpen && (
        <UpsertPricebookCategoryModal
          onClose={closeCreateTopLevel}
          onSubmit={createNewTopLevelCategory}
          mode="create"
          loading={saveCategoryMutation.isLoading}
        />
      )}
    </div>
  )
}
