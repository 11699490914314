import {
  Account,
  AccountGuidContainer,
  Address,
  BzDateTime,
  CalculatePaths,
  IsoDateString,
  KeysOfType,
  LocationGuidContainer,
  MaintenancePlanMinimalInfo,
  Tag,
  TimeZoneId,
  createPaymentQboLink,
  dates,
  formatMoney,
  isNullish,
} from '@breezy/shared'
import { faCheck, faX } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ColumnType } from 'antd/lib/table'
import {
  ColumnFilterItem,
  CompareFn,
  FilterValue,
} from 'antd/lib/table/interface'
import React from 'react'
import { Link } from 'react-router-dom'
import { BzMonospace } from '../../elements/BzMonospace/BzMonospace'
import { EmDash } from '../../elements/EmDash/EmDash'
import { ViewResourceButton } from '../../elements/ViewResourceButton/ViewResourceButton'
import { useMapLink } from '../../hooks/useMapLink'
import { stopPropagation } from '../../utils/react-utils'
import { QuickbooksViewLinkButton } from '../AccountingIntegration/QuickbooksOnline/QuickbooksViewLinkButton'
import {
  AddressViewType,
  LocationAddressView,
} from '../Addresses/LocationAddressView/LocationAddressView'
import { MaintenancePlanVipElement } from '../MaintenancePlanVipElement/MaintenancePlanVipElement'

export type LocationLinkProps = LocationGuidContainer & {
  readonly displayName?: string
  readonly address: Address
  readonly viewType?: AddressViewType
  readonly compactMapLink?: boolean
}

export const DataTableLocationLink = React.memo<LocationLinkProps>(
  ({ locationGuid, address, viewType, displayName, compactMapLink }) => {
    const [mapsButtonUrl] = useMapLink(address)
    return (
      <>
        <Link to={`/locations/${locationGuid}`} onClick={stopPropagation}>
          <LocationAddressView
            displayName={displayName}
            address={address}
            viewType={viewType ?? AddressViewType.Compact_Street}
          />
        </Link>
        <a
          href={mapsButtonUrl}
          className="mr-1"
          target="_blank"
          rel="noreferrer"
          style={{ marginLeft: '0.25rem', fontSize: '0.625rem' }}
          onClick={stopPropagation}
        >
          {compactMapLink ? '(Map)' : '(View on Map)'}
        </a>
      </>
    )
  },
)

export const PrimaryLinkedLocationColumn: ColumnType<Account> = {
  title: 'Linked Location',
  key: 'location',
  render: (account: Account) => {
    // [X] Audited in BZ-921 -> BZ-980
    // todo BZ-782
    const primaryLocation = account.accountLocations[0]?.location
    if (!primaryLocation) {
      return <EmDash />
    }
    return <DataTableLocationLink {...primaryLocation} />
  },
  ellipsis: {
    showTitle: true,
  },
}

export type AccountLinkProps = AccountGuidContainer & {
  readonly displayName: string
  readonly maintenancePlan?: MaintenancePlanMinimalInfo
  readonly maintenancePlans?: MaintenancePlanMinimalInfo[]
  readonly tags?: Tag[]
}

export const renderDataTableAccountLink = (
  account: AccountLinkProps,
  maxTextChars?: number,
) => {
  const maintenancePlan =
    account.maintenancePlan ?? account.maintenancePlans?.[0]
  return (
    <MaintenancePlanVipElement
      urlTo={`/accounts/${account.accountGuid}`}
      className="w-full"
      labelText={
        maxTextChars && account.displayName.length > maxTextChars
          ? account.displayName.substring(0, maxTextChars - 3) + '...'
          : account.displayName
      }
      maintenancePlan={maintenancePlan}
      customIconMargin="ml-2"
      spaceBetween
      textSizeClassName="text-[14px]"
      tags={account.tags}
    />
  )
}

const missingDateSortString = '0'
export const dateColumn = <T, K extends keyof T & string>(
  tzId: TimeZoneId,
  title: string,
  dataIndex: K,
  format?: string,
  sorterEnabled?: boolean,
  customSorter?:
    | boolean
    | CompareFn<T>
    | {
        compare?: CompareFn<T> | undefined
        multiple?: number | undefined
      }
    | undefined,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  sorter:
    isNullish(sorterEnabled) || sorterEnabled
      ? customSorter
        ? customSorter
        : (a, b) =>
            ((a[dataIndex] as string) ?? missingDateSortString).localeCompare(
              (b[dataIndex] as string) ?? missingDateSortString,
            )
      : undefined,
  render: (v?: IsoDateString) => {
    try {
      return !v ? (
        <EmDash />
      ) : (
        <div className="text-left">
          {BzDateTime.fromIsoString(v, tzId).toDateFormat(
            format ? format : 'MMM dd, yyyy',
          )}
        </div>
      )
    } catch (e) {
      console.error('Invalid Iso Date String', { value: v, error: e })
      return <EmDash />
    }
  },
  ellipsis: {
    showTitle: true,
  },
})

export const locationLinkColumn = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
  addressViewType?: AddressViewType,
  compactMapLink?: boolean,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  render: (location: LocationLinkProps) => (
    <DataTableLocationLink
      {...location}
      viewType={addressViewType}
      compactMapLink={compactMapLink}
    />
  ),
})

export const dateTimeColumn = <T, K extends keyof T & string>(
  tzId: TimeZoneId,
  title: string,
  dataIndex: K,
  format: string,
  sorterEnabled?: boolean,
  customSorter?:
    | boolean
    | CompareFn<T>
    | {
        compare?: CompareFn<T> | undefined
        multiple?: number | undefined
      }
    | undefined,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  sorter:
    isNullish(sorterEnabled) || sorterEnabled
      ? customSorter
        ? customSorter
        : (a, b) =>
            ((a[dataIndex] as string) ?? missingDateSortString).localeCompare(
              (b[dataIndex] as string) ?? missingDateSortString,
            )
      : undefined,
  render: (v?: IsoDateString) =>
    !v ? (
      <EmDash />
    ) : (
      <div className="text-left">
        {BzDateTime.fromIsoString(v, tzId).toDateFormat(format)}
      </div>
    ),
  ellipsis: {
    showTitle: true,
  },
})

export const localDateColumn = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
  sorterEnabled?: boolean,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  sorter:
    isNullish(sorterEnabled) || sorterEnabled
      ? (a, b) => (a[dataIndex] as string).localeCompare(b[dataIndex] as string)
      : undefined,
  render: (v: string) => (
    <div className="text-left">
      {dates.formatLocalDateToHumanFriendlyDate(v)}
    </div>
  ),
  ellipsis: {
    showTitle: true,
  },
})

export const moneyColumnUsc = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  sorter: (a, b) => (a[dataIndex] as number) - (b[dataIndex] as number),
  render: (_: string, item: T) => (
    <div className="text-right">
      {formatMoney((item[dataIndex] as number) / 100)}
    </div>
  ),
  ellipsis: {
    showTitle: true,
  },
})

export const booleanCheckOrXColumn = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  render: (_: string, item: T) => (
    <div className="text-center">
      {item[dataIndex] ? (
        <FontAwesomeIcon icon={faCheck} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faX} size="lg" />
      )}
    </div>
  ),
  ellipsis: {
    showTitle: true,
  },
})

export const booleanYesOrNoColumn = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  render: (_: string, item: T) => (
    <div className="text-left">{item[dataIndex] ? 'Yes' : 'No'}</div>
  ),
  ellipsis: {
    showTitle: true,
  },
})

export const textColumn = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
  format?: (s?: string) => string,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  sorter: (a, b) =>
    (a[dataIndex] as string).localeCompare(b[dataIndex] as string),
  render: (_: string, item: T) => {
    const formatted = format
      ? format(item[dataIndex] as string)
      : (item[dataIndex] as string)
    return <div className="text-left">{formatted}</div>
  },
  ellipsis: {
    showTitle: true,
  },
})

export const filteredTextColumn = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
  filters: ColumnFilterItem[],
  filterValue?: FilterValue,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  sorter: (a, b) =>
    (a[dataIndex] as string).localeCompare(b[dataIndex] as string),
  filters,
  onFilter: (value, record) => record[dataIndex] === value,
  filteredValue: filters ? filterValue : undefined,
  render: (_: string, item: T) => (
    <div className="text-left">{item[dataIndex] as string}</div>
  ),
  ellipsis: {
    showTitle: true,
  },
})

export const moneyColumnUsd = <T, K extends keyof T & string>(
  title: string,
  dataIndex: K,
): ColumnType<T> => ({
  title,
  dataIndex,
  key: dataIndex,
  sorter: (a, b) => (a[dataIndex] as number) - (b[dataIndex] as number),
  render: (_: string, item: T) => (
    <div className="text-right">{formatMoney(item[dataIndex] as number)}</div>
  ),
  ellipsis: {
    showTitle: true,
  },
})

export const viewDetailsLinkColumn = <T, K extends keyof T & string>(
  selectUrl: (item: T) => string,
  dataIndex: K,
): ColumnType<T> => ({
  title: '',
  dataIndex,
  key: dataIndex,
  render: (_: string, item: T) => (
    <ViewResourceButton linkTo={selectUrl(item)} />
  ),
  ellipsis: {
    showTitle: true,
  },
  width: 64,
  fixed: 'left',
})

export const numberColumn = <T, K extends KeysOfType<T, number>>(
  title: string,
  dataIndex: K,
): ColumnType<T> => ({
  title,
  // Typescript forgot
  dataIndex: dataIndex as string | number,
  // Typescript forgot
  key: dataIndex as string | number,
  // Typescript forgot
  sorter: (a, b) => (a[dataIndex] as number) - (b[dataIndex] as number),
  render: (num: number) => <div className="text-right">{num}</div>,
})

export const numberColumnMonospaced = <T, K extends KeysOfType<T, number>>(
  title: string,
  dataIndex: K,
): ColumnType<T> => ({
  title,
  // Typescript forgot
  dataIndex: dataIndex as string | number,
  // Typescript forgot
  key: dataIndex as string | number,
  // Typescript forgot
  sorter: (a, b) => (a[dataIndex] as number) - (b[dataIndex] as number),
  render: (num: number) => (
    <BzMonospace text={num.toString()} align="text-right" />
  ),
})

export const invoiceLinkColumn = <T, K extends keyof T & string>(
  dataIndex: K,
  displayIdIndex: keyof T & string,
): ColumnType<T> => ({
  title: 'Invoice',
  dataIndex,
  key: dataIndex,
  render: (_: string, record: T) => {
    const invoiceGuid = record[dataIndex] as string
    const displayId = displayIdIndex && record[displayIdIndex]
    return (
      (invoiceGuid && (
        <Link to={CalculatePaths.invoiceOverview({ invoiceGuid })}>
          {displayId ? `${displayId}` : 'Invoice'}
        </Link>
      )) || <EmDash />
    )
  },
  ellipsis: {
    showTitle: true,
  },
})

export const qboViewPaymentColumn = <T, K extends keyof T & string>(
  dataIndex: K,
): ColumnType<T> => ({
  title: 'QBO',
  dataIndex,
  key: dataIndex,
  render: (_: string, record: T) => {
    const qboLink = (record[dataIndex] as string)
      ? createPaymentQboLink(record[dataIndex] as string)
      : undefined
    return qboLink ? (
      <QuickbooksViewLinkButton
        onClick={() => window.open(qboLink, '_blank')}
      />
    ) : (
      <EmDash />
    )
  },
})
