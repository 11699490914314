import {
  BzDateFns,
  calculateInvoiceTotals,
  CartItemUsc,
  DiscountType,
  getDefaultTaxRate,
  InvoiceCartItem,
  nextGuid,
} from '@breezy/shared'
import { useCallback } from 'react'
import { useQuery } from 'urql'
import { GET_PRICEBOOK_TAX_RATES } from '../components/Pricebook/PricebookPickers.gql'
import {
  InvoiceCartItemsInsertInput,
  InvoiceDiscountsInsertInput,
  InvoicesInsertInput,
  ReadInvoiceTemplatesQuery,
} from '../generated/user/graphql'
import {
  QUERY_RELEVANT_INVOICE_EDIT_ACCOUNT_DATA,
  QUERY_RELEVANT_INVOICE_EDIT_JOB_DATA,
  UPSERT_INVOICE_MUTATION,
} from '../pages/Invoices/Invoices.gql'
import { useRelevantInvoiceData } from '../pages/Invoices/invoiceUtils'
import { READ_INVOICE_TEMPLATES_QUERY } from '../pages/InvoiceTemplatesPage/InvoiceTemplates.gql'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
  useExpectedUserGuid,
} from '../providers/PrincipalUser'
import { useUrqlAuthenticatedQueryClient } from '../providers/UrqlWrapper'
import { trpc } from './trpc'
import { queryMaintenancePlanJobOnsiteDiscount } from './useMaintenancePlanJobDiscount'

export type InvoiceTemplateItems = NonNullable<
  ReadInvoiceTemplatesQuery['invoiceTemplates']
>

export type InvoiceTemplateItem = NonNullable<InvoiceTemplateItems[0]>

export const useInvoiceTemplates = () => {
  const companyGuid = useExpectedCompanyGuid()
  const [{ data, fetching }, refetch] = useQuery({
    query: READ_INVOICE_TEMPLATES_QUERY,
    variables: {
      companyGuid,
    },
  })

  const templates = data?.invoiceTemplates ?? []
  return { templates, fetching, refetch }
}

type CreateAndSaveDraftInvoiceFromTemplateRequest = {
  jobGuid: string
  accountGuid: string
  item: InvoiceTemplateItem
}

const accountDataQueryKey = 12394823
const jobDataQueryKey = 12394825
const taxRatesQueryKey = 12394824
const upsertInvoiceQueryKey = 12394826

const cleansedCartItem = (c: CartItemUsc): CartItemUsc => {
  return {
    itemGuid: c.itemGuid,
    name: c.name,
    description: c.description,
    quantity: c.quantity,
    unitPriceUsc: c.unitPriceUsc,
    isTaxable: c.isTaxable,
    isDiscountable: c.isDiscountable,
    itemType: c.itemType,
    photoGuid: c.photoGuid,
    photoCdnUrl: c.photoCdnUrl,
    originalPricebookItemGuid: c.originalPricebookItemGuid,
  }
}

export const getInvoiceCartItemsFromTemplate = (
  template: InvoiceTemplateItem,
): InvoiceCartItem[] => {
  const cartItems = getCartItemsFromTemplate(template)
  return cartItems.map((c, idx) => ({
    ...cleansedCartItem(c),
    cartItemGuid: nextGuid(),
    seq: idx + 1,
  }))
}

export const getCartItemsFromTemplate = (
  template: InvoiceTemplateItem,
): CartItemUsc[] => {
  return template.invoiceTemplateCartItems.map(tc => ({
    ...tc.cartItem,
    itemType: tc.cartItem.cartItemType,
    itemGuid: tc.cartItem.cartItemGuid,
    description: tc.cartItem.description ?? '',
    photoCdnUrl: tc.cartItem.photo?.cdnUrl,
    createdAt: undefined,
    updatedAt: BzDateFns.nowISOString(),
  }))
}

export const useCreateAndSaveDraftInvoiceFromTemplate = () => {
  const companyGuid = useExpectedCompanyGuid()
  const userGuid = useExpectedUserGuid()
  const generateDisplayIdMutation =
    trpc.invoice['invoicing:generate-display-id'].useMutation()
  const tzId = useExpectedCompanyTimeZoneId()
  const urqlClient = useUrqlAuthenticatedQueryClient()
  const { companyDefaultTaxRateGuid, defaultInvoiceTerm } =
    useRelevantInvoiceData()

  const createAndSaveDraftInvoiceFromTemplate = useCallback(
    async (req: CreateAndSaveDraftInvoiceFromTemplateRequest) => {
      const displayId = await generateDisplayIdMutation.mutateAsync({
        entityType: 'INVOICE',
      })

      const accountDataResp = await urqlClient
        .executeQuery({
          key: accountDataQueryKey,
          query: QUERY_RELEVANT_INVOICE_EDIT_ACCOUNT_DATA,
          variables: {
            accountGuid: req.accountGuid,
          },
        })
        .toPromise()

      const mpOnsiteDiscounts = await queryMaintenancePlanJobOnsiteDiscount(
        urqlClient,
        req.jobGuid,
      )

      const jobDataResp = await urqlClient
        .executeQuery({
          key: jobDataQueryKey,
          query: QUERY_RELEVANT_INVOICE_EDIT_JOB_DATA,
          variables: {
            jobGuid: req.jobGuid,
          },
        })
        .toPromise()

      const taxRateResp = await urqlClient
        .executeQuery({
          key: taxRatesQueryKey,
          query: GET_PRICEBOOK_TAX_RATES,
          variables: {},
        })
        .toPromise()

      const invoiceTaxRate = getDefaultTaxRate(
        taxRateResp.data?.pricebookTaxRates ?? [],
        accountDataResp.data?.accountsByPk?.accountType,
        jobDataResp.data?.jobsByPk?.location.address.zipCode ??
          accountDataResp.data?.accountsByPk?.mailingAddress?.zipCode,
        companyDefaultTaxRateGuid,
        jobDataResp.data?.jobsByPk?.location.pricebookTaxRate
          ?.pricebookTaxRateGuid,
      )

      const { item, accountGuid } = req
      const invoiceGuid = nextGuid()
      const templateCartItems = item.invoiceTemplateCartItems
      const cartItems = templateCartItems.map(tc => ({
        ...tc.cartItem,
        cartItemGuid: nextGuid(),
        createdAt: undefined,
        updatedAt: BzDateFns.nowISOString(),
      }))
      const invoiceTotals = calculateInvoiceTotals(
        cartItems,
        invoiceTaxRate.rate,
        mpOnsiteDiscounts,
        [],
        undefined,
      )

      const insertCartItems: InvoiceCartItemsInsertInput[] = cartItems.map(
        (c, idx) => ({
          cartItem: { data: { ...c, __typename: undefined, photo: undefined } },
          seq: idx + 1,
        }),
      )

      const insertInvoiceDiscounts: InvoiceDiscountsInsertInput[] =
        mpOnsiteDiscounts.map(d => ({
          companyGuid,
          createdAt: BzDateFns.nowISOString(),
          createdBy: userGuid,
          descriptionHtml: d.descriptionHtml,
          discountAmountUsc: d.discountAmountUsc,
          discountRate: d.discountRate,
          discountType:
            d.type === DiscountType.RATE
              ? DiscountType.RATE
              : DiscountType.FLAT,
          invoiceDiscountGuid: nextGuid(),
          name: d.name,
          seq: 1,
          updatedAt: BzDateFns.nowISOString(),
        }))
      const validInvoiceDiscountGuids = insertInvoiceDiscounts
        .map(d => d.invoiceDiscountGuid)
        .filter(Boolean) as string[]

      const validCartItemGuids = cartItems.map(c => c.cartItemGuid)
      const invoice: InvoicesInsertInput = {
        invoiceGuid,
        companyGuid,
        accountGuid,
        messageHtml: '',
        status: 'DRAFT',
        displayId,
        displayIdV2: displayId.toString(),
        taxRate: invoiceTaxRate.rate,
        taxRateGuid: invoiceTaxRate.pricebookTaxRateGuid,
        taxRateName: invoiceTaxRate.name,

        serviceAddressGuid: jobDataResp.data?.jobsByPk?.location.addressGuid,
        billingContactGuid:
          jobDataResp.data?.jobsByPk?.pointOfContact.contactGuid,
        billingAddressGuid:
          accountDataResp.data?.accountsByPk?.mailingAddress?.addressGuid,
        customerPurchaseOrderNumber:
          jobDataResp.data?.jobsByPk?.customerPurchaseOrderNumber,

        serviceCompletionDate: BzDateFns.getTodayLocalDate(tzId),

        subtotalUsc: invoiceTotals.subtotalUsc,
        totalUsc: invoiceTotals.totalUsc,

        createdAt: BzDateFns.nowISOString(),
        updatedAt: BzDateFns.nowISOString(),
        createdBy: userGuid,

        invoiceTerm: defaultInvoiceTerm,

        jobInvoices: { data: [{ jobGuid: req.jobGuid }] },
        locationInvoices: {
          data: [{ locationGuid: jobDataResp.data?.jobsByPk?.locationGuid }],
        },
        invoiceCartItems: { data: insertCartItems },
        invoiceDiscounts: { data: insertInvoiceDiscounts },
      }

      const invoiceReq = {
        invoiceGuid: nextGuid(),
        validInvoiceDiscountGuids,
        validCartItemGuids,
        invoice,
      }
      await urqlClient
        .executeMutation({
          key: upsertInvoiceQueryKey,
          query: UPSERT_INVOICE_MUTATION,
          variables: invoiceReq,
        })
        .toPromise()
    },
    [
      generateDisplayIdMutation,
      urqlClient,
      userGuid,
      companyGuid,
      defaultInvoiceTerm,
      tzId,
      companyDefaultTaxRateGuid,
    ],
  )

  return createAndSaveDraftInvoiceFromTemplate
}
