import { z } from 'zod'
import { AsyncFn } from '../../common'
import { guidSchema, isoDateStringSchema, localDateSchema } from '../../contracts/_common'
import { AccountGuidContainerSchema } from '../Accounts/Account'
import { INFERRED_APPOINTMENT_STATUSES } from '../Appointments/Appointments'
import { Company, ForCompany } from '../Company/Company'
import { EquipmentType } from '../Equipment/EquipmentType'
import { LocationDTO } from '../Locations/Location'
import { NotificationPreferenceType } from '../NotificationPreferenceTypes'
import { Tag } from '../Tags'
import { DeductionType } from '../TechnicianPerformance/CompanyTechnicianPerformanceConfig'
import { bzOptional, Guid, isoWithOffsetDateSchema, localTimeSchema } from '../common-schemas'
import { Contact } from '../contacts/Contact'
import { INSTALL_JOB_PROJECT_TYPE, InstallProjectType } from './JobClass'
import { EquipmentTypeJobLink, JOB_TO_EQUIPMENT_RELATIONSHIP_TYPE_VALUES, JobDisplayId, JobGuid } from './JobInterfaces'

export type CompanyAppointmentArrivalWindowGuid = Guid

export const AppointmentWindowSchema = z.object({
  date: localDateSchema,
  startWindow: isoWithOffsetDateSchema,
  endWindow: isoWithOffsetDateSchema,
  window: z
    .object({
      /**
       * @deprecated Prefer startWindow
       */
      start: localTimeSchema,

      /**
       * @deprecated Prefer startWindow
       */
      end: localTimeSchema,
    })
    .refine(window => {
      return window.end >= window.start
    }),
})

export type AppointmentWindow = z.infer<typeof AppointmentWindowSchema>

export const IdentifiableAppointmentWindowSchema = AppointmentWindowSchema.extend({
  jobAppointmentGuid: guidSchema,
  jobAppointmentConfirmed: z.boolean(),
  jobAppointmentCanceled: z.boolean(),
  jobInferredAppointmentStatus: z.enum(INFERRED_APPOINTMENT_STATUSES),
  jobAppointmentReferenceNumber: z.string(),
})

export type IdentifiableAppointmentWindow = z.infer<typeof IdentifiableAppointmentWindowSchema>

export const CreateNewJobDTOSchema = z.object({
  jobGuid: guidSchema,
  displayId: bzOptional(z.number().int().gte(1)),
  accountGuid: guidSchema,
  locationGuid: guidSchema,
  jobTypeGuid: guidSchema,
  installProjectType: bzOptional(z.enum(INSTALL_JOB_PROJECT_TYPE as [(typeof INSTALL_JOB_PROJECT_TYPE)[number]])),
  summary: z.string().max(10000),
  equipmentTypeJobLinks: z
    .object({
      equipmentType: z.nativeEnum(EquipmentType),
      relationshipType: z.enum(
        JOB_TO_EQUIPMENT_RELATIONSHIP_TYPE_VALUES as [(typeof JOB_TO_EQUIPMENT_RELATIONSHIP_TYPE_VALUES)[number]],
      ),
      estimatedEquipmentAge: bzOptional(z.string()),
    })
    .array(),
  pointOfContactGuid: guidSchema,
  isOpportunity: z.boolean(),
  isHotLead: z.boolean(),
  isMembershipOpportunity: z.boolean(),
  isMembershipRenewalOpportunity: z.boolean(),
  companyLeadSource: z.object({
    leadSourceGuid: guidSchema,
    leadSourceReferringContactGuid: bzOptional(guidSchema),
    leadSourceAttributionDescription: bzOptional(z.string()),
  }),
  jobCreatedAt: bzOptional(isoDateStringSchema),
  tags: bzOptional(
    z
      .object({
        tagGuid: guidSchema,
        jobGuid: guidSchema,
      })
      .array(),
  ),
  useMaintenancePlanCredit: z.boolean(),
  maintenancePlanVisitGuid: bzOptional(guidSchema),
  customerPurchaseOrderNumber: bzOptional(z.string()),
  linkedJob: bzOptional(
    z.object({
      linkedJobGuid: guidSchema,
      linkNotes: z.boolean(),
      linkPhotos: z.boolean(),
      linkAttachments: z.boolean(),
      linkEstimates: z.string().array(),
    }),
  ),
  businessUnitGuid: bzOptional(guidSchema),
})

const cannotBeBothMembershipAndMembershipRenewalOpportunity = ({
  isMembershipOpportunity,
  isMembershipRenewalOpportunity,
}: {
  isMembershipOpportunity: boolean
  isMembershipRenewalOpportunity: boolean
}) => {
  if (isMembershipOpportunity && isMembershipRenewalOpportunity) {
    return false
  }
  return true
}

const cannotBeHotLeadIfNotAnOpportunity = ({
  isOpportunity,
  isHotLead,
}: {
  isOpportunity: boolean
  isHotLead: boolean
}) => {
  if (isHotLead && !isOpportunity) {
    return false
  }
  return true
}

const RefinedCreateNewJobDTOSchema = CreateNewJobDTOSchema.refine(
  data => cannotBeBothMembershipAndMembershipRenewalOpportunity(data),
  { message: 'A Job cannot be both a Membership Opportunity and a Membership Renewal Opportunity' },
).refine(data => cannotBeHotLeadIfNotAnOpportunity(data), {
  message: 'A Job cannot be a Hot Lead if it is not an Opportunity',
})

export type CreateNewJobDTO = z.infer<typeof RefinedCreateNewJobDTOSchema>

export const UpdateJobDTOSchema = CreateNewJobDTOSchema.omit({ accountGuid: true, locationGuid: true })
  .extend({
    workCompletedAt: bzOptional(isoDateStringSchema),
  })
  .refine(data => cannotBeBothMembershipAndMembershipRenewalOpportunity(data), {
    message: 'A Job cannot be both a Membership Opportunity and a Membership Renewal Opportunity',
  })
  .refine(data => cannotBeHotLeadIfNotAnOpportunity(data), {
    message: 'A Job cannot be a Hot Lead if it is not an Opportunity',
  })

export type UpdateJobDTO = z.infer<typeof UpdateJobDTOSchema>

export const JobGuidContainerSchema = z.object({
  jobGuid: guidSchema,
})

export const invoicingForJobQuerySchema = JobGuidContainerSchema.extend({
  companyGuid: guidSchema,
})

export const invoicingForAccountQuerySchema = AccountGuidContainerSchema.extend({
  companyGuid: guidSchema,
})

export type FetchJobDetailsDTO = z.infer<typeof JobGuidContainerSchema>

/** @deprecated Probably shouldn't exist.  */
export type JobDetailsAccountContact = {
  accountGuid: string
  primaryContact: {
    accountContactGuid: string
    contact: {
      firstName: string
      lastName: string
      notificationPreferenceType: NotificationPreferenceType
      phoneNumbers: {
        phoneNumberGuid: string
        phoneNumber: string
      }[]
      emailAddresses: {
        emailAddressGuid: string
        emailAddress: string
      }[]
    }
  }
  tags?: Tag[]
}

export type JobReferenceInfo = {
  jobGuid: JobGuid
  displayId: JobDisplayId
  installProjectType?: InstallProjectType
  equipmentTypeJobLinks: readonly EquipmentTypeJobLink[]
}

export type JobBasicInfo = JobReferenceInfo & {
  summary?: string
}

export type JobDetailsDTO = JobReferenceInfo & {
  summary: string
  appointments: IdentifiableAppointmentWindow[]
  company: Company
  createdAt: string
  customerPurchaseOrderNumber?: string
  location: LocationDTO
  // [X] Audited in BZ-921 -> No Action Required immediately
  // TODO: This Account Shape seems wrong
  account: JobDetailsAccountContact
  pointOfContact: Contact
  tags?: Tag[]
  businessUnitGuid?: string
}

// TODO Move all of this form-related stuff to the frontend. Create a DTO for the APi
// if needed

export const jobOutcomesTechnicianTurnoverSubformSchema = z.object({
  isTurnedOverByTechnician: z.boolean().default(false),
  technicianTurnoverUserGuids: z.array(guidSchema).default([]),
})

const jobOutcomesRevenueAttributionInvoiceSchema = z.object({
  guidToItemMap: z
    .record(
      guidSchema,
      z.object({
        totalRevenueUsc: z.number(),
        soldRevenueAttributionUserGuids: bzOptional(z.array(guidSchema)).default([]),
        earnedRevenueAttributionUserGuids: bzOptional(z.array(guidSchema)).default([]),
      }),
    )
    .default({}),
})

export const jobOutcomesRevenueAttributionSubformSchema = z.object({
  revenueAttributionGuidToInvoiceMap: z.record(guidSchema, jobOutcomesRevenueAttributionInvoiceSchema).default({}),
})

export const jobOutcomesCommissionDeductionsPopoverFormSchema = z.object({
  overheadAllocationDeductionPercent: bzOptional(z.number().min(0).max(100)),
  overheadAllocationFlatDeductionUsd: bzOptional(z.number().min(0)),
  overheadAllocationDeductionType: DeductionType,
  jobCostsDeductionPercent: bzOptional(z.number().min(0).max(100)),
  jobCostsFlatDeductionUsd: bzOptional(z.number().min(0)),
  jobCostsDeductionType: DeductionType,
})

export const jobOutcomesTechniciansCommissionAndBonusFormSchema = z.object({
  technicianGuidToCommissionAndBonusMap: z
    .record(
      guidSchema,
      z.object({
        commissionPercent: bzOptional(z.number().min(0).max(100)),
        bonusUsd: bzOptional(z.number().min(0)),
      }),
    )
    .default({}),
})

export const jobOutcomesFormSchema = z
  .object({
    jobGuid: guidSchema,
    isConverted: z.boolean(),
    isMembershipSold: z.boolean(),
  })
  .merge(jobOutcomesTechnicianTurnoverSubformSchema)
  .merge(jobOutcomesRevenueAttributionSubformSchema)
  .merge(jobOutcomesCommissionDeductionsPopoverFormSchema)
  .merge(jobOutcomesTechniciansCommissionAndBonusFormSchema)
  .refine(
    data => {
      if (data.isTurnedOverByTechnician) {
        return data.technicianTurnoverUserGuids.length > 0
      }
      return true
    },
    {
      message: 'If the job is turned over by a technician, at least one technician must be selected.',
      path: ['mustSpecifyTechniciansIfTurnedOver'],
    },
  )

export type JobOutcomesTechnicianTurnoverSubformSchema = z.infer<typeof jobOutcomesTechnicianTurnoverSubformSchema>
export type JobOutcomesRevenueAttributionSubformSchema = z.infer<typeof jobOutcomesRevenueAttributionSubformSchema>
export type JobOutcomesCommissionDeductionsPopoverFormSchema = z.infer<
  typeof jobOutcomesCommissionDeductionsPopoverFormSchema
>
export type JobOutcomesTechniciansCommissionAndBonusFormSchema = z.infer<
  typeof jobOutcomesTechniciansCommissionAndBonusFormSchema
>
export type JobOutcomesFormSchema = z.infer<typeof jobOutcomesFormSchema>

export type JobOutcomesUpserterInput = ForCompany<JobOutcomesFormSchema>

export type JobOutcomesUpserter = AsyncFn<JobOutcomesUpserterInput>
