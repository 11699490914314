import TextArea from 'antd/lib/input/TextArea'
import React, { useCallback, useState } from 'react'

import { OnsiteConfirmModal } from '../../../adam-components/OnsiteModal/OnsiteModal'

type DoNotServiceAccountModalProps = {
  onClose: () => void
  onSave: (doNotService: boolean, doNotServiceReason: string) => void
  doNotService: boolean
}

export const DoNotServiceAccountModal =
  React.memo<DoNotServiceAccountModalProps>(
    ({ doNotService, onClose, onSave }) => {
      const [reason, setReason] = useState('')

      const handleSubmit = useCallback(() => {
        setReason('')
        onSave(!doNotService, reason)
      }, [doNotService, setReason, reason, onSave])

      return (
        <OnsiteConfirmModal
          size="large-width"
          onCancel={onClose}
          onConfirm={handleSubmit}
          header={doNotService ? 'Remove Do Not Service' : 'Do Not Service'}
          confirmText="Done"
          confirmDisabled={!reason}
        >
          <span className="text-[14px] font-bold">
            Please provide a reason for marking this account as ‘Do Not
            Service’.&nbsp;
            <span className="font-normal text-bz-red-500">*</span>
          </span>
          <TextArea
            className="mt-2"
            placeholder="Reason for Do Not Service"
            value={reason}
            onChange={e => setReason(e.target.value)}
            rows={6}
          />
          <span className="text-description text-[14px] leading-5">
            This reason will be shown to team members when booking jobs and will
            also be recorded as a note on the account.
          </span>
        </OnsiteConfirmModal>
      )
    },
  )
