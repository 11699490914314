import { PageHeader } from '@ant-design/pro-components'
import { bzExpect, isNullish, isNullishOrEmpty } from '@breezy/shared'
import { faUser } from '@fortawesome/pro-light-svg-icons'
import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { PermissionControlsProvider } from '../../components/PermissionControls/usePermissionControls'
import SettingsCreateEditUserForm from '../../components/SettingsTeam/SettingsCreateEditTeamMemberForm'
import TrpcQueryLoader from '../../components/TrpcQueryLoader'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { gql } from '../../generated/user'
import { trpc } from '../../hooks/trpc'
import { useMessage } from '../../utils/antd-utils'

const USER_AVATAR_QUERY = gql(/* GraphQL */ `
  query SettingsEditTeamMemberPageUserAvatarQuery($photoGuid: uuid!) {
    photosByPk(photoGuid: $photoGuid) {
      photoGuid
      cdnUrl
    }
  }
`)

const SettingsEditTeamMemberPage = React.memo(() => {
  const message = useMessage()
  const userGuid = bzExpect(
    useParams<{ userGuid: string }>().userGuid,
    'userGuid',
    'User ID is required',
  )

  const query = trpc.user['users:get-by-guid'].useQuery({ userGuid })

  const userAvatarQuery = useQuery({
    query: USER_AVATAR_QUERY,
    variables: { photoGuid: query.data?.user.avatarPhotoGuid ?? '' },
    pause: isNullish(query.data?.user.avatarPhotoGuid),
  })

  return (
    <Page requiresCompanyUser className="overflow-hidden p-0">
      <PageHeader
        title={<PageTitle title="Edit Team Member" icon={faUser} />}
      />
      <ScrollCard
        className="relative flex flex-col overflow-auto p-9"
        hasPageHeading
      >
        <TrpcQueryLoader
          query={query}
          errorMessage={`Failed to load user by ID: ${userGuid}`}
          loadingComponent={
            <div className="flex h-full w-full items-center justify-center">
              <LoadingSpinner />
            </div>
          }
          render={({ user }) => (
            <PermissionControlsProvider
              permissions={Array.from(user.permissionsV2)}
            >
              {!isNullishOrEmpty(user.avatarPhotoGuid) ? (
                <GqlQueryLoader
                  query={userAvatarQuery}
                  loadingComponent={
                    <div className="flex h-full w-full items-center justify-center">
                      <LoadingSpinner />
                    </div>
                  }
                  render={({ photosByPk }) => (
                    <SettingsCreateEditUserForm
                      user={{
                        ...user,
                        ...(!isNullish(photosByPk)
                          ? {
                              avatar: {
                                photoGuid: photosByPk.photoGuid,
                                cdnUrl: photosByPk.cdnUrl,
                              },
                            }
                          : {}),
                      }}
                      savedUser={() => {
                        message.success('Your changes have been saved.')
                        query.refetch()
                      }}
                    />
                  )}
                />
              ) : (
                <SettingsCreateEditUserForm
                  user={user}
                  savedUser={() => {
                    message.success('Your changes have been saved.')
                    query.refetch()
                  }}
                />
              )}
            </PermissionControlsProvider>
          )}
        />
      </ScrollCard>
    </Page>
  )
})

export default SettingsEditTeamMemberPage
