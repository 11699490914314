import { Guid } from '@breezy/shared'
import {
  IsoDateString,
  LocalDateString,
} from '@breezy/shared/src/common/BzDateFns'
import { InvoiceV2Status } from '@breezy/shared/src/domain/InvoicesV2/InvoicesV2'
import { PhoneNumberType } from '@breezy/shared/src/domain/PhoneNumbers/Phone'
import { GetInvoiceListUsersQuery } from '../../generated/user/graphql'

export type InvoiceListingDataContextType = {
  listDataFetching: boolean
  summaryStatsLoading: boolean
  totalItems: number
  data: InvoiceListingInvoice[]
  summaryStats: InvoiceListingSummaryStats
  refetch?: () => void
}

export type InvoiceListingLeadSource = {
  companyLeadSourceGuid: Guid
  name: string
}

export type InvoiceListingSummaryStats = {
  outstanding: {
    amountUsc: number
    count: number
  }
  overdue: {
    amountUsc: number
    count: number
  }
  fullyPaid: {
    amountUsc: number
    count: number
  }
  avgValueUsc: number
  avgDaysToPay: number
}

export type InvoiceListingInvoice = {
  invoiceGuid: Guid
  accountGuid: Guid
  locationGuid?: Guid
  job?: {
    jobGuid: Guid
    displayId: number
    jobType: string
  }
  maintenancePlan?: {
    maintenancePlanGuid: Guid
    name: string
  }
  displayId: string
  address?: string
  fullyPaid: boolean
  totalUsc: number
  paidUsc: number
  processingPaymentsUsc: number
  status: InvoiceV2Status
  contact: {
    contactName: string
    contactPhoneNumber?: string
    contactPhoneType?: PhoneNumberType
    contactEmail?: string
  }
  serviceCompletionDate?: LocalDateString
  issuedAt?: IsoDateString
  dueAt?: string
  numDaysOverdue?: number
  isTerminalStatus: boolean
  createdByUserGuid?: Guid
  leadSource?: InvoiceListingLeadSource
  accountManagerUserGuid?: Guid
  businessUnitLink?: {
    businessUnitGuid: Guid
    sourceType: string
    sourceGuid: string
    businessUnit: {
      name: string
    }
  }
}

export const SORT_TYPES = [
  'NEWEST',
  'OLDEST',
  'OVERDUE',
  'TOTAL',
  'CREATED_BY',
] as const

export type SortType = (typeof SORT_TYPES)[number]

export type InvoiceListUser =
  GetInvoiceListUsersQuery['companyUsers'][number]['userByUserGuid']
export type UserMap = Record<string, InvoiceListUser | undefined>
