import {
  AbridgedInvoiceMetadataWithAmountDue,
  AccountGuid,
  AccountingIntegrationType,
  AddressDto,
  AddressDtoSchema,
  BreezySystemUserGuid,
  DEFAULT_BLURB,
  DUMMY_GUID,
  DYNAMIC_PRICING_TYPES,
  DiscountType,
  DynamicPricingType,
  Guid,
  HtmlString,
  INVOICE_TERMS_V2,
  InvoiceCartItem,
  InvoiceCartItemSchema,
  InvoiceDiscount,
  InvoiceDiscountSchema,
  InvoiceEventInput,
  InvoiceHistoryInfo,
  InvoiceTermV2,
  InvoiceTotals,
  InvoiceV2Status,
  IsoDateString,
  JobGuid,
  MaintenancePlanGuid,
  PricebookTaxRateDto,
  PricebookTaxRateDtoSchema,
  TimeZoneId,
  bzOptional,
  calculateInvoiceTotals,
  guidSchema,
  htmlStringSchema,
  invoiceV2ToInvoiceV1Status,
  isFinanceableInvoiceV2,
  localDateSchema,
  paymentPendingStatuses,
  paymentRecordsToInvoicePayments,
  usCentsToUsd,
} from '@breezy/shared'
import axios from 'axios'
import React, { useCallback, useMemo, useState } from 'react'
import { useMutation, useSubscription } from 'urql'
import { z } from 'zod'
import { dataURLtoFile } from '../../components/Upload/PhotoOptimize'
import { StatusTagColor } from '../../elements/StatusTag/StatusTag'
import { DocumentType } from '../../generated'
import {
  GetRelevantInvoiceEditAccountDataSubscription,
  GetRelevantInvoiceEditJobDataSubscription,
  InvoiceDataSubscription,
  InvoicesBoolExp,
} from '../../generated/user/graphql'
import { trpc } from '../../hooks/trpc'
import { useUpsertRecentPricebookItems } from '../../hooks/useUpsertRecentPricebookItems'
import { useWisetackEnabled } from '../../providers/CompanyFinancialConfigWrapper'
import { usePricebook } from '../../providers/PricebookProvider'
import {
  useExpectedCompanyGuid,
  useExpectedUserGuid,
} from '../../providers/PrincipalUser'
import { useStrictContext } from '../../utils/react-utils'
import {
  INVOICES_DATA_SUBSCRIPTION,
  INVOICE_FRAGMENT_WITH_GUID,
  RELEVANT_INVOICE_DATA_SUBSCRIPTION,
  RELEVANT_INVOICE_EDIT_ACCOUNT_DATA_SUBSCRIPTION,
  RELEVANT_INVOICE_EDIT_JOB_DATA_SUBSCRIPTION,
  UPDATE_INVOICE_MUTATION,
  UPSERT_INVOICE_MUTATION,
} from './Invoices.gql'
import { InvoiceAppointmentLink } from './components/InvoiceInfoModalContent'

export type InvoiceContextType = {
  invoiceGuid: Guid
  accountGuid: AccountGuid
  jobGuid?: JobGuid
  jobAppointmentGuid?: Guid
  maintenancePlanGuid?: MaintenancePlanGuid
  displayId: string
  realPricebookItemGuidMap: Record<Guid, true>
  status: InvoiceV2Status
  disclaimer: string
  defaultInvoiceTerm: InvoiceTermV2
  companyName: string
  companyBlurb: string
  logoUrl: string
  businessAddress?: AddressDto
  businessEmail?: string
  businessPhoneNumber?: string
  businessWebsite?: string
  tzId: TimeZoneId
  invoiceTerm?: InvoiceTermV2
  accountingAutoSyncSettings?: {
    onIssued?: boolean
    onPayment?: boolean
    onFullyPaid?: boolean
    onVoided?: boolean
  }
}

export const InvoiceContext = React.createContext<
  InvoiceContextType | undefined
>(undefined)

export const useRelevantInvoiceData = () => {
  const companyGuid = useExpectedCompanyGuid()

  const [{ data: relevantInvoiceData, fetching: fetchingRelevantInvoiceData }] =
    useSubscription({
      query: RELEVANT_INVOICE_DATA_SUBSCRIPTION,
      variables: {
        companyGuid,
      },
    })

  const realPricebookItemGuidMap = useMemo(() => {
    const map: Record<Guid, true> = {}
    for (const pricebookItem of relevantInvoiceData?.companiesByPk
      ?.pricebookItems ?? []) {
      map[pricebookItem.pricebookItemGuid] = true
    }
    return map
  }, [relevantInvoiceData?.companiesByPk?.pricebookItems])

  const defaultInvoiceTerm = useMemo<InvoiceTermV2>(() => {
    const term =
      relevantInvoiceData?.companiesByPk?.billingProfile?.invoiceTerm ??
      'DUE_ON_RECEIPT'
    if (term === 'AUTO') {
      return 'DUE_ON_RECEIPT'
    }
    return term
  }, [relevantInvoiceData?.companiesByPk?.billingProfile?.invoiceTerm])

  return {
    realPricebookItemGuidMap,
    companyDefaultTaxRateGuid:
      relevantInvoiceData?.companiesByPk?.billingProfile
        ?.defaultPricebookTaxRateGuid,
    disclaimer:
      relevantInvoiceData?.companiesByPk?.billingProfile?.invoiceDisclaimer ??
      '',
    defaultInvoiceTerm,
    companyName: relevantInvoiceData?.companiesByPk?.name ?? '',
    companyBlurb:
      relevantInvoiceData?.companiesByPk?.companyConfig?.blurb ?? DEFAULT_BLURB,
    logoUrl: relevantInvoiceData?.companiesByPk?.billingProfile?.logoUrl ?? '',
    isFetching: fetchingRelevantInvoiceData,
    hasLoaded: !!relevantInvoiceData,
    businessAddress:
      relevantInvoiceData?.companiesByPk?.billingProfile?.businessAddress,
    businessEmail:
      relevantInvoiceData?.companiesByPk?.billingProfile?.emailAddress
        .emailAddress,
    businessPhoneNumber:
      relevantInvoiceData?.companiesByPk?.billingProfile?.phoneNumber
        .phoneNumber,
    businessWebsite:
      relevantInvoiceData?.companiesByPk?.billingProfile?.websiteDisplayName,
    accountingAutoSyncSettings:
      (relevantInvoiceData?.companiesByPk?.accCompanyConfigs ?? []).length > 0
        ? {
            onIssued:
              relevantInvoiceData?.companiesByPk?.accCompanyConfigs?.[0]
                ?.autosyncInvoiceOnIssued ?? false,
            onPayment:
              relevantInvoiceData?.companiesByPk?.accCompanyConfigs?.[0]
                ?.autosyncInvoiceOnPayment ?? false,
            onFullyPaid:
              relevantInvoiceData?.companiesByPk?.accCompanyConfigs?.[0]
                ?.autosyncInvoiceOnFullyPaid ?? false,
            onVoided:
              relevantInvoiceData?.companiesByPk?.accCompanyConfigs?.[0]
                ?.autosyncInvoiceOnVoided ?? false,
          }
        : undefined,
  }
}

export type RelevantInvoiceData = ReturnType<typeof useRelevantInvoiceData>

export type InvoiceEditDataContextType = {
  account: NonNullable<
    GetRelevantInvoiceEditAccountDataSubscription['accountsByPk']
  >
  job?: GetRelevantInvoiceEditJobDataSubscription['jobsByPk']
}
export const InvoiceEditDataContext = React.createContext<
  InvoiceEditDataContextType | undefined
>(undefined)

export const useRelevantInvoiceEditData = (
  accountGuid?: AccountGuid,
  jobGuid?: JobGuid,
): {
  data: InvoiceEditDataContextType | undefined
  fetching: boolean
  hasLoaded: boolean
} => {
  const [{ data: accountData, fetching: fetchingAccountData }] =
    useSubscription({
      query: RELEVANT_INVOICE_EDIT_ACCOUNT_DATA_SUBSCRIPTION,
      variables: {
        accountGuid: accountGuid ?? DUMMY_GUID,
      },
    })

  const [{ data: jobData, fetching: fetchingJobData }] = useSubscription({
    query: RELEVANT_INVOICE_EDIT_JOB_DATA_SUBSCRIPTION,
    variables: {
      // If there is no job guid, we want to just not match anything
      jobGuid: jobGuid ?? DUMMY_GUID,
    },
  })

  return useMemo(
    () => ({
      data:
        accountData?.accountsByPk && jobData
          ? {
              account: accountData.accountsByPk,
              job: jobData?.jobsByPk,
            }
          : undefined,
      fetching: fetchingAccountData || fetchingJobData,
      hasLoaded: !!accountData && !!jobData,
    }),
    [accountData, fetchingAccountData, fetchingJobData, jobData],
  )
}

type InvoiceV2StatusMetadata = {
  label: string
  statusTagColor: StatusTagColor
  colorClassNames: string
  markAsOptions?: InvoiceV2Status[]
  hasSpecialMarkAsBehavior?: boolean
  // // If we try to set an invoice to this status, but it's already in one of the statuses in this array, we don't do it.
  // trumpedBy?: InvoiceV2Status[]
}

export const INVOICE_V2_STATUS_DISPLAY_INFO = {
  DRAFT: {
    label: 'Draft',
    colorClassNames: 'text-bz-gray-900 bg-bz-gray-400',
    statusTagColor: 'darkGray',
  },
  OPEN: {
    label: 'Open',
    colorClassNames: 'text-geek-blue-900 bg-geek-blue-200',
    statusTagColor: 'blue',
    markAsOptions: ['PAID', 'VOIDED', 'UNCOLLECTABLE'],
  },
  PAID: {
    label: 'Paid',
    colorClassNames: `text-bz-green-900 bg-bz-green-200`,
    statusTagColor: 'green',
    hasSpecialMarkAsBehavior: true,
  },
  VOIDED: {
    label: 'Voided',
    colorClassNames: 'text-bz-red-900 bg-bz-red-200',
    statusTagColor: 'red',
    // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3435
    // hasSpecialMarkAsBehavior: true,
  },
  UNCOLLECTABLE: {
    label: 'Uncollectable',
    colorClassNames: 'text-bz-gray-900 bg-bz-gray-500',
    statusTagColor: 'darkGray',
    markAsOptions: ['OPEN', 'PAID', 'VOIDED'],
    // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3436
    // hasSpecialMarkAsBehavior: true,
  },
} satisfies Record<InvoiceV2Status, InvoiceV2StatusMetadata>

export const getInvoiceV2StatusDisplayInfo = (
  status: InvoiceV2Status,
): InvoiceV2StatusMetadata => INVOICE_V2_STATUS_DISPLAY_INFO[status]

const SameAsServiceBillingAddressSchema = z.object({
  type: z.literal('same_as_service'),
  adHocAddress: bzOptional(AddressDtoSchema),
  saveAdHocAsDefault: bzOptional(z.boolean()),
  accountBillingAddressGuid: bzOptional(guidSchema),
})

const AdHocBillingAddressSchema = z.object({
  type: z.literal('adhoc'),
  adHocAddress: AddressDtoSchema,
  saveAdHocAsDefault: bzOptional(z.boolean()),
  accountBillingAddressGuid: bzOptional(guidSchema),
})

const AccountBillingAddressSchema = z.object({
  type: z.literal('account_billing_address'),
  adHocAddress: bzOptional(AddressDtoSchema),
  saveAdHocAsDefault: bzOptional(z.boolean()),
  accountBillingAddressGuid: guidSchema,
})

const BillingAddressSchema = z.discriminatedUnion('type', [
  SameAsServiceBillingAddressSchema,
  AdHocBillingAddressSchema,
  AccountBillingAddressSchema,
])

type AdHocBillingAddress = z.infer<typeof AdHocBillingAddressSchema>
type AccountBillingAddress = z.infer<typeof AccountBillingAddressSchema>
export type InvoiceBillingAddress = z.infer<typeof BillingAddressSchema>

export const isAdHocBillingAddress = (
  address: InvoiceBillingAddress,
): address is AdHocBillingAddress => address.type === 'adhoc'

export const isAccountBillingAddress = (
  address: InvoiceBillingAddress,
): address is AccountBillingAddress =>
  address.type === 'account_billing_address'

export const InvoiceInfoFormSchema = z.object({
  serviceCompletionDate: localDateSchema,
  invoiceTerm: z.enum(INVOICE_TERMS_V2),
  customerPurchaseOrderNumber: z.string(),
  serviceLocationGuid: guidSchema,
  billingContactGuid: guidSchema,
  billingAddress: BillingAddressSchema,
})

export type InvoiceInfoFormData = z.infer<typeof InvoiceInfoFormSchema>

type Contact = NonNullable<
  GetRelevantInvoiceEditAccountDataSubscription['accountsByPk']
>['accountContacts'][number]['contact']

export type InvoiceInfo = InvoiceInfoFormData & {
  serviceAddress: AddressDto
  serviceContact: Contact
  billingContact: Contact
  accountMailingAddress?: AddressDto
  resolvedBillingAddress?: AddressDto
  dueAt?: IsoDateString
  issuedAt?: IsoDateString
}

export type InvoiceDataContextType = {
  messageHtml: HtmlString
  taxRate: PricebookTaxRateDto
  dynamicPricingType: DynamicPricingType | undefined
  info: InvoiceInfo
  lineItems: InvoiceCartItem[]
  discounts: InvoiceDiscount[]
  invoiceTotals: InvoiceTotals
}

export const InvoiceDataContext = React.createContext<
  InvoiceDataContextType | undefined
>(undefined)

export const PRETTY_INVOICE_TERMS: Record<InvoiceTermV2, string> = {
  DUE_ON_RECEIPT: 'Due on receipt',
  NET_7: '7 days from now',
  NET_10: '10 days from now',
  NET_14: '14 days from now',
  NET_15: '15 days from now',
  NET_30: '30 days from now',
  NET_60: '60 days from now',
  AUTO: 'Automatic',
}

export const PRETTY_INVOICE_TERMS_FOR_ACCOUNT_TERM: Record<
  InvoiceTermV2,
  string
> = {
  DUE_ON_RECEIPT: 'Due on receipt',
  NET_7: 'Net 7',
  NET_10: 'Net 10',
  NET_14: 'Net 14',
  NET_15: 'Net 15',
  NET_30: 'Net 30',
  NET_60: 'Net 60',
  AUTO: 'Automatic',
}

type SaveableInvoice = {
  status: InvoiceV2Status
  messageHtml: HtmlString
  taxRate: PricebookTaxRateDto
  dynamicPricingType?: DynamicPricingType
  lineItems: InvoiceCartItem[]
  info: InvoiceInfo
  serviceAddressGuid: Guid
  totals: InvoiceTotals
  discounts: InvoiceDiscount[]
  event?: InvoiceEventInput
}

/**
 * This is a fire and forget style hook for persisting invoice events.
 */
export const useWriteInvoiceEvent = () => {
  const writeInvoiceEventMut =
    trpc.invoice['invoicing:invoicev2:write-event'].useMutation()

  return useCallback(
    async (input: InvoiceEventInput) => {
      try {
        await writeInvoiceEventMut.mutateAsync({
          input,
        })
      } catch (e) {
        console.error('Error writing invoice event', e)
      }
    },
    [writeInvoiceEventMut],
  )
}

export const useSaveInvoice = () => {
  const companyGuid = useExpectedCompanyGuid()
  const userGuid = useExpectedUserGuid()

  const {
    jobGuid,
    accountGuid,
    jobAppointmentGuid,
    realPricebookItemGuidMap,
    invoiceGuid,
    displayId,
  } = useStrictContext(InvoiceContext)

  const { refetchRecentItems } = usePricebook()

  const [, upsertInvoice] = useMutation(UPSERT_INVOICE_MUTATION)
  const writeInvoiceEvent = useWriteInvoiceEvent()
  const upsertRecentPricebookItems = useUpsertRecentPricebookItems()

  const [isUpserting, setIsUpserting] = useState(false)

  const saveInvoice = useCallback(
    async ({
      status,
      messageHtml,
      taxRate,
      dynamicPricingType,
      lineItems,
      info,
      serviceAddressGuid,
      totals,
      discounts,
      event,
    }: SaveableInvoice) => {
      // Shouldn't be possible
      if (!userGuid) {
        return
      }
      setIsUpserting(true)

      try {
        const validCartItemGuids: Guid[] = []

        for (const { cartItemGuid } of lineItems) {
          validCartItemGuids.push(cartItemGuid)
        }

        const validInvoiceDiscountGuids: Guid[] = []
        for (const { discountGuid } of discounts) {
          validInvoiceDiscountGuids.push(discountGuid)
        }

        let billingAddressGuid: Guid | undefined = undefined

        // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3031
        // let billingAddress: AddressDto | undefined = undefined;

        if (isAdHocBillingAddress(info.billingAddress)) {
          // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3031
          // billingAddress = ...
        } else if (isAccountBillingAddress(info.billingAddress)) {
          billingAddressGuid = info.billingAddress.accountBillingAddressGuid
        }

        const validRecentPricebookItemGuids = lineItems
          .filter(item => realPricebookItemGuidMap[item.itemGuid])
          .map(item => item.itemGuid)

        try {
          if (validRecentPricebookItemGuids.length) {
            await upsertRecentPricebookItems(validRecentPricebookItemGuids)
            refetchRecentItems()
          }
        } catch (e) {
          console.error('Error upserting recent pricebook items', e)
        }

        const upsertRes = await upsertInvoice({
          invoiceGuid,
          validCartItemGuids,
          validInvoiceDiscountGuids,
          invoice: {
            accountGuid,
            // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3031\
            // billingAddress: {}
            billingAddressGuid,
            billingContactGuid: info.billingContactGuid,
            companyGuid,
            createdBy: userGuid,
            customerPurchaseOrderNumber: info.customerPurchaseOrderNumber,
            displayId: parseInt(displayId, 10),
            displayIdV2: displayId,
            dueAt: info.dueAt,
            issuedAt: info.issuedAt,
            // Typically we just use a trigger on the table to update this column whenever there's a write but we had to
            // override the behavior of the trigger so we could manually pass in an updated_at value in the migration script
            // to make sure the invoice v2 qbo sync data would match the existing lastAlteredAt invoice v1 data so after we
            // cutover from v1 -> v2 our users don't have to resync every invoice to QBO. This comes with the unfortunate trade-off
            // of having to calculate the updateAt value on the fly when we upsert the invoice which we're doing here.
            updatedAt: new Date().toISOString(),
            invoiceCartItems: {
              onConflict: {
                constraint: 'invoice_cart_items_pkey',
                updateColumns: ['seq'],
              },
              data: lineItems.map(
                ({
                  itemGuid,
                  itemType,
                  savedToPricebook,
                  seq,
                  photoCdnUrl,
                  ...rest
                }) => ({
                  seq,
                  cartItem: {
                    onConflict: {
                      constraint: 'cart_items_pkey',
                      updateColumns: [
                        'name',
                        'description',
                        'quantity',
                        'unitPriceUsc',
                        'isTaxable',
                        'isDiscountable',
                        'cartItemType',
                        'originalPricebookItemGuid',
                        'photoGuid',
                      ],
                    },
                    data: {
                      ...rest,
                      companyGuid,
                      // If it's an ad-hoc item it gets a random guid, so we need to check if it's real
                      originalPricebookItemGuid: realPricebookItemGuidMap[
                        itemGuid
                      ]
                        ? itemGuid
                        : undefined,
                      cartItemType: itemType,
                    },
                  },
                }),
              ),
            },
            invoiceDiscounts: {
              onConflict: {
                constraint: 'invoice_discounts_pkey',
                updateColumns: [
                  'discountAmountUsc',
                  'discountRate',
                  'discountType',
                  'name',
                  'seq',
                ],
              },
              data: discounts.map(({ discountGuid, type, ...rest }, i) => ({
                companyGuid,
                invoiceDiscountGuid: discountGuid,
                createdBy: userGuid,
                discountType: type,
                seq: i,
                ...rest,
              })),
            },
            // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3040
            // invoiceEvents
            invoiceGuid,
            invoiceTerm: info.invoiceTerm,
            jobInvoices: jobGuid
              ? {
                  data: [
                    {
                      jobGuid,
                    },
                  ],
                  onConflict: {
                    constraint: 'job_invoices_pkey',
                    updateColumns: [],
                  },
                }
              : undefined,

            jobAppointmentInvoices: jobAppointmentGuid
              ? {
                  data: [
                    {
                      jobAppointmentGuid,
                    },
                  ],
                  onConflict: {
                    constraint: 'job_appointment_invoices_pkey',
                    updateColumns: [],
                  },
                }
              : undefined,
            locationInvoices: {
              data: [
                {
                  locationGuid: info.serviceLocationGuid,
                },
              ],
              onConflict: {
                constraint: 'location_invoices_pkey',
                updateColumns: [],
              },
            },
            messageHtml,
            serviceAddressGuid,
            serviceCompletionDate: info.serviceCompletionDate,
            status,
            subtotalUsc: totals.subtotalUsc,
            taxRate: taxRate.rate,
            taxRateGuid: taxRate.pricebookTaxRateGuid,
            taxRateName: taxRate.name,
            dynamicPricingType,
            totalUsc: totals.totalUsc,
          },
        })
        if (upsertRes.error) {
          throw upsertRes.error
        }

        if (event) {
          await writeInvoiceEvent(event)
        }

        return true
      } catch (e) {
        console.error(e)
      } finally {
        setIsUpserting(false)
      }
      return false
    },
    [
      accountGuid,
      companyGuid,
      displayId,
      invoiceGuid,
      jobAppointmentGuid,
      jobGuid,
      realPricebookItemGuidMap,
      refetchRecentItems,
      upsertInvoice,
      upsertRecentPricebookItems,
      userGuid,
      writeInvoiceEvent,
    ],
  )

  return [saveInvoice, isUpserting] as const
}

export type FetchedInvoice = NonNullable<
  InvoiceDataSubscription['invoicesByPk']
>

export type FetchedInvoiceWithGuid = FetchedInvoice & {
  invoiceGuid: Guid
}

export const INVOICE_STATUS_DISPLAY_INFO: Record<
  InvoiceV2Status,
  { label: string; colorClassName: string }
> = {
  DRAFT: {
    label: 'Draft',
    colorClassName: 'bg-bz-gray-400 text-bz-gray-1000',
  },
  OPEN: {
    label: 'Open',
    colorClassName: 'bg-geek-blue-200 text-geek-blue-900',
  },
  PAID: {
    label: 'Paid',
    colorClassName: 'bg-bz-green-200 text-bz-green-900',
  },
  VOIDED: {
    label: 'Voided',
    colorClassName: 'bg-bz-red-200 text-bz-red-900',
  },
  UNCOLLECTABLE: {
    label: 'Uncollectable',
    colorClassName: 'bg-bz-gray-500 text-bz-gray-900',
  },
}

export const getLineItemsFromInvoiceQuery = (
  invoice: FetchedInvoice,
): InvoiceCartItem[] => {
  return invoice.invoiceCartItems.map(
    ({ cartItem: { photo, ...cartItem }, seq }) => ({
      ...cartItem,
      seq,
      itemGuid: cartItem.originalPricebookItemGuid ?? '',
      description: cartItem.description ?? '',
      photoCdnUrl: photo?.cdnUrl ?? undefined,
      photoGuid: photo?.photoGuid ?? undefined,
    }),
  )
}

export const useLineItemsFromInvoiceQuery = (
  invoice: FetchedInvoice,
): InvoiceCartItem[] =>
  useMemo(() => getLineItemsFromInvoiceQuery(invoice), [invoice])

// TODO: this is the result of more TypeScript sadness. We need to clean this up
// and figure out a way to remove the need to cast the metadata to the correct type
const convertToInvoiceHistoryInvoice = (
  event: FetchedInvoice['invoiceEvents'][number],
): InvoiceHistoryInfo => {
  const createdByUserFullName =
    event.createdByUser.userGuid === BreezySystemUserGuid
      ? 'Breezy'
      : event.createdByUser.fullName ??
        `${event.createdByUser.firstName} ${event.createdByUser.lastName}`
  switch (event.eventType) {
    case 'PAYMENT_MADE':
      return {
        ...event,
        eventType: 'PAYMENT_MADE',
        createdByUserFullName,
        metadata: event.metadata as { paymentRecordGuid: string },
      }
    case 'MANUAL_STATUS_CHANGE':
      return {
        ...event,
        eventType: 'MANUAL_STATUS_CHANGE',
        createdByUserFullName,
        metadata: event.metadata as { status: InvoiceV2Status },
      }
    case 'SENT':
      return {
        ...event,
        eventType: 'SENT',
        createdByUserFullName,
        metadata: event.metadata as { deliveryMethod: 'EMAIL' | 'SMS' },
      }
    case 'FINANCING_APPLICATION_SENT':
      return {
        ...event,
        eventType: 'FINANCING_APPLICATION_SENT',
        createdByUserFullName,
        metadata: event.metadata as {
          applicationType: 'LOAN_APPLICATION' | 'PREQUAL_APPLICATION'
        },
      }
    case 'ACCOUNTING_SYNC':
      return {
        ...event,
        eventType: 'ACCOUNTING_SYNC',
        createdByUserFullName,
        metadata: event.metadata as {
          accountingIntegrationType: AccountingIntegrationType
        },
      }
    default:
      return {
        ...event,
        eventType: event.eventType,
        createdByUserFullName,
        metadata: event.metadata as Record<string, unknown>,
      }
  }
}

export const getFetchedInvoiceEventsToInvoiceHistory = (
  invoiceEvents: FetchedInvoice['invoiceEvents'],
): InvoiceHistoryInfo[] => {
  return invoiceEvents.map(convertToInvoiceHistoryInvoice)
}

export const useInvoiceHistory = (
  invoice: FetchedInvoice,
): InvoiceHistoryInfo[] =>
  useMemo(
    () => getFetchedInvoiceEventsToInvoiceHistory(invoice.invoiceEvents),
    [invoice.invoiceEvents],
  )

export const getFetchedDiscountsToInvoiceDiscounts = (
  invoiceDiscounts: FetchedInvoice['invoiceDiscounts'],
): InvoiceDiscount[] => {
  if (!invoiceDiscounts.length) {
    return []
  }

  return invoiceDiscounts.map(discount => {
    const base = {
      name: discount.name,
      descriptionHtml: discount.descriptionHtml ?? '',
      discountGuid: discount.invoiceDiscountGuid,
    } as const
    if (discount.discountType === DiscountType.FLAT) {
      return {
        ...base,
        type: DiscountType.FLAT,
        discountAmountUsc: discount.discountAmountUsc ?? 0,
      }
    } else {
      return {
        ...base,
        type: DiscountType.RATE,
        discountRate: discount.discountRate ?? 0,
      }
    }
  })
}

export const useFetchedDiscountsToInvoiceDiscounts = (
  invoiceDiscounts: FetchedInvoice['invoiceDiscounts'],
): InvoiceDiscount[] =>
  useMemo(
    () => getFetchedDiscountsToInvoiceDiscounts(invoiceDiscounts),
    [invoiceDiscounts],
  )

export const useDiscountsFromInvoiceQuery = (
  invoice: FetchedInvoice,
): InvoiceDiscount[] =>
  useFetchedDiscountsToInvoiceDiscounts(invoice.invoiceDiscounts)

export const useTaxRateFromInvoiceQuery = (
  invoice: FetchedInvoice,
): PricebookTaxRateDto =>
  useMemo(
    () => ({
      name: invoice.taxRateName,
      pricebookTaxRateGuid: invoice.taxRateGuid,
      rate: invoice.taxRate,
    }),
    [invoice.taxRate, invoice.taxRateGuid, invoice.taxRateName],
  )

export const InvoiceEditSchema = z.object({
  messageHtml: htmlStringSchema,
  taxRate: PricebookTaxRateDtoSchema,
  infoFormData: InvoiceInfoFormSchema,
  lineItems: z.array(InvoiceCartItemSchema),
  discounts: z.array(InvoiceDiscountSchema),
  dynamicPricingType: bzOptional(z.enum(DYNAMIC_PRICING_TYPES)),
})

export type InvoiceEditData = z.infer<typeof InvoiceEditSchema>

export const useSaveInvoiceSignature = () => {
  const { invoiceGuid } = useStrictContext(InvoiceContext)

  const [, updateInvoice] = useMutation(UPDATE_INVOICE_MUTATION)

  const [isUpdating, setIsUpdating] = useState(false)

  const getSignedUrl = trpc.photos['photos:get-signed-upload-url'].useMutation({
    // https://trpc.io/docs/v9/links#2-perform-request-without-batching
    trpc: {
      context: {
        skipBatch: true,
      },
    },
  })

  const saveSignature = useCallback(
    async (signaturePNG: string) => {
      setIsUpdating(true)

      try {
        const filename = `signature_${Date.now()}.jpg`
        const { uploadUrl, resultUrl } = await getSignedUrl.mutateAsync({
          filename: `invoicesV2/${filename}`,
        })

        const file = dataURLtoFile(signaturePNG, filename, 'image/png')

        const res = await axios({
          url: uploadUrl,
          method: 'PUT',
          data: file,
          headers: {
            'Content-Type': 'image/png',
          },
        })

        if (res.status !== 200) {
          console.error(res)
          throw new Error(
            `Signature upload failed with status code ${res.status}`,
          )
        }
        const updateRes = await updateInvoice({
          invoiceGuid,
          set: {
            signatureFileUrl: resultUrl,
          },
        })

        if (updateRes.error) {
          throw updateRes.error
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsUpdating(false)
      }
    },
    [getSignedUrl, invoiceGuid, updateInvoice],
  )

  return [saveSignature, isUpdating] as const
}

type SpecialInvoiceMarkAsStatus = {
  [K in keyof typeof INVOICE_V2_STATUS_DISPLAY_INFO]: (typeof INVOICE_V2_STATUS_DISPLAY_INFO)[K] extends {
    hasSpecialMarkAsBehavior: true
  }
    ? K
    : never
}[keyof typeof INVOICE_V2_STATUS_DISPLAY_INFO]

export const hasSpecialInvoiceMarkAsBehavior = (
  status: InvoiceV2Status,
  totals: InvoiceTotals,
): status is SpecialInvoiceMarkAsStatus => {
  if (status === 'PAID') {
    // If the invoice has an outstanding balance, we want to start the payment workflow.
    // If the invoice has an amount due of $0 but is still processing payments, we want to
    // trigger a signifier to the user that explains that the invoice is still processing payments.
    return totals.dueUsc > 0 || totals.processingPaymentsUsc > 0
  }
  return !!INVOICE_V2_STATUS_DISPLAY_INFO[status as SpecialInvoiceMarkAsStatus]
    .hasSpecialMarkAsBehavior
}

export const useInvoiceStatusUpdater = (currentStatus?: InvoiceV2Status) => {
  const { invoiceGuid } = useStrictContext(InvoiceContext)
  const [{ fetching }, updateInvoice] = useMutation(UPDATE_INVOICE_MUTATION)
  const writeInvoiceEvent = useWriteInvoiceEvent()

  const invoiceStatusUpdater = useCallback(
    async (newStatus: InvoiceV2Status) => {
      if (!currentStatus) {
        return
      }
      if (newStatus === currentStatus) {
        return
      }

      await updateInvoice({
        invoiceGuid,
        set: {
          status: newStatus,
          updatedAt: new Date().toISOString(),
        },
      })
      await writeInvoiceEvent({
        type: 'MANUAL_STATUS_CHANGE',
        invoiceGuid,
        data: {
          status: newStatus,
        },
      })
    },
    [currentStatus, invoiceGuid, updateInvoice, writeInvoiceEvent],
  )

  return [invoiceStatusUpdater, fetching] as const
}

export const mapInvoiceToAbidgedInvoiceMetadata = (
  invoiceGuid: Guid,
  invoice: FetchedInvoice,
): AbridgedInvoiceMetadataWithAmountDue => {
  return {
    invoiceGuid,
    accountGuid: invoice.accountGuid,
    displayId: invoice.displayIdV2 ?? invoice.displayId.toString(),
    // TODO: https://getbreezyapp.atlassian.net/browse/BZ-3440
    referenceNumber: invoiceGuid,
    status: invoiceV2ToInvoiceV1Status(invoice.status),
    serviceCompletionDate: invoice.serviceCompletionDate,
    amountDueUsc: getInvoiceTotals(invoice).dueUsc,
  }
}

export const getInvoiceTotals = (invoice: FetchedInvoice): InvoiceTotals => {
  const lineItems = getLineItemsFromInvoiceQuery(invoice)

  const discounts = getFetchedDiscountsToInvoiceDiscounts(
    invoice.invoiceDiscounts,
  )
  const payments = paymentRecordsToInvoicePayments(invoice.paymentRecords)

  return calculateInvoiceTotals(
    lineItems,
    invoice.taxRate,
    discounts,
    payments,
    invoice.dynamicPricingType,
  )
}

export const useInvoiceTotals = (invoice: FetchedInvoice): InvoiceTotals => {
  const lineItems = useLineItemsFromInvoiceQuery(invoice)

  const discounts = useDiscountsFromInvoiceQuery(invoice)

  return useMemo(
    () =>
      calculateInvoiceTotals(
        lineItems,
        invoice.taxRate,
        discounts,
        paymentRecordsToInvoicePayments(invoice.paymentRecords),
        invoice.dynamicPricingType,
      ),
    [
      discounts,
      invoice.dynamicPricingType,
      invoice.paymentRecords,
      invoice.taxRate,
      lineItems,
    ],
  )
}

export const useFetchInvoicesSubscription = (
  where: InvoicesBoolExp,
  pause?: boolean,
) => {
  const [fetchInvoicesSubscription] = useSubscription({
    query: INVOICES_DATA_SUBSCRIPTION,
    variables: {
      where,
    },
    pause,
  })

  return fetchInvoicesSubscription
}

export type InvoiceWithGuidFragment = DocumentType<
  typeof INVOICE_FRAGMENT_WITH_GUID
>

export const useIsInvoiceFinanceable = (
  status: InvoiceV2Status,
  totals: InvoiceTotals,
) => {
  const wisetackEnabled = useWisetackEnabled()
  return useMemo(
    () =>
      wisetackEnabled &&
      isFinanceableInvoiceV2({
        status,
        dueUsc: totals.dueUsc,
      }),
    [status, totals.dueUsc, wisetackEnabled],
  )
}

export const useIsInvoicePriceEditable = () => {
  const { status, invoiceTerm } = useStrictContext(InvoiceContext)
  return useMemo(
    () => status !== 'PAID' && (!invoiceTerm || invoiceTerm !== 'AUTO'),
    [invoiceTerm, status],
  )
}

export const useInvoiceQboInfo = (invoice: FetchedInvoice) => {
  return useMemo(() => {
    const qboSyncedAt = invoice.qboStaleInfo?.syncedAt
    const isStale = invoice.qboStaleInfo?.stale ?? false

    return {
      qboSyncedAt,
      isStale,
    }
  }, [invoice.qboStaleInfo?.stale, invoice.qboStaleInfo?.syncedAt])
}

export const getAppointmentLinkFromInvoice = (
  invoice: FetchedInvoice,
): InvoiceAppointmentLink | undefined => {
  const jobAppointment = invoice.jobAppointmentLink?.jobAppointment
  if (!jobAppointment) {
    return
  }

  return {
    appointmentGuid: jobAppointment.jobAppointmentGuid,
    appointmentType: jobAppointment.appointmentType,
    appointmentStartedAt: jobAppointment.appointmentWindowStart,
    assignedTechniciansFirstNameLastInitial: jobAppointment.assignments.length
      ? jobAppointment.assignments
          .map(assignment => `${assignment.technician.firstNameInitial}`)
          .join(', ')
      : 'Unassigned',
  }
}

export const getProcessingPayment = (
  invoice: FetchedInvoice,
): { paymentAmountUsd: number; occurredAt: IsoDateString } | undefined => {
  const invoicePayments = paymentRecordsToInvoicePayments(
    invoice.paymentRecords,
  )
  const processingPayment = invoicePayments.find(payment =>
    paymentPendingStatuses.has(payment.status),
  )
  if (!processingPayment) return

  return {
    paymentAmountUsd: usCentsToUsd(processingPayment.paidUsc),
    occurredAt: processingPayment.occurredAt,
  }
}
