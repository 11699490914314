import { formatAbbreviatedUsc } from '@breezy/shared/src/common/money-utils'
import React from 'react'
import { SummaryStatBox } from '../../adam-components/ListPage/SummaryStats'
import { useStrictContext } from '../../utils/react-utils'
import { InvoiceListingDataContext } from './InvoiceListPageContexts'

export const InvoiceSummaryStats = React.memo(() => {
  const {
    summaryStatsLoading,
    summaryStats: {
      outstanding,
      overdue,
      fullyPaid,
      avgValueUsc,
      avgDaysToPay,
    },
  } = useStrictContext(InvoiceListingDataContext)

  return (
    <>
      <SummaryStatBox
        doubleWide
        bottomBarColorClasses={[
          'bg-bz-cyan-300',
          'bg-bz-magenta-300',
          'bg-bz-purple-300',
        ]}
        label="Outstanding"
        loading={summaryStatsLoading}
        secondaryContent={outstanding.count}
        tooltipContent="Displays outstanding balance of invoices with a status of 'Open'"
      >
        {formatAbbreviatedUsc(outstanding.amountUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        label="Overdue"
        loading={summaryStatsLoading}
        secondaryContent={overdue.count}
        tooltipContent="Displays the total amount of invoices that are past due."
      >
        {formatAbbreviatedUsc(overdue.amountUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        bottomBarColorClasses={['bg-bz-green-300']}
        label="Fully Paid"
        loading={summaryStatsLoading}
        tooltipContent="Displays the monetary value of all fully paid invoices."
        secondaryContent={fullyPaid.count}
      >
        {formatAbbreviatedUsc(fullyPaid.amountUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        label="Avg. Invoice Value"
        loading={summaryStatsLoading}
        tooltipContent="Displays the average monetary amount of issued invoices."
      >
        {formatAbbreviatedUsc(avgValueUsc)}
      </SummaryStatBox>
      <SummaryStatBox
        label="Avg. Days to Pay"
        loading={summaryStatsLoading}
        tooltipContent="Displays the average number of calendar days from invoice issuance to payment."
      >
        {avgDaysToPay}
      </SummaryStatBox>
    </>
  )
})
