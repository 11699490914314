import { RoleId, User } from '@breezy/shared'

export type TechnicianResource = User & {
  avatarShortString: string
  avatarColor: string
  avatarImageUrl?: string
}

export const toRoleShortName = (role: RoleId): string => {
  switch (role) {
    case RoleId.INSTALL_TECHNICIAN:
      return 'Install'
    case RoleId.MAINTENANCE_TECHNICIAN:
      return 'Maint.'
    case RoleId.SERVICE_TECHNICIAN:
      return 'Service'
    case RoleId.SALES_TECHNICIAN:
      return 'Sales'
    case RoleId.CUSTOMER_SERVICE_REPRESENTATIVE:
      return 'CSR'
    case RoleId.DISPATCHER:
      return 'Disp.'
    case RoleId.ADMIN:
      return 'Admin'
    case RoleId.ACCOUNTANT:
      return 'Acc.'
    default:
      return 'Unknown'
  }
}
