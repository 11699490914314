import { AvatarData, isNullish, User } from '@breezy/shared'

export const userToAvatarData = (
  user: User,
  avatarImageUrl?: string,
): AvatarData => {
  return {
    avatarAltShortString: user.firstName[0] + user.lastName[0],
    avatarAltColor: !isNullish(user.color) ? user.color : '#F5F5F5',
    userGuid: user.userGuid,
    deactivatedAt: user.deactivatedAt,
    avatarImgSrc: avatarImageUrl,
  }
}
