import {
  faBan,
  faScrewdriverWrench,
  faTag,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React from 'react'

import { DetailChip } from '../../adam-components/ListPage/DetailChip'
import { TagRow } from '../../elements/TagRow/TagRow'
import { DoNotServiceBanner } from '../Accounts/AccountDetailsBanner/AccountDetailsBanner'
import { useOpenAccountBalance } from '../Accounts/AccountDetailsBanner/AccountDetailsBanner.gql'

type AccountTagRowMaintenancePlan = {
  color?: string
  name: string
}

type AccountTagRowProps = {
  accountGuid: string
  maintenancePlans?: AccountTagRowMaintenancePlan[]
  doNotServiceReason?: string
  tags: string[]
  chipClassName?: string
}

export const AccountTagRow = React.memo(
  ({
    accountGuid,
    maintenancePlans,
    doNotServiceReason,
    tags,
    chipClassName,
  }: AccountTagRowProps) => {
    const accountBalance = useOpenAccountBalance(accountGuid)
    const overdueBalanceFormatted = accountBalance?.overdueBalanceFormatted
    const ageOfOldestOpenInvoice = accountBalance?.ageOfOldestOpenInvoice
    return (
      <TagRow chipClassName={chipClassName}>
        {overdueBalanceFormatted && (
          <DetailChip
            className={classNames(
              'border-bz-orange-700 bg-bz-orange-100 text-bz-orange-700',
            )}
          >
            <span className="semibold_12_20">Due:</span>{' '}
            <span className="regular_14_22">{overdueBalanceFormatted}</span>{' '}
            <span className="semibold_12_20">Age:</span>{' '}
            <span className="regular_14_22">{ageOfOldestOpenInvoice} days</span>
          </DetailChip>
        )}
        {doNotServiceReason && (
          <DetailChip
            className={classNames(
              chipClassName,
              'border-bz-orange-700 bg-bz-orange-100 text-bz-orange-700',
            )}
            icon={
              <FontAwesomeIcon icon={faBan} className="text-bz-orange-700" />
            }
            popoverContent={
              <DoNotServiceBanner
                doNotServiceReason={doNotServiceReason}
                className="max-w-96 items-start"
              />
            }
          >
            Do Not Service
          </DetailChip>
        )}
        {maintenancePlans?.map((maintenancePlan, i) => (
          <React.Fragment key={`${i}_${maintenancePlan?.name}`}>
            {maintenancePlan && (
              <DetailChip
                className={chipClassName}
                accentColor={maintenancePlan.color}
                icon={
                  <FontAwesomeIcon
                    icon={faScrewdriverWrench}
                    style={{ color: maintenancePlan.color }}
                  />
                }
              >
                {maintenancePlan.name}
              </DetailChip>
            )}
          </React.Fragment>
        ))}
        {tags.map(tag => (
          <DetailChip
            className={chipClassName}
            key={tag}
            icon={<FontAwesomeIcon icon={faTag} className="text-bz-gray-900" />}
          >
            {tag}
          </DetailChip>
        ))}
      </TagRow>
    )
  },
)
