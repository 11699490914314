import { z } from 'zod'
import { bzOptional } from '..'
import { guidSchema } from '../../contracts'

export const JobLeadContactInformationSchema = z.object({
  // Existing Contact Information flow
  preExistingLocationGuid: bzOptional(guidSchema),

  // New Contact Information
  contactFirstName: bzOptional(z.string().describe('First Name')),
  contactLastName: bzOptional(z.string().describe('Last Name')),
  contactPhoneNumber: bzOptional(z.string().describe('Phone Number')),
  contactEmailAddress: bzOptional(z.string().describe('Email Address')),
  serviceAddressLine1: bzOptional(z.string().describe('Service Location Line 1')),
  serviceAddressLine2: bzOptional(z.string().describe('Service Location Line 2')),
  serviceAddressCity: bzOptional(z.string().describe('Service Location City')),
  serviceAddressStateAbbreviation: bzOptional(z.string().describe('Service Location State')),
  serviceAddressZipCode: bzOptional(z.string().describe('Service Location Zip Code')),
})
