type Base64ImageDataUrl = string

const optimize = (
  imgDataUrl: Base64ImageDataUrl,
  img: HTMLImageElement,
  maxDim: number,
): Promise<Base64ImageDataUrl> => {
  return new Promise((resolve, reject) => {
    const originalWidth = img.width
    const originalHeight = img.height
    if (originalWidth < maxDim && originalHeight < maxDim) {
      resolve(imgDataUrl)
      return
    }

    const canvas = document.createElement('canvas')
    let width = img.width
    let height = img.height

    // Maintain aspect ratio
    if (width > height) {
      if (width > maxDim) {
        height = Math.round((height * maxDim) / width)
        width = maxDim
      }
    } else {
      if (height > maxDim) {
        width = Math.round((width * maxDim) / height)
        height = maxDim
      }
    }

    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.drawImage(img, 0, 0, width, height)
      const dataUrl = canvas.toDataURL('image/jpeg', 0.8)
      resolve(dataUrl) // Resolve the promise with the optimized image
    } else {
      reject(new Error('Failed to get 2D context'))
    }
  })
}

export const dataURLtoFile = (
  dataurl: Base64ImageDataUrl,
  filename: string,
  type: string,
): File => {
  const [, base64Data] = dataurl.split(',')
  const binaryString = atob(base64Data)
  const length = binaryString.length
  const bytes = new Uint8Array(length)
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return new File([bytes], filename, { type })
}

const jpgDataURLtoFile = (
  dataurl: Base64ImageDataUrl,
  originalFilename: string,
): File => {
  const cleanFilename = originalFilename.replace(/\.[^/.]+$/, '')
  return dataURLtoFile(dataurl, `${cleanFilename}.jpeg`, 'image/jpeg')
}

// Needed to turn the image into a base64 string so we can show it as a thumbnail while it loads.
const file2Base64WithMetaData = (file: File): Promise<Base64ImageDataUrl> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (!reader.result) return reject('No result')

      resolve(reader.result.toString())
    }
    reader.onerror = error => reject(error)
  })

export const optimizeImageFile = async (
  file: File,
  maxDim: number,
): Promise<[File, Base64ImageDataUrl]> => {
  const originalBase64File = await file2Base64WithMetaData(file)
  const imageElement = new Image()
  imageElement.src = originalBase64File
  await new Promise(resolve => {
    imageElement.onload = resolve
  })

  const optimizedImgBase64 = await optimize(
    originalBase64File,
    imageElement,
    maxDim,
  )
  const optimizedFile = jpgDataURLtoFile(optimizedImgBase64, file.name)
  return [optimizedFile, optimizedImgBase64]
}

export const optimizeBase64Image = async (
  base64Image: Base64ImageDataUrl,
  maxDim: number,
): Promise<Base64ImageDataUrl> => {
  const imageElement = new Image()
  imageElement.src = base64Image
  await new Promise(resolve => {
    imageElement.onload = resolve
  })
  const optimizedImgBase64 = await optimize(base64Image, imageElement, maxDim)
  return optimizedImgBase64
}
