import { BzDateFns, CompanyGuid, EstimateGuid } from '@breezy/shared'
import { useCallback } from 'react'
import { useClient } from 'urql'
import { gql } from '../../../../../generated'
import { convertEstimateOptionsToOptions } from '../../../estimatesFlowUtils'

export const ESTIMATE_PDF_DATA_QUERY = gql(/* GraphQL */ `
  query EstimatePDFDataV2($estimateGuid: uuid!, $companyGuid: uuid!) {
    pricebookItems(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookItemGuid
    }
    billingProfilesByPk(companyGuid: $companyGuid) {
      defaultPricebookTaxRateGuid
      estimateDisclaimer
      businessFullLegalName
      contractorLicenseNumber
      invoiceTerm
      logoUrl
      logoPhotoGuid
      logoPhoto {
        cdnUrl
      }
    }
    companyConfigByPk(companyGuid: $companyGuid) {
      blurb
    }
    pricebookTaxRates(
      limit: 1
      where: { companyGuid: { _eq: $companyGuid }, isActive: { _eq: true } }
    ) {
      name
      rate
      pricebookTaxRateGuid
    }
    companiesByPk(companyGuid: $companyGuid) {
      name
      tzId: timezone
    }
    estimatesByPk(estimateGuid: $estimateGuid) {
      messageHtml
      acceptedAt
      signatureFileUrl
      acceptedOnBehalfByUser {
        firstName
        lastName
      }
      job {
        pointOfContact {
          fullName
        }
      }
      taxRate
      taxRateName
      taxRateGuid
      dynamicPricingType
      jobGuid
      displayId
      createdAt
      status
      companyGuid
      estimateOptions(orderBy: { seq: ASC }) {
        optionGuid: estimateOptionGuid
        descriptionHtml
        displayName
        totalUsc
        featuredPhotoGuid
        featuredPhoto {
          cdnUrl
        }
        estimateOptionDiscounts(orderBy: { seq: ASC }) {
          discountGuid: estimateOptionDiscountGuid
          discountAmountUsc
          discountRate
          discountType
          name
          seq
        }
        recommended: isRecommended
        selected: isSelected
        seq
        cartItems {
          seq
          cartItem {
            cartItemGuid
            itemType: cartItemType
            description
            isDiscountable
            isTaxable
            name
            originalPricebookItemGuid
            quantity
            unitPriceUsc
          }
        }
      }
    }
  }
`)

export const useDownloadableEstimatePdfData = (
  estimateGuid: EstimateGuid,
  companyGuid: CompanyGuid,
) => {
  const client = useClient()

  const executePdfDataQuery = useCallback(async () => {
    const res = await client.query(ESTIMATE_PDF_DATA_QUERY, {
      estimateGuid,
      companyGuid,
    })

    const companyDefaultTaxRateGuid =
      res.data?.billingProfilesByPk?.defaultPricebookTaxRateGuid
    const companyDefaultTaxRate = res.data?.pricebookTaxRates.find(
      taxRate => taxRate.pricebookTaxRateGuid === companyDefaultTaxRateGuid,
    )?.rate
    return {
      messageHtml: res.data?.estimatesByPk?.messageHtml,
      signatureFileUrl: res.data?.estimatesByPk?.signatureFileUrl,
      acceptedOnBehalfByUser: res.data?.estimatesByPk?.acceptedOnBehalfByUser,
      acceptedAt: res.data?.estimatesByPk?.acceptedAt,
      options: convertEstimateOptionsToOptions(
        res.data?.estimatesByPk?.estimateOptions ?? [],
      ),
      jobGuid: res.data?.estimatesByPk?.jobGuid,
      displayId: `${res.data?.estimatesByPk?.displayId ?? ''}`,
      createdAt: res.data?.estimatesByPk?.createdAt ?? new Date().toISOString(),
      status: res.data?.estimatesByPk?.status ?? 'CREATED',
      disclaimer: res.data?.billingProfilesByPk?.estimateDisclaimer ?? '',
      companyName: res.data?.companiesByPk?.name ?? '',
      businessFullLegalName:
        res.data?.billingProfilesByPk?.businessFullLegalName ?? '',
      logoUrl: res.data?.billingProfilesByPk?.logoUrl ?? '',
      logoPhotoGuid: res.data?.billingProfilesByPk?.logoPhotoGuid ?? '',
      logoPhotoCdnUrl: res.data?.billingProfilesByPk?.logoPhoto?.cdnUrl ?? '',
      taxRate: res.data?.estimatesByPk?.taxRate ?? companyDefaultTaxRate ?? 0,
      taxRateName: res.data?.estimatesByPk?.taxRateName ?? '',
      taxRateGuid: res.data?.estimatesByPk?.taxRateGuid ?? '',
      dynamicPricingType: res.data?.estimatesByPk?.dynamicPricingType,
      tzId: res.data?.companiesByPk?.tzId ?? BzDateFns.NY_TZ,
      contactFullName:
        res.data?.estimatesByPk?.job?.pointOfContact?.fullName ?? '',
    }
  }, [client, companyGuid, estimateGuid])

  return {
    executePdfDataQuery,
  }
}
