import {
  ASSIGNMENT_STATUSES,
  AssignmentStatus,
  AssignmentStatusDisplayNames,
  IsoDateString,
  JobClass,
} from '@breezy/shared'
import { Button, Form, Select } from 'antd'
import { AppointmentArrivalWindowForm } from './NewEventModal'

export interface EditAssignmentPopoverProps {
  appointment: {
    jobClass: JobClass
    assignment: {
      defaultStart: IsoDateString
      start?: IsoDateString
      end?: IsoDateString
    }
  }
  assignmentStatus?: AssignmentStatus
  onArrivalWindowChange: (changes: {
    start?: IsoDateString
    end?: IsoDateString
  }) => void
  onSave: () => void
  onCancel: () => void
  onAssignmentStatusChange?: (newStatus: AssignmentStatus) => void
}

export const EditAssignmentPopover = (props: EditAssignmentPopoverProps) => {
  const {
    appointment,
    assignmentStatus,
    onArrivalWindowChange,
    onSave,
    onCancel,
    onAssignmentStatusChange,
  } = props

  return (
    <div className="flex max-w-[265px] flex-col gap-y-4">
      <span>
        Edit the status or start and end time for the technician’s assignment.
      </span>

      <Form.Item
        label="Status"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="m-0"
      >
        <Select
          value={assignmentStatus}
          options={ASSIGNMENT_STATUSES.map(status => ({
            label: AssignmentStatusDisplayNames[status],
            value: status,
          }))}
          onChange={value => onAssignmentStatusChange?.(value)}
        />
      </Form.Item>

      <div>
        <AppointmentArrivalWindowForm
          hideHeader
          withLabels
          hidePresets
          jobClass={appointment.jobClass}
          defaultStart={appointment.assignment.defaultStart}
          start={appointment.assignment.start}
          end={appointment.assignment.end}
          onChange={onArrivalWindowChange}
        />
      </div>

      <div className="flex flex-row justify-end space-x-2">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={onSave}>
          Save
        </Button>
      </div>
    </div>
  )
}
