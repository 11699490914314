import { IconDefinition } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { CSSProperties, memo } from 'react'

type FaIconWithCircularBackgroundProps = {
  iconDefinition: IconDefinition
  backgroundColor: CSSProperties['backgroundColor']
  borderColor?: CSSProperties['borderColor']
  color?: CSSProperties['color']
  diameterPx?: number
  iconClassName?: string
  className?: string
  noRightMargin?: boolean
}

/**
 * A Font Awesome icon with a circular background. The background is fully colored (filled)
 * and the color can be customized via props.
 *
 * @component
 * @param {IconDefinition} iconDefinition - The Font Awesome icon to display.
 * @param {CSSProperties['backgroundColor']} backgroundColor - The background color of the circular background.
 * @param {number} [diameterPx=28] - The diameter of the circular background in pixels. Defaults to 28.
 *
 * @example
 * <FaIconWithCircularBackground iconDefinition={faCoffee} backgroundColor="red" diameterPx={30} />
 *
 * @returns {ReactElement} The Font Awesome icon with a circular background.
 */
const FaIconWithCircularBackground = memo<FaIconWithCircularBackgroundProps>(
  ({
    iconDefinition,
    backgroundColor,
    borderColor,
    color = 'rgba(0, 0, 0, .88)',
    diameterPx = 28,
    iconClassName,
    noRightMargin,
    className,
  }) => {
    return (
      <div
        style={{
          color,
          backgroundColor,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minWidth: `${diameterPx}px`,
          minHeight: `${diameterPx}px`,
          width: `${diameterPx}px`,
          height: `${diameterPx}px`,
          marginRight: noRightMargin ? '0px' : '6px',
          ...(borderColor && {
            borderColor: borderColor,
            borderWidth: '1px',
            borderStyle: 'solid',
          }),
        }}
        className={classNames(className)}
      >
        <FontAwesomeIcon icon={iconDefinition} className={iconClassName} />
      </div>
    )
  },
)

export default FaIconWithCircularBackground
