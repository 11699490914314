import z from 'zod'
import { EnRouteTechnicianEmailMetadata } from '..'
import { AsyncFn, IsoDateString, LocalDateString, TimeZoneId, emailUtils } from '../../common'
import { ReminderAssignmentEmailData, RemindersWeeklyDigestEmailData } from '../AccountReminders/AccountReminders'
import { AccountGuid } from '../Accounts/Account'
import { Address } from '../Address/Address'
import { CompanyGuid, CompanyGuidContainer, ForCompany } from '../Company/Company'
import { ICompanyPublish, ICompanyRead } from '../Company/CompanyAtoms'
import { BillingProfileDto } from '../Finance/CompanyBillingProfileTypes'
import { PaymentSubscriptionInterval } from '../Finance/PaymentSubscriptions/PaymentSubscriptionTypes'
import { PaymentMethod, PaymentRecordGuidContainer } from '../Finance/Payments/PaymentTypes'
import { PaymentReceipt } from '../Finance/Payments/ReceiptTypes'
import { JobGuid } from '../Job'
import {
  MaintenancePlanActivationEmailInfo,
  MaintenancePlanCancellationEmailRequest,
} from '../MaintenancePlans/MaintenancePlanRequestTypes'
import { MaintenancePlanLapsedPaymentEmailMetadata } from '../MaintenancePlans/MaintenancePlanTypes'
import { Guid, bzOptional } from '../common-schemas'
import { BasicBrandedEmailData } from './BasicBrandedEmail'

export type EmailTemplateType =
  | 'basic-notification'
  | 'basic-left-align-notification'
  | 'invite-user'
  | 'partial-invoice-payment-receipt'
  | 'invoice-payment-receipt'
  | 'action-button'
  | 'financial-document-attachment'
  | 'maintenance-plan-activation'
  | 'maintenance-plan-cancellation'
  | 'reminder-assignment-email'
  | 'reminders-weekly-digest-email'
  | 'basic-branded-email'
  | 'failed-payment-email'
  | 'internal-attachment-email'
  | 'maintenance-plan-lapsed-payment'
  | 'enroute-technician'

export type FinancialDocumentMessageMetadata = BillingProfileDto & {
  paymentLink?: string
}

export type Email = {
  to: EmailAddressValue
  subject: string
  message: string
  templateId?: EmailTemplateType
  whiteLabel?: EmailWhiteLabelData
  messageMetadata?:
    | PaymentReceipt
    | UrlButtonData
    | BillingProfileDto
    | MaintenancePlanActivationEmailInfo
    | MaintenancePlanCancellationEmailRequest
    | FinancialDocumentMessageMetadata
    | ReminderAssignmentEmailData
    | RemindersWeeklyDigestEmailData
    | BasicBrandedEmailData
    | FailedPaymentEmailData
    | MaintenancePlanLapsedPaymentEmailMetadata
    | EnRouteTechnicianEmailMetadata
  attachments?: EmailAttachments[]
}

export type EmailWhiteLabelData = {
  readonly companyName: string
  readonly companyLogoUrl: string
}

export type UrlButtonData = {
  readonly url: string
  readonly text: string
}

export type EmailAttachments = {
  /** Base 64 Format */
  readonly content: string
  readonly filename: string
  readonly type: 'application/pdf'
  readonly disposition: 'attachment'
}

export type Emailer = AsyncFn<ForCompany<Email>>
export type WhiteLabelEmailer = ICompanyPublish<Email>
export type ICompanyEmailWhiteLabelDataReader = AsyncFn<CompanyGuidContainer, EmailWhiteLabelData>

export const emailAddressValueSchema = z
  .string()
  .refine(val => emailUtils.validate(val), { message: 'Invalid Email Address' })

export const optionalEmailAddressValueSchema = bzOptional(
  z.string().refine(val => !val || emailUtils.validate(val), { message: 'Invalid Email Address' }),
)

export type ToEmailAddressContainer = {
  readonly to: EmailAddressValue
}

export type EmailAddressValue = z.infer<typeof emailAddressValueSchema>

export type EmailAddressGuid = Guid
export type EmailAddress = {
  emailAddressGuid: EmailAddressGuid
  companyGuid: CompanyGuid
  emailAddress: EmailAddressValue
}

export type EmailAddressLinks = {
  readonly accountGuid: AccountGuid
  readonly jobGuid?: JobGuid
}

export type ILinkedEmailAddressReader = ICompanyRead<EmailAddressLinks, EmailAddressValue>

export interface FailedPaymentEmailData {
  companyLogoUrl?: string

  accountDisplayName: string
  primaryContactFullName: string
  primaryContactPhoneNumber?: string
  primaryContactEmailAddress?: string
  serviceLocation?: Address
  companyTimeZone: TimeZoneId
  invoiceDisplayId: string
  invoiceAmountUsc: number

  jobDisplayId?: string
  jobType?: string
  appointmentDateTime?: IsoDateString

  maintenancePlanDefinitionName?: string
  maintenancePlanBillingInterval?: PaymentSubscriptionInterval
  maintenancePlanBillingDate?: LocalDateString

  payerName?: string
  paymentMethod: PaymentMethod
  paymentAmountUsc: number

  errorMessage: string
  errorCode?: string
  errorSolution?: string

  paymentDetailsUrl: Guid
}

export type FailedPaymentEmailDataWithSendTo = WithEmailSendTo<FailedPaymentEmailData>

export type FailedPaymentEmailDataWithSendToReader = ICompanyRead<
  PaymentRecordGuidContainer,
  FailedPaymentEmailDataWithSendTo
>

export type WithEmailSendTo<T> = {
  sendTo: string[]
  data: T
}
