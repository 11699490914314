import {
  AssignmentStatus,
  BzDateFns,
  CalculatePaths,
  dates,
  DUMMY_GUID,
  formatEquipmentType,
  formatUsc,
  getDisplayNameForAccountType,
  InferredAppointmentStatus,
  IsoDateString,
  nextGuid,
  phoneUtils,
  R,
  toPlural,
  toRoleDisplayName,
  WithRequiredKeys,
} from '@breezy/shared'
import {
  faClose,
  faEllipsis,
  faHistory,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Drawer, Dropdown, Form, MenuProps, Popover } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSubscription } from 'urql'
import { LabeledItemGrid } from '../../adam-components/LabeledItemGrid'
import { CloseConfirmModal } from '../../adam-components/OnsiteModal/useCloseConfirmModal'
import { useConfirmationModal } from '../../adam-components/OnsiteModal/useConfirmationModal'
import { SectionedCard } from '../../adam-components/SectionedCard/SectionedCard'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { Tag } from '../../components/Tags'
import { ServiceHistoryModal } from '../../components/TechnicianApp/MyAppointmentsPage/AppointmentDetail/ServiceHistory/ServiceHistory'
import { SendVisitConfirmationModal } from '../../components/VisitConfirmation/SendVisitConfirmationModal'
import { convertToJobPointOfContact } from '../../components/VisitConfirmation/useJobPointOfContact'
import { JobPointOfContact } from '../../components/VisitConfirmation/visitConfirmationUtils'
import { AbbreviatedList } from '../../elements/AbbreviatedList/AbbreviatedList'
import { CallablePhoneLink } from '../../elements/CallablePhoneLink/CallablePhoneLink'
import { EnDash } from '../../elements/EmDash/EmDash'
import { Link } from '../../elements/Link/Link'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { getColorForJobClass } from '../../utils/job-utils'
import {
  useBooleanState,
  useHidableData,
  useModalState,
  useStrictContext,
} from '../../utils/react-utils'
import { NotesList } from '../JobDetailsPage/components/NotesList'
import { AppointmentStatusTag } from './AppointmentStatusTag'
import { EditAssignmentPopover } from './EditAssignmentPopover'
import { StatusBox } from './EventStatusBox'
import { SetPendingChangesArg } from './PendingChanges/pendingChanges'
import { useSchedulePendingChanges } from './PendingChanges/SchedulePendingChangesContext'
import {
  APPOINTMENT_DETAILS_SUBSCRIPTION,
  AppointmentDetails,
  ScheduleAppointment,
} from './Schedule.gql'
import { ScheduleCancelAppointmentModal } from './ScheduleCancelAppointmentModal'
import {
  defaultDurationMinutesForJobClass,
  getInferredAppointmentStatusFromAssignments,
  SchedulePageContext,
  TechnicianResource,
  useRunningLateStatus,
} from './scheduleUtils'
import { TechMultiSelectForm } from './TechMultiSelectForm'

type MergedAppointment = ReturnType<
  typeof R.mergeDeepRight<ScheduleAppointment, AppointmentDetails>
>

type AppointmentDetailsDrawerProps = {
  appointment?: ScheduleAppointment
  techList: TechnicianResource[]
}

export const AppointmentDetailsDrawer =
  React.memo<AppointmentDetailsDrawerProps>(({ appointment, ...rest }) => {
    const { setSelectedAppointmentGuid, editingAppointmentGuid } =
      useStrictContext(SchedulePageContext)

    const [detailsSubscription] = useSubscription({
      query: APPOINTMENT_DETAILS_SUBSCRIPTION,
      variables: {
        appointmentGuid: appointment?.appointmentGuid ?? DUMMY_GUID,
      },
      pause: !appointment,
    })

    const providedData = useMemo(
      () =>
        appointment
          ? {
              appointment,
              details: detailsSubscription.data?.jobAppointmentsByPk,
            }
          : undefined,
      [appointment, detailsSubscription.data?.jobAppointmentsByPk],
    )

    const [data, isOpen] = useHidableData(providedData)

    const loading = detailsSubscription.fetching

    const onClose = useCallback(
      () => setSelectedAppointmentGuid(''),
      [setSelectedAppointmentGuid],
    )

    return (
      <Drawer
        open={!editingAppointmentGuid && isOpen}
        closeIcon={false}
        styles={{ body: { padding: 0 } }}
        width={624}
        onClose={onClose}
      >
        {data ? (
          <AppointmentDetailsDrawerInner
            appointment={data.appointment}
            details={data.details}
            loading={loading}
            onClose={onClose}
            {...rest}
          />
        ) : null}
      </Drawer>
    )
  })

type AppointmentDetailsDrawerInnerProps = WithRequiredKeys<
  AppointmentDetailsDrawerProps,
  'appointment'
> & {
  details?: AppointmentDetails
  loading: boolean
  onClose: () => void
}

const AppointmentDetailsDrawerInner =
  React.memo<AppointmentDetailsDrawerInnerProps>(
    ({ appointment, details, loading, onClose, ...rest }) => {
      const { job, appointmentType } = appointment

      const status = useMemo(() => {
        if (!details) {
          return undefined
        }
        const { assignments } = details
        const status = getInferredAppointmentStatusFromAssignments(
          assignments,
          appointment.cancellationStatus?.canceled,
        )
        return status
      }, [appointment.cancellationStatus?.canceled, details])

      const jobPointOfContact = useMemo(() => {
        return convertToJobPointOfContact(details?.job.pointOfContact)
      }, [details?.job.pointOfContact])

      return (
        <div className="flex max-h-screen min-h-screen flex-col">
          <div
            className={classNames(
              'min-h-2 w-full',
              getColorForJobClass(job.jobType.jobClass).ribbon,
            )}
          />
          <div className="mb-3 mt-6 flex flex-row items-center px-8">
            <div className="flex-1 text-xl font-semibold">
              {appointmentType} for {job.jobType.name}
            </div>
            {status && (
              <AppointmentStatusTag
                status={status}
                size="large"
                className="mr-3"
              />
            )}
            <Button
              shape="circle"
              icon={<FontAwesomeIcon icon={faClose} />}
              onClick={onClose}
            />
          </div>
          <div className="relative min-h-0 flex-1 overflow-auto px-8">
            {loading ? (
              <div className="absolute inset-0">
                <LoadingSpinner />
              </div>
            ) : details && status ? (
              <AppointmentDetailsDrawerContent
                appointment={appointment}
                details={details}
                status={status}
                jobPointOfContact={jobPointOfContact}
                {...rest}
              />
            ) : (
              <div>Could not find appointment details.</div>
            )}
          </div>
        </div>
      )
    },
  )

type ItemProps = React.PropsWithChildren<{
  title: React.ReactNode
  className?: string
  rightContent?: React.ReactNode
  doubleWide?: boolean
}>

export const Item = React.memo<ItemProps>(
  ({ title, children, className, rightContent, doubleWide }) => (
    <div
      className={classNames(
        {
          'col-span-2': doubleWide,
        },
        className,
      )}
    >
      <div className="mb-1 flex flex-row items-center">
        <div className="flex-1 text-sm font-semibold">{title}</div>
        {rightContent}
      </div>
      <div>{children}</div>
    </div>
  ),
)

type SectionProps = React.PropsWithChildren<{
  title: React.ReactNode
  rightContent?: React.ReactNode
}>

const Section = React.memo<SectionProps>(
  ({ title, children, rightContent }) => (
    <div className="border-0 border-b-8 border-solid border-b-bz-gray-300 pb-6 pt-6 last:border-b-0 last:pb-0">
      <div className="mb-3 flex flex-row items-center">
        <div className="flex-1 text-base font-semibold">{title}</div>
        {rightContent}
      </div>
      <div>{children}</div>
    </div>
  ),
)

type SubSectionProps = React.PropsWithChildren<{
  className?: string
}>

const SubSection = React.memo<SubSectionProps>(({ children, className }) => (
  <div
    className={classNames(
      'border-0 border-t border-solid border-bz-border-secondary pb-4 pt-4 first:border-t-0 first:pt-0',
      className,
    )}
  >
    {children}
  </div>
))

const renderMoreTags = (num: number) => (
  <Tag
    hideIcon
    noPopover
    compact
    className="mr-0"
    tag={{ name: `+${num}` }}
    tagStyleVersion="v2"
  />
)

type TagListItemProps = {
  title: React.ReactNode
  tags: { tag: { name: string } }[]
}

const TagListItem = React.memo<TagListItemProps>(({ title, tags }) => (
  <Item title={title} className="mt-4">
    {tags.length ? (
      <AbbreviatedList
        renderMore={renderMoreTags}
        spacingClassName="space-x-1.5"
      >
        {tags.map((tag, i) => (
          <Tag
            noPopover
            compact
            key={`${tag.tag.name}_${i}`}
            tag={tag.tag}
            className="mr-0"
            tagStyleVersion="v2"
          />
        ))}
      </AbbreviatedList>
    ) : (
      NA
    )}
  </Item>
))

type DetailsAssignment = AppointmentDetails['assignments'][0]

type TechsSectionProps = {
  appointment: MergedAppointment
  techList: TechnicianResource[]
  hideAdd?: boolean
}

const TechSection = React.memo<TechsSectionProps>(
  ({ appointment, techList, hideAdd }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { assignments } = appointment
    const [editModeOn, turnOnEditMode, turnOffEditMode] = useBooleanState()

    const { setPendingChanges } = useSchedulePendingChanges()

    const preSelectedTechGuids = useMemo(
      () => assignments.map(assignment => assignment.technician.userGuid),
      [assignments],
    )

    const [selectedTechGuids, setSelectedTechGuids] =
      useState(preSelectedTechGuids)

    useEffect(() => {
      setSelectedTechGuids(preSelectedTechGuids)
    }, [preSelectedTechGuids])

    const onSave = useCallback(() => {
      const usedTechGuids = assignments.reduce(
        (acc, assignment) => ({
          ...acc,
          [assignment.technician.userGuid]: true,
        }),
        {} as Record<string, boolean>,
      )

      let start: IsoDateString
      let end: IsoDateString

      const firstAssignment = assignments[0]

      if (firstAssignment) {
        start = firstAssignment.assignmentStart
        end = firstAssignment.assignmentEnd
      } else {
        start = appointment.appointmentWindowStart
        end = BzDateFns.withTimeZone(
          appointment.appointmentWindowStart,
          tzId,
          date =>
            BzDateFns.addMinutes(
              date,
              defaultDurationMinutesForJobClass[
                appointment.job.jobType.jobClass
              ],
            ),
        )
      }

      const changes: SetPendingChangesArg[] = []

      const deletedTechGuids = R.difference(
        preSelectedTechGuids,
        selectedTechGuids,
      )

      for (const techGuid of deletedTechGuids) {
        const assignment = assignments.find(
          assignment => assignment.technician.userGuid === techGuid,
        )
        // Logically it must exist. But better safe than sorry
        if (assignment) {
          changes.push({
            field: 'deletedEventMap',
            key: assignment.assignmentGuid,
            value: {
              appointmentGuid: appointment.appointmentGuid,
            },
          })
        }
      }

      for (const selectedTechGuid of selectedTechGuids) {
        if (usedTechGuids[selectedTechGuid]) {
          continue
        }
        const assignmentGuid = nextGuid()
        changes.push({
          field: 'newEventMap',
          key: assignmentGuid,
          value: {
            start,
            end,
            appointmentGuid: appointment.appointmentGuid,
            assignmentGuid,
            userGuids: [selectedTechGuid],
            resource: selectedTechGuid,
            jobClass: appointment.job.jobType.jobClass,
            accountDisplayName: appointment.job.account.accountDisplayName,
          },
        })
      }

      setPendingChanges(changes)
      turnOffEditMode()
    }, [
      appointment.appointmentGuid,
      appointment.appointmentWindowStart,
      appointment.job.account.accountDisplayName,
      appointment.job.jobType.jobClass,
      assignments,
      preSelectedTechGuids,
      selectedTechGuids,
      setPendingChanges,
      turnOffEditMode,
      tzId,
    ])

    return (
      <SubSection className="mb-[-16px]">
        <Item
          title="Assigned Techs"
          rightContent={
            !hideAdd &&
            (editModeOn ? (
              <div className="flex flex-row space-x-2">
                <Button onClick={turnOffEditMode}>Cancel</Button>
                <Button type="primary" onClick={onSave}>
                  Save
                </Button>
              </div>
            ) : (
              <Button onClick={turnOnEditMode}>Add Tech</Button>
            ))
          }
        >
          {editModeOn && (
            <Form layout="vertical" className="mt-3">
              <TechMultiSelectForm
                technicians={techList}
                selectedTechGuids={selectedTechGuids}
                onChange={setSelectedTechGuids}
              />
            </Form>
          )}
          <div className="mt-3 grid grid-cols-2 gap-3">
            {assignments.map(assignment => (
              <AssignmentCard
                key={assignment.assignmentGuid}
                appointment={appointment}
                assignment={assignment}
                removeDisabled={editModeOn}
              />
            ))}
          </div>
        </Item>
      </SubSection>
    )
  },
)

type AssignmentCardProps = {
  assignment: DetailsAssignment
  appointment: MergedAppointment
  removeDisabled?: boolean
}

const AssignmentCard = React.memo<AssignmentCardProps>(
  ({ assignment, appointment, removeDisabled }) => {
    const tzId = useExpectedCompanyTimeZoneId()
    const { assignmentGuid, assignmentStart, assignmentEnd, technician } =
      assignment

    const {
      appointmentGuid,
      job,
      appointmentWindowStart,
      appointmentWindowEnd,
    } = appointment

    const statusTag = useMemo(() => {
      const status = getInferredAppointmentStatusFromAssignments(
        [assignment],
        appointment.cancellationStatus?.canceled,
      )

      return <AppointmentStatusTag status={status} />
    }, [appointment.cancellationStatus?.canceled, assignment])

    const range = useMemo(() => {
      return dates.calculateDateTimeWindow(
        assignmentStart,
        assignmentEnd,
        tzId,
        {
          includeDate: false,
        },
      )
    }, [assignmentEnd, assignmentStart, tzId])

    const { setPendingChanges } = useSchedulePendingChanges()

    const [withConfirmation, closeConfirmProps] = useConfirmationModal()

    const onRemove = useCallback(() => {
      withConfirmation(() => {
        setPendingChanges({
          field: 'deletedEventMap',
          key: assignment.assignmentGuid,
          value: {
            appointmentGuid,
          },
        })
      })
    }, [
      appointmentGuid,
      assignment.assignmentGuid,
      setPendingChanges,
      withConfirmation,
    ])

    const defaultArrivalWindow = useMemo(
      () => ({
        start: appointmentWindowStart,
        end: appointmentWindowEnd,
      }),
      [appointmentWindowEnd, appointmentWindowStart],
    )

    const [newArrivalWindow, setNewArrivalWindow] = useState<{
      start: IsoDateString
      end: IsoDateString
    }>(defaultArrivalWindow)

    const onArrivalWindowChange = useCallback(
      (window: { start?: IsoDateString; end?: IsoDateString }) =>
        setNewArrivalWindow(existing => ({
          ...existing,
          ...window,
        })),
      [],
    )

    const [newAssignmentStatus, setNewAssignmentStatus] = useState<
      AssignmentStatus | undefined
    >(assignment.assignmentStatus?.status)

    const onAssignmentStatusChange = useCallback(
      (newStatus: AssignmentStatus) => {
        setNewAssignmentStatus(newStatus)
      },
      [],
    )

    const [editMode, setEditMode] = useState(false)

    const onEditSave = useCallback(() => {
      setPendingChanges({
        field: 'eventChangeMap',
        key: assignmentGuid,
        value: {
          assignmentGuid,
          appointmentGuid,
          start: newArrivalWindow.start,
          end: newArrivalWindow.end,
          userGuids: [technician.userGuid],
          status: newAssignmentStatus,
        },
      })
      setEditMode(false)
    }, [
      appointmentGuid,
      assignmentGuid,
      newArrivalWindow.end,
      newArrivalWindow.start,
      newAssignmentStatus,
      setPendingChanges,
      technician.userGuid,
    ])

    return (
      <>
        <SectionedCard
          key={assignmentGuid}
          small
          dashed
          sections={[
            {
              verticalPaddingClassName: 'pt-3 pb-2',
              content: (
                <div className="flex flex-row items-center">
                  <div className="flex-1 font-semibold">
                    {technician.fullName}
                  </div>
                  {statusTag}
                </div>
              ),
            },
            {
              verticalPaddingClassName: 'pt-2 pb-3',
              content: (
                <>
                  <LabeledItemGrid
                    items={[
                      'Assignment',
                      range,
                      'Contact',
                      technician.userPhoneNumbers[0]?.phoneNumber.phoneNumber
                        ? phoneUtils.tryFormat(
                            technician.userPhoneNumbers[0]?.phoneNumber
                              .phoneNumber,
                          )
                        : NA,
                      'Role',
                      technician.userRoles[0]?.role
                        ? toRoleDisplayName(technician.userRoles[0]?.role)
                        : NA,
                    ]}
                  />

                  {assignment.assignmentStatus?.status !== 'COMPLETED' && (
                    <div className="mt-2 flex flex-row justify-between *:h-[22px] *:p-0">
                      <Popover
                        title="Edit Assignment"
                        content={
                          <EditAssignmentPopover
                            assignmentStatus={newAssignmentStatus}
                            appointment={{
                              jobClass: job.jobType.jobClass,
                              assignment: {
                                defaultStart: appointmentWindowStart,
                                start: assignmentStart,
                                end: assignmentEnd,
                              },
                            }}
                            onAssignmentStatusChange={onAssignmentStatusChange}
                            onArrivalWindowChange={onArrivalWindowChange}
                            onSave={onEditSave}
                            onCancel={() => {
                              setNewArrivalWindow(defaultArrivalWindow)
                              setNewAssignmentStatus(
                                assignment.assignmentStatus?.status,
                              )
                              setEditMode(false)
                            }}
                          />
                        }
                        trigger="click"
                        open={editMode}
                        onOpenChange={setEditMode}
                      >
                        <Button
                          type="link"
                          disabled={appointment.cancellationStatus?.canceled}
                        >
                          Edit Assignment
                        </Button>
                      </Popover>
                      <Button
                        type="link"
                        danger
                        disabled={removeDisabled}
                        onClick={onRemove}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                </>
              ),
            },
          ]}
        />
        <CloseConfirmModal
          {...closeConfirmProps}
          header="Unassign Tech"
          confirmText="Yes, Remove"
        >
          Are you sure you want to remove {technician.fullName} from this
          appointment?
        </CloseConfirmModal>
      </>
    )
  },
)

const NA = (
  <span className="text-bz-text-tertiary">
    <EnDash />
  </span>
)

type AppointmentDetailsDrawerContentProps = {
  appointment: ScheduleAppointment
  details: AppointmentDetails
  jobPointOfContact: JobPointOfContact
  techList: TechnicianResource[]
  status: InferredAppointmentStatus
}

const AppointmentDetailsDrawerContent =
  React.memo<AppointmentDetailsDrawerContentProps>(
    ({
      appointment: basicAppointment,
      details,
      jobPointOfContact,
      techList,
      status,
    }) => {
      const { setEditingAppointmentGuid } =
        useStrictContext(SchedulePageContext)
      const [
        serviceHistoryModalOpen,
        openServiceHistoryModal,
        closeServiceHistoryModal,
      ] = useModalState()
      const tzId = useExpectedCompanyTimeZoneId()
      const appointment = useMemo(
        () => R.mergeDeepRight(basicAppointment, details),
        [basicAppointment, details],
      )

      const {
        job,
        appointmentType,
        appointmentWindowStart,
        appointmentWindowEnd,
        description,
        endOfAppointmentNextSteps,
        appointmentGuid,
      } = appointment
      const {
        account,
        pointOfContact,
        location,
        jobType,
        jobGuid,
        equipmentTypeJobLinks,
        jobInvoices,
        estimates,
      } = job
      const { locationGuid, address } = location
      const { accountGuid, accountDisplayName, accountType } = account

      const leadSource = useMemo(() => {
        const accountLeadSource = account.accountLeadSource[0]
        if (!accountLeadSource) {
          return undefined
        }
        return (
          accountLeadSource.companyLeadSource.canonicalLeadSourceNameOverride ??
          accountLeadSource.companyLeadSource.canonicalLeadSourceName
        )
      }, [account.accountLeadSource])

      const [dateStr, arrivalWindow] = useMemo(() => {
        const dateStr = BzDateFns.formatFromISO(
          appointmentWindowStart,
          'EEE, MMM d, yyyy',
          tzId,
        )
        const range = dates.calculateDateTimeWindow(
          appointmentWindowStart,
          appointmentWindowEnd,
          tzId,
          {
            includeDate: false,
          },
        )
        return [dateStr, range]
      }, [appointmentWindowEnd, appointmentWindowStart, tzId])

      const [isCanceling, showIsCanceling, hideIsCanceling] = useModalState()
      const [
        sendVisitConfirmationModalOpen,
        showSendVisitConfirmationModal,
        hideSendVisitConfirmationModal,
      ] = useModalState()

      const visitMoreDropdownProps = useMemo<MenuProps>(() => {
        const items: MenuProps['items'] = [
          {
            label: 'Send Visit Confirmation',
            key: 'send-visit-confirmation',
            onClick: showSendVisitConfirmationModal,
          },
          {
            type: 'divider',
          },
          {
            label: 'Cancel Visit',
            className: 'text-red-600',
            key: 'cancel',
            onClick: showIsCanceling,
          },
        ]

        return {
          items,
        }
      }, [showIsCanceling, showSendVisitConfirmationModal])

      const runningLateStatus = useRunningLateStatus(
        appointmentGuid,
        appointmentWindowEnd,
        status,
      )

      return (
        <div>
          <StatusBox
            appointmentGuid={appointmentGuid}
            endOfAppointmentNextSteps={endOfAppointmentNextSteps}
            cancellationStatus={basicAppointment.cancellationStatus}
            appointmentStatus={status}
            runningLateStatus={runningLateStatus}
            instantBookingAt={basicAppointment.job.instantBookingAt}
          />
          <Section
            title="Account Details"
            rightContent={
              <Button
                type="default"
                icon={<FontAwesomeIcon icon={faHistory} />}
                onClick={openServiceHistoryModal}
                data-dd-action-name="bz-service-history-appointment-details"
              >
                Service History
              </Button>
            }
          >
            <div className="grid grid-cols-2 gap-4">
              <Item title="Account">
                <Link
                  bold={false}
                  to={CalculatePaths.accountDetails({
                    accountGuid,
                  })}
                >
                  {accountDisplayName}
                </Link>
              </Item>
              <Item title="Account Type">
                {getDisplayNameForAccountType(accountType)}
              </Item>
              <Item title="Primary Contact">
                <div>{pointOfContact.fullName}</div>
                {pointOfContact.primaryPhoneNumber && (
                  <CallablePhoneLink
                    phoneNumber={pointOfContact.primaryPhoneNumber?.phoneNumber}
                  >
                    <div>
                      {phoneUtils.tryFormat(
                        pointOfContact.primaryPhoneNumber.phoneNumber,
                      )}
                    </div>
                  </CallablePhoneLink>
                )}
              </Item>
              <Item title="Lead Source">{leadSource ?? NA}</Item>
            </div>
            <TagListItem title="Account Tags" tags={account.tags} />
          </Section>
          <Section
            title="Visit Details"
            rightContent={
              status !== 'CANCELED' &&
              status !== 'COMPLETED' && (
                <div className="flex flex-row space-x-2">
                  <Button
                    onClick={() =>
                      setEditingAppointmentGuid(appointment.appointmentGuid)
                    }
                  >
                    Edit Visit
                  </Button>
                  <Dropdown menu={visitMoreDropdownProps}>
                    <Button icon={<FontAwesomeIcon icon={faEllipsis} />} />
                  </Dropdown>
                </div>
              )
            }
          >
            <SubSection>
              <div className="grid grid-cols-2 gap-4">
                <Item title="Visit Type">{appointmentType}</Item>
                <Item title="Location">
                  <Link
                    bold={false}
                    to={CalculatePaths.locationDetails({
                      locationGuid,
                    })}
                  >
                    <div>
                      {address.line1}
                      {address.line2 && <>, {address.line2}</>}
                    </div>
                    <div>
                      {address.city}, {address.stateAbbreviation},{' '}
                      {address.zipCode}
                    </div>
                  </Link>
                </Item>
                <Item title="Date">{dateStr}</Item>
                <Item title="Arrival Window">{arrivalWindow}</Item>
                <Item title="Description" doubleWide>
                  {description || NA}
                </Item>
              </div>
            </SubSection>
            {/* TODO: https://getbreezyapp.atlassian.net/browse/BZ-4002 */}
            {/*
            <SubSection>
              <Item
                title="Maintenance Plan & Visit Credit"
                rightContent={
                  <div className="flex flex-row space-x-2">
                    <Button>Change Plan Visit</Button>
                    <Button icon={<FontAwesomeIcon icon={faEllipsis} />} />
                  </div>
                }
              >
                <div className="mt-3 grid grid-cols-2">
                  <SectionedCard small dashed sections={[<div>TODO</div>]} />
                </div>
              </Item>
            </SubSection> */}
            <TechSection
              techList={techList}
              appointment={appointment}
              hideAdd={status === 'CANCELED' || status === 'COMPLETED'}
            />
          </Section>
          <Section title="Job Details">
            <SubSection>
              <div className="grid grid-cols-2 gap-4">
                <Item title="Job Type">
                  <Link
                    bold={false}
                    to={CalculatePaths.jobDetails({
                      jobGuid,
                    })}
                  >
                    {jobType.name} #{job.displayId}
                  </Link>
                </Item>
                <Item title="Equipment">
                  {equipmentTypeJobLinks.length
                    ? equipmentTypeJobLinks.map(
                        ({ equipmentType, estimatedEquipmentAge }, i) => (
                          <div key={i}>
                            {formatEquipmentType(equipmentType)}{' '}
                            {estimatedEquipmentAge
                              ? `(${estimatedEquipmentAge})`
                              : ''}
                          </div>
                        ),
                      )
                    : NA}
                </Item>
                <Item title="Invoices">
                  {jobInvoices.length
                    ? jobInvoices.map(
                        ({
                          invoice: { displayIdV2, totalUsc, invoiceGuid },
                        }) => (
                          <div key={invoiceGuid}>
                            <Link
                              bold={false}
                              to={CalculatePaths.invoiceOverview({
                                invoiceGuid,
                              })}
                            >
                              {displayIdV2} ({formatUsc(totalUsc)})
                            </Link>
                          </div>
                        ),
                      )
                    : NA}
                </Item>
                <Item title="Estimates">
                  {estimates.length
                    ? estimates.map(
                        ({
                          displayId,
                          estimateGuid,
                          estimateOptionsAggregate,
                        }) => {
                          // I'm not convinced this aggregate can be null, but the types seem to think so.
                          const numOptions =
                            estimateOptionsAggregate.aggregate?.count ?? 0
                          return (
                            <div key={estimateGuid}>
                              <Link
                                bold={false}
                                to={CalculatePaths.estimatesDetails({
                                  estimateGuid,
                                })}
                              >
                                #{displayId} ({numOptions}{' '}
                                {toPlural(numOptions, 'Option')})
                              </Link>
                            </div>
                          )
                        },
                      )
                    : NA}
                </Item>
                <Item title="Summary" doubleWide>
                  {job.summary ?? NA}
                </Item>
              </div>
              <TagListItem title="Job Tags" tags={job.tags} />
            </SubSection>
            <SubSection>
              <Item title="Job Notes">
                <NotesList
                  editable={false}
                  jobGuid={jobGuid}
                  className="mx-[-20px]"
                />
              </Item>
            </SubSection>
          </Section>
          {isCanceling && (
            <ScheduleCancelAppointmentModal
              onClose={hideIsCanceling}
              appointmentGuid={appointmentGuid}
            />
          )}
          {serviceHistoryModalOpen && (
            <ServiceHistoryModal
              appointmentGuid={appointmentGuid}
              locationGuid={locationGuid}
              jobGuid={jobGuid}
              onClose={closeServiceHistoryModal}
            />
          )}
          {sendVisitConfirmationModalOpen && (
            <SendVisitConfirmationModal
              appointmentGuid={appointmentGuid}
              pointOfContact={jobPointOfContact}
              onClose={hideSendVisitConfirmationModal}
            />
          )}
        </div>
      )
    },
  )
