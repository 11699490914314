import { PageHeader } from '@ant-design/pro-components'
import { faGear } from '@fortawesome/pro-light-svg-icons'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import PageTitle from '../../elements/PageTitle/PageTitle'
import ScrollCard from '../../elements/ScrollCard/ScrollCard'
import { SettingsPage } from '../../elements/SettingsPage/SettingsPage'
import { HideCallerIdPreferencePicker } from './HideCallerIdPreferencePicker'
import { MapAppPreferencePicker } from './MapAppPreferencePicker'
import { ScheduleColorPreferences } from './ScheduleColorPreferences'

export const UserSettingsView = () => {
  return (
    <div className="flex flex-col gap-6 pb-10">
      <MapAppPreferencePicker />

      <BehindFeatureFlag
        enabledFeatureFlag="user-avatars"
        render={<ScheduleColorPreferences />}
      />

      <BehindFeatureFlag
        enabledFeatureFlag="hide-caller-id"
        render={<HideCallerIdPreferencePicker />}
      />
    </div>
  )
}

export const UserSettingsPage = () => {
  return (
    <SettingsPage requiresCompanyUser>
      <div className="w-1/2 content-center">
        <PageHeader title={<PageTitle title="User Settings" icon={faGear} />} />
      </div>

      <ScrollCard
        className="mt-auto flex h-full w-full flex-col gap-3"
        hasPageHeading
      >
        <div className="flex max-w-[380px]">
          <UserSettingsView />
        </div>
      </ScrollCard>
    </SettingsPage>
  )
}
