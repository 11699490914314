import { Address, BzAddress, phoneUtils } from '@breezy/shared'
import { useCallback } from 'react'
import { useClient } from 'urql'
import { DOWNLOADABLE_INVOICE_DATA } from '../../../../../components/DownloadableInvoice/DownloadableInvoiceData.gql'

export type PdfBillingInfo = {
  companyAddressDisplay: string
  companyInfoDisplay: string
  addressesMatch: boolean | undefined
  businessFullLegalName: string | undefined
  companyLogoUrl: string | undefined
  serviceAddress: { contactName: string; address: Address } | undefined
  billingAddress: { contactName: string; address: Address } | undefined
}

export const usePdfBillingInfo = (companyGuid: string, jobGuid: string) => {
  const client = useClient()

  const executePdfBillingInfoQuery =
    useCallback(async (): Promise<PdfBillingInfo> => {
      const res = await client.query(DOWNLOADABLE_INVOICE_DATA, {
        companyGuid,
        jobGuid,
      })
      let companyAddressDisplay: string = ''
      const address = res.data?.billingProfilesByPk?.businessAddress
      if (address) {
        const items: string[] = []
        items.push(address.line1)
        if (address.line2) {
          items.push(address.line2)
        }
        items.push(
          `${address.city}, ${address.stateAbbreviation} ${BzAddress.getZip5(
            address,
          )}`,
        )
        companyAddressDisplay = items.join(' • ')
      }

      let companyInfoDisplay: string = ''

      const items: string[] = []
      const phoneNumber = res.data?.billingProfilesByPk?.phoneNumber.phoneNumber
      if (phoneNumber) {
        items.push(phoneUtils.tryFormat(phoneNumber))
      }
      const email = res.data?.billingProfilesByPk?.emailAddress.emailAddress
      if (email) {
        items.push(email)
      }
      const website = res.data?.billingProfilesByPk?.websiteDisplayName
      if (website) {
        items.push(website)
      }

      const contractorLicenseNumber =
        res.data?.billingProfilesByPk?.contractorLicenseNumber
      if (contractorLicenseNumber) {
        items.push(`CL # ${contractorLicenseNumber}`.trim())
      }
      companyInfoDisplay = items.join(' • ')

      let serviceAddress:
        | {
            contactName: string
            address: Address
          }
        | undefined = undefined
      let billingAddress:
        | {
            contactName: string
            address: Address
          }
        | undefined = undefined

      const jobs = res.data?.jobsByPk
      if (jobs) {
        serviceAddress = {
          contactName: jobs.pointOfContact.fullName ?? '',
          address: jobs.location.address,
        }
        billingAddress = {
          contactName: jobs.account.primaryContact[0].contact.fullName ?? '',
          address:
            jobs.account.mailingAddress ??
            jobs.account.firstLocation[0].location.address,
        }
      }

      const addressesMatch =
        serviceAddress &&
        billingAddress &&
        BzAddress.makeCanonicalFullAddress(serviceAddress.address) ===
          BzAddress.makeCanonicalFullAddress(billingAddress.address)

      const businessFullLegalName =
        res.data?.billingProfilesByPk?.businessFullLegalName

      const companyLogoUrl = res.data?.billingProfilesByPk?.logoUrl

      return {
        companyAddressDisplay,
        companyInfoDisplay,
        addressesMatch,
        serviceAddress,
        billingAddress,
        businessFullLegalName,
        companyLogoUrl,
      }
    }, [client, companyGuid, jobGuid])

  return {
    executePdfBillingInfoQuery,
  }
}
