import { faCog } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Popover, Radio, RadioGroupProps } from 'antd'
import { useCallback } from 'react'
import { useStrictContext } from 'src/utils/react-utils'
import { SCHEDULE_COLOR_PREFERENCES } from './ScheduleContext'
import { SchedulePageContext } from './scheduleUtils'

export const ScheduleColorPreferencesButtonPopoverContent = () => {
  const { scheduleColorPreference, setScheduleColorPreference } =
    useStrictContext(SchedulePageContext)

  const onScheduleColorPreferenceChange: NonNullable<
    RadioGroupProps['onChange']
  > = useCallback(
    event => {
      switch (event.target.value) {
        case 'JOB_CLASS':
        case 'TEAM_MEMBER':
          setScheduleColorPreference(event.target.value)
          break
        default:
          setScheduleColorPreference('JOB_CLASS')
          break
      }
    },
    [setScheduleColorPreference],
  )

  return (
    <div className="flex max-w-[298px] flex-col gap-2">
      <span className="text-sm font-semibold text-bz-text">
        Schedule Color Preferences
      </span>

      <div className="flex flex-col gap-1">
        <Radio.Group
          value={scheduleColorPreference}
          onChange={onScheduleColorPreferenceChange}
        >
          {Object.entries(SCHEDULE_COLOR_PREFERENCES).map(
            ([preference, options]) => (
              <Radio.Button key={preference} value={preference}>
                {options.label}
              </Radio.Button>
            ),
          )}
        </Radio.Group>

        <span className="text-sm text-bz-text-description">
          Customize the color scheme for how for schedule items based on Job
          Class or Technician.
        </span>
      </div>
    </div>
  )
}

export const ScheduleColorPreferencesButton = () => {
  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      arrow={false}
      content={<ScheduleColorPreferencesButtonPopoverContent />}
    >
      <Button icon={<FontAwesomeIcon icon={faCog} />} />
    </Popover>
  )
}
