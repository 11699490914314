import {
  calculateCartLineItemSubtotalsUsc,
  DiscountType,
  formatUsc,
} from '@breezy/shared'
import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { useMemo } from 'react'
import { stripHtml } from '../../../../../elements/HtmlRenderer/utils'
import { tailwindColors } from '../../../../../tailwind.config'
import { OptionCartItem, OptionDiscount } from '../../../estimatesFlowUtils'
import { PDFDiscountIcon } from './PDFDiscountIcon'
import { ResizedPdfImage } from './ResizedPdfImage'

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 24px',
  },
  lineItem: {
    flexDirection: 'row',
    padding: '12px 0',
    borderBottomWidth: 1,
    borderBottomColor: tailwindColors['bz-gray'][400],
    borderBottomStyle: 'dashed',
    flexGrow: 0,
    alignItems: 'center',
  },
  lineItemInfo: {
    flexDirection: 'column',
    flex: 1,
    gap: 4,
  },
  lineItemName: {
    fontWeight: 'semibold',
    fontSize: 14,
    color: tailwindColors.bz.text,
  },
  lineItemDescription: {
    color: tailwindColors['bz-gray'][1000],
    fontSize: 12,
    flexWrap: 'wrap',
  },
  lineItemValue: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    alignSelf: 'flex-start',
    marginLeft: 24,
    flexShrink: 0,
    gap: 8,
    color: tailwindColors.bz.text,
  },
  lineItemValueSubContent: {
    fontSize: 14,
  },
  discount: {
    color: tailwindColors['bz-green'][800],
  },
})

type PDFLineItemListProps = {
  lineItems: OptionCartItem[]
  discounts?: OptionDiscount[]
}

export const PDFLineItemList = ({
  lineItems,
  discounts = [],
}: PDFLineItemListProps) => {
  const totalDiscountAmountUsc = useMemo(() => {
    const summary = calculateCartLineItemSubtotalsUsc({
      items: lineItems,
      discount: { type: DiscountType.FLAT, discountAmountUsc: 0 },
      taxRate: {
        taxRateGuid: '',
        rate: 0,
      },
    })
    return summary.taxDiscSubtotalUsc + summary.nonTaxDiscSubtotalUsc
  }, [lineItems])

  return (
    <View style={styles.container}>
      {/* Header Row */}

      {/* Line Items */}
      {lineItems.map((item, index) => (
        <View
          key={index}
          style={[
            styles.lineItem,
            index === lineItems.length - 1 && discounts.length === 0
              ? { borderBottomWidth: 0 }
              : {},
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              marginRight: 12,
              minWidth: 32,
              minHeight: 32,
              alignItems: 'flex-start',
              height: '100%',
            }}
          >
            <ResizedPdfImage
              cdnUrl={item.photoCdnUrl}
              width={32}
              height={32}
              imageMargin={3}
            />
          </View>
          <View style={styles.lineItemInfo}>
            <Text style={styles.lineItemName}>{item.name}</Text>
            <Text style={styles.lineItemDescription}>
              {stripHtml(item.description)}
            </Text>
          </View>
          <View style={styles.lineItemValue}>
            <Text style={styles.lineItemValueSubContent}>
              {formatUsc(item.quantity * item.unitPriceUsc)}
            </Text>
            <Text
              style={[
                styles.lineItemValueSubContent,
                { color: tailwindColors.bz['text-tertiary'] },
              ]}
            >
              {item.quantity < -1 || item.quantity > 1
                ? `(${item.quantity} x ${formatUsc(item.unitPriceUsc)})`
                : undefined}
            </Text>
          </View>
        </View>
      ))}

      {/* Discounts */}
      {discounts.map((discount, index) => {
        const totalUsc =
          discount.type === DiscountType.FLAT
            ? discount.discountAmountUsc
            : totalDiscountAmountUsc * discount.discountRate

        return (
          <View
            key={`discount-${index}`}
            style={[
              styles.lineItem,
              index !== discounts.length - 1
                ? {
                    borderBottomWidth: 1,
                    borderBottomColor: tailwindColors['bz-gray'][400],
                  }
                : { borderBottomWidth: 0 },
            ]}
          >
            <View
              style={{
                flexDirection: 'row',
                marginRight: 12,
                minWidth: 32,
                minHeight: 32,
                alignItems: 'flex-start',
                height: '100%',
              }}
            />
            <View
              style={[
                styles.lineItemInfo,
                {
                  color: tailwindColors['bz-gray'][1000],
                },
              ]}
            >
              <View
                style={{ flexDirection: 'row', gap: 8, alignItems: 'center' }}
              >
                <Text style={[styles.lineItemName, styles.discount]}>
                  {discount.name}
                </Text>
                <PDFDiscountIcon
                  width={16}
                  height={16}
                  discountType={discount.type}
                />
              </View>
              <Text style={[styles.lineItemDescription, styles.discount]}>
                {stripHtml(discount.descriptionHtml)}
              </Text>
            </View>
            <View style={[styles.lineItemValue, styles.discount]}>
              <Text style={styles.lineItemValueSubContent}>
                {`-${formatUsc(totalUsc)}`}
              </Text>
            </View>
          </View>
        )
      })}
    </View>
  )
}
