import {
  IconDefinition,
  faCheck,
  faInfoCircle,
  faX,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Form, Switch, Tooltip } from 'antd'
import { CSSProperties, memo } from 'react'

import FaIconWithCircularBackground from '../../elements/FaIconWithCircularBackground/FaIconWithCircularBackground'

type SwitchWithIconAndTextProps = {
  disabled?: boolean
  formItemName: string
  icon: IconDefinition
  text: string
  tooltip?: string
  extraStyle?: CSSProperties
  onChange?: (checked: boolean) => void
  cols?: [number, number]
}

export const SwitchWithIconAndText = memo<SwitchWithIconAndTextProps>(
  ({
    disabled,
    formItemName,
    icon,
    text,
    tooltip,
    extraStyle,
    onChange,
    cols = [21, 3],
  }) => {
    return (
      <div
        className="pr-4 md:pr-0"
        style={{
          // Workaround to get a border thinner than 1px, which isn't normally possible
          // when using the border property in CSS
          boxShadow: 'inset 0 0 0 0.6px rgb(217, 217, 217)',
          ...extraStyle,
        }}
      >
        <div
          style={{
            height: '44px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0',
            padding: '0',
          }}
        >
          <Col span={cols[0]}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '0 0 0 16px',
              }}
            >
              <FaIconWithCircularBackground
                iconDefinition={icon}
                color="#AD4E00"
                backgroundColor="#FFE7BA"
                diameterPx={28}
              />
              {text}
              {tooltip && (
                <Tooltip title={tooltip}>
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    className="ml-2 h-4 w-4 text-bz-gray-700"
                  />
                </Tooltip>
              )}
            </div>
          </Col>
          <Col span={cols[1]}>
            <Form.Item
              name={formItemName}
              valuePropName="checked"
              style={{ marginTop: '12px', marginBottom: '12px' }}
            >
              <Switch
                disabled={!!disabled}
                checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                unCheckedChildren={<FontAwesomeIcon icon={faX} />}
                onChange={onChange}
              />
            </Form.Item>
          </Col>
        </div>
      </div>
    )
  },
)
