import { gql } from '../../generated'

export const UPDATE_ESTIMATE_MUTATION = gql(/* GraphQL */ `
  mutation UpdateEstimate(
    $estimateGuid: uuid!
    $estimate: EstimatesSetInput!
    $clearSelectedOptionsEstimateGuid: uuid!
  ) {
    updateEstimates(
      where: { estimateGuid: { _eq: $estimateGuid } }
      _set: $estimate
    ) {
      affectedRows
    }
    updateEstimateOptionsMany(
      updates: {
        where: { estimateGuid: { _eq: $clearSelectedOptionsEstimateGuid } }
        _set: { isSelected: false }
      }
    ) {
      __typename
    }
  }
`)

export const UPSERT_ESTIMATE_MUTATION = gql(/* GraphQL */ `
  mutation UpsertEstimate(
    $companyGuid: uuid!
    $estimateGuid: uuid!
    $estimate: EstimatesInsertInput!
    $validCartItemGuids: [uuid!]!
    $estimateOptionDiscountGuids: [uuid!]!
    $estimateOptionGuids: [uuid!]!
    $estimatePhotoGuids: [uuid!]!
    $jobPhotoLinks: [PhotoLinksInsertInput!]!
  ) {
    insertEstimatesOne(
      object: $estimate
      onConflict: {
        constraint: estimates_pkey
        updateColumns: [
          messageHtml
          status
          taxRate
          taxRateName
          taxRateGuid
          dynamicPricingType
        ]
      }
    ) {
      __typename
    }
    deleteCartItems(
      where: {
        estimateOptionCartItems: {
          estimateOptionGuid: { _in: $estimateOptionGuids }
          cartItemGuid: { _nin: $validCartItemGuids }
        }
      }
    ) {
      __typename
    }
    deleteEstimateOptionDiscounts(
      where: {
        estimateOption: { estimateGuid: { _eq: $estimateGuid } }
        estimateOptionDiscountGuid: { _nin: $estimateOptionDiscountGuids }
      }
    ) {
      __typename
    }
    deleteEstimateOptions(
      where: {
        companyGuid: { _eq: $companyGuid }
        estimateGuid: { _eq: $estimateGuid }
        estimateOptionGuid: { _nin: $estimateOptionGuids }
      }
    ) {
      affectedRows
    }
    deleteEstimatePhotos(
      where: {
        estimateGuid: { _eq: $estimateGuid }
        photoGuid: { _nin: $estimatePhotoGuids }
      }
    ) {
      affectedRows
    }
    insertPhotoLinks(
      objects: $jobPhotoLinks
      onConflict: { constraint: photo_links_pkey, updateColumns: [] }
    ) {
      affectedRows
    }
  }
`)

export const RELEVANT_ESTIMATE_DATA_QUERY = gql(/* GraphQL */ `
  query GetRelevantEstimateData($companyGuid: uuid!) {
    pricebookItems(where: { companyGuid: { _eq: $companyGuid } }) {
      pricebookItemGuid
    }
    billingProfilesByPk(companyGuid: $companyGuid) {
      defaultPricebookTaxRateGuid
      estimateDisclaimer
      businessFullLegalName
      contractorLicenseNumber
      invoiceTerm
      logoUrl
      logoPhotoGuid
      logoPhoto {
        cdnUrl
      }
    }
    companyConfigByPk(companyGuid: $companyGuid) {
      blurb
    }
    pricebookTaxRates(
      limit: 1
      where: { companyGuid: { _eq: $companyGuid }, isActive: { _eq: true } }
    ) {
      name
      rate
      pricebookTaxRateGuid
    }
    companiesByPk(companyGuid: $companyGuid) {
      name
    }
  }
`)

export const RELEVANT_NEW_ESTIMATE_DATA_QUERY = gql(/* GraphQL */ `
  query GetRelevantNewEstimateData($jobGuid: uuid!) {
    jobsByPk(jobGuid: $jobGuid) {
      accountGuid
      account {
        accountType
      }
      locationGuid
      location {
        address {
          zipCode
        }
        pricebookTaxRate {
          pricebookTaxRateGuid
          name
          rate
        }
      }
      pointOfContact {
        fullName
        firstName
        primaryPhoneNumber {
          phoneNumber
        }
        primaryEmailAddress {
          emailAddress
        }
      }
      location {
        address {
          line1
          city
          stateAbbreviation
          zipCode
        }
      }
    }
  }
`)

export const ESTIMATE_DATA_SUBSCRIPTION = gql(/* GraphQL */ `
  subscription GetEstimate($estimateGuid: uuid!) {
    estimatesByPk(estimateGuid: $estimateGuid) {
      createdAt
      displayId
      estimateGuid
      jobGuid
      jobAppointmentGuid
      job {
        displayId
        accountGuid
        account {
          createdAt
        }
        locationGuid
        location {
          address {
            line1
            city
            stateAbbreviation
            zipCode
          }
        }
        pointOfContact {
          fullName
          firstName
          primaryPhoneNumber {
            phoneNumber
          }
          primaryEmailAddress {
            emailAddress
          }
        }
        createdAt
        jobType {
          name
        }
      }
      invoiceLink {
        invoiceGuid
        invoice {
          displayId
          displayIdV2
          issuedAt
        }
      }
      messageHtml
      status
      acceptedAt
      signatureFileUrl
      acceptedOnBehalfByUser {
        firstName
        lastName
      }
      taxRate
      taxRateName
      taxRateGuid
      dynamicPricingType
      estimatePhotos {
        photoGuid
        photo {
          cdnUrl
        }
      }
      estimateOptions(orderBy: { seq: ASC }) {
        optionGuid: estimateOptionGuid
        descriptionHtml
        displayName
        totalUsc
        featuredPhotoGuid
        featuredPhoto {
          cdnUrl
        }
        estimateOptionDiscounts(orderBy: { seq: ASC }) {
          discountGuid: estimateOptionDiscountGuid
          discountAmountUsc
          discountRate
          discountType
          descriptionHtml
          name
          seq
        }
        recommended: isRecommended
        selected: isSelected
        seq
        cartItems(orderBy: { seq: ASC }) {
          seq
          cartItem {
            cartItemGuid
            itemType: cartItemType
            description
            isDiscountable
            isTaxable
            name
            originalPricebookItemGuid
            quantity
            unitPriceUsc
            photo {
              photoGuid
              cdnUrl
            }
          }
        }
        featuredPhotoGuid
        featuredPhoto {
          cdnUrl
        }
      }
      linkedToJobs {
        job {
          jobGuid
          displayId
          accountGuid
          locationGuid
          jobType {
            name
          }
          createdAt
        }
        linkedJobGuid
      }
    }
  }
`)

export const DELETE_ESTIMATE_QUERY = gql(/* GraphQL */ `
  mutation DeleteEstimateOptions($estimateGuid: uuid!) {
    deleteEstimatesByPk(estimateGuid: $estimateGuid) {
      __typename
    }
  }
`)

export const GET_JOB_PHOTOS_QUERY = gql(/* GraphQL */ `
  query GetJobPhotos($jobGuid: uuid!) {
    photoLinks(where: { jobGuid: { _eq: $jobGuid } }) {
      photo {
        cdnUrl
        photoGuid
      }
    }
  }
`)

export const ACCOUNT_REVIEW_RELEVANT_ESTIMATE_DATA_QUERY = gql(/* GraphQL */ `
  query GetRelevantEstimateDataForAccountReview($companyGuid: uuid!) {
    billingProfilesByPk(companyGuid: $companyGuid) {
      estimateDisclaimer
      businessFullLegalName
      contractorLicenseNumber
      logoUrl
      wisetackMerchantId
      logoPhotoGuid
      logoPhoto {
        cdnUrl
      }
      phoneNumber {
        phoneNumber
      }
    }
    companyConfigByPk(companyGuid: $companyGuid) {
      blurb
      wisetackEnabled
    }
    companiesByPk(companyGuid: $companyGuid) {
      name
    }
  }
`)

export const ACCOUNT_REVIEW_ESTIMATE_DATA_QUERY = gql(/* GraphQL */ `
  query GetEstimateForAccountReview($estimateGuid: uuid!) {
    estimatesByPk(estimateGuid: $estimateGuid) {
      companyGuid
      displayId
      estimateGuid
      messageHtml
      status
      taxRate
      dynamicPricingType
      acceptedAt
      job {
        jobGuid
        accountGuid
        locationGuid
        location {
          address {
            line1
            city
            stateAbbreviation
            zipCode
          }
        }
        pointOfContact {
          fullName
          firstName
          primaryPhoneNumber {
            phoneNumber
          }
          primaryEmailAddress {
            emailAddress
          }
        }
      }
      createdByUser {
        fullName
        userPhoneNumbers(limit: 1) {
          phoneNumber {
            phoneNumber
          }
        }
        avatarPhoto {
          cdnUrl
        }
      }
      estimatePhotos {
        photoGuid
        photo {
          cdnUrl
        }
      }
      estimateOptions(orderBy: { seq: ASC }) {
        optionGuid: estimateOptionGuid
        descriptionHtml
        displayName
        totalUsc
        featuredPhotoGuid
        featuredPhoto {
          cdnUrl
        }
        estimateOptionDiscounts(orderBy: { seq: ASC }) {
          discountGuid: estimateOptionDiscountGuid
          discountAmountUsc
          discountRate
          discountType
          descriptionHtml
          name
          seq
        }
        selected: isSelected
        recommended: isRecommended
        seq
        featuredPhoto {
          cdnUrl
          photoGuid
        }
        cartItems(orderBy: { seq: ASC }) {
          seq
          cartItem {
            description
            isDiscountable
            isTaxable
            name
            quantity
            unitPriceUsc
            photo {
              photoGuid
              cdnUrl
            }
          }
        }
      }
    }
  }
`)

export const ESTIMATE_WITH_LINKED_JOB_QUERY = gql(/* GraphQL */ `
  query EstimateFlowEstimateWithLinkedJobQuery($estimateGuid: uuid!) {
    estimatesByPk(estimateGuid: $estimateGuid) {
      jobGuid
      job {
        accountGuid
        locationGuid
        displayId
        jobType {
          name
        }
        createdAt
      }
      linkedToJobs {
        job {
          jobGuid
          displayId
          accountGuid
          locationGuid
          jobType {
            name
          }
          createdAt
        }
        linkedJobGuid
      }
    }
  }
`)
