import {
  AddressDto,
  BzDateFns,
  CalculatePaths,
  DynamicPricingType,
  HtmlString,
  InvoiceCartItem,
  InvoiceDiscount,
  InvoiceEventInput,
  InvoicePayment,
  InvoiceTotals,
  InvoiceV2Status,
  IsoDateString,
  PricebookTaxRateDto,
  calculateInvoiceTotals,
  getDueDate,
} from '@breezy/shared'
import { datadogRum } from '@datadog/browser-rum'
import { faEdit, faEye, faSave } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from 'antd'
import React, { useCallback, useMemo, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ActionBar } from '../../adam-components/ActionBar/ActionBar'
import {
  MobileActionBar,
  MobileActionBarButton,
} from '../../adam-components/ActionBar/MobileActionBar'
import { OnsiteConfirmModal } from '../../adam-components/OnsiteModal/OnsiteModal'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { useGoBack } from '../../adam-components/OnsitePage/onsitePageUtils'
import { RightArrowButton } from '../../adam-components/RightArrowButton'
import {
  getHistoryStackLocationFromLocation,
  getUrlFromHistoryStackLocation,
} from '../../components/BackButtonOverridesWrapper/BackButtonOverridesWrapper'
import { LoanPromoShortDisclaimer } from '../../components/Financing/LoanPromo/LoanPromoDisclaimer'
import FinancingSection from '../../components/FinancingSection/FinancingSection'
import { TaxRatePicker } from '../../components/Pricebook/TaxRatePicker'
import { useDirtyingSetValue } from '../../elements/Forms/useDirtyingSetValue'
import useIsMobile from '../../hooks/useIsMobile'
import { useUnsavedChangesWarning } from '../../hooks/useUnsavedChangesWarning'
import { BlockerFunction, useBlocker } from '../../providers/BlockerWrapper'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import { useMessage } from '../../utils/antd-utils'
import {
  useModalState,
  useQueryParamFlag,
  useStrictContext,
} from '../../utils/react-utils'
import { useUnblockedNavigateOnSave } from '../../utils/routerUtils'
import { EditableInvoiceContent } from './components/EditableInvoiceContent'
import { InvoicePageContainer } from './components/InvoicePageContainer'
import {
  useNegativeBalanceModal,
  useResultsInNegativeBalance,
} from './components/NegativeBalanceModal'
import { PresentableInvoiceContent } from './components/PresentableInvoiceContent'
import { TaxAndDynamicSection } from './components/TaxAndDynamicSection'
import {
  InvoiceContext,
  InvoiceDataContext,
  InvoiceEditData,
  InvoiceEditDataContext,
  InvoiceEditSchema,
  InvoiceInfo,
  InvoiceInfoFormData,
  isAccountBillingAddress,
  isAdHocBillingAddress,
  useIsInvoiceFinanceable,
  useIsInvoicePriceEditable,
  useSaveInvoice,
} from './invoiceUtils'

const PREVIEW_MODE_QUERY_PARAM = 'p'

const PREVIEW_BANNER_COLORS = 'text-[#871400] bg-[#FFD8BF]'

type InvoiceEditViewProps = {
  status?: InvoiceV2Status
  defaultTaxRate: PricebookTaxRateDto
  defaultDynamicPricingType?: DynamicPricingType
  defaultInfoFormData: InvoiceInfoFormData
  isNew?: boolean
  defaultMessageHtml?: HtmlString
  defaultLineItems?: InvoiceCartItem[]
  defaultDiscounts?: InvoiceDiscount[]
  issuedAt?: IsoDateString
  dueAt?: IsoDateString
  existingPayments?: InvoicePayment[]
  createdFromTemplate?: boolean
}

export const InvoiceEditView = React.memo<InvoiceEditViewProps>(
  ({
    status,
    isNew,
    defaultTaxRate,
    defaultDynamicPricingType,
    defaultMessageHtml = '',
    defaultInfoFormData,
    defaultLineItems = [],
    defaultDiscounts = [],
    existingPayments = [],
    issuedAt,
    dueAt,
    createdFromTemplate = false,
  }) => {
    const message = useMessage()
    const beganAsDraft = useMemo(
      () => status === 'DRAFT',
      // We only want to save what it was on mount.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )
    const isMobile = useIsMobile()
    const tzId = useExpectedCompanyTimeZoneId()
    const { account, job } = useStrictContext(InvoiceEditDataContext)
    const { displayId, invoiceGuid, accountGuid, jobGuid, companyName } =
      useStrictContext(InvoiceContext)

    const defaultValues = useMemo<InvoiceEditData>(
      () => ({
        messageHtml: defaultMessageHtml,
        taxRate: defaultTaxRate,
        infoFormData: defaultInfoFormData,
        lineItems: defaultLineItems,
        discounts: defaultDiscounts,
        dynamicPricingType: defaultDynamicPricingType,
      }),
      [
        defaultDiscounts,
        defaultDynamicPricingType,
        defaultInfoFormData,
        defaultLineItems,
        defaultMessageHtml,
        defaultTaxRate,
      ],
    )

    const editForm = useForm<InvoiceEditData>({
      resolver: zodResolver(InvoiceEditSchema),
      defaultValues,
    })

    const {
      formState: { isDirty },
      watch,
      setValue: rawSetValue,
      reset,
    } = editForm

    const setValue = useDirtyingSetValue(rawSetValue)

    const messageHtml = watch('messageHtml')
    const taxRate = watch('taxRate')
    const dynamicPricingType = watch('dynamicPricingType')
    const discounts = watch('discounts')
    const lineItems = watch('lineItems')
    const infoFormData = watch('infoFormData')

    const info = useMemo<InvoiceInfo>(() => {
      // We're assuming you can't have an account without at least one contact.
      const primaryContact = account.accountContacts[0].contact

      let serviceAddress: AddressDto =
        account.accountLocations[0].location.address

      let serviceContact = primaryContact

      if (job) {
        const jobLocation = account.accountLocations.find(
          location => location.locationGuid === job.locationGuid,
        )
        if (jobLocation) {
          serviceAddress = jobLocation.location.address
        } else {
          console.error('Job location not found in account locations')
        }

        serviceContact = job.pointOfContact
      }

      let resolvedBillingAddress: AddressDto | undefined = undefined
      const { billingAddress } = infoFormData
      if (billingAddress) {
        if (isAdHocBillingAddress(billingAddress)) {
          resolvedBillingAddress = billingAddress.adHocAddress
        } else if (isAccountBillingAddress(billingAddress)) {
          const foundLocation = account.accountLocations.find(
            ({ location }) =>
              location.address.addressGuid ===
              billingAddress.accountBillingAddressGuid,
          )
          if (foundLocation) {
            resolvedBillingAddress = foundLocation.location.address
          } else {
            const err = new Error(
              `For invoice ${invoiceGuid} the user selected address ${billingAddress.accountBillingAddressGuid} but it was not found in the locations for account ${accountGuid}`,
            )
            datadogRum.addError(err)
            resolvedBillingAddress = serviceAddress
          }
        }
      } else {
        resolvedBillingAddress = serviceAddress
      }

      const billingContact =
        account.accountContacts.find(
          ({ contact }) =>
            contact.contactGuid === infoFormData.billingContactGuid,
        )?.contact ?? primaryContact

      return {
        ...infoFormData,
        serviceAddress,
        serviceContact,
        billingContact,
        accountMailingAddress: account.mailingAddress,
        resolvedBillingAddress,
        dueAt,
        issuedAt,
      }
    }, [
      account.accountContacts,
      account.accountLocations,
      account.mailingAddress,
      accountGuid,
      dueAt,
      infoFormData,
      invoiceGuid,
      issuedAt,
      job,
    ])

    const [taxRateEditOpen, openTaxRateEdit, closeTaxRateEdit] = useModalState()

    const onEditingTaxRateSubmit = useCallback(
      (taxRate: PricebookTaxRateDto) => {
        setValue('taxRate', taxRate)
        closeTaxRateEdit()
      },
      [closeTaxRateEdit, setValue],
    )

    const setDynamicPricingType = useCallback(
      (type?: DynamicPricingType) => setValue('dynamicPricingType', type),
      [setValue],
    )

    const navigate = useNavigate()
    const goBack = useGoBack()

    const onSaveNavigate = useCallback(() => {
      if (isNew) {
        navigate(CalculatePaths.invoiceOverview({ invoiceGuid }))
      } else if (beganAsDraft) {
        navigate(CalculatePaths.invoiceOverview({ invoiceGuid }), {
          replace: true,
        })
      } else {
        goBack()
      }
    }, [beganAsDraft, goBack, invoiceGuid, isNew, navigate])

    const onDraftSave = useCallback(() => {
      if (isNew) {
        navigate(CalculatePaths.invoiceEdit({ invoiceGuid }))
      }
    }, [invoiceGuid, isNew, navigate])

    const {
      savingSuccessful,
      setSuccessfullySaved,
      setSuccessfullySavedAsDraft,
    } = useUnblockedNavigateOnSave(
      onSaveNavigate,
      onDraftSave,
      isNew ? undefined : defaultValues,
    )

    const shouldBlock = useCallback<BlockerFunction>(
      ({ currentLocation, nextLocation }) => {
        if (savingSuccessful) {
          return false
        }

        if (!isDirty) {
          return false
        }

        // If we're navigating from one page to the same page (with certain query params excluded), then we don't block
        const currentUrl = getUrlFromHistoryStackLocation(
          getHistoryStackLocationFromLocation(currentLocation),
        )
        const nextUrl = getUrlFromHistoryStackLocation(
          getHistoryStackLocationFromLocation(nextLocation),
        )

        // Going to and from preview mode doesn't matter
        currentUrl.searchParams.delete(PREVIEW_MODE_QUERY_PARAM)
        nextUrl.searchParams.delete(PREVIEW_MODE_QUERY_PARAM)

        if (currentUrl.href === nextUrl.href) {
          return false
        }
        return isDirty
      },
      [isDirty, savingSuccessful],
    )

    const blocker = useBlocker('InvoiceEditView', shouldBlock)

    useUnsavedChangesWarning(isDirty)

    const pageContainerRef = useRef<HTMLDivElement>(null)

    const [isPreviewMode, openPreviewMode, closePreviewMode] =
      useQueryParamFlag(PREVIEW_MODE_QUERY_PARAM)

    // TODO: https://getbreezyapp.atlassian.net/browse/BZ-2878
    const onPreviewClick = useCallback(() => {
      if (isPreviewMode) {
        closePreviewMode()
      } else {
        openPreviewMode()

        // The timeout is necessary for mobile Safari ugh
        setTimeout(() => {
          pageContainerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
        }, 100)
      }
    }, [closePreviewMode, isPreviewMode, openPreviewMode])

    const [saveInvoice, isUpserting] = useSaveInvoice()

    const [pageTitle, statusText, statusColorClassName] = useMemo(() => {
      if (isPreviewMode) {
        return ['Present Invoice', 'Preview Mode', PREVIEW_BANNER_COLORS]
      }

      if (isNew) {
        return ['Create Invoice', 'Draft']
      }

      if (status === 'DRAFT') {
        return [`Invoice ${displayId}`, 'Saved draft']
      }

      return [`Invoice ${displayId}`, 'Editing']
    }, [displayId, isNew, isPreviewMode, status])

    const invoiceTotals = useMemo<InvoiceTotals>(
      () =>
        calculateInvoiceTotals(
          lineItems,
          taxRate.rate,
          discounts,
          existingPayments,
          dynamicPricingType,
        ),
      [
        discounts,
        dynamicPricingType,
        existingPayments,
        lineItems,
        taxRate.rate,
      ],
    )
    const resultsInNegativeBalance =
      useResultsInNegativeBalance(existingPayments)

    const { negativeBalanceModal, showNegativeBalanceModal } =
      useNegativeBalanceModal()

    const isPriceEditable = useIsInvoicePriceEditable()
    const isFinanceable = useIsInvoiceFinanceable(
      status ?? 'DRAFT',
      invoiceTotals,
    )

    const onSaveInvoice = useCallback(
      async (saveDraft?: boolean) => {
        if (
          resultsInNegativeBalance(
            lineItems,
            taxRate.rate,
            dynamicPricingType,
            discounts,
          )
        ) {
          showNegativeBalanceModal({
            title: 'Invalid invoice',
            description: <NegativeInvoiceBalanceDescription />,
          })
          return
        }

        let serviceAddressGuid: string | undefined = undefined
        if (job?.locationGuid === info.serviceLocationGuid) {
          serviceAddressGuid = job?.location.addressGuid
        } else {
          const location = account.accountLocations.find(
            location => location.locationGuid === info.serviceLocationGuid,
          )
          if (!location) {
            message.error(
              "The selected service location does not match the account's locations. Please contact support.",
            )
            throw new Error(
              `Selected service location ${info.serviceLocationGuid} does not match any locations on account ${accountGuid}.`,
            )
          }
          serviceAddressGuid = location.location.address.addressGuid
        }
        let event: InvoiceEventInput | undefined
        let newStatus: InvoiceV2Status = 'OPEN'
        if (saveDraft) {
          newStatus = 'DRAFT'
        } else if (status && status !== 'DRAFT') {
          newStatus = status
        }

        let issuedAt: IsoDateString | undefined =
          info.issuedAt ?? BzDateFns.nowISOString()
        let dueAt: IsoDateString | undefined =
          info.dueAt ?? getDueDate(issuedAt, info.invoiceTerm, tzId)

        if (saveDraft) {
          issuedAt = undefined
          dueAt = undefined
        }
        // TODO - we should probably have some centralized place to manage
        // event generation. This is fine for now but when we add the other events we
        // should reconsider this pattern
        const newOrDraft = isNew || status === 'DRAFT'
        if (newOrDraft && newStatus === 'OPEN') {
          event = {
            type: 'CREATED',
            invoiceGuid,
          }
        } else if (status === 'OPEN' && newStatus === 'OPEN') {
          event = {
            type: 'UPDATED',
            invoiceGuid,
          }
        }

        if (invoiceTotals.totalUsc === 0) {
          newStatus = 'PAID'
        }

        const didSucceed = await saveInvoice({
          status: newStatus,
          messageHtml,
          taxRate,
          lineItems,
          dynamicPricingType,
          info: {
            ...info,
            issuedAt,
            dueAt,
          },
          serviceAddressGuid,
          totals: invoiceTotals,
          discounts,
          event,
        })

        if (didSucceed) {
          if (saveDraft) {
            setSuccessfullySavedAsDraft(true)
            reset(undefined, {
              keepValues: true,
            })
          } else {
            setSuccessfullySaved(true)
          }
        }
      },
      [
        account.accountLocations,
        accountGuid,
        discounts,
        dynamicPricingType,
        info,
        invoiceGuid,
        invoiceTotals,
        isNew,
        job?.location.addressGuid,
        job?.locationGuid,
        lineItems,
        message,
        messageHtml,
        reset,
        resultsInNegativeBalance,
        saveInvoice,
        setSuccessfullySaved,
        setSuccessfullySavedAsDraft,
        showNegativeBalanceModal,
        status,
        taxRate,
        tzId,
      ],
    )

    const onSaveDraft = useCallback(() => {
      onSaveInvoice(true)
    }, [onSaveInvoice])

    // Regardless of if it's dirty, you should be able to hit "Create Invoice" if this is true.
    const isCreatableDraft =
      (status === 'DRAFT' && !isNew) || createdFromTemplate

    const actionBar = useMemo(() => {
      const newOrDraft = isNew || status === 'DRAFT'
      // On mobile and and non-mobile, when editing, the buttons are just save and cancel
      if (!newOrDraft) {
        return (
          <ActionBar>
            <Button
              className="flex-1"
              size="large"
              disabled={isUpserting || savingSuccessful}
              onClick={goBack}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="flex-1"
              size="large"
              loading={isUpserting}
              disabled={
                isUpserting ||
                !isDirty ||
                savingSuccessful ||
                savingSuccessful ||
                !lineItems.length
              }
              onClick={() => onSaveInvoice()}
            >
              Save
            </Button>
          </ActionBar>
        )
      }

      const primaryCtaOnClick = () => onSaveInvoice()
      const primaryCtaDisabled =
        isUpserting ||
        (!isDirty && !isCreatableDraft) ||
        (!lineItems.length && !defaultLineItems?.length) ||
        savingSuccessful

      const previewDisabled =
        isPreviewMode || isUpserting || !lineItems.length || savingSuccessful
      const saveDisabled =
        isUpserting ||
        (!lineItems.length && !defaultLineItems?.length) ||
        savingSuccessful ||
        (!isDirty && !createdFromTemplate)

      if (isMobile) {
        // On mobile, if we're new or a draft, then we use a mobile action bar.

        return (
          <MobileActionBar
            primaryCtaOnClick={primaryCtaOnClick}
            primaryCtaDisabled={primaryCtaDisabled}
            primaryCtaText="Create Invoice"
            primaryCtaTestId="create-invoice-button"
          >
            {/* TODO: preview mode might be different */}
            <MobileActionBarButton
              onClick={onPreviewClick}
              icon={faEye}
              disabled={previewDisabled}
            >
              Preview
            </MobileActionBarButton>
            <MobileActionBarButton
              icon={isPreviewMode ? faEdit : faSave}
              onClick={isPreviewMode ? closePreviewMode : onSaveDraft}
              disabled={!isPreviewMode && saveDisabled}
            >
              {isPreviewMode ? 'Edit' : 'Save Draft'}
            </MobileActionBarButton>
            {/* TODO: https://getbreezyapp.atlassian.net/browse/BZ-3039 */}
            {/* {!isNew && (
              <MobileActionBarButton
                danger
                loading={isDeleting}
                icon={faTrash}
                disabled={isLoading}
                onClick={openDeleteConfirm}
              >
                Delete
              </MobileActionBarButton>
            )} */}
          </MobileActionBar>
        )
      } else {
        // Otherwise we use a normal action bar
        return (
          <ActionBar>
            {/* TODO: https://getbreezyapp.atlassian.net/browse/BZ-3039 */}
            {/* <Button
                    size="large"
                    className="min-w-[40px]"
                    icon={<FontAwesomeIcon icon={faEllipsis} />}
                    disabled={isUpserting}
                    onClick={openActionsModal}
                  /> */}
            <Button
              className="flex-1"
              size="large"
              icon={<FontAwesomeIcon icon={isPreviewMode ? faEdit : faSave} />}
              disabled={!isPreviewMode && saveDisabled}
              onClick={isPreviewMode ? closePreviewMode : onSaveDraft}
            >
              {isPreviewMode ? 'Edit' : 'Save Draft'}
            </Button>
            <Button
              className="flex-1"
              size="large"
              icon={<FontAwesomeIcon icon={faEye} />}
              disabled={previewDisabled}
              onClick={onPreviewClick}
            >
              Preview
            </Button>
            <RightArrowButton
              className="flex-[2]"
              loading={isUpserting || savingSuccessful}
              disabled={primaryCtaDisabled}
              onClick={primaryCtaOnClick}
              data-testid="create-invoice-button"
            >
              Create Invoice
            </RightArrowButton>
          </ActionBar>
        )
      }
    }, [
      closePreviewMode,
      goBack,
      isCreatableDraft,
      isDirty,
      isMobile,
      isNew,
      isPreviewMode,
      isUpserting,
      lineItems.length,
      onPreviewClick,
      onSaveDraft,
      onSaveInvoice,
      savingSuccessful,
      status,
      createdFromTemplate,
      defaultLineItems,
    ])

    return (
      <InvoiceDataContext.Provider
        value={{
          messageHtml,
          taxRate,
          info,
          lineItems,
          discounts,
          invoiceTotals,
          dynamicPricingType,
        }}
      >
        <InvoicePageContainer
          containerRef={pageContainerRef}
          statusText={statusText}
          statusColorClassName={statusColorClassName}
          title={pageTitle}
          spaceForNav={!isPreviewMode}
        >
          {isPreviewMode ? (
            <PresentableInvoiceContent />
          ) : (
            <>
              <OnsitePageSection title="Invoice">
                <EditableInvoiceContent
                  form={editForm}
                  existingPayments={existingPayments}
                />
              </OnsitePageSection>
              <TaxAndDynamicSection
                taxRate={taxRate}
                openTaxRateEdit={isPriceEditable ? openTaxRateEdit : undefined}
                dynamicPricingType={dynamicPricingType}
                setDynamicPricingType={setDynamicPricingType}
              />
              {isFinanceable && (
                <FinancingSection
                  accountGuid={accountGuid}
                  companyName={companyName}
                  jobGuid={jobGuid}
                  invoiceGuid={invoiceGuid}
                />
              )}
              {isFinanceable && (
                <LoanPromoShortDisclaimer
                  className={isMobile ? 'px-4' : ''}
                  amountUsc={invoiceTotals.dueUsc}
                />
              )}
            </>
          )}

          {actionBar}
        </InvoicePageContainer>

        {taxRateEditOpen && (
          <TaxRatePicker
            preSelectedTaxRateData={taxRate}
            onCancel={closeTaxRateEdit}
            onSubmit={onEditingTaxRateSubmit}
          />
        )}

        {blocker.state === 'blocked' && (
          <OnsiteConfirmModal
            danger
            header="Are you sure you want to exit?"
            onCancel={blocker.reset}
            onConfirm={blocker.proceed}
            confirmText="Yes, Exit"
          >
            You have unsaved changes. If you exit, they will be lost.
            <br />
            Are you sure you want to exit?
          </OnsiteConfirmModal>
        )}

        {negativeBalanceModal}
      </InvoiceDataContext.Provider>
    )
  },
)

const NegativeInvoiceBalanceDescription = React.memo(() => {
  return (
    <p>
      This action will make the invoice balance negative. Please edit a line
      item to bring it back to zero or more.
    </p>
  )
})
