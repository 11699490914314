import {
  isNullish,
  IsoDateString,
  tryParseEndOfAppointmentNextSteps,
} from '@breezy/shared'
import { faGlobePointer, faWrench } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import PersonAvatar from 'src/components/PersonAvatar/PersonAvatar'
import { useFeatureFlag } from 'src/hooks/useFeatureFlags'
import { userColor } from 'src/utils/color-utils'
import { toEventColorConfig } from '../../hooks/fetch/useScheduleColorMode'
import { getColorForJobClass } from '../../utils/job-utils'
import { StateSetter, useStrictContext } from '../../utils/react-utils'
import { BaseEventBox } from './BaseEventBox'
import { AssignmentCard } from './Cards/AssignmentCard'
import { ScheduleAssignment } from './Schedule.gql'
import {
  FullScheduleAppointment,
  getInferredAppointmentStatusFromAssignments,
  SchedulePageContext,
  TechnicianResource,
  usePopoverState,
  useRunningLateStatus,
} from './scheduleUtils'

type AssignmentEventBoxProps = {
  assignment: ScheduleAssignment
  tech?: TechnicianResource
  start: IsoDateString
  end: IsoDateString
  setChecklistAppointment: StateSetter<FullScheduleAppointment | undefined>
}

export const AssignmentEventBox = React.memo<AssignmentEventBoxProps>(
  ({ assignment, tech, start, end, setChecklistAppointment }) => {
    const { scheduleView, scheduleColorPreference } =
      useStrictContext(SchedulePageContext)
    const colorConfig =
      !!tech && scheduleColorPreference === 'TEAM_MEMBER'
        ? !isNullish(tech.userPrimaryHueHex)
          ? toEventColorConfig(tech.userPrimaryHueHex)
          : toEventColorConfig(userColor(tech.userGuid))
        : getColorForJobClass(assignment.appointment.job.jobType.jobClass)

    const createdByInstantBooking = useMemo(
      () => !!assignment.appointment.job.instantBookingAt,
      [assignment.appointment.job.instantBookingAt],
    )

    const isCompleted = useMemo(() => {
      if (assignment.assignmentStatus?.status === 'COMPLETED') {
        return true
      }
      if (assignment.appointment.endOfAppointmentNextSteps) {
        return true
      }
      if (assignment.appointment.cancellationStatus?.canceled) {
        return true
      }
      return false
    }, [
      assignment.appointment.cancellationStatus?.canceled,
      assignment.appointment.endOfAppointmentNextSteps,
      assignment.assignmentStatus?.status,
    ])

    const status = useMemo(
      () =>
        getInferredAppointmentStatusFromAssignments(
          [assignment],
          assignment.appointment.cancellationStatus?.canceled,
        ),
      [assignment],
    )

    const runningLateStatus = useRunningLateStatus(
      assignment.appointmentGuid,
      assignment.appointment.appointmentWindowEnd,
      status,
    )

    const [warning, withPulse] = useMemo(() => {
      if (assignment.appointment.endOfAppointmentNextSteps) {
        const parsedNextSteps = tryParseEndOfAppointmentNextSteps(
          assignment.appointment.endOfAppointmentNextSteps,
        )
        if (parsedNextSteps?.data.allOnsiteWorkCompleted === false) {
          return ['INC.']
        }
      }
      if (runningLateStatus) {
        return ['LATE', true]
      }

      return []
    }, [assignment.appointment.endOfAppointmentNextSteps, runningLateStatus])

    const [popoverOpen, setPopoverOpen] = usePopoverState()

    const userAvatarsEnabled = useFeatureFlag('user-avatars')

    return (
      <>
        <BaseEventBox
          title={assignment.appointment.job.account.accountDisplayName}
          icon={
            <FontAwesomeIcon
              // A little pixel pushing since the icon seems to be absolutely positioned a little wonky
              className="mr-[2px] mt-1"
              icon={createdByInstantBooking ? faGlobePointer : faWrench}
              fontSize={10}
            />
          }
          avatarContent={
            userAvatarsEnabled ? (
              !isNullish(tech) ? (
                <PersonAvatar
                  size={20}
                  fontSize={10}
                  userGuid={tech.userGuid}
                  avatarAltShortString={tech.avatarShortString}
                  avatarImgSrc={tech.userAvatarImageUrl}
                  avatarAltColor={
                    scheduleColorPreference === 'TEAM_MEMBER'
                      ? tech.userPrimaryHueHex
                      : '#F5F5F5'
                  }
                  withBorder
                  border={{
                    size: 1,
                    color: 'white',
                  }}
                />
              ) : null
            ) : (
              tech?.avatarShortString
            )
          }
          colorConfig={colorConfig}
          start={start}
          end={end}
          completed={isCompleted}
          warning={warning}
          className={classNames({
            'warning-pulse': withPulse,
          })}
          popoverOpen={popoverOpen}
          setPopoverOpen={setPopoverOpen}
          popoverContent={
            <AssignmentCard
              openChecklists={() =>
                setChecklistAppointment(assignment.appointment)
              }
              closePopover={() => setPopoverOpen(false)}
              start={start}
              end={end}
              assignment={assignment}
              tech={tech}
            />
          }
        >
          {assignment.appointment.job.location.address.city}
          {scheduleView === 'DISPATCH' || !tech
            ? ''
            : ` • ${tech.firstName} ${tech.lastName}`}
        </BaseEventBox>
      </>
    )
  },
)
