import { ZonedDateTime, ZoneId } from '@js-joda/core'
import * as Dfns from 'date-fns'
import * as DfnsTz from 'date-fns-tz'
import { DayOfTheWeek, isDayOfTheWeek } from './DayOfTheWeek'
import { ArgumentException, InvalidExternalInputException } from './errors/BusinessException'
import { ThisShouldNeverHappenError } from './errors/PlatformErrors'
import { Log } from './logging'
import { isNullish } from './type-utils'

/**
 *   ____     _____        _       ______
 *  |  _ \   |  __ \      | |     |  ____|
 *  | |_) |__| |  | | __ _| |_ ___| |__ _ __  ___
 *  |  _ <_  / |  | |/ _` | __/ _ \  __| '_ \/ __|
 *  | |_) / /| |__| | (_| | ||  __/ |  | | | \__ \
 *  |____/___|_____/ \__,_|\__\___|_|  |_| |_|___/
 *
 * BzDateFns is a wrapper around date-fns. date-fns is a collection of pure functions that operate on the JavaScript
 * `Date` object. Think of it as a better API than `Date`. BzDateFns exports all the functions from `date-fns`, adds
 * some of our own, and, most importantly, OVERRIDES some of them to add more guardrails. All of our parsers and
 * formatters require a timezone. We take UTC ISO date strings and turn them into dates in the appropriate timezone,
 * then we take those zoned dates and spit them out in UTC. When you want to do something with a date, look in this file
 * to see if we have implemented our own version. If not, consult the date-fns docs.
 *
 * https://date-fns.org/docs/Getting-Started
 *
 * Our functions also utilize three custom types: `IsoDateString`, `TimeZoneId`, and `LocalDateString`. These are
 * "branded" TypeScript types. They are just `string`s, and at runtime they will only be `string`s, but in our code we
 * use them to signify that something is "more than a string". For instance, a timezone is just a `string`, but we know
 * that "America/Los_Angeles" is a valid timezone, and we only want to accept valid timezones. So we require
 * `TimeZoneId`. Same goes for `IsoDateString`. The data from our GraphQL codegen will already have those types. If you
 * have a string that you KNOW is a time zone or an IsoDateString, you can CAREFULLY use the convenience functions to
 * type cast them.
 */

export type IsoDateString = string & {
  readonly __isoDateString: 'IsoDateString'
}

export type TimeZoneId = string & {
  readonly __timezoneId: 'TimeZoneId'
}

/* A dateString in YYYY-MM-DD format e.g. 2022-07-02 */
export type LocalDateString = string & {
  readonly __localDateString: 'LocalDateString'
}

export type LocalDateStringContainer = {
  localDate: LocalDateString
}

/* A timeString in HH:mm:ss format e.g. 08:00:00 serialized from the Postgres time type. 
This will be in the company's timezone but the timezone is not encoded in the string */
export type LocalTimeString = string & {
  readonly __localTimeString: 'LocalTimeString'
}

export const IsoLocalDate = 'yyyy-MM-dd'
export const IsoLocalTime = 'HH:mm:ss'

declare global {
  interface Date {
    // `new Date().toISOString()` obviously returns a string that's ISO formatted. IsoDateString is just a string, but
    // with a little more metadata saying we know that it's ISO formatted. So this makes perfect sense. This allows us
    // to use `new Date().toISOString()`, which is a pretty common way in our codebase to just get "now" as a string,
    // without any type assertions.
    toISOString(): IsoDateString
  }
}

// These three functions are just type assertions. They should be used very sparingly
const toIsoDateString = (dateStr: string) => dateStr as IsoDateString
const toTimeZoneId = (tzId: string) => tzId as TimeZoneId
const toLocalDateString = (localDateStr: string) => localDateStr as LocalDateString
const toLocalTimeString = (localTimeStr: string) => localTimeStr as LocalTimeString

const UTC: TimeZoneId = 'UTC' as TimeZoneId
const NY_TZ: TimeZoneId = 'America/New_York' as TimeZoneId
const CHI_TZ: TimeZoneId = 'America/Chicago' as TimeZoneId
const LA_TZ: TimeZoneId = 'America/Los_Angeles' as TimeZoneId
const MTN_TZ: TimeZoneId = 'America/Denver' as TimeZoneId
const LOCAL_TZ: TimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone as TimeZoneId

/**
 * Given a timezone, get the current moment in that timezone.
 * @param tzId Timezone in question
 * @returns a Date with the current moment in time in the given timezone
 */
const now = (tzId: TimeZoneId) => DfnsTz.utcToZonedTime(new Date(), tzId)

/**
 * Get the current moment in UTC
 * @returns the current moment in UTC as an ISO date string
 */
const nowISOString = () => new Date().toISOString()

/**
 * Gets the start of the day for the day in the given time zone
 * @param tzId timezone in question
 * @returns Date at midnight on the current day in the given timezone
 */
const getToday = (tzId: TimeZoneId) => Dfns.startOfDay(now(tzId))

/**
 * Gets the start of the day for the day in the given time zone
 * @param tzId timezone in question
 * @returns Date at midnight on the current day in the given timezone
 */
const getTodayLocalDate = (tzId: TimeZoneId): LocalDateString => formatLocalDate(getToday(tzId))

/**
 * Given a timezone, get the start of the day tomorrow
 * @param tzId Timezone in question
 * @returns a date at midnight tomorrow in the given timezone
 */
const getTomorrow = (tzId: TimeZoneId) => Dfns.addDays(getToday(tzId), 1)

/**
 * Given a timezone, get the start of the day yesterday
 * @param tzId Timezone in question
 * @returns a date at midnight yesterday in the given timezone
 */
const getYesterday = (tzId: TimeZoneId) => Dfns.subDays(getToday(tzId), 1)

/**
 * Given a UTC ISO Date String and a time zone, return a Date at that time in that timezone
 * @param dateStr The string to parse
 * @param tzId The timezone
 * @returns Date at that time in that timezone
 */
const parseISO = (dateStr: IsoDateString | LocalDateString, tzId: TimeZoneId) => {
  const d = Dfns.parseISO(dateStr)
  if (isNaN(d.getTime())) throw new ArgumentException(`Invalid date string '${dateStr}'`)
  return DfnsTz.utcToZonedTime(d, tzId)
}

/**
 * Given a date string in some format and a matching format string, return a Date at that time.
 *
 * Any unspecified values are 0. For instance, if you parse a string that's "yyyy-MM-dd", the "time" would be midnight.
 * The "0" year is 1970 and month is January.
 *
 * Most of the time you should use `parseWithTz` instead of this. Only use this if you have a date string that's ALREADY
 * IN the desired timezone. For instance, if the implication is that your "yyyy-MM-dd" is at midnight in your timezone,
 * not in UTC.
 * @param dateStr date string in some format (that's already in your desired timezone)
 * @param format format that matches the date string
 * @param referenceDate reference date to use for parsing
 * @returns Date at that time
 */
const parse = (dateStr: string, format: string, referenceDate?: IsoDateString) => {
  // `Dfns.parse` takes a Date as the third argument. When it parses, any fields missing from the parsed string are
  // filled in with the values from that "reference date". For instance, if you parse a string that's "yyyy-MM-dd" it
  // would fill in the time with the time from that date. In their docs they suggest using `new Date()`. I don't want to
  // do that because I think it's confusing and not that valuable. This reference date thing wasn't a thing several
  // versions ago but they had a breaking change that added it. Back then, iirc, it just filled unspecified values with
  // "0", which is basically what I want, especially since I want to adjust to the selected timezone (like I want
  // midnight in UTC so I can convert that so it's midnight in the company timezone). So I'm using the standard Unix
  // Epoch time. You'd think `new Date(0)` would do it, but it totally sucks and does weird timezone stuff with local
  // time. I think 99%, if not 100% of the time this will be used with a date without a time. If it's a time without a
  // date, then it will be January 1st, 1970.
  const d = Dfns.parse(
    dateStr,
    format,
    referenceDate ? Dfns.parseISO(referenceDate) : Dfns.parseISO('1970-01-01T00:00:00.000Z'),
  )
  if (isNaN(d.getTime())) throw new ArgumentException(`Invalid date string '${dateStr}' for format '${format}'`)
  return d
}

/**
 * Given a date string in some format, a matching format string, and a timezone, return a Date at that time in that
 * timezone with any unspecified values being 0. See the docs for parse for more info on what that means.
 *
 * You should be using this function instead of the basic parse if the date you're parsing is assumed to be in UTC
 *
 * @param dateStr date string in some format
 * @param format matching format
 * @param tzId timezone
 * @param referenceDate reference date to use for parsing
 * @returns Date at that time in that timezone
 */
const parseWithTz = (dateStr: string, format: string, tzId: TimeZoneId, referenceDate?: IsoDateString) => {
  const d = parse(dateStr, format, referenceDate)
  if (isNaN(d.getTime())) throw new ArgumentException(`Invalid date string '${dateStr}' for format '${format}'`)
  return DfnsTz.utcToZonedTime(d, tzId)
}

/**
 * `parse` but with "yyyy-MM-dd" as the format. Given a date string in that format, get a date at midnight with that
 * date.
 * @param dateStr "yyyy-MM-dd" date
 * @returns Date at midnight on that date
 */
const parseLocalDate = (dateStr: LocalDateString) => parse(dateStr, IsoLocalDate)

/**
 * Given a date in a timezone, return an ISO date string in UTC
 * @param date Date in question
 * @param tzId timezone to convert to
 * @returns IsoDateString in UTC of that date (assuming it's in the timezone provided)
 */
const formatISO = (date: Date, tzId: TimeZoneId): IsoDateString => {
  try {
    const utcDate = DfnsTz.zonedTimeToUtc(date, tzId)
    // https://github.com/date-fns/date-fns/issues/2151
    // https://github.com/orgs/date-fns/discussions/2366
    return utcDate.toISOString()
  } catch (e) {
    Log.error('Error formatting date', { date, tzId })
    throw new InvalidExternalInputException(`Error formatting date '${date}' with timezone '${tzId}'`, { cause: e })
  }
}

/**
 * Given a date, return a date string in the `yyyy-MM-dd` format
 * @param date Date in question
 * @returns `yyyy-MM-dd` date string
 */
const formatLocalDate = (date: Date) => Dfns.format(date, IsoLocalDate) as LocalDateString

/**
 * Given a Date in UTC, format it to an IsoDateString
 * @param date Date in UTC
 * @returns IsoDateString of that date
 */
const unZonedFormatISO = (date: Date): IsoDateString =>
  // https://github.com/date-fns/date-fns/issues/2151
  // https://github.com/orgs/date-fns/discussions/2366
  date.toISOString()

/**
 * Given a local date string, reformat it as "MMM d, yyyy"
 * @param d Date string in yyyy-MM-dd format
 * @returns Date in `MMM d, yyyy` format
 */
const localDateToFriendlyDateString = (d: LocalDateString) => Dfns.format(parseLocalDate(d), 'MMM d, yyyy')

/**
 * Given a local time string, parse it as a time string in HH:mm:ss format
 * @param t Local time string
 * @returns Date in HH:mm:ss format
 */
const parseLocalTimeString = (t: LocalTimeString) => parse(t, IsoLocalTime)

/**
 * Given a local time string, format it as a time string in h:mm a format e.g 8 AM instead of 8:00 AM
 * @param t Local time string
 * @returns Time in h:mm a format, with trailing zeros removed
 */
const localTimeStringToFormattedTimeString = (t: LocalTimeString, removeTrailingZeros = true) => {
  const date = parseLocalTimeString(t)
  const formatted = Dfns.format(date, 'h:mm a')
  return removeTrailingZeros ? formatted.replace(':00', '') : formatted
}

/**
 * Given a local time window, format it as a time window string in h:mm a - h:mm a format e.g 8 AM - 10 AM instead of 8:00 AM - 10:00 AM
 * @param window Local time window
 * @returns Time window in h:mm a - h:mm a format, with trailing zeros removed
 */
const localTimeWindowToFormattedTimeWindowString = (window: { start: LocalTimeString; end: LocalTimeString }) => {
  return `${localTimeStringToFormattedTimeString(window.start)} - ${localTimeStringToFormattedTimeString(window.end)}`
}

/**
 * Given a local date string and a format string, format the local date string using the format string
 * @param d Local date string
 * @param format Format string
 * @returns Formatted date string
 */
const localDateToFormattedDateString = (d: LocalDateString, format: string) => Dfns.format(parseLocalDate(d), format)

/**
 * Given a local date string, return the associated ISO date string in the given timezone."
 * @param d Date string in yyyy-MM-dd format
 * @returns IsoDateString of the start of that day
 */
const localDateStringToIsoDateString = (d: LocalDateString, tzId: TimeZoneId): IsoDateString => {
  const zonedTime = BzDateFns.parseLocalDate(d)
  return BzDateFns.formatISO(zonedTime, tzId)
}

/**
 * Given an ISO date string, a format, and a timezone, parse the date string using the timezone, and format it using the
 * format string
 * @param dateStr ISO date string
 * @param format format string
 * @param tzId timezone id
 * @returns The date string formatted using the timezone and format string
 */
const formatFromISO = (dateStr: IsoDateString, format: string, tzId: TimeZoneId) =>
  BzDateFns.format(BzDateFns.parseISO(dateStr, tzId), format)

/**
 * Returns true if the start date is or is after the start of today and the end date is before the start of
 * tomorrow
 * @param start Start of the time window
 * @param end End of the time window
 * @param tzId timezone id
 * @returns true if the window is entirely within today
 */
const windowIsToday = (window: { start: Date; end: Date }, tzId: TimeZoneId) => {
  const today = getToday(tzId)
  const tomorrow = getTomorrow(tzId)
  return !BzDateFns.isBefore(window.start, today) && BzDateFns.isBefore(window.end, tomorrow)
}

/**
 * Returns true if the date is before the start of today
 * @param date Date in question
 * @param tzId timezone id
 * @returns true if the date is before the start of today
 */
const isBeforeToday = (date: Date, tzId: TimeZoneId) => BzDateFns.isBefore(date, getToday(tzId))

/**
 * Returns true if the date is after the start of tomorrow
 * @param date Date in question
 * @param tzId timezone id
 * @returns true if the date is after the start of tomorrow
 */
const isAfterToday = (date: Date, tzId: TimeZoneId) => !BzDateFns.isBefore(date, getTomorrow(tzId))

/**
 * Returns true if the date is today in the given timezone
 * @param date Date to check
 * @param tzId Timezone to use for comparison
 * @returns true if the date is today
 */
const isToday = (date: Date, tzId: TimeZoneId): boolean => {
  const today = getToday(tzId)
  const tomorrow = getTomorrow(tzId)
  return !BzDateFns.isBefore(date, today) && BzDateFns.isBefore(date, tomorrow)
}

/**
 * Given a UTC ISO Date string, a timezone, and a function, parses the date string using the timezone, applies the
 * function, and returns the result parsed back to a UTC ISO Date string (using the same timezone)
 * @param dateStr UTC ISO Date string
 * @param tzId Timezone to operate in
 * @param fn Function that transforms your zoned date into a new date
 * @returns The result of the function as a UTC ISO Date string
 */
const withTimeZone = (dateStr: IsoDateString, tzId: TimeZoneId, fn: (date: Date) => Date) => {
  const date = parseISO(dateStr, tzId)
  const res = fn(date)
  return formatISO(res, tzId)
}

/**
 * Given a UTC ISO Date String and a function, parse the date string (using UTC), apply the function, and return the
 * result as a UTC ISO Date String
 * @param dateStr The string to parse
 * @param fn The function to apply
 * @returns ISO Date String
 */
const withUtc = (dateStr: IsoDateString, fn: (date: Date) => Date) => BzDateFns.withTimeZone(dateStr, UTC, fn)

/**
 * Given a timezone and function, apply the function to now (properly transformed using the provided timezone), and
 * return the result as an ISO Date String
 * @param tzId The timezone to use
 * @param fn The function to apply
 * @returns ISO Date String
 */
const nowTransform = (tzId: TimeZoneId, fn: (date: Date) => Date) => BzDateFns.formatISO(fn(BzDateFns.now(tzId)), tzId)

/**
 * Given a function, apply the function to now, and return the result as a UTC ISO Date String
 * @param fn The function to apply
 * @returns ISO Date String
 */
const nowUtcTransform = (fn: (date: Date) => Date) => nowTransform(UTC, fn)

/**
 * Given a function and a local date string, apply the function to the local date and return the result as a LocalDateString
 * @param fn The function to apply
 * @param localDateString The local date string to transform
 * @returns LocalDateString
 */
const localDateTransform = (localDateString: LocalDateString, fn: (date: Date) => Date): LocalDateString =>
  BzDateFns.formatLocalDate(fn(BzDateFns.parseLocalDate(localDateString)))

/**
 * Given a js-joda ZonedDateTime and a timezone, convert it to be usable with BzDateFns. Note that we ignore the
 * timezone from ZonedDateTime. We parse it as an instant (UTC) and then convert to the provided tzId
 * @param zonedDateTime js-joda ZonedDateTime you want to convert
 * @param tzId TimeZone you want the result to be in
 * @returns a Date object, for use
 * @deprecated This is just for interop with js-joda until we get rid of it
 */
const parseZonedDateTime = (zonedDateTime: ZonedDateTime, tzId: TimeZoneId): Date =>
  BzDateFns.parseISO(
    // We need to do this for ZonedDateTime
    // eslint-disable-next-line breezy/no-to-iso-date-string
    BzDateFns.toIsoDateString(zonedDateTime.toInstant().toString()),
    tzId,
  )

/**
 * Given a BzDateFns date and timezone, get a js-joda ZonedDateTime
 * @param date Date to convert
 * @param tzId TimeZone of date
 * @deprecated This is just for interop with js-joda until we get rid of it
 */
const toZonedDateTime = (date: Date, tzId: TimeZoneId): ZonedDateTime => {
  const isoDateStr = BzDateFns.formatISO(date, tzId)
  return ZonedDateTime.parse(isoDateStr).withZoneSameInstant(ZoneId.of(tzId))
}

type DateValues = {
  year: number
  month: number
  date: number
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

/**
 * Given a date and an object with various date/time values, return a new date with those values set.
 * @param date the date to change
 * @param values an object with optional keys year, month, date, hours, minutes, seconds, and milliseconds
 * @returns a new date with the values changed to the values defined in the object
 */
const setValues = (date: Date, values: Partial<DateValues>) => {
  let newDate = date
  if (!isNullish(values.year)) {
    newDate = BzDateFns.setYear(newDate, values.year)
  }
  if (!isNullish(values.month)) {
    newDate = BzDateFns.setMonth(newDate, values.month)
  }
  if (!isNullish(values.date)) {
    newDate = BzDateFns.setDate(newDate, values.date)
  }
  if (!isNullish(values.hours)) {
    newDate = BzDateFns.setHours(newDate, values.hours)
  }
  if (!isNullish(values.minutes)) {
    newDate = BzDateFns.setMinutes(newDate, values.minutes)
  }
  if (!isNullish(values.seconds)) {
    newDate = BzDateFns.setSeconds(newDate, values.seconds)
  }
  if (!isNullish(values.milliseconds)) {
    newDate = BzDateFns.setMilliseconds(newDate, values.milliseconds)
  }
  return newDate
}

/**
 * Given an object representing a date/time, return a new date with those values.
 * @param values an object specifying the year, month, date, hours, minutes, seconds, and milliseconds
 * @returns a new date with the values specified in the object
 */
const createDate = (values: DateValues) => BzDateFns.setValues(new Date(), values)

/**
 * Given two dates, return a copy of the first date with all the time values set to those of the second date.
 * @param date The date whose time is changing
 * @param dateToCopy The date whose time is being copied into the other date
 * @returns A new date with the date values of the first date and the time values of the second date
 */
const copyTime = (date: Date, dateToCopy: Date): Date =>
  BzDateFns.setValues(date, {
    hours: BzDateFns.getHours(dateToCopy),
    minutes: BzDateFns.getMinutes(dateToCopy),
    seconds: BzDateFns.getSeconds(dateToCopy),
    milliseconds: BzDateFns.getMilliseconds(dateToCopy),
  })

/**
 * Given a list of ISO date strings and a timezone, return true if any of the dates are in different years
 * @param isoDateStrings List of dates to compare
 * @param tzId TimeZone used to parse the strings
 * @returns true if any of the years are different
 */
const anyDifferentYears = (isoDateStrings: IsoDateString[], tzId: TimeZoneId) => {
  if (isoDateStrings.length < 2) return false

  const firstDate = BzDateFns.parseISO(isoDateStrings[0], tzId)

  return isoDateStrings.slice(1).some(iso => !BzDateFns.isSameYear(firstDate, BzDateFns.parseISO(iso, tzId)))
}

/**
 * Given a date and a timezone, return the number of days since that date
 * @param date Date to compare
 * @param tzId TimeZone of the date
 * @returns Number of days since the date
 */
export const daysBetween = (tzId: TimeZoneId, start: IsoDateString, end: IsoDateString): number => {
  const startDate = BzDateFns.parseISO(start, tzId)
  const endDate = BzDateFns.parseISO(end, tzId)

  return BzDateFns.differenceInCalendarDays(endDate, startDate)
}

/**
 * Given two local date strings, return the number of days between them
 * @param start The start date in ISO local date format
 * @param end The end date in ISO local date format
 * @returns Number of days between the start and end dates
 */
export const daysBetweenLocalDates = (start: LocalDateString, end: LocalDateString): number => {
  const startDate = BzDateFns.parseLocalDate(start)
  const endDate = BzDateFns.parseLocalDate(end)

  return BzDateFns.differenceInCalendarDays(endDate, startDate)
}

/**
 * Checks whether two dates are on the same day or not, in the same timezone.
 *
 * @param a The first date
 * @param b The second date
 * @param tz The timezone to pin a and b to.
 */
export const isSameDayWithTz = (a: IsoDateString, b: IsoDateString, tz: TimeZoneId): boolean => {
  const aDate = parseISO(a, tz)
  const bDate = parseISO(b, tz)
  const aEndOfDay = Dfns.endOfDay(aDate)

  return Dfns.isBefore(bDate, aEndOfDay)
}

/**
 * Converts an ISO date string to a local date string (yyyy-MM-dd format)
 * @param isoDateString The ISO date string to convert
 * @param tzId The timezone to use for the conversion
 * @returns A local date string in yyyy-MM-dd format
 */
const convertToLocalDateString = (isoDateString: IsoDateString, tzId: TimeZoneId): LocalDateString => {
  const date = parseISO(isoDateString, tzId)
  return formatLocalDate(date)
}

const convertLocalDateStringToDayOfWeek = (localDateString: LocalDateString): DayOfTheWeek => {
  const dayOfWeek = localDateToFormattedDateString(localDateString, 'EEEE')

  if (!isDayOfTheWeek(dayOfWeek)) {
    throw new ThisShouldNeverHappenError(`Invalid day of week: ${dayOfWeek}`)
  }

  return dayOfWeek
}

/**
 * Combines a local time and date into an ISO date string for a specific timezone
 * @param time Time in HH:mm:ss format
 * @param date Date in yyyy-MM-dd format
 * @param tzId Company's timezone
 * @returns ISO date string in the company's timezone
 */
const combineLocalTimeAndDate = (time: LocalTimeString, date: LocalDateString, tzId: TimeZoneId): IsoDateString => {
  const dateObj = parseLocalDate(date)
  const timeObj = parseLocalTimeString(time)

  const combined = setValues(dateObj, {
    hours: Dfns.getHours(timeObj),
    minutes: Dfns.getMinutes(timeObj),
    seconds: Dfns.getSeconds(timeObj),
  })

  return formatISO(combined, tzId)
}

export const BzDateFns = {
  ...Dfns,
  UTC,
  NY_TZ,
  CHI_TZ,
  LA_TZ,
  MTN_TZ,
  LOCAL_TZ,
  convertToLocalDateString,
  toIsoDateString,
  toTimeZoneId,
  toLocalDateString,
  toLocalTimeString,
  now,
  nowISOString,
  getToday,
  getTodayLocalDate,
  getTomorrow,
  parse,
  parseISO,
  parseWithTz,
  parseLocalDate,
  formatISO,
  formatLocalDate,
  unZonedFormatISO,
  localDateToFriendlyDateString,
  localDateStringToIsoDateString,
  localDateToFormattedDateString,
  localTimeStringToFormattedTimeString,
  localTimeWindowToFormattedTimeWindowString,
  formatFromISO,
  windowIsToday,
  isBeforeToday,
  isAfterToday,
  isToday,
  getYesterday,
  withTimeZone,
  tz: DfnsTz,
  withUtc,
  nowUtcTransform,
  nowTransform,
  parseZonedDateTime,
  parseLocalTimeString,
  toZonedDateTime,
  createDate,
  setValues,
  copyTime,
  anyDifferentYears,
  daysBetween,
  daysBetweenLocalDates,
  localDateTransform,
  isSameDayWithTz,
  convertLocalDateStringToDayOfWeek,
  combineLocalTimeAndDate,
} as const
