import { Popover } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import classNames from 'classnames'
import React from 'react'
import './DetailChip.less'

type DetailChipProps = React.PropsWithChildren<{
  // FIXME: it seems kinda strange to have a green boolean when we could use className
  green?: boolean
  accentColor?: string
  className?: string
  icon?: React.ReactNode
  popoverContent?: React.ReactNode
  popoverPlacement?: TooltipPlacement
  square?: boolean
  maxPopoverHeight?: number
}>

export const DetailChip = React.memo<DetailChipProps>(
  ({
    children,
    green,
    accentColor,
    className,
    icon,
    popoverContent,
    square,
    maxPopoverHeight = 400,
    popoverPlacement,
  }) => {
    const content = (
      <div
        className={classNames(
          'relative flex shrink-0 flex-row items-center overflow-hidden whitespace-nowrap rounded-md border border-solid px-2 py-1 transition-colors ease-in-out',
          green
            ? 'border-bz-green-400 bg-bz-green-100 text-bz-green-900'
            : 'border-bz-gray-500 text-bz-gray-1000',
          {
            'pl-3': !!accentColor,
            'hover:border-bz-gray-700': popoverContent,
            'h-8 w-8 items-center justify-center': square,
          },
          className,
        )}
      >
        {accentColor && (
          <div
            style={{ backgroundColor: accentColor }}
            className="absolute inset-y-0 left-0 h-full w-1"
          />
        )}
        {icon && (
          <div className="mr-1.5 h-4 w-4 leading-4 *:h-4 *:w-4">{icon}</div>
        )}
        <div>{children}</div>
      </div>
    )

    if (popoverContent) {
      return (
        <Popover
          overlayClassName="detail-chip-popover"
          placement={popoverPlacement}
          content={
            <div
              className="overflow-auto p-3"
              style={{ maxHeight: `${maxPopoverHeight}px` }}
            >
              {popoverContent}
            </div>
          }
        >
          {content}
        </Popover>
      )
    }
    return content
  },
)
