import { isNullish, toRoleDisplayName } from '@breezy/shared'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Input } from 'antd'
import { FilterDropdownProps } from 'antd/es/table/interface'
import { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import GqlQueryLoader from '../../../components/GqlQueryLoader/GqlQueryLoader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import PersonResourceView from '../../../components/PersonResourceView/PersonResourceView'
import { useDebouncedSearchText } from '../../../hooks/useDebouncedSearchText'
import { useExpectedCompanyGuid } from '../../../providers/PrincipalUser'
import { TIMESHEET_TECHNICIANS_QUERY } from '../TimesheetsSummaryPage.gql'

export type TechnicianColumnFilterDropdownProps = FilterDropdownProps

export const TechnicianColumnFilterDropdown = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  close,
}: TechnicianColumnFilterDropdownProps) => {
  const companyGuid = useExpectedCompanyGuid()
  const { searchText, debouncedSearchText, onSearch } = useDebouncedSearchText({
    wait: 250,
  })
  const [selectedUsers, setSelectedUsers] = useState(selectedKeys)
  const usersQuery = useQuery({
    query: TIMESHEET_TECHNICIANS_QUERY,
    variables: {
      companyGuid,
      search: `%${debouncedSearchText.trim().toLowerCase()}%`,
      roleSearch: `%${debouncedSearchText
        .trim()
        .toLowerCase()
        .split(' ')
        .join('_')}%`,
    },
  })

  useEffect(() => {
    setSelectedUsers(selectedKeys)
  }, [selectedKeys])

  const onUserClick = (userGuid: string) => {
    const userGuidIdx = selectedUsers.findIndex(curr => curr === userGuid)
    if (userGuidIdx < 0) {
      setSelectedUsers([...selectedUsers, userGuid])
    } else {
      setSelectedUsers(prev => {
        const updated = [...prev]
        updated.splice(userGuidIdx, 1)
        return updated
      })
    }
  }

  const onSaveSelectedUsersClick = () => {
    setSelectedKeys(selectedUsers)
    confirm()
    close()
  }

  if (usersQuery[0].error) {
    return (
      <div className="flex w-full flex-col items-center p-2">
        <p>Could not get users</p>
      </div>
    )
  }

  return (
    <div className="flex w-80 flex-col gap-3 p-2">
      <Input
        placeholder="Search user's name or their role..."
        value={searchText}
        onChange={onSearch}
      />

      <div className="flex h-72 flex-col gap-2 overflow-y-scroll">
        <GqlQueryLoader
          query={usersQuery}
          loadingComponent={<LoadingSpinner />}
          render={({ users }) => (
            <>
              {users.map(user => (
                <div
                  key={user.userGuid}
                  className="flex w-full cursor-pointer flex-row items-center justify-center rounded border p-1 hover:bg-bz-gray-400"
                  onClick={() => onUserClick(user.userGuid)}
                >
                  <PersonResourceView
                    userGuid={user.userGuid}
                    displayName={user.fullName ?? 'Unknown'}
                    displayCategory={
                      user.userRoles[0]
                        ? toRoleDisplayName(user.userRoles[0].role)
                        : 'Unknown'
                    }
                    avatarData={{
                      userGuid: user.userGuid,
                      avatarAltShortString: `${user.firstName.charAt(
                        0,
                      )}${user.lastName.charAt(0)}`,
                      avatarAltColor: user.userPrimaryHueHex ?? '#F5F5F5',
                      avatarImgSrc: user.avatarPhoto?.cdnUrl,
                    }}
                  />

                  <div className="ml-auto">
                    {!isNullish(
                      selectedUsers.find(
                        currUserGuid => user.userGuid === currUserGuid,
                      ),
                    ) && <FontAwesomeIcon icon={faCheck} />}
                  </div>
                </div>
              ))}
            </>
          )}
        />
      </div>

      <Button type="primary" onClick={onSaveSelectedUsersClick}>
        Save
      </Button>
      <Button onClick={() => setSelectedUsers([])}>Clear All</Button>
    </div>
  )
}
