import { BzDateFns, formatMoney } from '@breezy/shared'
import { Col, Row } from 'antd'
import { ReactNode, memo } from 'react'
import { EmDash } from '../../elements/EmDash/EmDash'
import { useExpectedCompanyTimeZoneId } from '../../providers/PrincipalUser'
import InvoiceStatusTag from '../Invoicing/InvoiceStatusTag/InvoiceStatusTag'
import OutcomeCard from './OutcomeCard'
import { JobOutcomeInvoice } from './job-outcome-modal-types'
type InvoiceCardProps = {
  invoice: JobOutcomeInvoice
}

const InvoiceCard = memo<InvoiceCardProps>(({ invoice }) => {
  const companyTimezoneId = useExpectedCompanyTimeZoneId()
  let issueDateString: ReactNode
  try {
    issueDateString = BzDateFns.formatFromISO(
      invoice.issuedAt,
      'MMM dd, yyyy',
      companyTimezoneId,
    )
  } catch (e) {
    console.error(e)
    issueDateString = <EmDash />
  }

  return (
    <OutcomeCard
      title={
        <Row justify="space-between" className="-p-3 m-0">
          <Col>{`Invoice ${invoice.displayId}`}</Col>
          <Col>
            <InvoiceStatusTag status={invoice.status} />
          </Col>
        </Row>
      }
    >
      <Row justify="space-between" className="mb-1">
        <Col>Issue Date</Col>
        <Col>{issueDateString}</Col>
      </Row>
      <Row justify="space-between" className="mb-1">
        <Col>Subtotal</Col>
        <Col>{formatMoney(invoice.subtotalPriceUsd)}</Col>
      </Row>
      <Row justify="space-between" className="mb-0">
        <Col>Total (Incl. tax)</Col>
        <Col>{formatMoney(invoice.totalPriceUsd)}</Col>
      </Row>
    </OutcomeCard>
  )
})

export default InvoiceCard
