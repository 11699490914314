import { BzDateFns, IsoDateString, TimeZoneId } from '@breezy/shared'
import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import { tailwindColors } from '../../../../../tailwind.config'
import { Option } from '../../../estimatesFlowUtils'
type PDFSignatureProps = {
  signatureFileUrl?: string
  acceptedOnBehalfByUser?: {
    firstName: string
    lastName: string
  }
  acceptedAt?: IsoDateString
  tzId: TimeZoneId
  contactFullName: string
  acceptedOption?: Option
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    gap: 24,
    padding: '24px 30px',
    borderTop: `1px solid ${tailwindColors['bz-gray'][400]}`,
    marginTop: 24,
  },
  signatureBox: {
    flex: 1,
  },
  contentBox: {
    minHeight: 80,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  signatureImageContainer: {
    height: 80,
    width: '100%',
  },
  signatureImage: {
    maxHeight: '100%',
    maxWidth: '100%',
    objectFit: 'contain',
    objectPosition: 'left bottom',
  },
  signatureText: {
    fontSize: 14,
    lineHeight: 1.5,
    color: tailwindColors['bz-gray'][1000],
  },
  labelContainer: {
    marginTop: 8,
    borderTopWidth: 1,
    borderTopColor: tailwindColors['bz-gray'][500],
    borderTopStyle: 'solid',
    paddingTop: 8,
  },
  label: {
    fontSize: 16,
    fontWeight: 'semibold',
    color: tailwindColors['bz-gray'][1000],
  },
})

export const PDFSignature = ({
  signatureFileUrl,
  acceptedOnBehalfByUser,
  acceptedAt,
  tzId,
  contactFullName,
  acceptedOption,
}: PDFSignatureProps) => {
  const signatureContent = signatureFileUrl ? (
    <View style={styles.signatureImageContainer}>
      <Image src={signatureFileUrl} style={styles.signatureImage} />
    </View>
  ) : acceptedOnBehalfByUser ? (
    <Text style={styles.signatureText}>
      {`Accepted by ${acceptedOnBehalfByUser.firstName} ${acceptedOnBehalfByUser.lastName} on behalf of ${contactFullName} with their permission.`}
    </Text>
  ) : (
    <Text style={styles.signatureText}>N/A</Text>
  )
  const acceptedOptionContent = acceptedOption ? (
    <Text style={styles.signatureText}>
      {acceptedOption.displayName || `Option #${acceptedOption.seq + 1}`}
    </Text>
  ) : (
    <Text style={styles.signatureText}></Text>
  )

  const dateContent = acceptedAt ? (
    <Text style={styles.signatureText}>
      {BzDateFns.formatFromISO(acceptedAt, 'MMMM d, yyyy', tzId)}
    </Text>
  ) : (
    <Text style={styles.signatureText}></Text>
  )

  return (
    <View style={styles.container}>
      <View style={styles.signatureBox}>
        <View style={styles.contentBox}>{signatureContent}</View>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>Signature</Text>
        </View>
      </View>
      {acceptedOption && (
        <View style={styles.signatureBox}>
          <View style={styles.contentBox}>{acceptedOptionContent}</View>
          <View style={styles.labelContainer}>
            <Text style={styles.label}>Accepted Option</Text>
          </View>
        </View>
      )}
      <View style={styles.signatureBox}>
        <View style={styles.contentBox}>{dateContent}</View>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>Date</Text>
        </View>
      </View>
    </View>
  )
}
