import { z } from 'zod'
import { AsyncFn, Dfns, IsoDateString, LocalDate, R, ThisShouldNeverHappenError, isNullish } from '../../common'
import { guidSchema, localDateSchema } from '../../contracts/_common'
import { ForCompany } from '../Company/Company'
import {
  EquipmentManufacturerSchema,
  EquipmentModelNumberSchema,
  EquipmentSerialNumberSchema,
} from '../Equipment/EquipmentManufacturer'
import { EquipmentType, EquipmentTypeSchema, formatEquipmentType } from '../Equipment/EquipmentType'
import { LocationGuid } from '../Locations/Location'
import { ForUser } from '../Users/User'
import { NonNegativeNumberSchema, bzOptional } from '../common-schemas'

export enum EquipmentInstallationParty {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export const EquipmentInstallationPartyDisplayNames = {
  [EquipmentInstallationParty.INTERNAL]: 'Internal',
  [EquipmentInstallationParty.EXTERNAL]: 'External',
}

export const isKeyOfEquipmentInstallationParty = (
  key: string | undefined,
): key is keyof typeof EquipmentInstallationParty =>
  key ? Object.keys(EquipmentInstallationParty).includes(key) : false

export const EquipmentInstallationPartySchema = z.nativeEnum(EquipmentInstallationParty)

export enum EquipmentOperationalStatus {
  IN_SERVICE = 'IN_SERVICE',
  DEACTIVATED = 'DEACTIVATED',
  UNKNOWN = 'UNKNOWN',
}

export const EquipmentOperationalStatusDisplayNames = {
  [EquipmentOperationalStatus.IN_SERVICE]: 'In Service',
  [EquipmentOperationalStatus.DEACTIVATED]: 'Deactivated',
  [EquipmentOperationalStatus.UNKNOWN]: 'Unknown',
} as const

export const EquipmentOperationalStatusDisplayNamesSchema = z.nativeEnum(EquipmentOperationalStatusDisplayNames)
export type EquipmentOperationalStatusDisplayName = z.infer<typeof EquipmentOperationalStatusDisplayNamesSchema>
export const isKeyOfEquipmentOperationalStatus = (
  key: string | undefined,
): key is keyof typeof EquipmentOperationalStatus =>
  key ? Object.keys(EquipmentOperationalStatus).includes(key) : false

export const EquipmentOperationalStatusSchema = z.nativeEnum(EquipmentOperationalStatus)

export enum EquipmentCondition {
  LIKE_NEW = 'LIKE_NEW',
  GOOD = 'GOOD',
  FAIR = 'FAIR',
  POOR = 'POOR',
  AT_RISK_OF_FAILURE = 'AT_RISK_OF_FAILURE',
}

export const EquipmentConditionDisplayNames = {
  [EquipmentCondition.LIKE_NEW]: 'Like New',
  [EquipmentCondition.GOOD]: 'Good',
  [EquipmentCondition.FAIR]: 'Fair',
  [EquipmentCondition.POOR]: 'Poor',
  [EquipmentCondition.AT_RISK_OF_FAILURE]: 'At Risk of Failure',
} as const

export const INSTALLED_EQUIPMENT_SOURCE_TYPES = ['LABEL_SCANNER', 'FORM', 'DATA_MIGRATION', 'UNKNOWN'] as const
export type InstalledEquipmentSourceType = (typeof INSTALLED_EQUIPMENT_SOURCE_TYPES)[number]
const InstalledEquipmentSourceTypeSchema = z.enum(INSTALLED_EQUIPMENT_SOURCE_TYPES)
export const isInstalledEquipmentSourceType = (key: string | undefined): key is InstalledEquipmentSourceType => {
  const result = InstalledEquipmentSourceTypeSchema.safeParse(key)
  return result.success
}

export const EquipmentConditionDisplayNamesSchema = z.nativeEnum(EquipmentConditionDisplayNames)
export type EquipmentConditionDisplayName = z.infer<typeof EquipmentConditionDisplayNamesSchema>
export const isKeyOfEquipmentCondition = (key: string | undefined): key is keyof typeof EquipmentCondition =>
  key ? Object.keys(EquipmentCondition).includes(key) : false

export const EquipmentConditionSchema = z.nativeEnum(EquipmentCondition)

export const InstalledEquipmentGuidSchema = guidSchema
export type InstalledEquipmentGuid = z.infer<typeof guidSchema>

const AverageLifeExpectancyYearsSchema = NonNegativeNumberSchema
export type AverageLifeExpectancyYears = z.infer<typeof AverageLifeExpectancyYearsSchema>

const ManufacturerWarrantyTermsSchema = z.string()
export type ManufacturerWarrantyTerms = z.infer<typeof ManufacturerWarrantyTermsSchema>

const LaborWarrantyTermsSchema = z.string()
export type LaborWarrantyTerms = z.infer<typeof LaborWarrantyTermsSchema>

const EquipmentDimensionsSchema = z.string()
export type EquipmentDimensions = z.infer<typeof EquipmentDimensionsSchema>

export const InstalledEquipmentDescriptionSchema = z.string()
export type InstalledEquipmentDescription = z.infer<typeof InstalledEquipmentDescriptionSchema>

export const UpsertInstalledEquipmentInputSchema = z.object({
  installedEquipmentGuid: InstalledEquipmentGuidSchema,
  locationGuid: guidSchema,
  equipmentType: EquipmentTypeSchema,
  installationDate: bzOptional(localDateSchema),
  installationParty: bzOptional(EquipmentInstallationPartySchema),
  estimatedEndOfLifeDate: bzOptional(localDateSchema),
  averageLifeExpectancyYears: bzOptional(AverageLifeExpectancyYearsSchema),
  manufacturer: bzOptional(EquipmentManufacturerSchema),
  modelNumber: bzOptional(EquipmentModelNumberSchema),
  serialNumber: bzOptional(EquipmentSerialNumberSchema),
  manufacturerWarrantyStartDate: bzOptional(localDateSchema),
  manufacturerWarrantyEndDate: bzOptional(localDateSchema),
  manufacturerWarrantyTerms: bzOptional(ManufacturerWarrantyTermsSchema),
  description: bzOptional(InstalledEquipmentDescriptionSchema),
  operationalStatus: EquipmentOperationalStatusSchema,
  equipmentCondition: bzOptional(EquipmentConditionSchema),
  laborWarrantyStartDate: bzOptional(localDateSchema),
  laborWarrantyEndDate: bzOptional(localDateSchema),
  manufacturingDate: bzOptional(localDateSchema),
  laborWarrantyTerms: bzOptional(LaborWarrantyTermsSchema),
  equipmentDimensions: bzOptional(EquipmentDimensionsSchema),
  sourceType: InstalledEquipmentSourceTypeSchema,
})

export type UpsertInstalledEquipmentInput = z.infer<typeof UpsertInstalledEquipmentInputSchema>

export type IInstalledEquipmentUpserter = AsyncFn<ForUser<UpsertInstalledEquipmentInput>, void>

export const InstalledEquipmentQuerySchema = z.discriminatedUnion('type', [
  z.object({ type: z.literal('by-location-guid'), locationGuid: guidSchema, companyGuid: guidSchema }),
  z.object({ type: z.literal('by-account-guid'), accountGuid: guidSchema, companyGuid: guidSchema }),
])

export type InstalledEquipmentQuery = z.infer<typeof InstalledEquipmentQuerySchema>

export type IInstalledEquipmentQuerier = AsyncFn<ForCompany<InstalledEquipmentQuery>, InstalledEquipmentSummary[]>

export type InstalledEquipmentSummary = {
  readonly installedEquipmentGuid: string
  readonly equipmentType: string
  readonly installationDate?: LocalDate
  readonly installationParty?: string
  readonly estimatedEndOfLifeDate?: LocalDate
  readonly averageLifeExpectancyYears?: number
  readonly manufacturer?: string
  readonly manufacturingDate?: LocalDate
  readonly modelNumber?: string
  readonly serialNumber?: string
  readonly manufacturerWarrantyStartDate?: LocalDate
  readonly manufacturerWarrantyEndDate?: LocalDate
  readonly manufacturerWarrantyTerms?: string
  readonly operationalStatus: string
  readonly equipmentCondition?: string
  readonly laborWarrantyStartDate?: LocalDate
  readonly laborWarrantyEndDate?: LocalDate
  readonly laborWarrantyTerms?: string
  readonly equipmentDimensions?: string
  readonly locationGuid: LocationGuid
  readonly description?: string
  readonly lastJobDate?: IsoDateString
  readonly sourceType: InstalledEquipmentSourceType
}

export type InstalledEquipmentSummaryDto = {
  readonly installedEquipmentGuid: string
  readonly equipmentType: string
  readonly installationDate?: string
  readonly installationParty?: string
  readonly estimatedEndOfLifeDate?: string
  readonly averageLifeExpectancyYears?: number
  readonly manufacturer?: string
  readonly modelNumber?: string
  readonly serialNumber?: string
  readonly manufacturerWarrantyStartDate?: string
  readonly manufacturerWarrantyEndDate?: string
  readonly manufacturerWarrantyTerms?: string
  readonly operationalStatus: string
  readonly equipmentCondition?: string
  readonly laborWarrantyStartDate?: string
  readonly laborWarrantyEndDate?: string
  readonly manufacturingDate?: string
  readonly laborWarrantyTerms?: string
  readonly equipmentDimensions?: string
  readonly locationGuid: LocationGuid
  readonly description?: string
  readonly sourceType: InstalledEquipmentSourceType
}

export type InstalledEquipmentAgeCalculationFields = {
  readonly installationDate?: LocalDate
  readonly estimatedEndOfLifeDate?: LocalDate
  readonly averageLifeExpectancyYears?: number
}

const convertLocalDateToDate = (localDate: LocalDate): Date => new Date(localDate.toString())

export const convertInstalledEquipmentSummaryDtoToInstalledEquipmentSummary = (
  summary: InstalledEquipmentSummaryDto,
): InstalledEquipmentSummary => {
  return {
    ...summary,
    installationDate: summary.installationDate ? LocalDate.parse(summary.installationDate) : undefined,
    estimatedEndOfLifeDate: summary.estimatedEndOfLifeDate
      ? LocalDate.parse(summary.estimatedEndOfLifeDate)
      : undefined,
    manufacturerWarrantyStartDate: summary.manufacturerWarrantyStartDate
      ? LocalDate.parse(summary.manufacturerWarrantyStartDate)
      : undefined,
    manufacturerWarrantyEndDate: summary.manufacturerWarrantyEndDate
      ? LocalDate.parse(summary.manufacturerWarrantyEndDate)
      : undefined,
    laborWarrantyStartDate: summary.laborWarrantyStartDate
      ? LocalDate.parse(summary.laborWarrantyStartDate)
      : undefined,
    laborWarrantyEndDate: summary.laborWarrantyEndDate ? LocalDate.parse(summary.laborWarrantyEndDate) : undefined,
    manufacturingDate: summary.manufacturingDate ? LocalDate.parse(summary.manufacturingDate) : undefined,
  }
}

export enum ProbabilityOfEquipmentReplacement {
  UNKNOWN = 'UNKNOWN',
  NOT_PROBABLE = 'NOT_PROBABLE',
  MEDIUM_PROBABILITY = 'MEDIUM_PROBABILITY',
  HIGH_PROBABILITY = 'HIGH_PROBABILITY',
  EXTREMELY_PROBABLE = 'EXTREMELY_PROBABLE',
}

export class InstalledEquipment {
  static calculateFriendlyName = (
    equipment: Pick<InstalledEquipmentSummary, 'manufacturer' | 'equipmentType'>,
  ): string => {
    const parts: string[] = []

    if (equipment.equipmentType)
      parts.push(formatEquipmentType(equipment.equipmentType as EquipmentType) ?? equipment.equipmentType)

    if (equipment.manufacturer) parts.push(`(${equipment.manufacturer})`)

    return parts.join(' ')
  }

  static calculateIdentifyingName = (
    equipment: Pick<InstalledEquipmentSummary, 'manufacturer' | 'equipmentType' | 'serialNumber'>,
  ): string => {
    const parts: string[] = []

    parts.push(InstalledEquipment.calculateFriendlyName(equipment))

    if (equipment.serialNumber) {
      parts.push(`(Serial #: ${equipment.serialNumber})`)
    }

    return parts.join(' ')
  }

  static getProbabilityOfEquipmentReplacement = (
    currentDate: Date,
    equipment: InstalledEquipmentAgeCalculationFields,
  ): ProbabilityOfEquipmentReplacement => {
    const yearsSinceInstall = InstalledEquipment.yearsEquipmentAge(currentDate, equipment)
    if (isNullish(yearsSinceInstall)) return ProbabilityOfEquipmentReplacement.UNKNOWN

    if (yearsSinceInstall < 6) return ProbabilityOfEquipmentReplacement.NOT_PROBABLE
    if (yearsSinceInstall >= 6 && yearsSinceInstall < 10) return ProbabilityOfEquipmentReplacement.MEDIUM_PROBABILITY
    if (yearsSinceInstall >= 10 && yearsSinceInstall < 18) return ProbabilityOfEquipmentReplacement.HIGH_PROBABILITY
    if (yearsSinceInstall >= 18) return ProbabilityOfEquipmentReplacement.EXTREMELY_PROBABLE

    throw new ThisShouldNeverHappenError(`Logically all cases have been covered`)
  }

  static yearsEquipmentAge = (
    currentDate: Date,
    equipment: InstalledEquipmentAgeCalculationFields,
  ): number | undefined => {
    if (equipment.installationDate) {
      const installationDate = convertLocalDateToDate(equipment.installationDate)
      if (installationDate > currentDate) return undefined
      return Math.abs(Dfns.differenceInYears(currentDate, installationDate))
    }

    if (equipment.estimatedEndOfLifeDate && equipment.averageLifeExpectancyYears) {
      const endOfLifeDate = convertLocalDateToDate(equipment.estimatedEndOfLifeDate)
      const installationYear = endOfLifeDate.getFullYear() - equipment.averageLifeExpectancyYears
      const installationDate = new Date(installationYear, endOfLifeDate.getMonth(), endOfLifeDate.getDate())
      return Math.abs(Dfns.differenceInYears(currentDate, installationDate))
    }

    return undefined
  }

  static getOldestInstalledEquipmentAgeYears = (
    currentDate: Date,
    installedEquipments?: InstalledEquipmentAgeCalculationFields[],
  ): number | undefined => {
    if (!installedEquipments) return undefined

    const ages: number[] = installedEquipments
      .map(e => InstalledEquipment.yearsEquipmentAge(currentDate, e))
      .filter(e => !!e)
      // NOTE: TypeScript is blind to the fact that we've filtered out nulls
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .map(e => e!)
    const sortedAges = R.sort((a: number, b: number) => b - a, ages)
    return sortedAges[0]
  }

  static getOldestEquipment = (
    installedEquipments?: InstalledEquipmentSummary[],
  ): InstalledEquipmentSummary | undefined => {
    if (!installedEquipments) return undefined

    const now = new Date()
    const sortedEquipments = R.sort((a: InstalledEquipmentSummary, b: InstalledEquipmentSummary) => {
      const ageA = InstalledEquipment.yearsEquipmentAge(now, a) ?? 0
      const ageB = InstalledEquipment.yearsEquipmentAge(now, b) ?? 0
      return ageB - ageA
    }, installedEquipments)
    return sortedEquipments[0]
  }
}
