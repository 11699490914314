import {
  Address,
  BzAddress,
  BzDateFns,
  HtmlString,
  IsoDateString,
  JobGuid,
  LocalDateString,
  formatUsc,
  phoneUtils,
} from '@breezy/shared'
import classNames from 'classnames'
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useQuery } from 'urql'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import {
  useExpectedCompanyGuid,
  useExpectedCompanyTimeZoneId,
} from '../../providers/PrincipalUser'
import { DEFAULT_FONT_FAMILY } from '../../themes/theme'
import { DOWNLOADABLE_INVOICE_DATA } from './DownloadableInvoiceData.gql'

export const DOWNLOADABLE_INVOICE_DATA_FETCHING_CLASS_NAME =
  'downloadable-invoice-data-fetching'

const DASHED_BOTTOM_CLASS_NAME =
  'border-0 border-b-2 border-dashed border-bz-gray-400'

const addressToLines = (address: Address): string[] => {
  const lines = [address.line1]
  if (address.line2) {
    lines.push(address.line2)
  }
  return [
    ...lines,
    `${address.city}, ${address.stateAbbreviation}`,
    address.zipCode,
  ]
}

type EstimateSummaryOptionNameProps = {
  index: number
  name: string
}

// When I have truncation with CSS, `html2canvas` doesn't pick up on it so it just gets cut off. So I have to manually
// compute the ...s. 😩
const EstimateSummaryOptionName = React.memo<EstimateSummaryOptionNameProps>(
  ({ index, name }) => {
    const ref = useRef<HTMLDivElement>(null)

    const idealText = useMemo(() => `${index + 1}: ${name}`, [index, name])

    const [text, setText] = useState(idealText)

    // Manually truncate the text
    useLayoutEffect(() => {
      if (ref.current) {
        const ELLIPSIS = '...'

        const maxWidth = ref.current.offsetWidth

        // Create a temporary element for measuring text width
        const measure = document.createElement('span')
        document.body.appendChild(measure)
        measure.style.visibility = 'hidden' // Make it invisible
        measure.style.position = 'fixed' // Remove from document flow
        // We need to put this up in the corner. Otherwise it goes where it otherwise would (below the content) and
        // cause scroll bars.
        measure.style.top = '0'
        measure.style.left = '0'
        measure.style.whiteSpace = 'nowrap' // Prevent wrapping

        const containerStyle = window.getComputedStyle(ref.current)
        // Make sure all the font stuff matches in our measurer
        measure.style.font = containerStyle.font
        measure.style.fontWeight = containerStyle.fontWeight
        measure.style.fontSize = containerStyle.fontSize

        // If our text fits in the container, we don't need to do any computing.
        measure.innerHTML = idealText
        if (measure.offsetWidth < maxWidth) {
          setText(idealText)
          document.body.removeChild(measure)
          return
        }

        // Now we know it doesn't fit. So we'll add the "..." and start removing letters until it fits.
        let currentText = idealText
        do {
          currentText = currentText.slice(0, -1)
          measure.innerHTML = currentText + ELLIPSIS
        } while (measure.offsetWidth > maxWidth && currentText.length > 0)

        setText(currentText + ELLIPSIS)

        document.body.removeChild(measure)
      }
    }, [idealText])

    return (
      <div className="truncate font-bold" ref={ref}>
        {text}
      </div>
    )
  },
)

type InvoiceLogoProps = {
  src?: string
}

// To further my pain, `html2canvas` doesn't pick up "object-fit" and "object-position" CSS. We need this to make sure
// the image is within a 174x72 box and aligned in the upper right. So we're going to have to do this the old-fashioned
// way.
const InvoiceLogo = React.memo<InvoiceLogoProps>(({ src }) => {
  const ref = useRef<HTMLImageElement>(null)

  const [[paddingLeft, paddingBottom], setPadding] = useState([0, 0])

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.onload = () => {
        if (ref.current) {
          const aspectRatio = 174 / 72
          const imageAspectRatio =
            ref.current.naturalWidth / ref.current.naturalHeight

          if (imageAspectRatio > aspectRatio) {
            setPadding([0, (72 - 174 / imageAspectRatio) / 2])
          } else {
            setPadding([(174 - 72 * imageAspectRatio) / 2, 0])
          }
        }
      }
    }
  }, [])

  return (
    <img
      crossOrigin="anonymous"
      ref={ref}
      src={src}
      alt="logo"
      className="ml-1 max-h-[72px] max-w-[174px]"
      style={{
        paddingLeft: `${paddingLeft}px`,
        paddingBottom: `${paddingBottom}px`,
      }}
    />
  )
})

export type EstimateSummaryData = {
  options: {
    name: string
    totalUsc: number
  }[]
}

export type InvoiceSummaryData = {
  issuedDate: IsoDateString
  serviceCompletionDate: LocalDateString
  dueDate: IsoDateString
  total: number
  paid: number
}

const isEstimateSummaryData = (
  data: EstimateSummaryData | InvoiceSummaryData,
): data is EstimateSummaryData => !!(data as EstimateSummaryData).options

type DownloadableInvoiceProps = React.PropsWithChildren<{
  jobGuid: JobGuid
  displayId: string
  issuedDate: IsoDateString
  messageHtml?: HtmlString
  summaryData: EstimateSummaryData | InvoiceSummaryData
  midBannerText?: React.ReactNode
  downloadableElementRef: React.RefObject<HTMLDivElement>
  debugMode?: boolean
  disclaimer?: string
}>

export const useInvoiceBillingInfo = (companyGuid: string, jobGuid: string) => {
  const [
    { data: relevantInvoiceData, fetching: fetchingRelevantData },
    fetchInvoiceData,
  ] = useQuery({
    query: DOWNLOADABLE_INVOICE_DATA,
    variables: {
      companyGuid,
      jobGuid,
    },
  })

  const companyAddressDisplay = useMemo(() => {
    const address = relevantInvoiceData?.billingProfilesByPk?.businessAddress
    if (address) {
      const items: string[] = []
      items.push(address.line1)
      if (address.line2) {
        items.push(address.line2)
      }
      items.push(
        `${address.city}, ${address.stateAbbreviation} ${BzAddress.getZip5(
          address,
        )}`,
      )
      return items.join(' • ')
    }
    return ''
  }, [relevantInvoiceData?.billingProfilesByPk?.businessAddress])

  const companyInfoDisplay = useMemo(() => {
    const items: string[] = []
    const phoneNumber =
      relevantInvoiceData?.billingProfilesByPk?.phoneNumber.phoneNumber
    if (phoneNumber) {
      items.push(phoneUtils.tryFormat(phoneNumber))
    }
    const email =
      relevantInvoiceData?.billingProfilesByPk?.emailAddress.emailAddress
    if (email) {
      items.push(email)
    }
    const website = relevantInvoiceData?.billingProfilesByPk?.websiteDisplayName
    if (website) {
      items.push(website)
    }

    const contractorLicenseNumber =
      relevantInvoiceData?.billingProfilesByPk?.contractorLicenseNumber
    if (contractorLicenseNumber) {
      items.push(`CL # ${contractorLicenseNumber}`.trim())
    }
    return items.join(' • ')
  }, [
    relevantInvoiceData?.billingProfilesByPk?.emailAddress.emailAddress,
    relevantInvoiceData?.billingProfilesByPk?.phoneNumber.phoneNumber,
    relevantInvoiceData?.billingProfilesByPk?.websiteDisplayName,
    relevantInvoiceData?.billingProfilesByPk?.contractorLicenseNumber,
  ])

  const { serviceAddress, billingAddress } = useMemo(
    () =>
      relevantInvoiceData?.jobsByPk
        ? {
            serviceAddress: {
              contactName:
                relevantInvoiceData.jobsByPk.pointOfContact.fullName ?? '',
              address: relevantInvoiceData.jobsByPk.location.address,
            },
            billingAddress: {
              contactName:
                relevantInvoiceData.jobsByPk.account.primaryContact[0].contact
                  .fullName ?? '',
              address:
                relevantInvoiceData.jobsByPk.account.mailingAddress ??
                relevantInvoiceData.jobsByPk.account.firstLocation[0].location
                  .address,
            },
          }
        : {},
    [relevantInvoiceData?.jobsByPk],
  )

  const addressesMatch = useMemo(
    () =>
      serviceAddress &&
      billingAddress &&
      BzAddress.makeCanonicalFullAddress(serviceAddress.address) ===
        BzAddress.makeCanonicalFullAddress(billingAddress.address),
    [billingAddress, serviceAddress],
  )

  const businessFullLegalName = useMemo(
    () => relevantInvoiceData?.billingProfilesByPk?.businessFullLegalName,
    [relevantInvoiceData?.billingProfilesByPk?.businessFullLegalName],
  )

  const companyLogoUrl = useMemo(
    () => relevantInvoiceData?.billingProfilesByPk?.logoUrl,
    [relevantInvoiceData?.billingProfilesByPk?.logoUrl],
  )

  return {
    companyAddressDisplay,
    companyInfoDisplay,
    addressesMatch,
    serviceAddress,
    billingAddress,
    businessFullLegalName,
    companyLogoUrl,
    fetching: fetchingRelevantData,
    fetchInvoiceData,
  }
}

export type InvoiceBillingInfo = Omit<
  ReturnType<typeof useInvoiceBillingInfo>,
  'fetching'
>

export const DownloadableInvoice = React.memo<DownloadableInvoiceProps>(
  ({
    jobGuid,
    displayId,
    issuedDate,
    summaryData,
    messageHtml,
    midBannerText,
    children,
    downloadableElementRef,
    debugMode,
    disclaimer,
  }) => {
    const companyGuid = useExpectedCompanyGuid()
    const tzId = useExpectedCompanyTimeZoneId()

    const {
      companyAddressDisplay,
      companyInfoDisplay,
      addressesMatch,
      serviceAddress,
      billingAddress,
      businessFullLegalName,
      companyLogoUrl,
      fetching: fetchingRelevantData,
    } = useInvoiceBillingInfo(companyGuid, jobGuid)

    return createPortal(
      <div
        className={classNames(
          'border-5 fixed top-0 w-[754px] max-w-[754px] border-dashed border-black',
          debugMode
            ? 'left-0 z-[10000] max-h-screen overflow-auto'
            : 'left-[-754px]',
        )}
      >
        <div
          className="w-full bg-white p-10 text-sm text-bz-gray-1000"
          ref={downloadableElementRef}
          style={{
            fontFamily: DEFAULT_FONT_FAMILY,
          }}
        >
          {fetchingRelevantData && (
            <div className={DOWNLOADABLE_INVOICE_DATA_FETCHING_CLASS_NAME} />
          )}
          <div
            className={classNames(
              'flex flex-row pb-5',
              DASHED_BOTTOM_CLASS_NAME,
            )}
          >
            <div className="flex flex-1 flex-col">
              <div className="mb-2 text-xl font-bold">
                {businessFullLegalName}
              </div>
              <div>
                {companyAddressDisplay && <div>{companyAddressDisplay}</div>}
                {companyInfoDisplay && <div>{companyInfoDisplay}</div>}
              </div>
            </div>
            <InvoiceLogo src={companyLogoUrl} />
          </div>
          <div className="mt-4 flex flex-row items-start *:min-w-[0]">
            {serviceAddress &&
              billingAddress &&
              (
                [
                  [
                    'Service Address',
                    serviceAddress.contactName,
                    addressToLines(serviceAddress.address),
                  ],
                  [
                    'Billing Address',
                    addressesMatch ? undefined : billingAddress.contactName,
                    addressesMatch
                      ? ['(Same as service)']
                      : addressToLines(billingAddress.address),
                  ],
                ] as const
              ).map(([title, contactName, addressLines]) => (
                <div key={title} className="flex-1 leading-5">
                  <div className="mb-2 text-base font-bold">{title}</div>
                  {contactName && (
                    <div className="font-bold">{contactName}</div>
                  )}
                  {addressLines.map(line => (
                    <div key={line}>{line}</div>
                  ))}
                </div>
              ))}
            <div className="w-[275px] rounded-md border-2 border-solid border-bz-gray-400">
              <div className="bg-bz-gray-300 px-3 py-2 text-base font-bold">
                {isEstimateSummaryData(summaryData) ? 'Estimate' : 'Invoice'} #
                {displayId}
              </div>
              <div className="grid grid-cols-[1fr_auto] gap-y-2 px-3 py-3">
                <div className="font-bold text-bz-gray-700">Issued Date</div>
                <div className="text-right">
                  {BzDateFns.formatFromISO(issuedDate, 'MMM d, yyyy', tzId)}
                </div>
                <div
                  className={classNames('col-span-2', DASHED_BOTTOM_CLASS_NAME)}
                />
                {(summaryData as EstimateSummaryData).options.map(
                  ({ name, totalUsc }, i) => (
                    <React.Fragment key={name + totalUsc + i}>
                      <EstimateSummaryOptionName index={i} name={name} />
                      <div className="text-right">{formatUsc(totalUsc)}</div>
                    </React.Fragment>
                  ),
                )}
              </div>
            </div>
          </div>
          {messageHtml && (
            <div>
              <div className="text-base font-bold">Message</div>
              <div>
                <HtmlRenderer htmlContent={messageHtml} />
              </div>
            </div>
          )}
          {midBannerText && (
            <div className="mx-[-40px] mt-4 bg-bz-gray-700 py-2 text-center text-base text-bz-gray-100">
              {midBannerText}
            </div>
          )}
          <div>{children}</div>
          {disclaimer ? (
            <>
              <div className="my-6 h-2 w-full bg-[rgba(0,0,0,0.04)]" />
              <div className="mb-2 text-base font-semibold">
                Terms & Conditions
              </div>
              <div className="text-xs text-bz-gray-1000">{disclaimer}</div>
            </>
          ) : null}
        </div>
      </div>,
      document.body,
    )
  },
)
