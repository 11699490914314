import { JobsV2Class, UserGuid } from '@breezy/shared'
import { IsoDateString } from '@breezy/shared/src/common/BzDateFns'
import { InvoiceV2Status } from '@breezy/shared/src/domain/InvoicesV2/InvoicesV2'
import { CompanyLeadSourceGuid } from '@breezy/shared/src/domain/LeadSources/CompanyLeadSource'
import React from 'react'
import { UsePaginationReturnType } from '../../adam-components/Pagination/usePagination'
import {
  InvoiceListingDataContextType,
  SortType,
  UserMap,
} from './InvoiceListPageTypes'

type InvoiceListingContextType = {
  pagination: UsePaginationReturnType
  userMap: UserMap
  hasFilters: boolean
  searchTerm: string
  sortType: SortType
  startDate?: IsoDateString
  endDate?: IsoDateString
  selectedJobClasses: JobsV2Class[]
  selectedV2Statuses: InvoiceV2Status[]
  selectedUserGuids: UserGuid[]
  selectedCompanyLeadSourceGuids: CompanyLeadSourceGuid[]
  selectedAccountManagerUserGuids: UserGuid[]
  selectedBusinessUnitGuids: string[]
  showMPInvoices: boolean
}

export const InvoiceListingContext = React.createContext<
  InvoiceListingContextType | undefined
>(undefined)

export const InvoiceListingDataContext = React.createContext<
  InvoiceListingDataContextType | undefined
>(undefined)
