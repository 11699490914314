import {
  DayOfTheWeek,
  Guid,
  hasTechnicianRole,
  InstantBookingWeeklySchedule,
  OnlineBookingServiceTypeSettingsConfig,
} from '@breezy/shared'
import React from 'react'

import { BookableArrivalWindow } from '@breezy/shared'
import { useQuery } from 'urql'
import { GqlMultiQueryLoader } from '../../components/GqlQueryLoader/GqlQueryLoader'
import { DocumentType, gql } from '../../generated'
import {
  CompanyArrivalWindowOption,
  CompanyJobType,
  CompanyTechnician,
  WithJobClass,
} from './utils'

const FETCH_ONLINE_BOOKING_SERVICE_TYPE_CONFIG_QUERY = gql(/* GraphQL */ `
  query FetchOnlineBookingServiceTypeConfig(
    $onlineServiceTypeConfigGuid: uuid!
  ) {
    onlineBookingServiceTypeConfigsByPk(
      onlineBookingServiceTypeGuid: $onlineServiceTypeConfigGuid
    ) {
      onlineBookingServiceTypeGuid
      companyGuid
      serviceType
      bookingType
      earliestAvailability
      latestAvailability
      legalBlurbHtml
      bookableJobTypes {
        jobTypeGuid
      }
      bookableTechnicians {
        technicianGuid
      }
      instantBookingWeeklyScheduleEntries {
        dayOfWeek
        isEnabled
        bookableArrivalWindows {
          arrivalWindow {
            companyAppointmentArrivalWindowGuid
            arrivalWindowStartTime
            arrivalWindowEndTime
          }
        }
      }
      company {
        companyUsers(where: { user: { deactivatedAt: { _isNull: true } } }) {
          userByUserGuid {
            userGuid
            fullName
            deactivatedAt
            userRoles {
              roleById {
                role
              }
            }
          }
        }
        companyAppointmentArrivalWindows {
          companyAppointmentArrivalWindowGuid
          jobClass
          arrivalWindowStartTime
          arrivalWindowEndTime
        }
        jobTypes(where: { archivedAt: { _isNull: true } }) {
          jobTypeGuid
          jobClass
          name
        }
      }
    }
  }
`)

type FetchOnlineBookingServiceTypeConfigQuery = DocumentType<
  typeof FETCH_ONLINE_BOOKING_SERVICE_TYPE_CONFIG_QUERY
>

const convertQueryToBookableArrivalWindow = (
  data: NonNullable<
    FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk']
  >['instantBookingWeeklyScheduleEntries'][number]['bookableArrivalWindows'][number],
): BookableArrivalWindow => {
  return {
    companyAppointmentArrivalWindowGuid:
      data.arrivalWindow.companyAppointmentArrivalWindowGuid,
    arrivalWindowStartTime: data.arrivalWindow.arrivalWindowStartTime,
    arrivalWindowEndTime: data.arrivalWindow.arrivalWindowEndTime,
  }
}

const getWeeklyScheduleDayEnabled = (
  data: NonNullable<
    FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk']
  >['instantBookingWeeklyScheduleEntries'],
  dayOfWeek: DayOfTheWeek,
) => {
  return data.find(entry => entry.dayOfWeek === dayOfWeek)?.isEnabled ?? false
}

const getWeeklyScheduleDayBookableArrivalWindows = (
  data: NonNullable<
    FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk']
  >['instantBookingWeeklyScheduleEntries'],
  dayOfWeek: DayOfTheWeek,
) => {
  return (
    data
      .find(entry => entry.dayOfWeek === dayOfWeek)
      ?.bookableArrivalWindows.map(ba =>
        convertQueryToBookableArrivalWindow(ba),
      ) ?? []
  )
}

const convertQueryToInstantBookingWeeklySchedule = (
  data: NonNullable<
    FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk']
  >['instantBookingWeeklyScheduleEntries'],
): InstantBookingWeeklySchedule => {
  return {
    Sunday: {
      enabled: getWeeklyScheduleDayEnabled(data, 'Sunday'),
      bookableArrivalWindows: getWeeklyScheduleDayBookableArrivalWindows(
        data,
        'Sunday',
      ),
    },
    Monday: {
      enabled: getWeeklyScheduleDayEnabled(data, 'Monday'),
      bookableArrivalWindows: getWeeklyScheduleDayBookableArrivalWindows(
        data,
        'Monday',
      ),
    },
    Tuesday: {
      enabled: getWeeklyScheduleDayEnabled(data, 'Tuesday'),
      bookableArrivalWindows: getWeeklyScheduleDayBookableArrivalWindows(
        data,
        'Tuesday',
      ),
    },
    Wednesday: {
      enabled: getWeeklyScheduleDayEnabled(data, 'Wednesday'),
      bookableArrivalWindows: getWeeklyScheduleDayBookableArrivalWindows(
        data,
        'Wednesday',
      ),
    },
    Thursday: {
      enabled: getWeeklyScheduleDayEnabled(data, 'Thursday'),
      bookableArrivalWindows: getWeeklyScheduleDayBookableArrivalWindows(
        data,
        'Thursday',
      ),
    },
    Friday: {
      enabled: getWeeklyScheduleDayEnabled(data, 'Friday'),
      bookableArrivalWindows: getWeeklyScheduleDayBookableArrivalWindows(
        data,
        'Friday',
      ),
    },
    Saturday: {
      enabled: getWeeklyScheduleDayEnabled(data, 'Saturday'),
      bookableArrivalWindows: getWeeklyScheduleDayBookableArrivalWindows(
        data,
        'Saturday',
      ),
    },
  }
}

const convertQueryToOnlineBookingServiceTypeConfig = (
  data: FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk'],
): OnlineBookingServiceTypeSettingsConfig => {
  return {
    onlineBookingServiceTypeGuid: data?.onlineBookingServiceTypeGuid ?? '',
    companyGuid: data?.companyGuid ?? '',
    serviceType: data?.serviceType ?? 'MAINTENANCE',
    bookingType: data?.bookingType ?? 'REQUEST',
    earliestAvailability: data?.earliestAvailability ?? '1 day',
    latestAvailability: data?.latestAvailability ?? '60 days',
    legalBlurbHtml: data?.legalBlurbHtml ?? '',
    bookableJobTypeGuids:
      data?.bookableJobTypes.map(jobType => jobType.jobTypeGuid) ?? [],
    bookableTechnicianGuids:
      data?.bookableTechnicians.map(technician => technician.technicianGuid) ??
      [],
    instantBookingWeeklySchedule: convertQueryToInstantBookingWeeklySchedule(
      data?.instantBookingWeeklyScheduleEntries ?? [],
    ),
  }
}

const convertQueryToCompanyJobTypes = (
  data: NonNullable<
    FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk']
  >['company']['jobTypes'],
): CompanyJobType[] => {
  return data.map(jobType => ({
    jobTypeGuid: jobType.jobTypeGuid,
    jobClass: jobType.jobClass,
    displayName: jobType.name,
  }))
}

const convertQueryToCompanyTechnicians = (
  data: NonNullable<
    FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk']
  >['company']['companyUsers'],
): CompanyTechnician[] => {
  return data
    .filter(user =>
      hasTechnicianRole(
        user.userByUserGuid.userRoles.map(r => r.roleById.role),
      ),
    )
    .map(user => ({
      technicianGuid: user.userByUserGuid.userGuid,
      fullName: user.userByUserGuid.fullName,
      roles: user.userByUserGuid.userRoles.map(r => r.roleById.role),
      deactivatedAt: user.userByUserGuid.deactivatedAt,
    }))
}

const convertQueryToCompanyArrivalWindowOptions = (
  data: NonNullable<
    FetchOnlineBookingServiceTypeConfigQuery['onlineBookingServiceTypeConfigsByPk']
  >['company']['companyAppointmentArrivalWindows'],
): WithJobClass<CompanyArrivalWindowOption>[] => {
  return data.map(arrivalWindow => ({
    arrivalWindowGuid: arrivalWindow.companyAppointmentArrivalWindowGuid,
    jobClass: arrivalWindow.jobClass,
    arrivalWindowStartTime: arrivalWindow.arrivalWindowStartTime,
    arrivalWindowEndTime: arrivalWindow.arrivalWindowEndTime,
  }))
}

const useFetchOnlineBookingServiceTypeConfig = (
  onlineServiceTypeConfigGuid: Guid,
) => {
  const companyInfoQuery = useQuery({
    query: FETCH_ONLINE_BOOKING_SERVICE_TYPE_CONFIG_QUERY,
    variables: {
      onlineServiceTypeConfigGuid,
    },
  })

  return companyInfoQuery
}

type WithOnlineBookingServiceTypeConfigProps = {
  onlineServiceTypeConfigGuid: Guid
  loadingComponent?: JSX.Element
  render: (
    companyInfo: OnlineBookingServiceTypeSettingsConfig | undefined,
    companyJobTypes: CompanyJobType[],
    companyTechnicians: CompanyTechnician[],
    companyArrivalWindowOptions: CompanyArrivalWindowOption[],
  ) => JSX.Element
}

export const WithOnlineBookingServiceTypeConfig =
  React.memo<WithOnlineBookingServiceTypeConfigProps>(
    ({ onlineServiceTypeConfigGuid, render, loadingComponent }) => {
      const companyInfoQuery = useFetchOnlineBookingServiceTypeConfig(
        onlineServiceTypeConfigGuid,
      )

      return (
        <GqlMultiQueryLoader
          queries={[companyInfoQuery]}
          loadingComponent={loadingComponent}
          idleComponent={loadingComponent}
          render={data => {
            if (!data[0].onlineBookingServiceTypeConfigsByPk) {
              return <></>
            }

            return render(
              convertQueryToOnlineBookingServiceTypeConfig(
                data[0].onlineBookingServiceTypeConfigsByPk,
              ),
              convertQueryToCompanyJobTypes(
                data[0].onlineBookingServiceTypeConfigsByPk.company.jobTypes,
              ),
              convertQueryToCompanyTechnicians(
                data[0].onlineBookingServiceTypeConfigsByPk.company
                  .companyUsers,
              ),
              convertQueryToCompanyArrivalWindowOptions(
                data[0].onlineBookingServiceTypeConfigsByPk.company
                  .companyAppointmentArrivalWindows,
              ),
            )
          }}
        />
      )
    },
  )
