import { z } from 'zod'
import { bzOptional } from '../common-schemas'

export const businessUnitSchema = z.object({
  name: z.string(),
  businessUnitGuid: z.string(),
  accountingIntegrationName: bzOptional(z.string()),
})

export type BusinessUnit = z.infer<typeof businessUnitSchema>
