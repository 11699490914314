import { z } from 'zod'
import { AsyncFn, BusinessException } from '../../../common'
import { guidSchema } from '../../../contracts/_common'
import { CompanyGuid, ForCompanyUser } from '../../Company/Company'
import { InvoiceV2TermSchema } from '../../InvoicesV2/InvoicesV2'
import { bzOptional } from '../../common-schemas'
import { AccountType } from '../AccountType'

export type AccountReferenceNumberGenerator = AsyncFn<CompanyGuid, string>

export const UpsertAccountDTOSchema = z.object({
  accountGuid: guidSchema,
  accountType: z.nativeEnum(AccountType),
  displayName: z.string(),
  accountTags: bzOptional(
    z
      .object({
        accountGuid: guidSchema,
        tagGuid: guidSchema,
      })
      .array(),
  ),
  accountManagerUserGuid: bzOptional(guidSchema),
  companyLeadSource: bzOptional(
    z.object({
      leadSourceGuid: bzOptional(guidSchema),
      leadSourceReferringContactGuid: bzOptional(guidSchema),
      leadSourceAttributionDescription: bzOptional(z.string()),
    }),
  ),
  doNotService: bzOptional(z.boolean()),
  doNotServiceReason: bzOptional(z.string()),
  defaultInvoiceTerm: bzOptional(InvoiceV2TermSchema),
})

export type UpsertAccountDTO = z.infer<typeof UpsertAccountDTOSchema>

export type AccountUpserter = AsyncFn<ForCompanyUser<UpsertAccountDTO>>

export class AccountReferenceNumberProvisionException extends BusinessException {}
