import { isNullish } from '@breezy/shared'
import { TeamMemberCell } from 'src/adam-components/ListPage/TeamMemberCell'
import { InvoiceListUser } from './InvoiceListPageTypes'

export interface InvoiceListPageTableTeamMemberCellProps {
  user?: InvoiceListUser
}

export const InvoiceListPageTableTeamMemberCell = (
  props: InvoiceListPageTableTeamMemberCellProps,
) => {
  if (isNullish(props.user)) {
    return null
  }

  return (
    <TeamMemberCell
      user={{
        deactivatedAt: props.user.deactivatedAt,
        userGuid: props.user.userGuid,
        userRoles: props.user.userRoles,
        firstName: props.user.firstName,
        lastName: props.user.lastName,
        avatarImageUrl: props.user.avatarPhoto?.cdnUrl,
        avatarColor: props.user.userPrimaryHueHex,
      }}
    />
  )
}
