import React from 'react'
import PersonAvatar from '../PersonAvatar/PersonAvatar'
import { TechnicianResource } from '../Scheduler/scheduleUtils'

const AVATAR_SPACING = 8
const AVATAR_SIZE = 24

type StackedAvatarsProps = {
  users: Pick<
    TechnicianResource,
    | 'avatarColor'
    | 'avatarShortString'
    | 'userGuid'
    | 'deactivatedAt'
    | 'avatarImageUrl'
  >[]
  onAvatarClick?: () => void
}

export const StackedAvatars = React.memo<StackedAvatarsProps>(
  ({ users, onAvatarClick }) => {
    const avatars: React.ReactNode[] = []

    for (const tech of users) {
      avatars.push(
        <div
          key={tech.userGuid}
          className="relative inline-block"
          style={{
            width: `${AVATAR_SPACING}px`,
            height: `${AVATAR_SIZE}px`,
          }}
        >
          <span className="absolute right-0">
            <PersonAvatar
              userGuid={tech.userGuid}
              deactivatedAt={tech.deactivatedAt}
              size={AVATAR_SIZE}
              fontSize={9}
              avatarAltShortString={tech.avatarShortString}
              avatarAltColor={tech.avatarColor}
              avatarImgSrc={tech.avatarImageUrl}
            />
          </span>
        </div>,
      )
    }
    return (
      <div
        className="relative overflow-hidden whitespace-nowrap"
        style={{
          minHeight: `${AVATAR_SIZE}px`,
          minWidth: `${AVATAR_SIZE + (users.length - 1) * AVATAR_SPACING}px`,
        }}
        onClick={e => {
          e.stopPropagation()
          onAvatarClick?.()
        }}
      >
        <div className="absolute right-0 text-right">{avatars}</div>
      </div>
    )
  },
)
