import {
  BusinessResourceConflictException,
  BusinessResourceDoesNotExistException,
  Event,
  EventPayload,
  IEventStore,
  InvalidEntityStateException,
  IsoDateString,
  LocalDateString,
  MissingCaseError,
  Mutable,
  ReadOnlyEventStore,
  bzExpect,
  nextGuid,
} from '../../../common'

import { AccountGuid } from '../../Accounts/Account'
import { ForCompany } from '../../Company/Company'
import { BzDateTime, UTC_TIME_ZONE } from '../../DateTime/BzDateTime'
import { DiscountType } from '../../Discounts/DiscountTypes'
import { ForUser, UserGuid, UserGuidContainer } from '../../Users/User'
import { GuidAndReferenceNumber } from '../../common-schemas'
import { FinanceDocumentViewModel } from '../FinanceDocumentTypes'
import {
  CartDiscountSetEventTypeName,
  CartDiscountV2RemovedEventTypeName,
  CartDiscountV2UpsertEventTypeName,
  CartItem,
  CartItemGuidContainer,
  CartItemRemovedEventTypeName,
  CartItemSetEventData,
  CartItemSetEventTypeName,
  CreditSetEventData,
  DiscountGuidContainer,
  DiscountSetEventData,
  DiscountV2EventData,
  ICartEntity,
  InvoiceCreatedEventTypeName,
  LinkAddedEventTypeName,
  LinkRemovedEventTypeName,
  LinkedEntitiesEventData,
  TaxRateSetEventData,
  TransactionGuid,
  TransactionLinkMap,
  calculateCartOrderSummary,
  toV2Discount,
} from '../Transactions/TransactionTypes'
import {
  InvoiceAcceptedEventTypeName,
  InvoiceCreatedEventData,
  InvoiceCreationMetadata,
  InvoiceDisplayNameSetEventData,
  InvoiceDisplayNameSetEventTypeName,
  InvoiceEntityTypeName,
  InvoiceFullyPaidEventTypeName,
  InvoiceGuid,
  InvoiceGuidContainer,
  InvoicePresentedEventTypeName,
  InvoiceRefundedEventTypeName,
  InvoiceRejectedEventTypeName,
  InvoiceReminderSentEventTypeName,
  InvoiceSelfServePaymentLinkSentEventData,
  InvoiceSelfServePaymentLinkSentEventTypeName,
  InvoiceStatusContainer,
  InvoiceStatusDisplayMap,
  InvoiceStatuses,
  InvoiceSummarySetEventData,
  InvoiceSummarySetEventTypeName,
  InvoiceTerm,
  InvoiceViewModel,
  InvoiceVoidedEventTypeName,
  invoiceTermDays,
  isInvoiceDisplayNameSetEvent,
  isInvoiceSelfServePaymentLinkSentEvent,
  isInvoiceSummarySetEvent,
} from './InvoiceTypes'

export class Invoice implements ICartEntity {
  private internalModel: Mutable<InvoiceViewModel> | undefined
  // NOTE: Injectable for Testing
  private readonly clock: () => IsoDateString

  /** @deprecated */
  static createFinanceViewModeFromEvents = async (
    events: Event<unknown>[],
    entityReq: InvoiceGuidContainer,
  ): Promise<FinanceDocumentViewModel> => {
    const x = new Invoice(new ReadOnlyEventStore(events))
    return await x
      .load({ companyGuid: events[0].companyGuid, invoiceGuid: entityReq.invoiceGuid })
      .then(x => x.getComprehensiveViewModel())
      .then(vm => ({ ...vm, documentType: InvoiceEntityTypeName, invoiceMetadata: vm }))
  }

  /** @deprecated */
  static readViewModel = async (
    eventStore: IEventStore,
    req: ForCompany<InvoiceGuidContainer>,
  ): Promise<InvoiceViewModel> => await Invoice.load(eventStore, req).then(m => m.getComprehensiveViewModel())

  /** @deprecated */
  static load = async (eventStore: IEventStore, req: ForCompany<InvoiceGuidContainer>): Promise<Invoice> =>
    new Invoice(eventStore).load(req)

  /** @deprecated */
  static invoke = async (
    eventStore: IEventStore,
    req: ForCompany<InvoiceGuidContainer>,
    f: (mp: Invoice) => Promise<void>,
  ): Promise<void> => {
    return Invoice.load(eventStore, req).then(f)
  }

  constructor(private readonly eventStore: IEventStore, clockOverride?: () => IsoDateString) {
    this.clock = clockOverride ? clockOverride : () => new Date().toISOString()
  }

  // Queries

  /** @deprecated */
  isInitialized = (): boolean => !!this.internalModel

  /** @deprecated */
  getComprehensiveViewModel = (): InvoiceViewModel => {
    if (!this.internalModel) throw new BusinessResourceDoesNotExistException('Invoice is not initialized')

    return this.internalModel
  }

  /** @deprecated */
  getFinanceDocumentViewModel = (): FinanceDocumentViewModel => {
    const vm = this.getComprehensiveViewModel()
    return { ...vm, documentType: InvoiceEntityTypeName, invoiceMetadata: vm }
  }

  // Commands

  /** @deprecated */
  init = async ({
    companyGuid,
    userGuid,
    accountGuid,
    transactionGuid,
    transactionVersion,
    items,
    discount,
    taxRate,
    links,
    invoiceTerm,
    issuedAt,
    serviceCompletionDate,
    referenceNumber,
    displayId,
    credit,
    summary,
    displayName,
    invoiceGuid,
    discountsV2 = [],
  }: InvoiceCreationMetadata): Promise<GuidAndReferenceNumber> => {
    if (this.internalModel) throw new BusinessResourceConflictException('Invoice is already initialized')

    const effectiveInvoiceGuid = invoiceGuid ?? nextGuid()

    this.internalModel = this.createInitialViewModel({
      actingUserGuid: userGuid,
      invoiceGuid: effectiveInvoiceGuid,
      referenceNumber,
      displayId,
      invoiceVersion: 0,
      accountGuid,
      transactionGuid,
      companyGuid,
      transactionVersion,
      items,
      discount,
      taxRate,
      links,
      invoiceTerm,
      serviceCompletionDate,
      issuedAt: issuedAt ?? this.clock(),
      summary,
      displayName,
      discountsV2,
    })

    const e = this.createNextEvent<InvoiceCreatedEventData>({
      eventType: InvoiceCreatedEventTypeName,
      eventData: {
        accountGuid,
        transactionGuid,
        transactionVersion,
        items,
        discount,
        taxRate,
        links,
        referenceNumber,
        displayId,
        issuedAt,
        invoiceTerm,
        serviceCompletionDate,
        credit,
        summary,
        displayName,
        discountsV2,
      },
      userGuid,
    })

    // Here is where we set that issuedAt properly
    this.internalModel.issuedAt = e.occurredAt

    return await this.persistAndApplyEvent(e).then(() => ({
      guid: effectiveInvoiceGuid,
      referenceNumber,
    }))
  }

  /** @deprecated */
  load = async (req: ForCompany<InvoiceGuidContainer>): Promise<Invoice> => {
    this.requireNotInitialized()
    const events = await this.eventStore.read({ entityGuid: req.invoiceGuid, companyGuid: req.companyGuid })
    await events.map(this.applyEvent)
    return this
  }

  /** @deprecated */
  setItem = (input: ForUser<CartItemSetEventData>): Promise<void> =>
    this.applyEventIfInitialized(CartItemSetEventTypeName, input)

  /** @deprecated */
  removeItem = (input: ForUser<CartItemGuidContainer>): Promise<void> =>
    this.applyEventIfInitialized(CartItemRemovedEventTypeName, input)

  /** @deprecated Use upsertDiscountV2 and removeDiscountV2 instead */
  setDiscount = (input: ForUser<DiscountSetEventData>): Promise<void> =>
    this.applyEventIfInitialized(CartDiscountSetEventTypeName, input)

  /** @deprecated */
  upsertDiscountV2 = async (input: ForUser<DiscountV2EventData>): Promise<void> => {
    const currentDiscounts = this.internalModel?.discountsV2 || []
    const hasDifferentType = currentDiscounts.some(
      discount => discount.discountGuid !== input.discountGuid && discount.type !== input.type,
    )
    if (hasDifferentType) {
      const existingType = currentDiscounts[0].type
      const newType = input.type
      if (existingType === DiscountType.RATE && newType === DiscountType.FLAT) {
        throw new Error('Cannot apply a flat discount when a rate discount is already present')
      }
      if (existingType === DiscountType.FLAT && newType === DiscountType.RATE) {
        throw new Error('Cannot apply a rate discount when a flat discount is already present')
      }
      throw new Error('Cannot upsert discount with a different type from existing discounts')
    }
    if (
      currentDiscounts.some(
        discount => discount.discountGuid !== input.discountGuid && discount.type === DiscountType.RATE,
      )
    ) {
      throw new Error('Cannot apply more than one rate discount')
    }

    return this.applyEventIfInitialized(CartDiscountV2UpsertEventTypeName, input)
  }

  /** @deprecated */
  removeDiscountV2 = (input: ForUser<DiscountGuidContainer>): Promise<void> =>
    this.applyEventIfInitialized(CartDiscountV2RemovedEventTypeName, input)

  /** @deprecated */
  linkTo = (input: ForUser<LinkedEntitiesEventData>) => this.applyEventIfInitialized(LinkAddedEventTypeName, input)

  /** @deprecated */
  unlinkFrom = (input: ForUser<LinkedEntitiesEventData>) =>
    this.applyEventIfInitialized(LinkRemovedEventTypeName, input)

  /** @deprecated */
  markStatus = (input: ForUser<InvoiceStatusContainer>) => {
    switch (input.status) {
      case InvoiceStatuses.PRESENTED:
        return this.markPresented(input)
      case InvoiceStatuses.ACCEPTED:
        return this.markAccepted(input)
      case InvoiceStatuses.REJECTED:
        return this.markRejected(input)
      case InvoiceStatuses.FULLY_PAID:
        return this.markFullyPaid(input)
      case InvoiceStatuses.VOID:
        return this.markVoid(input)
      case InvoiceStatuses.REFUNDED:
        return this.markRefunded(input)
      default:
        throw new MissingCaseError(input.status)
    }
  }

  /** @deprecated */
  markPresented = (input: UserGuidContainer) => this.applyEventIfNotFullyPaid(InvoicePresentedEventTypeName, input)

  /** @deprecated */
  markAccepted = (input: UserGuidContainer) => this.applyEventIfNotFullyPaid(InvoiceAcceptedEventTypeName, input)

  /** @deprecated */
  markRejected = (input: UserGuidContainer) => this.applyEventIfNotFullyPaid(InvoiceRejectedEventTypeName, input)

  /** @deprecated */
  markFullyPaid = async (input: UserGuidContainer): Promise<void> => {
    if (this.internalModel?.status !== InvoiceStatuses.FULLY_PAID) {
      await this.applyEventIfInitialized(InvoiceFullyPaidEventTypeName, input)
    }
  }

  /** @deprecated */
  markRefunded = (input: UserGuidContainer) => this.applyEventIfInitialized(InvoiceRefundedEventTypeName, input)

  /** @deprecated */
  markVoid = (input: UserGuidContainer) => this.applyEventIfInitialized(InvoiceVoidedEventTypeName, input)

  /** @deprecated */
  sendSelfServePaymentLink = (input: ForUser<InvoiceSelfServePaymentLinkSentEventData>) =>
    this.applyEventIfInitialized(InvoiceSelfServePaymentLinkSentEventTypeName, input)

  /** @deprecated */
  sendReminder = (input: UserGuidContainer) => this.applyEventIfInitialized(InvoiceReminderSentEventTypeName, input)

  /** @deprecated */
  setSummary = (input: ForUser<InvoiceSummarySetEventData>) =>
    this.applyEventIfInitialized(InvoiceSummarySetEventTypeName, input)

  /** @deprecated */
  setDisplayName = (input: ForUser<InvoiceDisplayNameSetEventData>) =>
    this.applyEventIfInitialized(InvoiceDisplayNameSetEventTypeName, input)

  // Internal

  private applyEvent = (e: Event<unknown>): void => {
    if (e.eventType === InvoiceCreatedEventTypeName) {
      const te = e as Event<InvoiceCreatedEventData>
      this.internalModel = this.createInitialViewModel({
        actingUserGuid: te.actingUserGuid,
        invoiceVersion: e.entityVersion,
        invoiceGuid: e.entityGuid,
        companyGuid: e.companyGuid,
        accountGuid: te.eventData.accountGuid,
        transactionGuid: te.eventData.transactionGuid,
        transactionVersion: te.eventData.transactionVersion,
        items: te.eventData.items,
        discount: te.eventData.discount,
        taxRate: te.eventData.taxRate,
        links: te.eventData.links,
        referenceNumber: te.eventData.referenceNumber,
        displayId: te.eventData.displayId?.toString(),
        invoiceTerm: te.eventData.invoiceTerm,
        serviceCompletionDate: te.eventData.serviceCompletionDate,
        credit: te.eventData.credit,
        issuedAt: e.occurredAt,
        summary: te.eventData.summary,
        displayName: te.eventData.displayName,
        discountsV2: te.eventData.discountsV2,
      })
      if (
        !te.eventData.discountsV2?.length &&
        ((te.eventData.discount.discountAmountUsd ?? 0) !== 0 || (te.eventData.discount.discountRate ?? 0) !== 0)
      ) {
        this.internalModel.discountsV2 = [toV2Discount(te.eventData.discount)]
      }

      this.updateOrderSummary()

      return
    }
    if (!this.internalModel) throw new BusinessResourceDoesNotExistException('Invoice is not initialized')

    const vm = this.internalModel
    vm.invoiceVersion = e.entityVersion > vm.invoiceVersion ? e.entityVersion : vm.invoiceVersion
    if (e.eventType === CartItemSetEventTypeName) {
      const te = e as Event<CartItem>
      vm.items = vm.items.filter(i => i.itemGuid !== te.eventData.itemGuid).concat([te.eventData])
      vm.lastAlteredAt = e.occurredAt
      this.updateOrderSummary()
    } else if (e.eventType === CartItemRemovedEventTypeName) {
      const te = e as Event<CartItemGuidContainer>
      vm.items = vm.items.filter(i => i.itemGuid !== te.eventData.itemGuid)
      vm.lastAlteredAt = e.occurredAt
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountSetEventTypeName) {
      const te = e as Event<DiscountSetEventData>
      vm.discount = te.eventData
      if ((te.eventData.discountAmountUsd ?? 0) !== 0 || (te.eventData.discountRate ?? 0) !== 0) {
        vm.discountsV2 = [toV2Discount(te.eventData)]
      }
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountV2UpsertEventTypeName) {
      const te = e as Event<DiscountV2EventData>
      const existingIndex = vm.discountsV2.findIndex(d => d.discountGuid === te.eventData.discountGuid)
      if (existingIndex !== -1) {
        vm.discountsV2[existingIndex] = te.eventData
      } else {
        vm.discountsV2.push(te.eventData)
      }
      this.updateOrderSummary()
    } else if (e.eventType === CartDiscountV2RemovedEventTypeName) {
      const te = e as Event<DiscountGuidContainer>
      const existingIndex = vm.discountsV2.findIndex(d => d.discountGuid === te.eventData.discountGuid)
      if (existingIndex !== -1) {
        vm.discountsV2.splice(existingIndex, 1)
      }
      this.updateOrderSummary()
    } else if (Object.keys(InvoiceStatusDisplayMap).includes(e.eventType)) {
      vm.status = InvoiceStatusDisplayMap[e.eventType]
    } else if (e.eventType === LinkAddedEventTypeName) {
      const te = e as Event<LinkedEntitiesEventData>
      for (const entity of te.eventData.entities) {
        vm.links[entity.guidType] = entity.guid
      }
    } else if (e.eventType === LinkRemovedEventTypeName) {
      const te = e as Event<LinkedEntitiesEventData>
      for (const entity of te.eventData.entities) {
        // validate that we are unlinking the right Guid
        if (vm.links[entity.guidType] === entity.guid) vm.links[entity.guidType] = undefined
      }
    } else if (isInvoiceSummarySetEvent(e)) {
      vm.summary = e.eventData.summary
      vm.lastAlteredAt = e.occurredAt
      this.updateOrderSummary()
    } else if (isInvoiceDisplayNameSetEvent(e)) {
      vm.displayName = e.eventData.displayName
      this.updateOrderSummary()
    } else if (isInvoiceSelfServePaymentLinkSentEvent(e)) {
      vm.selfServePaymentLinkSentAt = e.occurredAt
      vm.previousSelfServePaymentLink = e.eventData.paymentLink
      // When a payment link is sent, we reset the reminders
      vm.remindersSent = 0
    } else if (e.eventType === InvoiceReminderSentEventTypeName) {
      vm.remindersSent += 1
    } else {
      throw new MissingCaseError(`Unknown event type: ${e.eventType}`)
    }
  }

  private updateOrderSummary = () => {
    if (!this.internalModel) throw new BusinessResourceDoesNotExistException('Invoice is not initialized')
    const vm = this.internalModel

    const discountType = vm.discountsV2.reduce((_, curr) => curr.type, DiscountType.FLAT)
    if (discountType === DiscountType.FLAT) {
      vm.discount = vm.discountsV2.reduce(
        (acc, curr) => {
          if (curr.type === DiscountType.FLAT) {
            return {
              type: DiscountType.FLAT,
              discountAmountUsd: (acc.discountAmountUsd || 0) + (curr.discountAmountUsd || 0),
              discountRate: null,
            }
          }
          return acc
        },
        { type: DiscountType.FLAT, discountAmountUsd: 0, discountRate: null },
      )
    }
    if (discountType === DiscountType.RATE) {
      vm.discount = vm.discountsV2.reduce(
        (acc, curr) => {
          if (curr.type === DiscountType.RATE) {
            return {
              type: DiscountType.RATE,
              discountRate: (acc.discountRate || 0) + (curr.discountRate || 0),
              discountAmountUsd: null,
            }
          }
          return acc
        },
        { type: DiscountType.RATE, discountRate: 0, discountAmountUsd: null },
      )
    }

    const cartUpdate = calculateCartOrderSummary(vm)
    const isFullyPaid = vm.items.length > 0 && cartUpdate.totalPriceUsd === 0

    this.internalModel = {
      ...vm,
      ...cartUpdate,
      status: isFullyPaid ? InvoiceStatuses.FULLY_PAID : vm.status,
    }
  }

  private requireNotInitialized = (): void => {
    if (this.isInitialized()) throw new BusinessResourceConflictException('Invoice is already initialized')
  }

  private requireInitialized = (): void => {
    if (!this.isInitialized()) throw new BusinessResourceConflictException('Invoice is not initialized')
  }

  private applyEventIfNotFullyPaid = async <TEventData>(
    eventType: string,
    userInput: ForUser<TEventData>,
  ): Promise<void> => {
    if (this.internalModel?.status === InvoiceStatuses.FULLY_PAID) {
      throw new InvalidEntityStateException('Invoice is already fully paid')
    }

    return this.applyEventIfInitialized(eventType, userInput)
  }

  private applyEventIfInitialized = async <TEventData>(
    eventType: string,
    userInput: ForUser<TEventData>,
  ): Promise<void> => {
    this.requireInitialized()
    const e = this.createNextEvent<TEventData>({ eventType, eventData: userInput, userGuid: userInput.userGuid })
    return this.persistAndApplyEvent(e)
  }

  private persistAndApplyEvent = async (e: Event<unknown>): Promise<void> => {
    await this.eventStore.create(e)
    this.applyEvent(e)
  }

  private createInitialViewModel = ({
    actingUserGuid,
    companyGuid,
    invoiceGuid,
    invoiceVersion,
    accountGuid,
    transactionGuid,
    transactionVersion,
    items,
    discount,
    taxRate,
    links,
    referenceNumber,
    displayId,
    invoiceTerm,
    serviceCompletionDate,
    credit,
    issuedAt,
    summary,
    displayName,
    discountsV2 = [],
  }: {
    actingUserGuid: UserGuid
    invoiceGuid: InvoiceGuid
    companyGuid: string
    invoiceVersion: number
    accountGuid: AccountGuid
    transactionGuid: TransactionGuid
    transactionVersion: number
    items: CartItem[]
    discount: DiscountSetEventData
    taxRate: TaxRateSetEventData
    links: TransactionLinkMap
    displayId: string
    referenceNumber: string
    invoiceTerm: InvoiceTerm
    serviceCompletionDate?: LocalDateString
    credit?: CreditSetEventData
    issuedAt: IsoDateString
    summary?: string
    displayName?: string
    discountsV2?: DiscountV2EventData[]
  }): InvoiceViewModel => ({
    originatingUserGuid: actingUserGuid,
    invoiceGuid,
    invoiceVersion,
    referenceNumber,
    displayId,
    accountGuid,
    transactionGuid,
    transactionVersion,
    companyGuid,
    items,
    discount,
    discountsV2,
    credit,
    taxRate,
    links,
    invoiceTerm,
    issuedAt,
    dueAt: BzDateTime.fromIsoString(issuedAt, UTC_TIME_ZONE).plusDays(invoiceTermDays[invoiceTerm]).toIsoDateString(),
    serviceCompletionDate,
    subtotalPriceUsd: 0,
    totalPriceUsd: 0,
    discountAmountUsd: 0,
    creditAmountUsd: 0,
    taxAmountUsd: 0,
    status: InvoiceStatuses.CREATED,
    targetDocumentType: 'Invoice',
    lastAlteredAt: issuedAt,
    summary,
    displayName,
    remindersSent: 0,
  })

  private createNextEvent = <TEventData>({
    eventType,
    eventData,
    userGuid,
  }: ForUser<EventPayload<TEventData>>): Event<TEventData> => {
    const { companyGuid, invoiceVersion, invoiceGuid } = bzExpect(this.internalModel, 'Invoice internal model')
    const nextVersion = invoiceVersion + 1

    return {
      companyGuid,
      entityType: InvoiceEntityTypeName,
      entityGuid: invoiceGuid,
      entityVersion: nextVersion,
      actingUserGuid: userGuid,
      occurredAt: this.clock(),
      eventType,
      eventData,
    }
  }
}
